import React from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { bellIcon, leftArrowIcon } from "./assets";
import CircularProgress from '@mui/material/CircularProgress';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCircularProgressBar = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.progressCon}>
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        {this.renderCircularProgressBar()}
      </Box>
    )
  }

  renderNotifications = () => {
    if(this.state.notificationsList.length === 0 && this.state.isNotificationsLoading) {
      return this.renderCircularProgressBar();
    }

    let lastDate: string | null = "";
    const renderData = this.state.notificationsList.length > 0 ?
      this.state.notificationsList.map((notification,idIndex) => {
       const dateString = this.getLastDate(notification.attributes.created_at);
       const showDate = lastDate !== dateString;
       lastDate = dateString;
        return (
          <div 
            ref={this.state.notificationsList.length === (idIndex + 1) ? this.designerCardRef : null} 
            className="dayContentCon" 
            key={notification.id}>
            {showDate && <Box className="dayHrLineCon">
              <Typography variant="h4">{dateString}</Typography>
              <Box className="horizontalLine" component={"hr"} />
            </Box>}
            <Box className="contentDateCon" data-test-id="notificationRedirection" onClick={()=>{this.navigateToRoute(notification)}}>
              <Box className="contentCon">
                <Box className="circle" />
                <Typography>{notification.attributes.contents}</Typography>
              </Box>
              <Typography variant="caption" className="date">{this.getDate(notification.attributes.created_at)}</Typography>
            </Box>
          </div>
        )
      }) : <Box className="noNotificationCon">
        <Typography variant="h2">{configJSON.noNotificationsFoundText}</Typography>
      </Box>;
      return renderData;
  }

  renderNotificatonData = () => {
    return (<Box className={this.props.classes.responsiveCon}>
      <Box className={`${this.props.classes.notificationCon} ${this.props.classes.notificationTabHeight}`}>
        <IconButton onClick={this.handleNavigateToLandingPage} data-testid="backButton">
          <img className="leftIcon" src={leftArrowIcon} alt={configJSON.arrowAltText} />
        </IconButton>
        <img className="bellIcon" src={bellIcon} alt={configJSON.bellIconText} />
        <Typography variant="h1" data-testid="heading">{configJSON.notificationsText}</Typography>
      </Box>
      <Box className={this.props.classes.tabsOuterCon}>
        <Box className={`${this.props.classes.tabsCon} ${this.props.classes.notificationTabHeight}`}>
          {
            this.state.tabsList.map(
              (tabData) => (
                <Box data-testid="tabItem" key={tabData.tabId} className={`tabCon`} onClick={this.handleActiveTab.bind(this, tabData.tabId)}>
                  <Box className="countCon">
                    <Typography variant="h4" className={this.isActiveHeading(tabData.tabId)}>{`${tabData.tabName}`}</Typography>
                    <Box className="gradient">
                      <Typography variant="h4">{this.getNotificationCount(tabData.count)}</Typography>
                    </Box>
                  </Box>
                  <Box data-testid="tabBorder" className={`gutter ${this.isActiveTab(tabData.tabId)}`} />
                </Box>)
            )
          }
        </Box>
        <Box className={this.props.classes.notificationsListCon}>
          {this.renderNotifications()}
        </Box>
      </Box>
    </Box>)
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.newResponseCon}>
        <NavigationMenu {...this.props} activeTab="notification"/>
        <ThemeProvider theme={theme}>
          <Box className={this.props.classes.container}>
            {this.state.isLoading ? this.renderBackDrop() : this.renderNotificatonData()}
          </Box>
        </ThemeProvider>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const typoWhite = "#FFF2E3";


const theme = createTheme({
  palette: {
    primary: {
      main: typoWhite,
      contrastText: "#8C8C8C",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});

const webStyleSetToOneLine = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical" as "vertical",
  wordBreak:"break-word" as "break-word"
}

const webStyle = {
  progressCon: {
    width: "100%",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  newResponseCon: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#111111",
    overflow: "hidden"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "calc(100vh - 76px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .circular": {
      color: typoWhite,
    }
  },
  container: {
    width: '100%',
    display: "flex",
    paddingTop: "76px",
    height: "calc(100% - 76px)",
    "& *": {
      boxSizing: "border-box",
    }
  },
  responsiveCon: {
    width: "min(100%,1920px)",
    height: "100%",
    margin: "0 auto",
    padding: "2rem 3rem",
    display: "flex",
    gap: "4.5rem",
    alignItems: "flex-start",
  },
  notificationTabHeight: {
    height: "52px",
  },
  notificationCon: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    flexShrink: 0,
    "& .bellIcon": {
      marginRight: "1rem",
      height: "28px",
      width: "28px",
      objectFit: "contain",
    },
    "& .leftIcon": {
      height: "17px",
      width: "9px",
      objectFit: "cover",
    }
  },
  tabsOuterCon: {
    maxHeight: "100%",
    width: "100%",
    maxWidth: "916px",
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#4D4D4D',
      borderRadius: '10px',
      border: '3px solid #4D4D4D',
      maxHeight: "200px"
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#4D4D4D',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#111111',
    },
    scrollbarWidth: 'thin' as "thin", 
    scrollbarColor: '#4D4D4D #4D4D4D',
  },
  tabsCon: {
    display: "flex",
    alingItems: "center",
    gap: "2rem",
    borderBottom: ".33px solid #FFF2E3",
    width: "100%",
    "& .tabCon": {
      display: "flex",
      gap: ".5rem",
      flexDirection: "column" as "column",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      flexShrik: 0,
    },
    "& .countCon": {
      display: "flex",
      alignItems:  "center",
      gap: "1rem",
    },
    "& .gradient": {
      width: "35px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      backgroundImage: "linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)"
    },
    "& .inactiveHeading": {
      fontFamily: "Inter !important"
    },
    "& .gutter": {
      height: 0,
      width: "100%",
      border: "2px solid transparent",
      borderRadius: "1rem",
      alignSelf: "flex-end"
    },
    "& .activeTab": {
      border: "2px solid #FFF2E3",
      fontFamily: "Inter"
    },
  },
  notificationsListCon: {
    backgroundColor: "#171717",
    filter: "blur(2.83)",
    width: "100%",
    height: "100%",
    marginTop: "2rem",
    borderRadius: "14.86px",
    padding: "14.86px 0",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    "& .noNotificationCon": {
      padding: "1rem"
    },
    "& dayContentCon": {
      width: "100%",
    },
    "& .dayHrLineCon": {
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      padding: ".65rem 0 .65rem 2rem",
      marginBottom: "1rem"
    },
    "& .horizontalLine": {
      flexGrow: 1,
      color: "#525252",
      height: ".54px"
    },
    "& .contentDateCon": {
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
      padding: ".65rem 3.25rem .65rem 2rem",
      transition: "ease .45s all",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#222222",
      },
    },
    "& .unreadNotification": {
      backgroundColor: "#222222",
    },
    "& .contentCon": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
    "& .circle": {
      backgroundColor: "#3D3D3D",
      borderRadius: "50%",
      height: "35px",
      width: "35px",
      flexShrink: 0,
    },
    "& .date": {
      color: "#888888",
      flexShrink: 0,
    }
  }
};

const NewNotifications = withStyles(webStyle)(Notifications);
export { NewNotifications }
// Customizable Area End
