import React, { Component, createRef } from "react";
import Flatpickr from "react-flatpickr";
import { Instance as FlatpickrInstance } from "flatpickr/dist/types/instance";
import { IconButton } from "@material-ui/core";

interface RangeSelectorProps {
  handleDateChange: (
    selectedDates: Date[],
    _dateStr: string,
    instance: FlatpickrInstance
  ) => void;
  dateRange: string | number | Date | readonly (string | number | Date)[] | undefined;
}

interface RangeSelectorState {
  selectedRange: string;
  isCalendarOpen: boolean;
}

export default class RangeSelector extends Component<
  RangeSelectorProps,
  RangeSelectorState
> {
  private flatpickrRef = createRef<Flatpickr>();

  constructor(props: RangeSelectorProps) {
    super(props);
    this.state = {
      selectedRange: "",
      isCalendarOpen: false,
    };
  }

  toggleCalendar = () => {
    const flatpickrInstance = this.flatpickrRef.current?.flatpickr;
    if (flatpickrInstance) {
      if (this.state.isCalendarOpen) {
        flatpickrInstance.close();
      } else {
        flatpickrInstance.open();
      }
      this.setState((prevState) => ({
        isCalendarOpen: !prevState.isCalendarOpen,
      }));
    }
  };

  handleDateChange = (
    selectedDates: Date[],
    dateStr: string,
    instance: FlatpickrInstance
  ) => {
    this.props.handleDateChange(selectedDates, dateStr, instance);
    this.setState({ isCalendarOpen: false });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#171717",
          height: "44px",
          borderRadius: "8px",
          padding: "0px 5px 0px 15px",
          cursor: "pointer",
        }}
      >
        <Flatpickr
          ref={this.flatpickrRef}
          options={{
            mode: "range",
            dateFormat: "M j, Y",
            maxDate: "today",
            locale: {
                rangeSeparator: " - ",
              },
            defaultDate: this.props.dateRange as Date[]
          }}
          className="flatpickr-input"
          onChange={this.handleDateChange}
        />
        <IconButton
          style={{ paddingLeft: "46px" }}
          onClick={this.toggleCalendar}
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 0C3.44772 0 3 0.447715 3 1V2H2C0.895431 2 0 2.89543 0 4V6H18V4C18 2.89543 17.1046 2 16 2H15V1C15 0.447715 14.5523 0 14 0H13C12.4477 0 12 0.447715 12 1V2H6V1C6 0.447715 5.55228 0 5 0H4Z"
              fill="#FFF2E3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 8H0V17C0 18.1046 0.89543 19 2 19H16C17.1046 19 18 18.1046 18 17V8ZM3 11C3 10.4477 3.44772 10 4 10H6C6.55228 10 7 10.4477 7 11V12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12V11Z"
              fill="#FFF2E3"
            />
          </svg>
        </IconButton>
      </div>
    );
  }
}