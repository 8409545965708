import React from "react";

// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";
import { ExpandMore } from "@material-ui/icons";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <Box
          style={styles.newResponseCon}
          data-testid="outside">
          <NavigationMenu {...this.props} activeTab="" />
          <Box style={styles.mainClientWrap as React.CSSProperties}>
            <Box style={styles.mainBox}>
              {this.state.isFaq &&
                <Typography style={styles.mainTitle}>
                  FAQs
                </Typography>}
              {this.state.isFaq ?
                this.state.faqData.map((item) => (
                  <Accordion
                    style={styles.accordionStyle}
                    key={item.id}
                    expanded={this.state.expanded === `panel${item.id}`}
                    onChange={this.handleChange(`panel${item.id}`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMore style={{ color: "#fff" }} />}>
                      <Typography style={styles.mainTitle1}>{item.attributes.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        style={styles.mainDetail1}
                        component="div"
                        dangerouslySetInnerHTML={{ __html: item.attributes.answer }}
                      />
                    </AccordionDetails>
                  </Accordion>))
                :
                <Box>
                  <Typography style={styles.mainTitle}>
                    {this.state.pageData.title}
                  </Typography>
                  <Typography
                    style={styles.mainDetail}
                    component="div"
                    dangerouslySetInnerHTML={{ __html: this.state.pageData.description }}
                  />

                </Box>}
            </Box>
          </Box>
        </Box>
      </MainWrapper>
    ) 
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({}));
const styles = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  mainClientWrap:{
    backgroundColor: '#111',
    fontFamily: 'Inter, sans-serif',
    color: '#fff2e3',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    width: 'min(100%, 1920px)',
    margin: 'auto',
  },
  mainBox:{
    marginTop: "150px", padding: "0px 50px"
  },
  mainBox1:{
    marginTop: "10px",
  },
  mainTitle:{
    marginBottom: "40px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: "600", fontSize: "36px", lineHeight: "24px", letterSpacing: "0.38px"
  },
  mainTitle1:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: "600", fontSize: "18px", lineHeight: "16px"
  },
  mainDetail:{
    color: "#5E5E5E",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19.36px",
    textAlign: "justify" as "justify"
  },
  mainDetail1:{
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.36px",
    textAlign: "justify" as "justify"
  },
  accordionStyle: {
    backgroundColor: "#252525",
    color: "#fff",
    borderRadius: "5px",
    marginBottom: "10px",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px"
  }
};
// Customizable Area End
