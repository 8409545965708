import {
    Box, Button, Dialog, DialogContent, Divider, Typography, Collapse,
    TextField
} from '@material-ui/core';
import React, { Component } from 'react';
import { Deliverable, Milestone } from '../ContractDetailsController.web';
import { styled } from '@material-ui/styles';

interface RequestChangeModalProps {
    open: boolean;
    handleClose: () => void;
    mileston: Milestone;
    index: number;
    sendChangeRequestApiCall: (contractId: string, milestoneId: number, changeMessage: string) => void;
    contractId: string;
    handleViewSubmissionClick: (mileston: Milestone, index: number, contractId: number | string) => void;
}

interface StateProp {
    isExpanded: boolean;
    textField: string;
}

const StyledTextField = styled(TextField)({
    backgroundColor: "#2F2F2F",
    color: "#595959",
    fontFamily: "Inter, sans-serif",
    fontStyle: "italic",
    borderRadius: "10px",
    width: "100%",
    fontSize: "16px",

    "& .MuiOutlinedInput-root": {
        borderRadius: "10px",
        border: "none",
    },

    "& .MuiOutlinedInput-input": {
        color: "#FFF2E3",
    },

    "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
    },

    "&:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline, &:active .MuiOutlinedInput-notchedOutline": {
        display: "none",
    },

    "& .MuiInputBase-multiline": {
        padding: "10px",
    },
});

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "700px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px"
        },
    },
});



export default class RequestChangeModal extends Component<RequestChangeModalProps, StateProp> {
    constructor(props: RequestChangeModalProps) {
        super(props);
        this.state = {
            isExpanded: false,
            textField: ""
        };
    }

    handleAccordion = () => {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded
        }));
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            textField: event.target.value
        })
    }

    render() {
        const { open, handleClose, mileston, index, sendChangeRequestApiCall, contractId, handleViewSubmissionClick } = this.props;
        const { isExpanded } = this.state;

        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box>
                        <Box style={{ display: "flex", justifyContent: "space-between", padding: "24px 40px", fontFamily: "inter" }}>
                            <span style={{ color: "#FFF2E3", fontSize: "20px", fontWeight: 600 }}>
                                Request changes
                            </span>
                            <span
                                data-test-id="open-view-sub-modal"
                                onClick={() => handleViewSubmissionClick(mileston, index, contractId)}
                                style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, textDecoration: "underline", cursor: "pointer" }}>
                                View submission
                            </span>
                        </Box>
                        <Box style={{ background: "transparent", borderRadius: "10px", border: "1px solid #494949", margin: "24px 40px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                                <span style={{ display: "flex", alignItems: "center", cursor: "pointer" }} data-test-id="open-accordian" onClick={this.handleAccordion}>
                                    <span style={{
                                        height: "30px",
                                        width: "30px",
                                        boxShadow: "-4px 4px 4px 0px #00000040",
                                        borderRadius: "50%",
                                        background: "#FFF2E3",
                                        color: "#2F2F2F",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontFamily: "inter"
                                    }}>
                                        {index}
                                    </span>
                                    <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, paddingLeft: "10px", paddingRight: "5px", fontFamily: "inter", }}>
                                        {mileston.name}
                                    </span>
                                    <span>
                                        {isExpanded ? (
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <circle cx="10" cy="10" r="10" transform="rotate(90 10 10)" fill="#333333" />
                                                <path d="M15 11L10 6L5 11" stroke="#FFF2E3" strokeWidth="1.56" strokeLinecap="round" />
                                            </svg>
                                        ) : (
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <circle cx="10" cy="10" r="10" transform="rotate(-90 10 10)" fill="#333333" />
                                                <path d="M5 9L10 14L15 9" stroke="#FFF2E3" strokeWidth="1.56" strokeLinecap="round" />
                                            </svg>
                                        )}
                                    </span>
                                </span>

                                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 25px" }}>
                                        <svg style={{ paddingRight: "10px" }} width="22" height="24" viewBox="0 0 22 24" fill="none">
                                            <rect x="1" y="4.66797" width="19.9127" height="18.3333" rx="2" stroke="#FFF2E3" />
                                            <path d="M1 8.66797C1 6.78235 1 5.83954 1.58579 5.25376C2.17157 4.66797 3.11438 4.66797 5 4.66797H16.9127C18.7983 4.66797 19.7411 4.66797 20.3269 5.25376C20.9127 5.83954 20.9127 6.78235 20.9127 8.66797V9.55686H1V8.66797Z" fill="#FFF2E3" />
                                        </svg>
                                        <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                            {mileston.due_date}
                                        </span>
                                    </span>
                                    <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                        ₹ {mileston.amount}
                                    </span>
                                </span>
                            </Box>

                            <Divider style={{ width: "100%", height: "1px", background: "#494949" }} />

                            <Typography style={{
                                fontFamily: "inter",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#FFF2E3",
                                padding: "20px 15px"
                            }}>
                                {mileston.description}
                            </Typography>

                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Box style={{ padding: "15px", paddingTop: "0px" }}>
                                    <Typography style={{ color: "#7D7D7D", fontSize: "12px", fontWeight: 400, fontFamily: "inter" }}>
                                        Deliverables:
                                    </Typography>
                                    {mileston.deliverables.map((item: Deliverable, index: number) => (
                                        <Typography key={item.id} style={{ paddingTop: "10px", fontSize: "12px", fontWeight: 400, color: "#FFF2E3", fontFamily: "inter", }}>
                                            {index + 1}. {item.deliverable}
                                        </Typography>
                                    ))}
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                    <Divider style={{ height: "1px", width: "100%", marginTop: "20px", background: "#494949" }} />
                    <DialogContent>
                        <Typography style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", padding: "10px 0px 12px 0px" }}>
                            Enter changes
                        </Typography>
                        <StyledTextField
                            value={this.state.textField}
                            id="outlined-multiline-static"
                            data-test-id="change-inpute-field"
                            multiline
                            rows={5}
                            placeholder="Type here"
                            variant="outlined"
                            onChange={this.handleChange}
                            style={{
                                backgroundColor: "#2F2F2F",
                                color: "#595959",
                                fontFamily: "inter",
                                fontStyle: "italic",
                                borderRadius: "10px",
                                width: "100%"
                            }}
                        />
                    </DialogContent>
                    <Box
                        style={{
                            paddingTop: "26px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "20px"
                        }}>
                        <Button
                            data-test-id="close-req-change-modal"
                            style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                borderRadius: "7px",
                                padding: "10px 30px",
                                fontFamily: "'Inter', sans-serif",
                                textTransform: "none",
                                cursor: "pointer",
                                marginRight: "15px",
                                background: "#0D0D0D",
                                color: "#FFF2E3",
                            } as React.CSSProperties}
                            onClick={() => {
                                this.setState({
                                    textField: ""
                                })
                                handleClose()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-test-id="send-changes-btn"
                            disabled={this.state.textField.trim() === ""}
                            style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                borderRadius: "7px",
                                padding: "10px 30px",
                                fontFamily: "'Inter', sans-serif",
                                textTransform: "none",
                                cursor: "pointer",
                                background: "rgba(255, 242, 227, 1)",
                                color: "#121212",
                                marginRight: "10px",
                            } as React.CSSProperties}
                            onClick={() => {
                                sendChangeRequestApiCall(contractId, mileston.id, this.state.textField);
                                this.setState({
                                    textField: ""
                                });
                                handleClose();
                            }}
                        >
                            Send changes
                        </Button>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
