// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  styled,
  Grid,
  Link,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {filelink,Backbtn,closeIcon,checkIcon,dropDown} from "../assets";
import PlacesAutocomplete from "react-places-autocomplete";
import ToastMSG from '../../../../components/src/ToastMSG.web';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import WorkExperienceFormController,{Props} from "./WorkExperienceFormController";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";

export default class WorkExperienceForm extends WorkExperienceFormController {

  MainWrapper = styled(Box)(({ theme }) => ({

    
    
    '& .user_profile_EXP':{
      fontFamily: 'Inter, sans-serif',
      alignSelf: 'center',
      backgroundColor: '#222222',
      color: '#FFF2E3',
      width: '100%',
      height: '100%',
      left:0
    },
    
    '& .Experience-profile-header':{
      fontFamily: 'Inter, sans-serif',
      alignSelf: 'center',
      color: '#FFF2E3',
      height: '80%',
      margin:"2% auto",
      width:"90%",
      overflow:'scroll',
      scrollbarWidth:'none'

    },
    '& .Experience-profile-container' :{
      position:'fixed',
      backgroundColor: 'black',
      width: '100%',
      top:'12%',
      left:0,
      height:'100vh'
    },
    '& .experience-header':{
      fontFamily:'Inter, sans-serif',
      fontSize:'36px',
      fontWeight:700
    },
    '& .inputLabelConatiner': {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection:'column',
    gap:'20px'
    },

'& .required': {
  color: '#FF7878'
},

'& .nameInputProfileEdit' :{
  width:'50%'
},
'& .inputCommanProfileEdit': {
    backgroundColor:'#171717',
    borderRadius: '10px',
    border: 'none',
    color: '#FFF2E3',
    paddingLeft: '25px',
    fontSize: '18px',
},
'& .selectMonthsandYears':{
width:'48%',
height:'60px'
},
'& .selectExploc':{
  width:'95%',
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 25px center',
  
},
'& .selectImage':{
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 25px center',
},
'& .MonthsandYears':{
  width:'95%',
  display:'flex',
  justifyContent:'space-between'
},
'& .inputLables':{
  fontSize:'20px',
  fontWeight:600,
  forntFamily:'Inter, sans-serif',
  collor:'#FFF2E3'
},
'& .editProfileInputContainerEighth':{
  width:'50%',
  margin:'2% auto'

},
'& .editProfileInputContainerEighth_exp':{
  width:'50%',
  marginTop:'2%',
  marginBottom:'2%'
},
'& .aboutdesignerTextfield' :{
  backgroundColor: '#171717',
  borderRadius: '10px',
  border: 'none',
  color: '#DCCFCF',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  width: '100%',
  height: '150px',
  margin: '20px 0px',
  padding: '12px',
  fontFamily: 'Inter, sans-serif',
  scrollbarWidth: 'none',
  resize:'none'
},
'& .Addbutton' :{
  width:'10%',
  height:'10%',
  alignContent: 'center',
  background:' #2d2c2c',
  borderRadius: '10px',
  textAlign: 'center',
  cursor:'pointer'

},
'& .Addrefrencelink':{
  width:'5%',
  height:'10%',
  alignContent: 'center',
  background:' #2d2c2c',
  borderRadius: '10px',
  textAlign: 'center',
  corsor:'pointer'
},
'& .inputandAddBtn':{
  display:'flex',
  width:'100%',
  justifyContent:'space-between'
},
'& .inputabortmant':{
  backgroundImage: `url(${filelink})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '10px center',
  paddingLeft:' 4%',
  backgroundSize:'30px'
},
'& .SavechangesBtn':{
  fontSize:'16px',
  fontWeight:500,
  padding:'10px',
  marginTop:'4%',
  fontFamily:'Inter, sans-serif',
  cursor:'pointer',
  borderRadius:'8px'
},

'& .Identitylinks':{
  fontSize:"20px",
  fontWeight:600,
  fontFamily:"Inter,sans-serif",
  color:"#498EFF",
  textDecorationLine:"underline",
  background:'#222222',
  padding:"15px",
  marginTop:'10px',
  borderRadius:'10px'
 },
 '& .select_list_edit_profile_exp':{
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  width: '100%',
 },
 '& .edit_profile_data_area_Exp':{
  width: '98%',
  backgroundColor: '#171717',
  marginBottom:'2%',
  borderRadius: '8px',
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent:'space-between',

 },
 '& .select_list_edit_profile_exp .select_button' :{
  color: '#FFF2E3',
    border: '0.5px solid #FFF2E3',
    borderRadius: '20px',
    backgroundColor:' #222222',
    padding: '11px 18px 11px 18px',
    display:'flex',
    alignItems:'center'
 },
 '& .select-collection-heading_Exp':{
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.94px',
  color:' rgba(125, 125, 125, 1)',
  
 },
 '& .select-all-heading_Exp':{
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.36px',
  color: 'rgba(255, 242, 227, 1)',
  
 },
 '& .collection-heading-item-style_Exp':{
  fontFamily: 'Inter,sans-serif',
fontSize: '14px',
fontWeight: 700,
lineHeight: '16.94px',
color: 'rgba(255, 242, 227, 1)',
 },
 '& .collection-subheading-item-style_Exp':{
  fontFamily: 'Inter,sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.52px',
color: 'rgba(255, 242, 227, 1)',
 },
 '& .ExpertiseAreaSelectedExp_Wxp':{
  textDecoration: 'underline',
textUnderlineOffset: '5px',
width: 'auto',
padding:'20px'
 },
 '& .inputProfileEdit_Exp' :{
  width:'100%',
  height: '60px',
 },
 '& .StartedinputProfileEdit select' :{
  appearance: 'none',
  paddingRight: '20px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 20px center',
 },
 '& .hideSroll':{
  scrollbarWidth:'none',
  appearance: 'none',
  '&::-webkit-scrollbar': {
        width: '0px',
        display:'none'
      }
 },
 '& .skill-container':{
width:"100%"
 },
 '& .search-container': {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent:'space-between',
  borderRadius:'10px',
  margin:'20px 0px',
  position:'relative'
},'& .search-field': {
       color: '#fff2e3',
       fontSize: '16px',
        lineHeight: '19.36px',
        background:'rgb(23, 23, 23)',
        width:'100%',
        border:'0px',
        paddingLeft:'20px',
        height:'50px',
        borderRadius:'10px'
            },
            
 '& .skill-list-container':{
   display:'flex',
     gap:'10px',
    width:'100%',
    overflowX:'scroll',
    scrollbarWidth:'none',
    margin:'10px 0px'
    },
  '& .active-skill-list-container' :{
    display:'flex',
    gap:'10px',
    width:'100%',
    overflowX:'scroll',
    scrollbarWidth:'none',
    margin:'10px 0px'
  },
  '& .clear-icon':{
    position:'absolute',
    right:'10px',
    top:'50%',
    transform:'translateY(-50%)',
    cursor:'pointer',
    width:'14px',
    height:'14px'

  }
      

  })
)


  constructor(props: Props) {
    super(props);
  }

 
handleCollectionList=()=>{
  return    this.state.collectionList.map((collection:any, index:number) => {
    return (
      <Box  key={index} style={{display:'flex',
        background:this.state.currentCollectionId==collection.id ?'rgba(33, 33, 33, 1)':'unset',
      cursor:'pointer',flexDirection:'row',paddingInline:'15px',gap:'10px',alignItems:'center',justifyContent:'space-between'}} data-test-id="collectionData" onClick={() => this.handleCollection(collection.id)}>
      <p 
      className="collection-item-style"
      style={{ padding: "4px 0px", cursor: "pointer",  fontWeight:this.state.currentCollectionId==collection.id ?600:400}} >{collection.name}</p>
  <Box className="collection-style">

      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.31641 9L5.31641 5L1.31641 1" stroke="#FFF2E3" stroke-width="1.52371" stroke-linecap="round"/>
</svg>
  </Box>


      </Box>
    )
  })
}

handleSkillsContainer =()=>{
  return (
    <div className="inputandAddBtn">
                  <Box className="skill-container">
                    {this.state.selectedSkillTags.length ? <Box className="active-skill-list-container">
                    {this.state.selectedSkillTags.map((list, index) => <Box 
                        className="tags tags-active" 
                        data-test-id="selectedSkillTags"
                        key={`selectedSkillTags${index}`}
                        onClick={() => this.handleRemoveSkillTags(list)}
                    >
                        <img src={`${closeIcon}`} alt="remove skill" />{list.name}</Box>)}
                    </Box>: null}
                    <Box className={`search-container`}>
                        
                        <input
                            type="text"
                            placeholder="Ex: Logo Design, User Centric Design"
                            data-test-id="skill-search-end"
                            className={'search-field'}
                            disabled={!this.state.skillList.length}
                            value={this.state.searchText}
                            onChange={(event:any) => this.handleChangess(event.target.value)}
                            
                        />
                         { this.state.searchText && 
                         <img src={`${closeIcon}`} alt="cross" data-testId="clearIconClick" onClick={()=>this.handleClearText()} className="clear-icon"/>}
                    </Box>
                    {((this.state.searchText.trim() && this.state.searchSkillList.length) || (!this.state.searchText.trim() && this.state.skillList.length)) ? <><Box className="skill-Suggestion">Suggestions</Box><Box className="skill-list-container">
                        {(this.state.searchSkillList.length ? this.state.searchSkillList: this.state.skillList).map((list, index) => <Box 
                            className="tags"
                            data-test-id="skillList"
                            key={`skillTags${index}`} 
                            onClick={() => this.handleAddSkillTags(list)}
                        >{list.name}
                        </Box>)}
                        </Box></>
                        :<><Typography className="skill-Suggestion">Suggestions</Typography><Box className="skill-list-container text-elm fs-16">No result found</Box></>}
                </Box>
                        
                
                 
                  </div>
  )
}

handleCheckIcon=(data:any)=>{
  return this.handleViewCheck(data)?  <img
                          style={{
                            width: "25.5px",
                            height: "25.5px",
                            borderRadius: "50%",
                            position: "absolute",
                            border: "2px solid rgba(255, 242, 227, 1)",
                            right: "3%",
                            cursor: 'pointer',
                            top: "3%",
                            background:'rgba(17, 17, 17, 1)'
                          }}
                          src={ checkIcon}
                        />
                        :
                        <Box   style={{
                            width: "25.5px",
                            height: "25.5px",
                            borderRadius: "50%",
                            position: "absolute",
                            border: "2px solid rgba(255, 242, 227, 1)",
                            right: "3%",
                            cursor: 'pointer',
                            top: "3%",
                            background:'rgba(70, 70, 70, 1)'
                          }}>

                        </Box>
}

PlaceAutoCompleteWrapper = styled(Box)(({ theme }) => ({
  "& .placeAutoComplete": { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px" },
  "& .suggestion_box": { position: 'relative', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: '100%',marginTop:'10px' },
  "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
  "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
  }));
  addEditButton = () =>{
    return(
    <Typography className="experience-header">{this.props.EditProfile ? "Edit":"Add"} Work Experience</Typography>
  )}
  SaveButtonLoader =() =>{
return (
  <>
   {this.state.AddBtnLoader ? (

<Box className="SavechangesBtn">

  <CircularProgress color='inherit' size={25}/>
  </Box>
)
: (
<button data-testId="SaveChangesBtn" disabled={!this.hasErrors()} onClick={()=>this.handleSaveChanges()} className="SavechangesBtn">
  {this.props.id ? "Edit Experience":"Add Experience"}
</button>
)

}
  </>
)
  }
  render() {
  
    return (
      <ThemeProvider theme={theme}>
        <this.MainWrapper >
        <ToastMSG open={this.state.toastStatusexp} data-testId="tostmessage" close={() => this.handleToastClose()} message={this.state.toastMsgExp} action={this.state.toastActionExp} />
     
        <Box className="Experience-profile-container">
          <Box className="Experience-profile-header">
            <Box style={webStyle.mainHeader}>
            <Box className="MyboardbackBtn">
                  <img className="BackBtnMy" onClick={()=>this.HandleBackBtn()} data-testId='BackBtnMyBoard'  src={Backbtn} alt='backicon'/>
                </Box>
            {this.addEditButton()}
            </Box>
            <Box style={webStyle.SecondBox}>
            <div className="inputLabelConatiner editProfileInputContainerFirst">
                
                  <label htmlFor="CompanyOrganization" className="inputLables">
                    Company Organization<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="CompanyOrganization"
                    data-testid="companyorg"
                    name="companyOrganization"
                    placeholder="Name"
                    className="nameInputProfileEdit inputCommanProfileEdit"
                    value={this.state.companyOrganization}
                    onChange={this.handleChange}
                    onBlur={() => this.handleBlur('companyOrganization')}
                    maxLength={86}
                  />
                  {this.state.companyError && <span style={{ color: 'red' }}>{this.state.companyError}</span>}
        
                </div>

                <div className="inputLabelConatiner editProfileInputContainerFirst" style={webStyle.PositionMain}>
                  {" "}
                  <label htmlFor="CompanyOrganization" className="inputLables">
                    Position<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="position"
                    style={webStyle.PositionOrg}
                    name="position"
                    placeholder="Ex: Illustrator, Graphic Designer, etc."
                    className="nameInputProfileEdit inputCommanProfileEdit"
                    value={this.state.position}
                    onChange={this.handleChange}
                    onBlur={() => this.handleBlur('position')}
                    maxLength={30}
                  />
                  {this.state.positionError && <span style={{ color: 'red' }}>{this.state.positionError}</span>}
                </div>
                <div style={webStyle.twoinputcontainer}>
                          <div className="inputLabelConatiner editProfileInputContainerEighth">
                            <label
                              htmlFor="Location"
                              className="inputLables"
                            >
                            Location<span className="required">*</span>
                            </label>
                            <select
                              
                              className="StartedinputProfileEdit inputCommanProfileEdit selectExploc"
                              style={{ backgroundImage: `url(${dropDown})` }}
                              id="region"
                              name="selectedRegion"
                              value={this.state.selectedRegion}
                              onChange={this.handleChange}
                              onBlur={() => this.handleBlur('region')}
                            >
                              
                              <option value=''>Select Location</option>
                              {this.state.AllStates.map((data:any,index:number)=>{
                                return (
                                  <option key={index} value={data.name}>{data.name}</option>
                                )
                              })}
                            </select>
                            {this.state.regionError && <span style={{ color: 'red' }}>{this.state.regionError}</span>}
      
                          </div>
                          <div className="inputLabelConatiner editProfileInputContainerFirst editProfileInputContainerEighth" style={webStyle.leftsideinputs}>
                  
                  {this.state.selectedRegion === 'All/Countries' && (
                    <>
                      <label htmlFor="CompanyOrganization" style={webStyle.leftsideLable} className="inputLables">
                    City<span className="required">*</span>
                  </label>
                  <div style={{height:'60px',width:'100%'}}>

                  <this.PlaceAutoCompleteWrapper style={{width:'95%'}}>
                    <PlacesAutocomplete
                    data-test-id="placeAuto-data"
                                           value={this.state.city}
                                            onChange={this.handleChangeLocationText}
                                            onSelect={this.handleChangeLocation}
                                            searchOptions={{
                                            types: ['locality']
                                            }}
                                            >
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => 
                                            (
                                            <Box>
                                            <input
                                            {...getInputProps()}
                                            style={{width:'100%'}}
                                            onBlur={this.handleChangeLocationTextBlur}
                                            onKeyDown={this.handlePressEnter}
                                            data-test-id="location-data"
                                            className="inputProfileEdit inputCommanProfileEdit"
                                            />
                                            <Box data-test-id="location-description" className="autocomplete-dropdown-container suggestion_box">
                                            {suggestions.map((suggestion, index) => 
                                             (
                                            <Box className="suggestion_container"
                                            {...getSuggestionItemProps(suggestion, { key: { index } })}
                                            >
                                            <Typography  key={index} className="suggestion">{suggestion.description}</Typography>
                                            </Box>
                                            )
                                            )}
                                            </Box>
                                            </Box>
                                            )}
                                            </PlacesAutocomplete>
                    </this.PlaceAutoCompleteWrapper>
                  </div>
                  
                 
                    </>
                  )}
                  
                </div>
                          
                </div>
                <div style={webStyle.twoinputcontainer}>
                          <div className="inputLabelConatiner editProfileInputContainerEighth_exp">
                            <label
                              htmlFor="Location"
                              className="inputLables"
                            >
                            Starting from<span className="required">*</span>
                            </label>
                            <div className="MonthsandYears">
                            <select
                              id={"Started" }
                              className="inputCommanProfileEdit selectMonthsandYears selectImage hideSroll"
                              name='startMonth'
                              style={{ backgroundImage: `url(${dropDown})` }}
                              value={this.state.startMonth}
                              onChange={this.handleChange}
                              >
                                <option value="">Select a month</option>
                {this.getMonthList().map(month => (
                    <option key={month.value} value={month.label}>
                        {month.label}
                    </option>
                ))}
                            </select>
                            <select
                              id={"Started" }
                              style={{ backgroundImage: `url(${dropDown})` }}
                              className="inputCommanProfileEdit selectMonthsandYears selectImage hideSroll"
                              value={this.state.startyear}
                              name='startyear'
                              onChange={this.handleChange}
                              data-testId='StartingYeartest'
                             >
                              <option>Years</option>
                              {this.getYearList().map((year: any) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            
                            </div>
                           
                          </div>
                        {!this.state.ischecked && (
                          <div className="inputLabelConatiner editProfileInputContainerFirst editProfileInputContainerEighth_exp" style={webStyle.leftsideinputs}>
                          <label htmlFor="CompanyOrganization" style={webStyle.leftSideLableDate} className="inputLables">
                            Ending In<span className="required">*</span>
                          </label>
                           <div className="MonthsandYears">
                           <select
                                      id={"Started" }
                                      className="inputCommanProfileEdit selectMonthsandYears selectImage hideSroll"
                                      name="endMonth"
                                      style={{ backgroundImage: `url(${dropDown})` }}
                                      value={this.state.endMonth}
                                      onChange={this.handleChange}
                                      >
                                      <option value="">Select a month</option>
                        {this.getMonthList().map(month => (
                            <option key={month.value} value={month.label}>
                                {month.label}
                            </option>
                        ))}
                                    </select>
                                    <select
                                      id={"endyear"}
                                      data-testId="EndYeartest"
                                      style={{ backgroundImage: `url(${dropDown})` }}
                                      className="inputCommanProfileEdit selectMonthsandYears selectImage hideSroll"
                                      name="endYear"
                                      value={this.state.endYear}
                                      onChange={this.handleChange}
                                      
                                     >
                                      <option>Years</option>
                                      {this.getYearList().map((year: any) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                   
                                    </div>
                        </div>
                        )}
                          
                            
                </div>
                <div style={{ color: 'red', fontSize: '12px',marginBottom:"15px",marginTop:'5px' }}>
                          {this.state.endYearError && <span>{this.state.endYearError}</span>}
                          </div>  
                <Box style={webStyle.checkboxvalues}>
                   
                    { this.state.ischecked ? (
                          <>
                          <img data-testId='Isnotchecked' onClick={()=>this.handleEndYear()} style={{ width: "15px",
                            height: "15px",
                            borderRadius: "5px",
                            border: "2px solid rgba(255, 242, 227, 1)",
                            right: "3%",
                            cursor: 'pointer',
                            top: "3%",
                            background:'rgba(17, 17, 17, 1)',
                            marginRight:'10px'
                          }}
                          src={ checkIcon} alt="checked"/>
                          </>
                        ):(
                          <>
                          <Box data-testId='IscheckedBox' onClick={()=>this.handleEndYear()} 
                                style={{
                                         width: "15px",
                                         height: "15px",
                                         borderRadius: "5px",
                                         border: "2px solid rgba(255, 242, 227, 1)",
                                         right: "3%",
                                         marginRight:'10px',
                                          cursor: 'pointer',
                                         background:'rgba(70, 70, 70, 1)'
                                                }}>

                                              </Box>
                          </>
                        )}
                                          
  
                    <label>Currently Working</label>
                </Box>
                
                <Box style={webStyle.PositionMain}>
                <label htmlFor="CompanyOrganization" style={webStyle.leftsideLable} className="inputLables">
                    Details<span className="required">*</span>
                  </label>
                  <textarea
                id="details"
                name="details"
                placeholder={'Ex: My responsibilities include...'}
                className="aboutdesignerTextfield"
                maxLength={500}
                value={this.state.details}
                onChange={this.handleChange}
                
              />

                </Box>
                {this.state.detailsError && <span style={{ color: 'red' }}>{this.state.detailsError}</span>}
                <Box>
                <div className="inputLabelConatiner editProfileInputContainerFirst" style={webStyle.PositionMain}>
                  {" "}
                  <label htmlFor="CompanyOrganization" className="inputLables">
                    Add Skills<span className="required">*</span>
                  </label>
                  {this.handleSkillsContainer()}
                  
                </div>
                </Box>
                
               

                <Box>
                
                  <div className="inputLabelConatiner editProfileInputContainerFirst" style={webStyle.PositionMain}>
                <label htmlFor="refrencelinks" className="inputLables">
                    Add Reference Links
                </label>
                <div className="inputandAddBtn">
                    <input
                        type="text"
                        id="refrencelinks"
                        data-testid="refrencelinks"
                        style={webStyle.Skillsinput}
                        name="referencelinks"
                        placeholder="Add"
                        className="nameInputProfileEdit inputCommanProfileEdit inputabortmant"
                        value={this.state.referencelinks}
                        onChange={this.handleChange}
                    />
                    <Box className="Addrefrencelink" data-testid="Addlinkbtn" onClick={this.handleAddLink} style={{ cursor: 'pointer' }}>
                        <Typography style={webStyle.Addrefrencelink}>+</Typography>
                    </Box>
                </div>
                {this.state.linkError && <Typography style={{ color: 'red' }}>{this.state.linkError}</Typography>}
                </div>
                <Box>
                    {this.state.links.length > 0 && (
                      this.state.links.map((link:any, index:number) => (
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "15px",
                          borderRadius: '10px',
                          marginTop: '10px',
                          background: '#222222',
                          alignItems: "center"
                        }}
                        >
                          <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={link}
                            style={{
                              color: "#498EFF",
                              cursor: "pointer",
                              fontWeight: 600,
                              textDecoration: "none",
                              fontFamily: "Inter,sans-serif",
                              fontSize: "20px",
                              textDecorationLine: "underline",
                            }}
                          >
                            {link}
                          </Link>
                          <IconButton onClick={() => this.handleRemoveLinkExt(index)} data-test-id="remove-link-btn">
                            <svg height="16" width="14" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.72461 12.4492L5.72461 6.72469" stroke-linecap="round" stroke="#FFF2E3" />
                              <path d="M8.17773 12.4492L8.17773 6.72469" stroke-linecap="round" stroke="#FFF2E3" />
                              <path d="M0 4.26953H13.9024V4.26953C12.7388 4.26953 12.1569 4.26953 11.7591 4.58173C11.6669 4.65409 11.5837 4.73726 11.5114 4.82946C11.1992 5.22727 11.1992 5.8091 11.1992 6.97278V11.974C11.1992 13.3537 11.1992 14.0436 10.7706 14.4722C10.3419 14.9008 9.65208 14.9008 8.27236 14.9008H5.63008C4.25036 14.9008 3.5605 14.9008 3.13188 14.4722C2.70325 14.0436 2.70325 13.3537 2.70325 11.974V6.97278C2.70325 5.8091 2.70325 5.22727 2.39106 4.82946C2.3187 4.73726 2.23552 4.65409 2.14332 4.58173C1.74552 4.26953 1.16368 4.26953 0 4.26953V4.26953Z" stroke-linecap="round" stroke="#FFF2E3" />
                              <path d="M5.31534 1.81799C5.31534 1.81799 5.72424 1 7.35982 1C8.9954 1 9.4043 1.81779 9.4043 1.81779" stroke-linecap="round" stroke="#FFF2E3" />
                            </svg>
                          </IconButton>
                        </Box>
                      ))
                        
                    )}
                </Box>
                </Box>
                <Box>
                <div className="inputLabelConatiner editProfileInputContainerFirst" style={webStyle.PositionMain}>
                  {" "}
                  <label htmlFor="CompanyOrganization" className="inputLables">
                  Add Projects from DK
                  </label>
                  {this.state.collectionList.length>0? (
        <Grid container style={{ border: "1px solid #7d7d7d", borderRadius: "11.7px", minHeight: "543px", marginTop: "20px" }}>
          <Grid item md={3} style={{ borderRight: "1px solid #7d7d7d",  }}>
            <p style={{ paddingBlock: "10px",paddingInline:'15px' }} className="select-collection-heading_Exp">Select Collection</p>
            {this.state.collectionList.length > 0 &&
                     this.handleCollectionList()
                      }
          </Grid>
          <Grid item md={9}>
            <Grid container>
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 16px", width: "100%" }}>
                <p style={{ padding: "10px 0px" }}  className="select-collection-heading_Exp">Select Projects</p>
                <p
                  style={{ borderBottom: "1px solid #7d7d7d", cursor: 'pointer' }}
                  className="select-all-heading_Exp"
                  data-test-id="isProjectSelectedAll123"
                  onClick={()=>this.handleSelectAll()}
                >
                  {this.handleareAllProjectsSelected() }
                </p>
              </Box>
            </Grid>
            <Box style={{ height: "600px", overflowY: "auto", overflowX: "hidden" ,position:'relative',scrollbarWidth:'none',padding: "0 16px",}}>
              <Grid container  spacing={2}>
                {this.state.particularCollectionData ? (
                  this.state.particularCollectionData.map((data,index) => (
                    <Grid item md={4} key={index}>
                      <Box
                        style={{  display: "flex", justifyContent: "space-between", whiteSpace: "normal", gap: "5px" }}
                        data-test-id="toggleSelectProject"
                        onClick={() => this.toggleSelectProject(data)}
                      >
                        <Box
                          style={{
                            width: "271.19px",
                            height: "243.15px",
                            borderRadius: "11.97px",
                            overflow: "hidden",
                            position: "relative"
                          }}
                        >
                        {this.handleCheckIcon(data)}
                          <img src={`${this.baseUrlStirng}${data.attributes.cover_image_url}`} style={{ width: "100%", height: "180px",  borderRadius: "11.97px", }} />
                          <Typography className='collection-heading-item-style_Exp' style={{ fontSize: "14px", fontWeight: 700 ,marginTop:'7px'}}>{data.attributes.project_name}</Typography>
                          <Typography className='collection-subheading-item-style_Exp' style={{ fontSize: "12px", fontWeight: 400 }}>{data.attributes.username}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) :
                <p style={{width: '100%',
                  textAlign: 'center'}} data-test-id="isProjectSelected">
                No projects available
              </p>
                }
              </Grid>
              {this.state.totalCount > 1 && <Box style={{position:'absolute',bottom:0,left:0,right:0, display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "15px", }}>

<PaginationComponent count={this.state.totalCount}
  onChange={this.handleChanges}
  page={this.state.currentPage}

/>
</Box>}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <p style={{ marginLeft:57 ,fontSize:'16px'}} data-test-id="isProjectSelected">
        No DK Contracts Available
        </p>
      )}
                  </div>
                </Box>
                <Box>
                  {this.SaveButtonLoader()}
                 
                  
                </Box>
            </Box>
          </Box>
        
          </Box>
        </this.MainWrapper>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  Addrefrencelink:{
     fontSize:'36px',
    color:'#878787'
  },
  PositionMain:{
    marginTop:'4%'
  },
  Addbutton:{
    fontSize:'36px',
    color:'#878787'
  },
  Skillsinput:{
    width:'94%'
  },
  checkboxvalues:{
    display:'flex'
  },
  twoinputcontainer:{
    display:'flex',
    // justifyContent:'space-around'
  },
  CityLocation :{

  },
  mainHeader:{
    marginTop:"5%",
    display:'flex',
    alignItems:'center'
  },
  SecondBox:{
    width:"90%",
    margin:"3% auto"
  },
  PositionOrg:{
    width:'100%'
  },
  leftsideinputs:{
    display:'flex',
    alignItems:'end'
  },
  leftsideLable :{
    width:'100%'
  },
  leftSideLableDate :{
    width:'95%'
  }
      

};


export const PaginationComponent = styled(Pagination)({
  '& .MuiPaginationItem-page.Mui-selected': {
    color: "#03B5AA",
    background: "unset",
    fontWeight: 700

  },
 
  '& .MuiPaginationItem-root': {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400
  },
 
})


// Customizable Area End
