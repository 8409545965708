import React from "react";
// Customizable Area Start
import ContractController from "./ContractWebController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { contractImage } from "../../joblisting/src/assets";
import { Box, Chip,  CircularProgress,  Divider, Grid, IconButton, Tooltip, InputAdornment, Slider, styled, Tab, Tabs, TextField,  Typography, withStyles } from "@material-ui/core";
import { AppBarProposal } from "../../joblisting/src/JobListing.web";
import SearchIcon from '@material-ui/icons/Search';
import SortBy from "../../../components/src/SortBy.web";
import DoneIcon from '@material-ui/icons/Done';
import { webStyle2 } from "./Cfproposalmanagement.web";
import { NoResultFound ,CustomTabPanel, handleFirstLetter,returnCount, returnNotificationIcon,returnMsgIcon} from "../../../components/src/ProposalComponent";
import { profileIcon } from "./assets";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { handleColor } from "../../../../packages/components/src/Utils";
const Config = require("./config.js");

const config = require("../../../framework/src/config.js");

const PrettoSlider = withStyles({
 
  root: {
    color: 'unset',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&.MuiSlider-thumb.Mui-disabled':{
      width:"16px",
      height:'16px'
        },
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
    borderRadius: 4,
  },
  rail: {
    height: 8,
    border:"1px solid rgba(86, 86, 86, 1)",
    borderRadius: 4,
  },
})(Slider);

// Customizable Area End


class ContractWeb extends ContractController {
// Customizable Area Start

StyledTabs = styled(Tabs)({
  '& .MuiTabs-wrapper': {
    justifyContent: 'flex-start',
    flexDirection: 'row'
  },
});

SearchButton = styled(IconButton)({
  color: '#fff',
  textDecoration: 'underline',
  borderLeft: '1px solid #434343',
  fontFamily: "Inter , sans-serif",
  borderRadius: '0px',
  textTransform: 'none',
  fontSize: 14,
  '&:disabled': {
    color: '#888',
    opacity: 0.5, 
    textDecoration: 'none', 
  },
});

CustomTextField = styled(TextField)({
  height:"48px",
  borderRadius: 24,
  margin: "0px 0px 0px 0",
  backgroundColor: '#1c1c1c',
  fontFamily: "Inter , sans-serif",
  fontSize: 14,
  '& .MuiOutlinedInput-adornedEnd':{
    paddingRight:"34px",
paddingLeft:"31px"

  },

  '& .MuiOutlinedInput-root': {
    overflow: "hidden",
    height:'100%',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    color: '#fff',
    fontSize: 14,
    '&:placeholder': {
      textDecoration: 'italic',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#66625C',
  }
});

SearchIconStyled = styled(SearchIcon)({
  color: '#66625C',
  fontSize: 18
});
renderSection = () => {
  return <>
    <this.CustomTextField
      variant="outlined"
      size="small"
      id="find_work_search"
      data-test-id="find-text-elm"
      fullWidth
      onChange={this.handleFindWorkSearch}
      value={this.state.findWorkSearch}
      placeholder="Type here"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <this.SearchIconStyled />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {this.state.isSearched && <IconButton  id="clear-text" style={{ color: "white", marginRight: 20, fontFamily: "Inter , sans-serif", }} size="small">
              x
            </IconButton>}
            <this.SearchButton 
             >
              Search
            </this.SearchButton>
          </InputAdornment>
        ),
      }}
    />
  </>
}
returnCrossIcon=()=>{
  return <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 1.5L1 11.5" stroke="#FFF2E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 1.5L11 11.5" stroke="#FFF2E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
}

  mainContent = () => {
    return (
      this.state.activeData.length > 0 ? this.state.activeData.map((item: any) => {
        return <Grid item lg={3} md={5} sm={6}
          style={{ cursor: "pointer" }}
          className="offer_contract_grid" data-test-id="navigateContractDetail" onClick={() => this.navigateContractDetail(item.id)}>
          <Box className="offer_contract_gridSecond" >
            <Box
              className="timelineClass"
              style={{ marginBottom: '21px' }}
            >
              <Typography className="offerStyleForSubHeading" style={handleColor(item.attributes.milestone_status)}>
                {item.attributes.milestone_status &&
                  item.attributes.milestone_status.charAt(0).toUpperCase() +
                  item.attributes.milestone_status.slice(1)}
              </Typography>
              <Box style={{ display: 'flex', gap: '21px' }}>
                <Box style={{ position: "relative" }}>

                  {returnMsgIcon(item.attributes.message_count)}
                  {item.attributes.message_count > 0 && returnCount(item.attributes.message_count)}

                </Box>
                <Box style={{ position: "relative" }}>
                  {returnNotificationIcon(item.attributes.notification_count)}
                  {item.attributes.notification_count > 0 && returnCount(item.attributes.notification_count)}
                </Box>
              </Box>

            </Box>
            <Tooltip title={item.attributes.title}>
              <Typography noWrap className="offer_contract_heading">
                {item.attributes.title}
              </Typography>
            </Tooltip>
            <Box>
            </Box>
          </Box>
          <Box className="timelineClass">
            <Box>
              <Typography className="offer-received-sub-heading" style={{ fontSize: "14px" }}>
                {"Project timeline"}
              </Typography>
              <Typography className="offer-received-sub-sub-heading" style={{ fontSize: "12px" }}>
                {item.attributes.contract_started}
              </Typography>
            </Box>
            <Typography style={{ ...webStyle2.month, height: 'unset', wordBreak: 'break-all' }} >
              {item.attributes.project_timeline}{" "}
              {item.attributes.project_timeline_type}
            </Typography>
          </Box>
          <Box style={{ padding: "7px 28px" }}>
            <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={item.attributes.progress_percentage} disabled />
          </Box>
          <Box style={{ padding: "40px 28px" }}>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Typography className="offer_contract_milestone_active_heading" style={{ marginBottom: "8px", width: '45%' }}>
                Active milestone : {" "}
              </Typography>
              <Tooltip title={item.attributes.milestone_name}>
                <Typography noWrap style={{ fontWeight: 700, width: '60%', color: '#fff2e3' }}>{item.attributes.milestone_name}</Typography>
              </Tooltip>
            </Box>
            <Typography className="offer_contract_milestone_active_heading" >
              Remaining milestones : {" "}
              <span style={{ fontWeight: 700 }}>
                {item.attributes.remaining_milestones}
              </span>
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px', marginTop: "39px" }}>
              <Typography className="offer_contract_milestone_active_heading" >
                Budget : {" "}
                <span style={{ fontWeight: 700 }}>
                  ₹ {item.attributes.budget}
                </span>
              </Typography>
              <Typography className="offer_contract_milestone_active_heading" >
                Wallet :{" "}
                <span style={{ fontWeight: 700 }}>
                  ₹  {"2000"}
                </span>
              </Typography>
            </Box>

          </Box>
          <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)" }} />
          <Box style={{ display: 'flex', gap: '10px', padding: "24px 28px 19px 28px" }}>
            {this.getProfileDetailsRenderItem(item)}
            <Box style={{
              flexDirection: 'column',
              display: 'flex',
              gap: '4px',
            }}>
              <Typography
                className="offere_contract_user_profile_style">
                {item.attributes.profile_details.profile_user_name}
              </Typography>
              <Typography
                className="offere_contract_user_location_style">
                {item.attributes.profile_details.profile_location && handleFirstLetter(item.attributes.profile_details.profile_location)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      })
        :
        <NoResultFound />
    )
  }


getProfileDetailsRenderItem = (proposalData: any) => {
  return proposalData?.attributes?.profile_details?.profile_photo && proposalData.attributes.profile_details.profile_photo.includes("rails") 
    ? (
      <img src={config.baseURL + proposalData.attributes.profile_details.profile_photo} style={{ width: 44,
        height: 44,
        borderRadius: 22,
        marginRight: 8}} alt="Default Profile" />
    ) : (
      proposalData?.attributes?.profile_details?.profile_backgroud_color ? (
        <Box
          style={{ 
            width: 44,
    height: 44,
    borderRadius: 22,
    marginRight: 8,
            backgroundColor: proposalData.attributes.profile_details.profile_backgroud_color 
          }}
        />
      ) : (
        <img src={profileIcon} alt="Default Profile" style={{ width: 44,
          height: 44,
          borderRadius: 22,
          marginRight: 8}} />
      )
    );
};

  archievedContent = () => {
    return (this.state.archiveData.length > 0 ? this.state.archiveData.map((item: any) => {
      return <Grid item lg={3} md={5} sm={6} data-test-id="navigateArchiveContract" style={{ cursor: "pointer" }} className="offer_contract_grid" onClick={() => this.navigateArchiveContract(item.id)}>
        <Box >
          <Box style={{ display: 'flex', justifyContent: 'space-between', padding: "26px 27px 21px 27px", alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography className="offerStyleForSubHeading" style={handleColor(item.attributes.milestone_status)}>
              {
                item.attributes.milestone_status &&
                item.attributes.milestone_status.charAt(0).toUpperCase() +
                item.attributes.milestone_status.slice(1)
              }

            </Typography>
          </Box>
          <Typography className="offer_contract_heading">
            {item.attributes.title}
          </Typography>
          <Box>
          </Box>
        </Box>
        <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)" }} />
        <Box style={{ padding: "21px 27px", display: 'flex', gap: '24px', flexDirection: 'column' }}>
          <Typography className="offer_contract_milestone_active_heading" >
            Total earnings: {" "}
            <span style={{ fontWeight: 700 }}>
              ₹ {item.attributes.total_transactions}
            </span>
          </Typography>
          <Typography className="offer_contract_milestone_active_heading" >
            Ended on:{" "}
            <span style={{ fontWeight: 700 }}>
              {item.attributes.contract_ended}
            </span>
          </Typography>
          <Box style={{ display: 'flex', gap: "8px", alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography className="offer_contract_milestone_active_heading" >
              Duration:  {" "}
            </Typography>
            <Typography style={{ ...webStyle2.month, height: 'unset', wordBreak: 'break-all' }} >
              {item.attributes.project_timeline}{" "}

              {item.attributes.project_timeline_type}
            </Typography>
          </Box>
        </Box>
        <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)" }} />
        <Box style={{ display: 'flex', gap: '10px', padding: "24px 28px 19px 28px" }}>
          {this.getProfileDetailsRenderItem(item)}
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography className="offere_contract_user_profile_style">
              {item.attributes.profile_details.profile_user_name}
            </Typography>
            <Typography className="offere_contract_user_location_style">
              {item.attributes.profile_details.profile_location && handleFirstLetter(item.attributes.profile_details.profile_location)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    })
      :
      <NoResultFound />)
  }

renderPaginationArror = () => {
  let pageNo=this.state.tabValues==0?Math.ceil(this.state.activeData.length/8):Math.ceil(this.state.archiveData.length/8)
  return (
    (Math.ceil(this.state.totalData / 8) > pageNo ) &&
      <Box>
        <div className="MoreVectorIcon"  style={{cursor:'pointer'}}>
          <ExpandMoreRoundedIcon data-testId="paginationTestid" style={{
             width: "50px",
             height: "50px",
             background: "#171717",
             borderRadius: "50px",
             color: '#fff'
          }}
          onClick={()=>{
      this.getDataList(this.state.tabValues,this.state.selectedSort.value,this.state.filterDataActive,this.state.findWorkSearch,this.state.page)
          }} />
        </div>
      </Box>
  )
}

// Customizable Area End


  render() {
// Customizable Area Start
    return (
      <>
      <Box style={{background:"rgba(17, 17, 17)"}}>

      <NavigationMenu {...this.props} activeTab="contracts" />
      <Box sx={styles.responsiveStyles}>
      <Box className="imageContainer">
              <img src={contractImage} className="imagePoster"  alt="My Work Poster" />
      </Box>
      <Grid container style={{alignItems:'end',padding:"0px 64px"}}>
        <Grid lg={7} md={12} sm={12} item>
      <AppBarProposal position="static" style={{paddingLeft:"0px"}}>
      <TabsComponent value={this.state.tabValues}  data-test-id="handleTabValueChange" onChange={this.handleTabValueChange} aria-label="simple tabs example">

                      <Tab {...this.a11yProps23(0, Config.activeTab)} />
  <Tab {...this.a11yProps23(1, Config.archievedTab)} />
  </TabsComponent>
                   <Divider className="divider divider_for_tabs" />

                 </AppBarProposal>
        </Grid>
        <Grid lg={5} md={12} sm={12} item>
          <Box style={{display:'flex',width:'100%'}}>
            <Box style={{width:'75%'}}>
          {this.renderSection()}
            </Box>
            <Box style={{width:'25%',display:'flex',justifyContent:'end'}}>
          <SortBy selectedSort={this.state.selectedSort} handleApply={this.handleSortSelect} sortData={this.state.sortData}/>
            </Box>
          </Box>
          </Grid>
      </Grid>
   
      <CustomTabPanel value={this.state.tabValues} index={0}>

        <Box style={{padding:'0px 40px'}}>
          
      <Box style={{width:"100%",display:'flex',gap:'15px'}}>
    {
  this.state.filterDataActive.map((item:{key:number,name:string;select:boolean}) => {
    const select=this.handleStyle(item.select);
    return (
      <ChipStyle
      onClick={() => this.toggleSelect(item.key)}
      key={item.key}  
      data-test-id="toggleSelect"
      style={select}
        deleteIcon={<DoneIcon />}
        variant="outlined"
        label={<>
{item.select && 
this.returnCrossIcon()}
       { item.name}
        </>
        }
      
      />
    )
  })
}

    
    </Box>
    <Grid container style={{marginTop:'56px',display:'flex',gap:"40px",alignItems:'center'}}>  
    {
      this.state.loading ?
        <Box 
        style={{ 
          marginTop: '10%',
          width: '100%', 
          height:"80vh"
         }}>
          <Box style={{ width: '100%',
             justifyContent: 'center' ,
             display: 'flex', 
             alignItems: 'center', 
             }}>
            <CircularProgress className="gray_color" size={25} />
          </Box>
        </Box>:
     this.mainContent()
    }
      </Grid>
      {
          this.renderPaginationArror()
        }
        </Box>

                 </CustomTabPanel>
                 <CustomTabPanel value={this.state.tabValues} index={1} data-test-id={'find-text'}>
                 <Box style={{padding:'0px 40px'}}>
          
          <Box style={{width:"100%",display:'flex',gap:'15px'}}>
        {
      this.state.filterDataArchive.map((item:{key:number,name:string;select:boolean}) => {
        const select=this.handleStyle(item.select);
        return (
          <ChipStyle
          data-test-id="toggleSelectArchive"
          onClick={() => this.toggleSelectArchive(item.key)}
            key={item.key}  
            style={select}
            label={<>
    {item.select && this.returnCrossIcon()}
           { item.name}
            </>
            }
            deleteIcon={<DoneIcon />}
            variant="outlined"
          />
        )
      })
    }
    
        
        </Box>
        <Grid container style={{marginTop:'56px',display:'flex',gap:"40px",alignItems:'center'}}>  
        {
           this.state.loading ?
           <Box style={{ width: '100%', marginTop: '10%',
            height:"80vh"

            }}>
             <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <CircularProgress className="gray_color" size={25} />
             </Box>
             <Typography className="gray_color" style={{ marginTop: '20px', textAlign: 'center' }}>Searching...</Typography>
           </Box>:
     this.archievedContent()
    
        }
          </Grid>
        {
          this.renderPaginationArror()
        }
            </Box>
                 </CustomTabPanel>
        </Box>
      </Box>

      </>
    );
// Customizable Area End

  }
}
// Customizable Area Start
const styles = {
  responsiveStyles: {
    width: "100%",
    boxSizing: "border-box",
    maxWidth: "1920px",
    margin: "0 auto",
    "& .imageContainer": {
      paddingTop: "76px",
      boxSizing: "border-box",
      width: "100%",
      height: "403px",
      "@media(min-width: 1920px)": {
        width: "calc(100% - 48px)",
        paddingLeft: "48px",

      }
    },
    "& .imagePoster": {
     width:"100%",
     height: "100%",
     objectFit: "cover",
    }
  }
}
const ChipStyle=styled(Chip)({
  '& .MuiChip-label':{
    gap:"4px",
    display:'flex',
    padding:"11px 22px"

  }
})

const TabsComponent=styled(Tabs)({
  '& .MuiTabs-indicator':{
background:'unset'
  }
})
export default ContractWeb;
// Customizable Area End

