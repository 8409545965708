import {
    Box, Button, CircularProgress, Dialog, Divider, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import { Deliverable, Milestone, RazorpayOrderResponse } from '../../blocks/cfdesignersidecontractmanagement/src/ContractDetailsController.web';
import { styled, withStyles } from '@material-ui/styles';
import { formatWalletAmount } from './Utils';

interface ActivateMilestoneModalProps {
    open: boolean;
    handleClose: () => void;
    milestone: Milestone;
    contractId: string | number;
    index: number;
    handleWalletActivation: () => void;
    handleRazorPayActivation: () => void;
    razorPayOrderData: RazorpayOrderResponse;
    taxValues: {
        loadWallet: number,
        gst: number,
    };
    walletBalance: number | string;
    isActivatingLoader: boolean
}

interface StateProp {
    currentRadio: string;
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "750px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px"
        },
    },
});

const CustomFormControlLabel = withStyles({
    root: {
        "& .MuiFormControlLabel-label": {
            color: "#FFF2E3 !important",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600
        }
    },
})(FormControlLabel)

const CustomRadio = withStyles({
    root: {
        color: "#FFF2E3",
        "&.Mui-checked": {
            color: "#FFF2E3",
        },
    },
    "& .MuiFormControlLabel-label": {
        color: "#FFF2E3 !important"
    }
})(Radio);

export default class ActivateMilestone extends Component<ActivateMilestoneModalProps, StateProp> {
    constructor(props: ActivateMilestoneModalProps) {
        super(props);
        this.state = {
            currentRadio: "wallet"
        };
    }

    handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void = (event) => {
        this.setState({
            currentRadio: event.target.value
        })
    }

    render() {
        const { handleClose, milestone, open, index, handleWalletActivation, handleRazorPayActivation } = this.props;

        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px", padding: "30px 20px 40px 50px" }}>
                    <Box
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: "20px",
                        }}
                    >
                        <Typography style={{ fontFamily: "Inter", fontSize: "20px", color: "#FFF2E3", fontWeight: 600 }}>Activate milestone</Typography>
                        <IconButton onClick={handleClose} style={{ paddingRight: "30px" }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 1L1 15" stroke="#FFF2E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 1L15 15" stroke="#FFF2E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </IconButton>
                    </Box>
                    <Grid container>
                        <Grid item sm={5} xs={12}>
                            <Box style={{ background: "transparent", borderRadius: "10px", border: "0px solid #494949", backgroundColor: "#2F2F2F" }}>
                                <Box style={{ padding: "20px" }}>
                                    <span style={{ display: "flex", alignItems: "center", cursor: "pointer", width: "100%" }}>
                                        <span style={{
                                            height: "30px",
                                            width: "30px",
                                            boxShadow: "-4px 4px 4px 0px #00000040",
                                            borderRadius: "50%",
                                            background: "#FFF2E3",
                                            color: "#2F2F2F",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontWeight: 600
                                        }}>
                                            {index}
                                        </span>
                                        <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, paddingLeft: "10px", paddingRight: "5px", fontFamily: "inter", }}>
                                            {milestone.name}
                                        </span>
                                    </span>
                                    <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "20px 0px 0px 0px" }}>
                                        <span style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                            <svg style={{ paddingRight: "10px" }} width="22" height="24" viewBox="0 0 22 24" fill="none">
                                                <rect x="1" y="4.66797" width="19.9127" height="18.3333" rx="2" stroke="#FFF2E3" />
                                                <path d="M1 8.66797C1 6.78235 1 5.83954 1.58579 5.25376C2.17157 4.66797 3.11438 4.66797 5 4.66797H16.9127C18.7983 4.66797 19.7411 4.66797 20.3269 5.25376C20.9127 5.83954 20.9127 6.78235 20.9127 8.66797V9.55686H1V8.66797Z" fill="#FFF2E3" />
                                            </svg>
                                            <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                                {milestone.due_date}
                                            </span>
                                        </span>
                                        <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                            ₹ {milestone.amount}
                                        </span>
                                    </span>
                                </Box>
                                <Divider style={{ width: "100%", height: "1.2px", background: "#494949" }} />
                                <Typography style={{
                                    fontFamily: "inter",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: "#FFF2E3",
                                    padding: "20px"
                                }}>
                                    {milestone.description}
                                </Typography>
                                <Box style={{ padding: "0px 20px 20px 20px", }}>
                                    <Typography style={{ color: "#7D7D7D", fontSize: "12px", fontWeight: 400, fontFamily: "inter" }}>
                                        Deliverables:
                                    </Typography>
                                    {milestone.deliverables.filter((item => !!item.deliverable)).map((item: Deliverable, index: number) => (
                                        <Typography key={item.id} style={{ paddingTop: "10px", fontSize: "12px", fontWeight: 400, color: "#FFF2E3", fontFamily: "inter", }}>
                                            {index + 1}. {item.deliverable}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={7} xs={12}>
                            <Box style={{ display: "flex", flexDirection: "column", padding: "0px 20px 0px 30px" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            color: "#FFF2E3",
                                            fontFamily: "Inter"
                                        }}
                                    >
                                        Pay amount:
                                    </Typography>
                                    <Typography style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                        ₹ {milestone.amount}
                                    </Typography>
                                </Box>
                                <Divider style={{ width: "100%", height: "1.5px", background: "#494949", margin: "20px 0px" }} />
                                <RadioGroup
                                    name="paymentMethod"
                                    value={this.state.currentRadio}
                                    onChange={this.handleChange}
                                    style={{ gap: "20px", marginBottom: "20px" }}
                                >
                                    <CustomFormControlLabel
                                        value="wallet"
                                        control={<CustomRadio />}
                                        label="Use your available balance"
                                    />
                                    {
                                        (milestone.amount > +this.props.walletBalance) ? <Typography
                                            style={{
                                                color: 'red',
                                                marginTop: '-28px',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                paddingLeft: '32px'
                                            }}
                                        >
                                            Insufficient balance please try other payment methods
                                        </Typography> : <Typography
                                            style={{
                                                color: '#585858CC',
                                                marginTop: '-28px',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                paddingLeft: '32px'
                                            }}
                                        >
                                            Use your Rs.{formatWalletAmount(this.props.walletBalance)} from your DK wallet balance
                                        </Typography>
                                    }


                                    <CustomFormControlLabel
                                        value="razorPay"
                                        control={<CustomRadio />}
                                        label="Other Payment Methods"
                                    />
                                </RadioGroup>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        padding: "15px",
                                        marginBottom: "30px",
                                        background: "rgb(47, 47, 47)",
                                        borderRadius: "8px"
                                    }}>
                                    {
                                        this.state.currentRadio === "razorPay" && Object.keys(this.props.razorPayOrderData).length > 0 && <>
                                            <Box style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                color: '#FFF2E3',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                flex: 1
                                            }}>
                                                <Typography
                                                >
                                                    Base Amount
                                                </Typography>
                                                <Typography>
                                                    INR {this.props.razorPayOrderData.wallet.wallet_amount}
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                color: '#FFF2E3',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                flex: 1
                                            }}>
                                                <Typography
                                                >
                                                    Processing Fee ({this.props.taxValues.loadWallet}%)
                                                </Typography>
                                                <Typography>
                                                    INR {this.props.razorPayOrderData.wallet.processing_fee_amount}
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                color: '#FFF2E3',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                flex: 1
                                            }}>
                                                <Typography
                                                >
                                                    Tax and GST ({this.props.taxValues.gst}%)
                                                </Typography>
                                                <Typography>
                                                    INR {this.props.razorPayOrderData.wallet.gst_amount}
                                                </Typography>
                                            </Box>
                                            <Divider style={{ width: "100%", height: "1.2px", background: "#494949", margin: "5px 0px" }} />
                                        </>
                                    }

                                    <Box style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        color: '#FFF2E3',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        flex: 1
                                    }}>
                                        <Typography
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Total to be paid
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            INR {this.state.currentRadio === "razorPay" ? this.props.razorPayOrderData.wallet.total_amount : milestone.amount}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Button
                                    data-test-id="submit-pay-now-btn"
                                    style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        borderRadius: "7px",
                                        padding: "10px 30px",
                                        fontFamily: "'Inter', sans-serif",
                                        textTransform: "none",
                                        cursor: "pointer",
                                        background: "rgba(255, 242, 227, 1)",
                                        color: "#121212",
                                        marginRight: "10px",
                                        display: "flex",
                                        alignItems: "center"
                                    } as React.CSSProperties}
                                    disabled={((milestone.amount > +this.props.walletBalance) && this.state.currentRadio === "wallet") || this.props.isActivatingLoader}
                                    onClick={() => {
                                        if (this.state.currentRadio === "wallet") {
                                            handleWalletActivation();
                                        } else {
                                            handleRazorPayActivation();
                                            handleClose();
                                        }
                                    }}
                                >
                                    {this.props.isActivatingLoader && <CircularProgress style={{ color: "#121212", height: "25px", marginRight: "10px", width: "25px" }} />} Pay now
                                </Button>

                            </Box>
                        </Grid>
                    </Grid>


                </Box>
            </StyledDialog>
        );
    }
}
