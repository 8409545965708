// Customizable Area Start
import React from "react";
import { Box, Typography,Grid, Card, CardContent, ImageList, ImageListItem, withStyles, Button, styled, Modal } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import PublicUserProfileController, { Props,ProfileDetails,configJSON, ForumPostData, CommentData,ReplyCommentData } from "../../blocks/user-profile-basic/src/PublicUserProfile/PublicUserProfileController.web";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { insta, linkdin, dribble, share, edit, profileImg, rating, expertise, rightArrow,userLogo, closeIcon,addImgeIcon} from "../../blocks/user-profile-basic/src/assets"; 
const config = require("../../framework/src/config.js");
import { profileIcon } from "../../blocks/cfproposalmanagement/src/assets";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { eyeIcon, likeIcon, solidShareIcon, likedIcon } from "../../blocks/landingpage/src/assets";
const customStyle = require('../../blocks/landingpage/src/discoverproject/customStyle.js');
import ToastMSG from "../src/ToastMSG.web";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ShareDialog from "../../blocks/landingpage/src/components/ShareDialog";

const webStyle: Record<string, CSSProperties> = {
  content: {
    color: "#d4d4d8",
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: 24,
    marginTop: '18px'
  },
  engagementBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #27272a",
    paddingTop: 16,
  },
  stats: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  statItem: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    color: "#a1a1aa",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  iconButton: {
    color: "#a1a1aa",
    textTransform: "none",
  },
  commentButton: {
    backgroundColor: "#3f3f46",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#52525b",
    },
  },
  imageSliderContainer: {
    overflowX: "hidden",
    width: "100%",
    scrollbarWidth: 'none'
  },
  playIcon: {
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "white",
    width: "75px",
    height: "75px",
    cursor: 'pointer'
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0
  },
  overlayItem: {
    position: "relative",
    cursor: "pointer",
    opacity: '0.5',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    marginBottom: 16,
  },
  headerInfo: {
    display: "flex",
    flexDirection: "column",
  },
  timestamp: {
    color: "#a1a1aa",
    fontSize: 14,
    cursor: 'pointer'
  },
  card: {
    backgroundColor: "#171717",
    color: "#fff",
    borderRadius: 16,
  },
  commentContainer: {
    margin: "0 auto",
    padding: "20px 0",
  },
  cursor: {
    cursor: "pointer",
  },
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh',
    "& *": {
      boxSizing: "border-box",
    }
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    boxSizing: "border-box",
    "& .ImgprofileResp": {
      maxWidth: "619px !important"
    },
    "& .body-container": {
      margin: "120px 50px 40px 50px !important",
    },
  },
  Imgprofiles: {
    position: "relative",
    marginTop: "-47px"
  },
  photoStyle: {
    width: "100px", height: "100px", borderRadius: "50%"
  },
  educationQualification: {
    width: '70%',
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Inter",
    lineHeight: "16px",
    color: "#666666",
    textAlign: "end"
  },
  educationDescription: {
    width: "30%",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '20px'
  },
  profileImageBoxes: {
    height: "100px",
    width: "100px",
    borderRadius: "50px",
  } as any,

  imageBackgrounds: {
    width: "600px",
    borderRadius: "24px 24px 0px 0px",
    heigth: "220px",
    minWidth: "400px"
  },
  editIcon: {
    cursor: "pointer"
  },
  skillContainer: {
    gap: '8px',
    display: 'flex',
    width: '100%',
  },
  skillChip: {
    minWidth: 'max-content',
  },
  defaultText: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    linehHeigth: "24px",
    color: '#535353',
  },
  pointerCursorStyle: {
    cursor: "pointer",
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40vw',
    border: 'none',
    focus: 'none',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: 'transparent',
  },
  ModalPlayerWrapper: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '52px'
  },
  cancelButton: {
    position: "absolute",
    top: "-7%",
    right: "9%",
    background: "transparent",
    color: "white",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    zIndex: 10,
  },
}

const ImageListWrapper = styled(Box)({
  "& .imageList": {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  "& .scrollContainer": {
    '&::-webkit-scrollbar': {
      height: '10px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFF2E3',
      borderRadius: '10px',
      border: '2px solid transparent',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#0D0D0D',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
  },
});

const CustomBox = withStyles((theme) => ({
  root: {
    '& .default-text-reply-comment': {
      fontFamily: "Inter,sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      color: "#fff2e3",
      textAlign: "justify",
      wordWrap: "break-word",
      wordBreak: 'break-word',
      width: "94%"
    },
    '& .new-user-comment-reply-container': {
      width: "90%",
      marginBottom: "24px",
      marginTop: "24px",
      display: "flex",
      flexDirection: "row",
      marginLeft: "74px"
    },
    '& .reply-comment-container-new': {
      maxWidth: "810px", display: "flex", alignItems: "center", marginTop: "-4%"
    },
    '& .cancel-btn': {
      fontFamily: "Inter-SemiBold,sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      color: "#fff",
      width: "max-content",
      cursor: "pointer",
      borderBottom: "1px solid #fff"
    },
    '& .newOneTextAreaReply': {
      overflow: 'scroll',
      scrollbarWidth: 'none',
      background: '#222222',
      width: '84%',
      border: '1px solid #222222',
      resize: 'none',
      borderRadius: '50px',
      height: '45px',
      outline: 'none',
      color: '#FFF2E3',
      paddingLeft: '20px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px'
    },
    '& .d-none': {
      display: "none"
    },
    '& .reply-btn': {
      fontFamily: "Inter-SemiBold,sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      color: "#fff2e3",
      borderBottom: "1px solid #fff2e3",
      width: "max-content",
      cursor: "pointer",
      marginLeft: "8px",
      height: "max-content"
    },
    '&.commented-data-user': {
      display: "flex",
      flexDirection: "column",
      gap: "25px",
      maxWidth: "1084px"
    },
    '&.new-user-comment-container': {
      marginBottom: "24px",
      marginTop: "24px",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    '&.commented-user': {
      display: "flex",
      gap: "24px",
      width: "86%"
    },
    '& .commented-by': {
      marginRight: "16px",
      color: "#ffffff !important"
    },
    '& .default-text-new': {
      fontSize: "16px",
      fontFamily: "Inter,sans-serif",
      color: "#fff2e3",
      lineHeight: "19.36px",
      maxWidth: "740px",
      marginRight: "15px",
      textAlign: "justify",
      wordWrap: "break-word"
    },
    '& .commented-at': {
      marginRight: "8px",
      marginBottom: "0 !important"
    },
    '& .description-label': {
      maxWidth: "1008px",
      marginBottom: "16px",
      color: "#8d8d8d !important"
    },
    '& .comment-text': {
      marginTop: "12px",
      color: "#8c8c8c !important"
    },
    '& .commented-by-user': {
      width: "52.98px",
      height: "52.98px",
      borderRadius: "50%",
      cursor: 'pointer'
    },
    '& .progressbarContainer': {
      position: 'absolute',
      top: '49%',
      left: '28%',
      width: '44%',
      backgroundColor: '#111',
      zIndex: 22,
      height: '7px'
    },
    '& .progressbar': {
      position: 'absolute',
      height: '100%',
      maxWidth: '100%',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
    },
    '& .delete-profile': {
      display: 'flex',
      width: '25px',
      height: '25px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 'auto',
      borderRadius: '50%',
      marginTop: '-38px',
      color: 'rgb(140, 140, 140)'
    },
    '& .profile-image-container': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .newOneTextArea': {
      overflow: 'scroll',
      scrollbarWidth: 'none',
      background: '#171717',
      width: '84%',
      border: '0px',
      resize: 'none',
      borderRadius: '50px',
      height: '45px',
      outline: 'none',
      color: '#FFF2E3',
      paddingLeft: '20px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px'
    },
    '&.discover-project-container': {
      backgroundColor: "#111111",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      paddingBottom: "40px"
    },
    '&.discover-project-container .card-container': {
      maxWidth: "94%",
      margin: "20 auto 0 auto",

    },
    '&.discover-project-container .select-btn': {
      backgroundColor: "transparent",
      fontFamily: "Inter-SemiBold, sans-serif",
      fontSize: "14px",
      lineHeight: "16.94px",
      display: "flex",
      alignItems: "center",
      color: "#fff2e3",
      justifyContent: "center"
    },
    '&.discover-project-container .single-select-container .select-btn': {
      border: "none",
      padding: "0",
      justifyContent: "flex-start"
    },
    '&.discover-project-container .manage-gap': {
      columnGap: "19px"
    },
    '&.discover-project-container .card-style': {
      maxWidth: "100%",
      height: "12rem",
      borderRadius: "10px",
      marginBottom: "8px"
    },
    '&.discover-project-container .project-title': {
      paddingRight: "10px"
    },
    '&.discover-project-container .d-none': {
      display: "none"
    },
    '&.discover-project-container .card-style img': {
      width: "100%",
      height: "100%",
      borderRadius: "10px"
    },
    '&.discover-project-container .options-position': {
      marginRight: "19px",
      marginTop: "33px",
      cursor: "pointer"
    },
    '&.discover-project-container .title-container': {
      width: "378.33px !important"
    },
    '&.discover-project-container .view-more-btn': {
      width: "145px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "55px"
    },
    '&.discover-project-container .view-more-btn:active': {
      backgroundColor: "#252525"
    },
    '&.discover-project-container .select-container,&.discover-project-container .tags-container': {
      display: "flex",
      alignItems: "center",
      columnGap: "24px",
      marginTop: "40px"
    },
    '&.discover-project-container .tags-container': {
      flexWrap: "nowrap",
      overflow: "auto",
      scrollbarWidth: "none",
      MsOverflowStyle: "none"
    },
    "&.no_post_container": {
      textAlign: 'center', margin: '50px'
    },
    "& .no_projects_container": {
      display: 'flex', width: "100%", justifyContent: "center", alignItems: 'center'
    }, "& .no_project_text": {
      color: "#fff2e3", fontSize: 25, fontWeight: 500
    },
    '& .MoreVectorIcon': {
      textAlign: 'center',
      paddingBottom: '20px',
      cursor: 'pointer'
    },
    '& .MoreVectorIcon svg': {
      width: "50px",
      height: "50px",
      background: "#171717",
      borderRadius: "50px",
      color: '#fff'
    },
    '&.discover-project-container .heading-text': {
      fontFamily: "Inter-SemiBold,sans-serif",
      fontSize: "36px",
      lineHeight: "43.57px",
      color: "#fff2e3"
    },
    [theme.breakpoints.between(1370, 1460)]: {
      '&.discover-project-container .project-title': {
        maxWidth: "114px",
        paddingRight: "6px"
      },
      '&.discover-project-container .manage-gap': {
        columnGap: "10px"
      },
      '&.discover-project-container .title-container': {
        width: "288px !important"
      },
      '&.discover-project-container .card-text': {
        fontSize: "10px"
      }
    },
    ...customStyle.customStyle(theme)
  }
}))(Box);

const CustomButton = withStyles({
  root: {
    textTransform: "none",
    '&.add-comment-btn': {
      height: "50px",
      border: "none",
      borderLeft: "1.29px solid #3C3C3C",
      backgroundColor: "transparent",
      borderTopRightRadius: "37.47px",
      borderBottomRightRadius: "37.47px",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "15%",
      '& .comment-data': {
        fontFamily: "Inter-SemiBold,sans-serif",
        fontSize: "16px",
        lineHeight: "19.36px",
        color: "#fff2e3",
        borderBottom: "1px solid #fff2e3"
      }
    },
    '&.post-btn': {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#121212",
      backgroundColor: "#fff2e3",
      width: '84px',
      "&:hover": {
        backgroundColor: "#FFF2E3",
      }
    }
  }
})(Button);

class UserActivity extends PublicUserProfileController {
  renderPaginationArror = () => {
    return (
      ((this.state.pageOfPost * this.state.perPageOfPost) < this.state.totalPostofForum) &&
      <CustomBox>
        <div className="MoreVectorIcon">
          <ExpandMoreRoundedIcon data-testId="paginationTestid" 
            onClick={this.getPostForumScroll}
          />
        </div>
      </CustomBox>
    )
  }

  renderPhoto = (mainPhoto: string | undefined, backgroundColor: string | undefined) => {
    let content;
    if (mainPhoto) {
      content = (
        <img
          className="profile_css"
          style={{ borderRadius: "50%", marginRight: 8 }} alt="Default Profile" src={`${config.baseURL}${mainPhoto}`} />
      );
    } else if (backgroundColor) {
      content = (
        <Box
          className="profile_css"
          style={{ borderRadius: "50%", marginRight: 8, backgroundColor: backgroundColor }}></Box>
      );
    } else {
      content = (
        <img
          src={profileIcon} alt="Default Profile" className="profile_css" style={{ borderRadius: "50%", marginRight: 8 }} />
      );
    }
    return <>
      {content}
    </>;
  }

  renderPaginationButton = (index: number, id: number) => {
    return (
      ((this.state.forumData[index].attributes.commentPage * this.state.commentPerPage) < this.state.forumData[index].attributes.commentTotalPage) &&
      <Box>
        <div className="MoreVectorIcon">
          <Button style={webStyle.commentButton} data-test-id="load-more" onClick={() => this.getCommentScroll(index, id)}>Load More</Button>
        </div>
      </Box>
    )
  }

  HanndleImageComment = (comment: CommentData | ReplyCommentData) => {
    return (
      <div data-testId="NavigationDiv" onClick={() => this.HandleNavigation({ collection_id: 0, collection_name: '', user_role: comment.attributes.user_role, userId: comment.attributes.account_id }, this.state.UserInfoData.meta.id)}>
        {comment.attributes.profile_photo &&
          <img src={`${config.baseURL + comment.attributes.profile_photo}`} className="commented-by-user" alt="commented by user icon" />
        }
        {(!comment.attributes.profile_photo && comment.attributes.background_color_code) && (
          <div className="commented-by-user" style={{ background: `${comment.attributes.background_color_code}` }}></div>
        )

        }
        {(!comment.attributes.profile_photo && !comment.attributes.background_color_code) &&
          <img src={profileIcon} className="commented-by-user" alt="commented by user icon" />
        }
      </div>
    )
  }

  showReplyBtn = (comment: CommentData) => {
    return this.state.showReply !== comment.attributes.id ? "" : "d-none";
  }

  showReplyViewBtn = (data: boolean) => {
    return data ? "" : "d-none";
  }

  showReplyElm = (id: string | number) => {
    return (this.state.showReply !== Number(id)) ? { display: "none" } : { display: "flex", gap: "28px", alignItems: "center" }
  }

  showCommentList = (id: number, allComentData: CommentData[], index: number) => {
    const { commentOpenArray } = this.state;
    return (
      <>
        {allComentData.map((comment, index) =>
          commentOpenArray[id] &&
          <CustomBox key={`comment_id${comment.id}`} className="commented-data-user">
            <CustomBox className="new-user-comment-container">
              <CustomBox className="commented-user">
                {this.HanndleImageComment(comment)}
                <CustomBox style={{ width: "100%" }}>
                  <CustomBox style={{ display: "flex" }}>
                    <Typography className="commented-by default-text-new">{this.state.UserInfoData.meta.id == comment.attributes.account_id ? "You" : comment.attributes.account_user_name}</Typography>
                    <Typography className="commented-at default-text-new description-label">{comment.attributes.commented_time_ago?.replace('commented', '')}</Typography>
                    <Typography data-test-id="action-elm" onClick={() => this.handleReplyClick(comment.attributes.id)} className={`reply-btn pointer-cursor ${this.showReplyBtn(comment)}`}>Reply</Typography>
                    <Typography data-test-id="action-elm-new" onClick={() => this.getReplyList(comment.id, id)} className={`reply-btn pointer-cursor ${this.showReplyViewBtn(comment.attributes.has_reply)}`}>View Reply</Typography>
                  </CustomBox>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography className="comment-text default-text-new" style={{ width: "95%" }}>{comment.attributes.comment}</Typography>
                  </Box>
                </CustomBox>
              </CustomBox>
            </CustomBox>
            {this.handleReply(comment.attributes.id) && comment.attributes.commentReplyData.map((reply) =>
              <CustomBox className="reply-comment-container-new">
                <CustomBox className="new-user-comment-reply-container">
                  {this.HanndleImageComment(reply)}
                  <CustomBox style={{ marginLeft: "20px", marginRight: "20px" }}>
                    <Typography className="commented-by default-text-new" style={{ display: "flex" }}>{reply.attributes.account_id == this.state.UserInfoData.meta.id ? "You" : reply.attributes.account_user_name} <Typography className="commented-at default-text description-label" style={{ marginLeft: "16px" }}>{reply.attributes.commented_time_ago?.replace('commented', '')}</Typography></Typography>
                    <Typography className="comment-text default-text-reply-comment">{reply.attributes.comment}</Typography>
                  </CustomBox>

                </CustomBox>
              </CustomBox>)}
            <CustomBox sx={{
              ...this.showReplyElm(comment.id)
            }}>
              <textarea
                className="newOneTextAreaReply"
                data-test-id="input-reply-data"
                value={this.state.replyText}
                onChange={(event) => this.handleReplyChange(event.target.value)}
                onKeyDown={(event) => this.handleReplyAdded(event, id)}
                rows={2}
              />

              <Typography data-test-id="cancel-reply" onClick={() => this.handleCancelReply()} className="cancel-btn">Cancel</Typography>
            </CustomBox>
          </CustomBox>)}
        {commentOpenArray[id] && this.renderPaginationButton(index, id)}
      </>
    )
  }

  handleLikedData = (like: boolean) => {
    return like ? likedIcon : likeIcon;
  }

  toggleLike = (like: boolean, id: number, like_id: number) => {
    this.setState((prevState) => {
      const { commentOpenArray, forumData } = prevState;
      const updatedShowAll = { ...commentOpenArray };
      if (id in updatedShowAll) { delete updatedShowAll[id]; }
      return {
        commentOpenArray: updatedShowAll, showReply: "",
      }
    })
    if (like) {
      this.disLikeProject(like_id);
    } else {
      this.postViewLike(id, "like")
    }
  }

  videoPlayer = () => {
    return (
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.openVideo}
        onClose={this.handleModelCloseOnCross}
      >
        <Box style={webStyle.modalStyle}>
          <Box>
            <div style={webStyle.ModalPlayerWrapper}>
              <button style={webStyle.cancelButton}
                onClick={this.handleModelCloseOnCross}
              >
                <CloseOutlinedIcon fontSize="small" />
              </button>
              <video
                controls={true}
                width="80%"
                height='auto'
                src={config.baseURL + this.state.selectedVideoSrc}
              >
              </video>
            </div>
          </Box>
        </Box>
      </Modal>
    )
  }

  commentData = (data: ForumPostData[]) => {
    const { showAll } = this.state;
    const visibleImages = 3;
    return data.length > 0 ?
      data.map((item, index) => {
        const remainingImages = item.attributes.images.length - visibleImages;
        return (
          <div style={webStyle.commentContainer} key={item.id}>
            <Card style={webStyle.card}>
              <CardContent>
                <div style={webStyle.header}>
                  <div data-test-id="renderimg" style={{ cursor: 'pointer' }} onClick={() => this.HandleNavigation({ collection_id: 0, collection_name: '', user_role: item.attributes.user_details.role, userId: item.attributes.user_details.id }, this.state.UserInfoData.meta.id)}>
                    {this.renderPhoto(item.attributes.user_details.profile_photo,
                      item.attributes.user_details.background_color)}
                  </div>
                  <div style={webStyle.headerInfo}>
                    <Typography variant="subtitle1">{item.attributes.user_details.name}</Typography>
                    <Typography style={webStyle.timestamp}>{item.attributes.create_time}</Typography>
                  </div>
                </div>

                <ImageListWrapper style={{ ...webStyle.imageSliderContainer, overflowX: showAll[item.attributes.id] ? "auto" : "hidden" }}>
                  <ImageList className="imageList scrollContainer" cols={4}>
                    {item.attributes.media.slice(0, visibleImages).map((itemImages, index) => (
                      <ImageListItem key={index}>
                        <img src={`${config.baseURL + itemImages.src}`} style={{ position: "relative" }} alt={`Image ${index}`} />
                        {itemImages.type === "video" &&
                          <PlayCircleOutlineIcon style={webStyle.playIcon} onClick={() => { this.handleModelOpen(itemImages.videoUrl) }} data-testId="paginationTestid" />
                        }
                      </ImageListItem>
                    ))}

                    {!showAll[item.attributes.id] && remainingImages > 0 && (
                      item.attributes.media.slice(visibleImages, visibleImages + 1).map((itemImages, index) => (
                        <ImageListItem onClick={() => this.handleShowAll(item.attributes.id)} style={webStyle.overlayItem}>
                          <img src={`${config.baseURL + itemImages.src}`} alt={`Image ${index}`} />
                          <div style={webStyle.overlay}>+{remainingImages}</div>
                        </ImageListItem>
                      ))
                    )}

                    {showAll[item.attributes.id] &&
                      item.attributes.media.slice(visibleImages).map((itemImages, index) => (
                        <ImageListItem key={index + visibleImages}>
                          <img src={`${config.baseURL + itemImages.src}`} alt={`Image ${index + visibleImages}`} />
                          {itemImages.type === "video" &&
                            <PlayCircleOutlineIcon style={webStyle.playIcon} onClick={() => { this.handleModelOpen(itemImages.videoUrl) }} data-testId="paginationTestid" />
                          }
                        </ImageListItem>
                      ))}
                  </ImageList>
                </ImageListWrapper>

                <Typography style={webStyle.content}>
                  {item.attributes.post_description}
                </Typography>

                <div style={webStyle.engagementBar}>
                  <div style={webStyle.stats}>
                    <div style={webStyle.statItem}>
                      <img className="eyeIcon" src={eyeIcon} alt="eye icon" />
                      <span>{item.attributes.views}</span>
                    </div>
                    <div style={webStyle.statItem}>
                      <img className="likeIcon" src={this.handleLikedData(item.attributes.is_like)} style={{ cursor: 'pointer', height: 18, width: 19 }} alt="like icon" data-test-id="unlike" onClick={() => this.toggleLike(item.attributes.is_like, item.attributes.id, item.attributes.like_id)} />
                      <span>{item.attributes.likes}</span>
                    </div>
                    <Button data-test-id="sharepost" onClick={()=>{this.handleShareModal(item.attributes.id)}} startIcon={<img src={solidShareIcon} />} style={webStyle.iconButton} size="small">
                      Share
                    </Button>
                  </div>
                  <div style={webStyle.actions}>
                    <Typography style={webStyle.timestamp} data-test-id="getComment" onClick={() => { this.getComment(index, item.attributes.id) }}>{item.attributes.comments} Comments</Typography>
                  </div>
                </div>
                {this.showCommentList(item.attributes.id, item.attributes.commentData, index)}
                <CustomBox sx={{
                  height: "50px",
                  marginTop: '10px',
                  borderRadius: "37.47px",
                  border: "1.29px solid #2F2F2F",
                  display: "flex",
                  alignItems: 'center'
                }}>
                  <textarea
                    className="newOneTextArea"
                    data-test-id="comment-text-elm"
                    value={this.state.commentTextArray[item.attributes.id] || ""}
                    onChange={(event) => this.handleComment(item.attributes.id, event.target.value)}
                    rows={2}
                    onKeyDown={(event) => this.handleKeyPress(event, item.attributes.id)}
                  />
                  <CustomButton data-test-id="add-comment" disabled={!this.enableCommentBtn(item.attributes.id)} className="add-comment-btn" onClick={() => this.postComment(item.attributes.id)}>
                    <Typography className="comment-data">Comment</Typography>
                  </CustomButton>
                </CustomBox>
              </CardContent>
            </Card>
          </div>
        )
      })
      :
      <CustomBox className="no_post_container">
        <Typography
          id="no_projects_to_show"
          className="no_project_text"
        >
          No Post To Show
        </Typography>
      </CustomBox>
  }

  shareDialog = () => {
    return (
      <ShareDialog
        open={this.state.shareDialog}
        onClose={() => this.handleShareModal()}
        url={`${document.location.origin}/Forum/${this.state.shareSelectedProjectId}`}
        title={"Deziner's knot"}
        description={"Designer's Forum Post"}
        coverImage=''
        ForumPostContent={this.getProjectIDForumPostContent()}
      />
    )
  }
  
  render() {
    return (
     <div>
         {this.commentData(this.state.forumData)}
         {this.renderPaginationArror()}
         {this.videoPlayer()}
         {this.shareDialog()}
        <ToastMSG data-test-id="toastMessage" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
     </div>
    );
  }
}

export default UserActivity;

// Customizable Area End
