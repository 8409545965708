import React from "react";
// Customizable Area Start
import {
  StyleSheet,
} from "react-native";
import PostCreationController, {Props, SkillItem, ForumPostData,CommentData,ReplyCommentData} from "./PostCreationController.web";
import { Box,CircularProgress, Grid, IconButton, withStyles, styled, Typography, Modal, RadioGroup,FormControlLabel, Radio, Card, CardContent, Button, Tab, Tabs, ImageList, ImageListItem, InputLabel, Input, Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ProfileCard from "../../../components/src/ProfileCard.web"
import { eyeIcon, likeIcon, featureIcon, solidShareIcon, likedIcon, closeIcon } from "../../landingpage/src/assets";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
const customStyle = require('../../landingpage/src/discoverproject//customStyle.js');
const config = require("../../../framework/src/config.js");
import ProjectDetails from "../../landingpage/src/discoverproject/ProjectDeatils.web";
import { addImage, addVideo, world, checkradio, uncheckradio, attach, Layer_1, rightArrow } from "./assets";
import { profileIcon } from "../../cfproposalmanagement/src/assets";
import { DioalogComponent } from "../../cfproposalmanagement/src/OfferReceived.web";
import ToastMSG from "../../../components/src/ToastMSG.web";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ShareDialog from "../../landingpage/src/components/ShareDialog";
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  renderProfileCards = () => {
    return this.state.mayFollowList.map((item, index: number) => {
      return (
        <div data-testid="Navigatediv">
          <ProfileCard
            data-test-id="profilecard"
            completedSkills={[]}
            handleNavigation={this.HandleNavigations}
            data={item.attribute}
            handleOpen={this.handleOpen}
            isOpen={this.state.isOpen}
            index={index}
          />
        </div>
      );
    });
  };

  DisplayedCollections = (displayedItems: SkillItem[]) => {
    return (
      <>
        {displayedItems.length > 0 &&
          displayedItems.map((item, index: number) => (
            <div
              key={index}
              className={
                index < this.state.skillPerPage - 1 &&
                  index < displayedItems.length - 1
                  ? "CollectionsList"
                  : "lastCollectionList"
              }
            >
              <Typography
                data-testId="ActiveHeader"
                className={`${this.state.ActiveSkill == item.name
                    ? "ActiveHeader"
                    : "CollectionName"
                  }`}
                data-test-id="activeskill"
                onClick={() => {
                  this.handleActiveSkill(item.name);
                }}
              >
                {item.name}
              </Typography>
            </div>
          ))}
      </>
    );
  };

  showProgressbar = (index: number) => {
    if ((index === (this.state.profileImage.length - 1)) && this.state.isFileLoading) {
      return true;
    }
    return false;
  }

  renderPaginationArror = () => {
    return (
      ((this.state.pageOfPost * this.state.perPageOfPost) < this.state.totalPostofForum) &&
      <Box>
        <div className="MoreVectorIcon">
          <ExpandMoreRoundedIcon data-testId="paginationTestidArrow" onClick={this.getPostForumScroll} />
        </div>
      </Box>
    )
  }

  renderPaginationButton = (index: number, id: number) => {
    return (
      ((this.state.forumData[index].attributes.commentPage * this.state.commentPerPage) < this.state.forumData[index].attributes.commentTotalPage) &&
      <Box>
        <div className="MoreVectorIcon">
          <Button style={webStyle.commentButton} data-test-id="load-more" onClick={() => this.getCommentScroll(index, id)}>Load More</Button>
        </div>
      </Box>
    )
  }

  videoPlayer = () => {
    return (
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.openVideo}
        onClose={this.handleModelCloseOnCross}
      >
        <Box style={webStyle.modalStyle}>
          <Box>
            <div style={webStyle.ModalPlayerWrapper as React.CSSProperties}>
              <button style={webStyle.cancelButton as React.CSSProperties}
                onClick={this.handleModelCloseOnCross}
              >
                <CloseOutlinedIcon fontSize="small" />
              </button>
              <video
                controls={true}
                width="80%"
                height='auto'
                src={config.baseURL + this.state.selectedVideoSrc}
              >
              </video>
            </div>
          </Box>
        </Box>
      </Modal>
    )
  }

  shareProjectIdea = () => {
    return (
      <Box className="opportunity_card_main_containerChangeColor">
        <div style={webStyle.topSection}>
          {this.renderPhoto(this.state.userInfo.profile_photo,
            this.state.userInfo.profile_backgroud_color)}
          <div className="searchContainer">
            <textarea
              data-test-id="comment-text-elm"
              placeholder="Share your ideas"
              value={this.state.shareIdeaText}
              onChange={(event) => this.handleShareIdea(event.target.value)}
              rows={2}
            />
          </div>
        </div>
        {this.state.profileImage.length > 0 &&
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '35px', gap: '30px' }}>
              {this.state.profileImage.map((image, index) =>
                <CustomBox key={`profile${index}`} style={{ position: 'relative' }}>
                  <CustomBox className="profile-image-container" style={{ opacity: this.showProgressbar(index) ? "0.3" : "1", display: this.showProgressbar(index) ? "flex" : "block", backgroundImage: `url("${this.handleURL(image)}")`, paddingTop: '13px', height: 300, width: 300, borderRadius: '10px' }}>
                    <CustomBox className="delete-profile">
                      <CloseIcon data-test-id="delete-profile-image" onClick={() => this.deleteProfile(index)} />
                    </CustomBox>
                  </CustomBox>
                  {this.showProgressbar(index) ?
                    <CustomBox className="progressbarContainer" style={{ opacity: 1 }}>
                      <CustomBox className="progressbar" style={{ width: this.state.loadedImage + '%', opacity: 1 }}>
                      </CustomBox>
                    </CustomBox> : null}
                </CustomBox>
              )}
            </div>
            <Typography style={{ ...webStyle.font16gray600, margin: '32px 0', display: 'flex', alignItems: 'center' }}>
              <img style={{ marginRight: "15px" }} src={attach} alt="attach" />
              {this.state.profileImage.length} images attached
            </Typography>
          </>
        }
        {this.state.profileVideo.length > 0 &&
          <>
            <div>
              <div style={{ display: "flex", flexWrap: "wrap", marginTop: "35px", gap: "40px" }}>
                {this.state.profileVideo.map((thumbnail, index) => (
                  <CustomBox>
                    <CustomBox className="delete-profile">
                      <CloseIcon data-test-id="delete-profile-image" onClick={() => this.deleteVideo(index)} />
                    </CustomBox>
                    <img
                      key={index}
                      src={thumbnail.data}
                      alt={`Video Thumbnail ${index + 1}`}
                      style={{ width: "300px", margin: "5px", borderRadius: "8px" }}
                    />
                  </CustomBox>
                ))}
              </div>
            </div>
            <Typography style={{ ...webStyle.font16gray600, margin: '32px 0', display: 'flex', alignItems: 'center' }}>
              <img style={{ marginRight: "15px" }} src={attach} alt="attach" />
              {this.state.profileVideo.length} videos attached
            </Typography>
          </>
        }
        <div style={webStyle.mediaOptions}>
          <div style={webStyle.mediaOptionsInternal}>
            <div onClick={this.handleUploadFileClick} data-test-id="upload-file-button">
              <InputLabel style={{ color: "#fffee3", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px" }} onClick={e => e.stopPropagation()} data-test-id="upload-file-label">
                <Input
                  id="profile-image"
                  data-test-id="upload-file-input"
                  type="file"
                  ref={this.refElmFile}
                  hidden
                  inputProps={{
                    accept: "image/*"
                  }}
                  onChange={this.handleProfileImage}
                  style={{ display: "none" }}
                />
                <div style={webStyle.mediaButton}>
                  <span style={webStyle.icon}><img src={addImage} alt="add image" /></span><Typography style={webStyle.font16white600}>Add image</Typography>
                </div>
              </InputLabel>
            </div>
            <div onClick={this.handleUploadFileVideoClick} data-test-id="upload-file-button">
              <InputLabel style={{ color: "#fffee3", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px" }} onClick={e => e.stopPropagation()} data-test-id="upload-file-label">
                <Input
                  id="profile-image"
                  data-test-id="upload-file-input"
                  type="file"
                  ref={this.fileInputRef}
                  hidden
                  inputProps={{
                    accept: "video/*"
                  }}
                  onChange={this.handleVideoUpload}
                  style={{ display: "none" }}
                />
                <div style={webStyle.mediaButton}>
                  <span style={webStyle.icon}><img src={addVideo} alt="add video" /></span><Typography style={webStyle.font16white600}> Add video</Typography>
                </div>
              </InputLabel>
            </div>
          </div>
          <div style={webStyle.visibility} data-test-id="everyone" onClick={this.manageEveryoneModal}>
            <img style={{ marginRight: "15px" }} src={world} alt="world" />
            <Typography style={webStyle.font16gray600}>
              {this.state.everyoneValue === "everyone" ? "Everyone can view" : "Your Followers"}
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '15px' }}>
        <CustomButton
            className={`post-btn ${this.enablebuttonText()}`}
            disabled={!this.enablePostWorkOpportunity() || this.state.sharePostLoader} 
            data-test-id={`post-btn`}
            onClick={() => this.postForum()}
          >{this.state.sharePostLoader && <CircularProgress color='inherit' size={25} />} Post</CustomButton>
        </div>
      </Box>
    )
  }

  everyoneModal = () => {
    return (
      <DioalogComponent
        open={this.state.everyoneModal}
        fullWidth={true}
        data-test-id="close-onclick"
        style={{
          scrollbarWidth: 'none'
        }}
        onClose={this.manageEveryoneModal}
        maxWidth="sm"
      >
        <DialogContent
          style={{
            borderRadius: '24px'
          }}
          className="submit_work_box_main"
        >
          <Box style={{ width: '85%', padding: '30px' }}>
            <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography className="submit_work_heading">
                {'Who can view?'}
              </Typography>
              <Box onClick={this.manageEveryoneModal}>
                <CloseIcon style={webStyle.closeIconStyle} />
              </Box>
            </Box>
            <Box>
              <RadioGroup style={{ marginTop: 23 }} value={this.state.everyoneValue}
                data-test-id="SubmitYourWork"
                onChange={(e) => this.handleSelectChange(e.target.value)}
              >
                <FormControlLabel
                  value={"everyone"}
                  control={<Radio checkedIcon={<img src={checkradio} />} icon={<img src={uncheckradio} />} />}
                  label={<Typography style={{ marginTop: 6, ...webStyle.font16gray600 }}>Everyone can view</Typography>}
                />
                <FormControlLabel value={"followers"} control={<Radio checkedIcon={<img src={checkradio} />} icon={<img src={uncheckradio} />} />} label={<Typography style={{ marginTop: 6, ...webStyle.font16gray600 }}>Your Followers</Typography>} />
              </RadioGroup>
            </Box>
          </Box>
        </DialogContent>
      </DioalogComponent>
    )
  }

  shareDialog = () => {
    return (
      <ShareDialog
        data-test-id="share-dialog"
        open={this.state.shareDialog}
        onClose={() => this.handleShareModal()}
        url={`${document.location.origin}/Forum/${this.state.shareSelectedProjectId}`}
        title={"Deziner's knot"}
        description={"Designer's Forum Post"}
        coverImage=''
        ForumPostContent={this.getProjectIDForumPostContent()}
      />
    )
  }

  singleForumDialog = () => {
    return (
      <StyledDialog
        open={this.state.forumIdModal}
        data-test-id="close-modal-icon"
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        classes={{ paper: "data-container" }}
        maxWidth={false}
        fullWidth={true}
        fullScreen={true}
      >
        <StyledDialogContent dividers={true} >
          <DialogContentText>
            <CustomBox className="discover-projectDetails-container">
              <CustomBox style={{ display: "flex" }}><img data-test-id="closeForumModal" onClick={() => this.handleCloseForumModal()} src={closeIcon} alt="close modal" style={{ marginRight: "24px", marginLeft: "auto", cursor: "pointer" }} /></CustomBox>
              <CustomBox style={{ marginTop: "64px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <CustomBox className="width-90">
                <MainWrapper>
                  <Box className="opportunity_card_main_container">
                    {this.commentData(this.state.forumData)}
                  </Box>
                  </MainWrapper>
                </CustomBox>
              </CustomBox>
            </CustomBox>
          </DialogContentText>
        </StyledDialogContent>
      </StyledDialog>
    )
  }

  renderPhoto = (mainPhoto: string | undefined, backgroundColor: string | undefined) => {
    let content;
    if (mainPhoto) {
      content = (
        <img
          className="profile_css"
          style={{ borderRadius: "50%", marginRight: 8 }} alt="Default Profile" src={`${config.baseURL}${mainPhoto}`} />
      );
    } else if (backgroundColor) {
      content = (
        <Box
          className="profile_css"
          style={{ borderRadius: "50%", marginRight: 8, backgroundColor: backgroundColor }}></Box>
      );
    } else {
      content = (
        <img
          src={profileIcon} alt="Default Profile" className="profile_css" style={{ borderRadius: "50%", marginRight: 8 }} />
      );
    }
    return <>
      {content}
    </>;
  }

  a11yProps = (index: number, activeCard: string) => {
    return ({
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: activeCard,
      className: "tabs_css",
    })
  }

  tabSection = () => {
    return (
      <StyledTabs
        id="tabs"
        data-testId="Tabsvalues"
        onChange={this.handleTabValueChange}
        value={this.state.tabsValue}
        indicatorColor="primary"
        className="Mui-indicator"
        style={{ borderBottom: "1px solid grey", width: '100%' }}
      >
        <Tab data-testId="Opportunities" {...this.a11yProps(0, "For You")} />
        <Tab data-testId="Dk Contracts" {...this.a11yProps(0, "Following")} />
        <Tab data-testId="Inspirations" {...this.a11yProps(0, "Your Activity")} />
      </StyledTabs>
    )
  }

  HanndleImageComment = (comment: CommentData|ReplyCommentData) => {
    return (
      <div data-testId="NavigationDiv" onClick={() => this.HandleNavigation({ collection_id: 0, collection_name: '', user_role: comment.attributes.user_role, userId: comment.attributes.account_id }, this.state.userInfo.userId)}>
        {comment.attributes.profile_photo &&
          <img
            src={`${config.baseURL + comment.attributes.profile_photo}`}
            className="commented-by-user" alt="commented by user icon" />
        }
        {(!comment.attributes.profile_photo && comment.attributes.background_color_code) &&
          (
            <div
              className="commented-by-user"
              style={{ background: `${comment.attributes.background_color_code}` }}></div>)
        }
        {(!comment.attributes.profile_photo && !comment.attributes.background_color_code) &&
          <img
            src={profileIcon} className="commented-by-user" alt="commented by user icon" />
        }
      </div>
    )
  }

  showReplyBtn = (comment: CommentData) => {
    return this.state.showReply !== comment.attributes.id ? "" : "d-none";
  }

  showReplyViewBtn = (data: boolean) => {
    return data ? "" : "d-none";
  }

  showReplyElm = (id: string | number) => {
    return (this.state.showReply !== Number(id)) ? { display: "none" } : { display: "flex", gap: "28px", alignItems: "center" }
  }

  showCommentList = (id: number, allComentData: CommentData[], index: number) => {
    const { commentOpenArray } = this.state;
    return (
      <>
        {allComentData.map((comment, index) =>
          commentOpenArray[id] &&
          <CustomBox key={`comment_id${comment.id}`} className="commented-data-user">
            <CustomBox className="new-user-comment-container">
              <CustomBox className="commented-user">
                {this.HanndleImageComment(comment)}
                <CustomBox style={{ width: "100%" }}>
                  <CustomBox style={{ display: "flex" }}>
                    <Typography className="commented-by default-text-new">{this.state.userInfo.userId == comment.attributes.account_id ? "You" : comment.attributes.account_user_name}</Typography>
                    <Typography className="commented-at default-text-new description-label">{comment.attributes.commented_time_ago?.replace('commented', '')}</Typography>
                    <Typography data-test-id="action-elm" onClick={() => this.handleReplyClick(comment.attributes.id)} className={`reply-btn pointer-cursor ${this.showReplyBtn(comment)}`}>Reply</Typography>
                    <Typography data-test-id="action-elm-new" onClick={() => this.getReplyList(comment.id, id)} className={`reply-btn pointer-cursor ${this.showReplyViewBtn(comment.attributes.has_reply)}`}>View Reply</Typography>
                  </CustomBox>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography className="comment-text default-text-new" style={{ width: "95%" }}>{comment.attributes.comment}</Typography>
                  </Box>
                </CustomBox>
              </CustomBox>
              </CustomBox>
              {this.handleReply(comment.attributes.id) && comment.attributes.commentReplyData.map((reply) =>
                <CustomBox className="reply-comment-container-new">
                  <CustomBox className="new-user-comment-reply-container">
                    {this.HanndleImageComment(reply)}
                    <CustomBox style={{ marginLeft: "20px", marginRight: "20px" }}>
                      <Typography className="commented-by default-text-new" style={{ display: "flex" }}>{reply.attributes.account_id == this.state.userInfo.userId ? "You" : reply.attributes.account_user_name} <Typography className="commented-at default-text description-label" style={{ marginLeft: "16px" }}>{reply.attributes.commented_time_ago?.replace('commented', '')}</Typography></Typography>
                      <Typography className="comment-text default-text-reply-comment">{reply.attributes.comment}</Typography>
                    </CustomBox>

                  </CustomBox>
                </CustomBox>)}
              <CustomBox sx={{
                ...this.showReplyElm(comment.id)
              }}>
              <textarea
                className="newOneTextAreaReply"
                data-test-id="input-reply-data"
                value={this.state.replyText}
                onChange={(event) => this.handleReplyChange(event.target.value)}
                onKeyDown={(event) => this.handleReplyAdded(event, id)}
                rows={2}
              />

              <Typography data-test-id="cancel-reply" onClick={() => this.handleCancelReply()} className="cancel-btn">Cancel</Typography>
            </CustomBox>
          </CustomBox>)}
        {this.renderPaginationButton(index, id)}
      </>
    )
  }

  handleLikedData = (like: boolean) => {
    return like ? likedIcon : likeIcon;
  }

  toggleLike = (like: boolean, id: number, like_id: number) => {
    if (like) {
      this.disLikeProject(like_id, id);
    } else {
      this.postViewLike(id, "like")
    }
  }

  commentData = (data: ForumPostData[]) => {
    const { showAll } = this.state;
    const visibleImages = 3;
    return data.length > 0 ?
      data.map((item, index) => {
        const remainingImages = item.attributes.images.length - visibleImages;
        return (
          <div style={webStyle.commentContainer} key={item.id}>
            <Card style={webStyle.card}>
              <CardContent>
                <div style={webStyle.header}>
                  <div data-test-id="renderimg" style={{cursor: 'pointer'}} onClick={() => this.HandleNavigation({ collection_id: 0, collection_name: '', user_role: item.attributes.user_details.role, userId: item.attributes.user_details.id }, this.state.userInfo.userId)}>
                    {this.renderPhoto(item.attributes.user_details.profile_photo,
                      item.attributes.user_details.background_color)}
                  </div>
                  <div style={webStyle.headerInfo}>
                    <Typography variant="subtitle1">{item.attributes.user_details.name}</Typography>
                    <Typography style={webStyle.timestamp}>{item.attributes.create_time}</Typography>
                  </div>
                </div>

                <div style={{ ...webStyle.imageSliderContainer, overflowX: showAll[item.attributes.id] ? "auto" : "hidden" }}>
                  <ImageList className="imageList scrollContainer" cols={4}>
                    {item.attributes.media.slice(0, visibleImages).map((itemImages, index) => (
                      <ImageListItem key={index}>
                        <img src={`${config.baseURL + itemImages.src}`} style={{ position: "relative" }} alt={`Image ${index}`} />
                        {itemImages.type === "video" &&
                          <PlayCircleOutlineIcon style={webStyle.playIcon} onClick={() => { this.handleModelOpen(itemImages.videoUrl) }} data-testId="paginationTestid" />
                        }
                      </ImageListItem>
                    ))}

                    {!showAll[item.attributes.id] && remainingImages > 0 && (
                      item.attributes.media.slice(visibleImages, visibleImages + 1).map((itemImages, index) => (
                        <ImageListItem onClick={() => this.handleShowAll(item.attributes.id)} style={webStyle.overlayItem}>
                          <img src={`${config.baseURL + itemImages.src}`} alt={`Image ${index}`} />
                          <div style={webStyle.overlay}>+{remainingImages}</div>
                        </ImageListItem>
                      ))
                    )}

                    {showAll[item.attributes.id] &&
                      item.attributes.media.slice(visibleImages).map((itemImages, index) => (
                        <ImageListItem key={index + visibleImages}>
                          <img src={`${config.baseURL + itemImages.src}`} alt={`Image ${index + visibleImages}`} />
                          {itemImages.type === "video" &&
                            <PlayCircleOutlineIcon style={webStyle.playIcon} onClick={() => { this.handleModelOpen(itemImages.videoUrl) }} data-testId="paginationTestid" />
                          }
                        </ImageListItem>
                      ))}
                  </ImageList>
                </div>

                <Typography style={webStyle.content}>
                  {item.attributes.post_description}
                </Typography>

                <div style={webStyle.engagementBar}>
                  <div style={webStyle.stats}>
                    <div style={webStyle.statItem}>
                      <img className="eyeIcon" src={eyeIcon} alt="eye icon" />
                      <span>{item.attributes.views}</span>
                    </div>
                    <div style={webStyle.statItem}>
                      <img className="likeIcon" src={this.handleLikedData(item.attributes.is_like)} style={{ cursor: 'pointer', height: 18, width: 19 }} alt="like icon" data-test-id="unlike" onClick={()=>this.toggleLike(item.attributes.is_like,item.attributes.id,item.attributes.like_id)} />
                      <span>{item.attributes.likes}</span>
                    </div>
                    <Button data-test-id="share-post" onClick={()=>{this.handleShareModal(item.attributes.id)}} startIcon={<img src={solidShareIcon} />} style={webStyle.iconButton} size="small">
                      Share
                    </Button>
                  </div>
                  <div style={webStyle.actions}>
                    <Typography style={webStyle.timestamp} data-test-id="getComment" onClick={()=>{this.getComment(index,item.attributes.id)}}>{item.attributes.comments} Comments</Typography>
                  </div>
                </div>
                {this.showCommentList(item.attributes.id, item.attributes.commentData,index)}
                <CustomBox sx={{
                  marginTop: '10px',
                  height: "50px",
                  borderRadius: "37.47px",
                  border: "1.29px solid #2F2F2F",
                  display: "flex",
                  alignItems: 'center'
                }}>
                  <textarea
                    className="newOneTextArea"
                    data-test-id="comment-text-elm"
                    value={this.state.commentTextArray[item.attributes.id] || ""}
                    onChange={(event) => this.handleComment(item.attributes.id, event.target.value)}
                    rows={2}
                    onKeyDown={(event) => this.handleKeyPress(event,item.attributes.id)}
                  />
                  <CustomButton data-test-id="add-comment" disabled={!this.enableCommentBtn(item.attributes.id)}  className="add-comment-btn" onClick={()=>this.postComment(item.attributes.id)}>
                    <Typography className="comment-data">Comment</Typography>
                  </CustomButton>
                </CustomBox>
              </CardContent>
            </Card>
          </div>
        )
      })
      :
      <CustomBox className="no_post_container">
        <Typography
          id="no_projects_to_show"
          className="no_project_text"
        >
          No Post To Show
        </Typography>
      </CustomBox>
  }

  render() {
    const { skillItem, skillPage, skillPerPage, skillTotal } = this.state;
    const displayedItems = skillItem;
    return (
        <MainWrapper>
          <Box style={webStyle.newResponseCon} data-testid="outside">
            <NavigationMenu {...this.props} activeTab="Forum" />
            <Box style={webStyle.container}>
              <img
                src={Layer_1}
                style={webStyle.titleImg}
                alt="My Work Poster"
              />
              <div style={webStyle.mainClientWrap}>
                <Box
                  style={{
                    width: "100%",
                    backgroundColor: "#111111",
                    display: "flex",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    style={{
                      maxWidth: "400px",
                      opacity: "0px",
                      display: "flex",
                      backgroundColor: "#171717",
                      width: "35%",
                      padding: "40px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "40px",
                        opacity: "0px",
                      }}
                    >
                      <Box>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "28px",
                            fontWeight: 600,
                            lineHeight: "24.2px",
                            textAlign: "left",
                            color: "#FFF2E3",
                          }}
                        >
                          Trending Tags
                        </Typography>
                        <div style={webStyle.tagsContainer}>
                          {this.state.tags.map((tag, index) => (
                            tag.name === this.state.selectedTag ?
                              <Box style={webStyle.tagFontSelected} data-test-id="cleartag" onClick={() => this.selectTag(tag.name)}>
                                <CloseIcon className="close_button_icon" />
                                <Typography>
                                  {tag.name}
                                </Typography>
                              </Box>
                              :
                              <div key={index} style={webStyle.tag} data-test-id="selecttag" onClick={() => this.selectTag(tag.name)}>
                                <Typography style={webStyle.tagFont}>
                                  {tag.name}
                                </Typography>
                              </div>
                          ))}
                        </div>
                      </Box>
                      <div style={{ position: "relative" }}>
                        <Box
                          style={{
                            display: "flex",
                            gap: "32px",
                            flexDirection: "column",
                            opacity: "0px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontSize: "28px",
                              fontWeight: 600,
                              lineHeight: "24.2px",
                              textAlign: "left",
                              color: "#FFF2E3",
                            }}
                          >
                            Talents you may follow
                            <Box style={{ marginTop: "60px" }}>
                              <div
                                onScroll={this.handleScroll}
                                ref={this.itemsContainerRef}
                                className="skills_main_container"
                                data-test-id="profile_container"
                              >
                                {this.renderProfileCards()}
                                {this.state.isLoading &&
                                  !this.state.isFirstTimeLoading && (
                                    <Box className="loader_box">
                                      <CircularProgress className="loader" />
                                    </Box>
                                  )}
                              </div>
                            </Box>
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: "70%",
                      padding: "40px 13px 0",
                    }}
                  >
                    <Box>
                      <Box className="SecondBox">
                        <Typography
                          style={{
                            ...webStyle.projectTitleFont,
                            marginRight: "30px",
                          }}
                        >
                          Top Projects
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {this.state.skillPage > 1 &&
                            (this.state.skillPage > 1 ||
                              displayedItems.length > 0) && (
                              <button
                                className="view-Btn backBtn"
                                data-testId="showlesstestId"
                                onClick={() => this.showLessSkills()}
                              >
                                <img
                                  src={rightArrow}
                                  width={24}
                                  height={24}
                                  alt="view history"
                                />
                              </button>
                            )}
                        </div>
                        <Box
                          style={{
                            width: "80%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "flex-start",
                          }}
                        >
                          {this.DisplayedCollections(displayedItems)}
                        </Box>
                        <div
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {skillPage * skillPerPage < skillTotal && (
                            <button
                              className="view-Btn"
                              data-testId="showMoretestId"
                              onClick={() => this.showMoreSkills()}
                            >
                              <img
                                src={rightArrow}
                                width={24}
                                height={24}
                                alt="view history"
                              />
                            </button>
                          )}
                        </div>
                      </Box>
                    </Box>
                    <Box>
                      <CustomBox
                        className="discover-project-container"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          {this.state.projectPage > 1 &&
                            (this.state.projectPage > 1 ||
                              this.state.projectList.length > 0) && (
                              <button
                                className="view-Btn backBtn"
                                data-testId="showlessProject"
                                onClick={() => this.showLessProjects()}
                              >
                                <img
                                  src={rightArrow}
                                  width={24}
                                  height={24}
                                  alt="view history"
                                />
                              </button>
                            )}
                        </div>
                        <CustomGrid
                          container
                          className="main_grid_containerNew"
                          spacing={4}
                        >
                          {this.state.projectList.length ? (
                            this.state.projectList.map((item) => (
                              <Grid
                                item
                                xs={3}
                                data-test-id="grid-items"
                                key={item.id}
                                onClick={() => this.openModal(item)}
                              >
                                <Box className="card_container">
                                  <Box>
                                    <img
                                      className="card_image"
                                      src={`${config.baseURL +
                                        item.attributes.cover_image_url
                                        }`}
                                      alt="project cover image"
                                    />
                                  </Box>
                                  <Box className="title_container">
                                    <CustomBox className="project-title">
                                      <Typography
                                        className="card-label"
                                        title={item.attributes.project_name}
                                      >
                                        {item.attributes.project_name}
                                      </Typography>
                                      <Typography className="card-text">
                                        {item.attributes.username}
                                      </Typography>
                                    </CustomBox>
                                    <CustomBox
                                      className="manage-gap"
                                      style={{
                                        display: "flex",
                                        height: "24px",
                                      }}
                                    >
                                      <CustomBox className="manage-space">
                                        <span className="card-text">
                                          {item.attributes.views}
                                        </span>
                                        <img
                                          className="eyeIcon"
                                          src={eyeIcon}
                                          alt="eye icon"
                                        />
                                      </CustomBox>
                                      <CustomBox className="manage-space">
                                        <span className="card-text">
                                          {item.attributes.likes}
                                        </span>
                                        <img
                                          className="likeIcon"
                                          src={likeIcon}
                                          alt="like icon"
                                        />
                                      </CustomBox>
                                      <CustomBox className="manage-space-feature">
                                        <span className="card-text">
                                          {item.attributes.saved_count}
                                        </span>
                                        <img
                                          className="featureIcon"
                                          src={featureIcon}
                                          alt="feature icon"
                                        />
                                      </CustomBox>
                                    </CustomBox>
                                  </Box>
                                </Box>
                              </Grid>
                            ))
                          ) : (
                            <Box className="no_projects_container">
                              <Typography
                                id="no_projects_to_show"
                                className="no_project_text"
                              >
                                No Projects To Show
                              </Typography>
                            </Box>
                          )}
                        </CustomGrid>
                        <div>
                          {this.state.projectPage * this.state.projectPerPage < this.state.projectTotal && (
                            <button
                              className="view-Btn"
                              data-testId="showMoreProject"
                              onClick={() => this.showMoreProjects()}
                            >
                              <img
                                src={rightArrow}
                                width={24}
                                height={24}
                                alt="view history"
                              />
                            </button>
                          )}
                        </div>
                      </CustomBox>
                    </Box>
                    {this.shareProjectIdea()}
                    <Box className="opportunity_card_main_container">
                      {this.tabSection()}
                      {this.commentData(this.state.forumData)}
                      {this.renderPaginationArror()}
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
          <CustomModal
            open={this.state.isOpen}
            className="modal"
            data-test-id="profile_modal"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box className="modal_main_container">
              <Box>
                <Box className="close_button">
                  <IconButton
                    onClick={this.profileClose}
                    data-test-id="profile_modal_cross"
                  >
                    <CloseIcon className="close_button_icon" />
                  </IconButton>
                </Box>
                {this.state.openedData?.attribute.skills.length ? (
                  <ProfileCard
                    data-test-id="profileCard"
                    handleNavigation={this.handleNavigation}
                    completedSkills={this.state.openedData.attribute.skills}
                    data={this.state.openedData.attribute}
                    handleOpen={this.handleOpen}
                    isOpen={this.state.isOpen}
                    index={0}
                  />
                ) : (
                  <ProfileCard
                    data-test-id="profileCard"
                    handleNavigation={this.handleNavigation}
                    completedSkills={[]}
                    data={this.state.openedData.attribute}
                    handleOpen={this.handleOpen}
                    isOpen={this.state.isOpen}
                    index={0}
                  />
                )}
              </Box>
            </Box>
          </CustomModal>
          {this.state.showDetails && <ProjectDetails handlenavigation={this.HandleNavigation} navigateTo={this.handleNavigation} token={this.state.token} selectedProject={this.state.selectedProject} open={this.state.showDetails} closeModal={this.closeModal} allprojectList={this.state.specificProjectList} data-test-id="projectDetails" />}
          {this.everyoneModal()}
          {this.videoPlayer()}
          {this.singleForumDialog()}
          {this.shareDialog()}
          <ToastMSG data-test-id="toastMessage" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
        </MainWrapper>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({});

const MainWrapper = styled(Box)({
  '& .new-user-comment-reply-container': {
    width: "104%",
    marginBottom: "24px",
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    marginLeft: "74px"
  },
  '& .reply-comment-container-new': {
    maxWidth: "810px", display: "flex", alignItems: "center", marginTop: "-4%"
  },
  '& .cancel-btn': {
    fontFamily: "Inter-SemiBold,sans-serif",
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#fff",
    width: "max-content",
    cursor: "pointer",
    borderBottom: "1px solid #fff"
  },
  '& .newOneTextAreaReply': {
    overflow: 'scroll',
    scrollbarWidth: 'none',
    background: '#222222',
    width: '84%',
    border: '1px solid #222222',
    resize: 'none',
    borderRadius: '50px',
    height: '45px',
    outline: 'none',
    color: '#FFF2E3',
    paddingLeft: '20px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px'
  },
  '& .d-none': {
    display: "none"
  },
  '& .reply-btn': {
    fontFamily: "Inter-SemiBold,sans-serif",
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#fff2e3",
    borderBottom: "1px solid #fff2e3",
    width: "max-content",
    cursor: "pointer",
    marginLeft: "8px",
    height: "max-content"
  },
  '& .MoreVectorIcon': {
    textAlign: 'center',
    paddingBottom: '20px',
    cursor: 'pointer'
  },
  '& .MoreVectorIcon svg': {
    width: "50px",
    height: "50px",
    background: "#171717",
    borderRadius: "50px",
    color: '#fff'
  },
  "& .skills_main_container": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    overflowY: "auto",
    scrollbarWidth: "none",
    maxHeight: "895px",
    cursor: "pointer",

    overflow: "hidden",
    marginTop: 30,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
  },
  "& .loader_box": {
    width: 394,
    height: 180,
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .loader": {
    color: "white",
  },
  "& .ActiveHeader": {
    color: "transparent",
    fontWeight: 700,
    borderWidth: " 0px 0px 2px",
    backgroundColor: "transparent",
    backgroundImage:
      "linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)",
    fontFamily: "Inter, sans-serif",
    fontSize: "20px",
    lineHeight: "19.36px",
    width: "max-content",
    textAlign: "left",
    cursor: "pointer",
    backgroundClip: "text",
    alignContent: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .CollectionName": {
    fontWeight: 400,
    fontSize: "20px",
    color: "rgba(140, 140, 140, 1)",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingTop: "4px",
  },
  "& .CollectionsList": {
    borderRight: "1px solid white",
    maxWidth: "30%",
    display: "flex",
    justifyContent: "center",
    padding: "0px 15px",
    fontFamily: "Inter, sans-serif",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "5px 0"
  },
  "& .lastCollectionList": {
    maxWidth: "30%",
    display: "flex",
    justifyContent: "center",
    padding: "0px 15px",
    fontFamily: "Inter, sans-serif",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "5px 0"
  },
  "& .view-Btn": {
    width: "24px",
    height: "24px",
    borderRadius: "8px",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    cursor: "pointer",
  },
  "& .backBtn": {
    rotate: "180deg",
  },
  "& .SecondBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  "& .opportunity_card_main_containerChangeColor": {
    position: 'relative',
    borderRadius: '13px',
    background: '#171717',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontFamily: "Inter,sans-serif",
    fontSize: '20px',
    padding: '22px 20px 22px 20px',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '13px',
      padding: '1px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      pointerEvents: 'none'
    }
  },
  "& .opportunity_card_main_container": {
    borderRadius: '13px',
    marginTop: '50px',
    background: '#171717',
    padding: '40px'
  },
  "& .searchContainer": {
    height: '44px',
    alignSelf: 'center',
    backgroundColor: '#252525',
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  "& .searchContainer textarea": {
    backgroundColor: 'transparent',
    color: '#fff2e3',
    resize: 'none',
    outline: 'none',
    scrollbarWidth: 'none',
    border: 'none',
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: '800',
    lineHeight: '15.73px',
    textAlign: 'left',
    width: '100%',
    padding: '0 10px',
    '&::placeholder': {
      color: "transparent",
      fontWeight: 600,
      backgroundColor: "transparent",
      lineHeight: '30px',
      backgroundImage:
        "linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)",
      fontFamily: "Inter, sans-serif",
      fontSize: "20px",
      width: "max-content",
      backgroundClip: "text",
      alignContent: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  '& .tabs_css': {
    color: "#FFF2E3",
    fontWeight: 700,
    fontFamily: "Inter,sans-serif",
    textTransform: 'none',
    fontSize: 20,
    textAlign: 'start',
    paddingLeft: '0px',
    cursor: 'pointer',
    paddingRight: '0px'
  },
  "& .imageList": {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  "& .scrollContainer": {
    '&::-webkit-scrollbar': {
      height: '10px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFF2E3',
      borderRadius: '10px',
      border: '2px solid transparent',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#0D0D0D',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
  },
});

const CustomModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  "& .close_button": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .close_button_icon": {
    color: "white",
    right: -1,
    position: "absolute",
    marginBottom: 10,
    fontSize: "18px",
  },
  "& .backgroundColor": {
    backgroundColor: "#222222",
  },
});

const CustomGrid = styled(Grid)({
  width: "100% !important",
  maxWidth: "1655px",
  boxSizing: "border-box",
})

const CustomBox = withStyles((theme) => ({
  root: {
    "&.width-90": {
      width: '45%'
    },
    '&.discover-projectDetails-container .MuiDialog-paper': {
      maxWidth: "100% !important"
    },
    '& .default-text-reply-comment': {
      fontFamily: "Inter,sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      color: "#fff2e3",
      textAlign: "justify",
      wordWrap: "break-word",
      wordBreak: 'break-word',
      width: "94%"
    },
    '&.commented-data-user': {
      display: "flex",
      flexDirection: "column",
      gap: "25px",
      maxWidth: "1084px"
    },
    '&.new-user-comment-container': {
      marginBottom: "24px",
      marginTop: "24px",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    '&.commented-user': {
      display: "flex",
      gap: "24px",
      width: "86%"
    },
    '& .commented-by': {
      marginRight: "16px",
      color: "#ffffff !important"
    },
    '& .default-text-new': {
      fontSize: "16px",
      fontFamily: "Inter,sans-serif",
      color: "#fff2e3",
      lineHeight: "19.36px",
      maxWidth: "740px",
      marginRight: "15px",
      textAlign: "justify",
      wordWrap: "break-word"
    },
    '& .commented-at': {
      marginRight: "8px",
      marginBottom: "0 !important"
    },
    '& .description-label': {
      maxWidth: "1008px",
      marginBottom: "16px",
      color: "#8d8d8d !important"
    },
    '& .comment-text': {
      marginTop: "12px",
      color: "#8c8c8c !important"
    },
    '& .commented-by-user': {
      width: "52.98px",
      height: "52.98px",
      borderRadius: "50%",
      cursor: 'pointer'
    },
    '& .progressbarContainer': {
      position: 'absolute',
      top: '49%',
      left: '28%',
      width: '44%',
      backgroundColor: '#111',
      zIndex: 22,
      height: '7px'
    },
    '& .progressbar': {
      position: 'absolute',
      height: '100%',
      maxWidth: '100%',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
    },
    '& .delete-profile': {
      display: 'flex',
      width: '25px',
      height: '25px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 'auto',
      borderRadius: '50%',
      marginTop: '-38px',
      color: 'rgb(140, 140, 140)'
    },
    '& .profile-image-container': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .newOneTextArea': {
      overflow: 'scroll',
      scrollbarWidth: 'none',
      background: '#171717',
      width: '84%',
      border: '0px',
      resize: 'none',
      borderRadius: '50px',
      height: '45px',
      outline: 'none',
      color: '#FFF2E3',
      paddingLeft: '20px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px'
    },
    '&.discover-project-container': {
      backgroundColor: "#111111",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      paddingBottom: "40px"
    },
    '&.discover-project-container .card-container': {
      maxWidth: "94%",
      margin: "20 auto 0 auto",

    },
    '&.discover-project-container .select-btn': {
      backgroundColor: "transparent",
      fontFamily: "Inter-SemiBold, sans-serif",
      fontSize: "14px",
      lineHeight: "16.94px",
      display: "flex",
      alignItems: "center",
      color: "#fff2e3",
      justifyContent: "center"
    },
    '&.discover-project-container .single-select-container .select-btn': {
      border: "none",
      padding: "0",
      justifyContent: "flex-start"
    },
    '&.discover-project-container .manage-gap': {
      columnGap: "19px"
    },
    '&.discover-project-container .card-style': {
      maxWidth: "100%",
      height: "12rem",
      borderRadius: "10px",
      marginBottom: "8px"
    },
    '&.discover-project-container .project-title': {
      paddingRight: "10px"
    },
    '&.discover-project-container .d-none': {
      display: "none"
    },
    '&.discover-project-container .card-style img': {
      width: "100%",
      height: "100%",
      borderRadius: "10px"
    },
    '&.discover-project-container .options-position': {
      marginRight: "19px",
      marginTop: "33px",
      cursor: "pointer"
    },
    '&.discover-project-container .title-container': {
      width: "378.33px !important"
    },
    '&.discover-project-container .view-more-btn': {
      width: "145px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "55px"
    },
    '&.discover-project-container .view-more-btn:active': {
      backgroundColor: "#252525"
    },
    '&.discover-project-container .select-container,&.discover-project-container .tags-container': {
      display: "flex",
      alignItems: "center",
      columnGap: "24px",
      marginTop: "40px"
    },
    '&.discover-project-container .tags-container': {
      flexWrap: "nowrap",
      overflow: "auto",
      scrollbarWidth: "none",
      MsOverflowStyle: "none"
    },
    "&.no_post_container": {
      textAlign: 'center', margin: '50px'
    },
    "& .no_projects_container": {
      display: 'flex', width: "100%", justifyContent: "center", alignItems: 'center'
    }, "& .no_project_text": {
      color: "#fff2e3", fontSize: 25, fontWeight: 500
    },
    '&.discover-project-container .heading-text': {
      fontFamily: "Inter-SemiBold,sans-serif",
      fontSize: "36px",
      lineHeight: "43.57px",
      color: "#fff2e3"
    },
    [theme.breakpoints.between(1370, 1460)]: {
      '&.discover-project-container .project-title': {
        maxWidth: "114px",
        paddingRight: "6px"
      },
      '&.discover-project-container .manage-gap': {
        columnGap: "10px"
      },
      '&.discover-project-container .title-container': {
        width: "288px !important"
      },
      '&.discover-project-container .card-text': {
        fontSize: "10px"
      }
    },
    ...customStyle.customStyle(theme)
  }
}))(Box);

const CustomButton = withStyles({
  root: {
    textTransform: "none",
    '&.add-comment-btn': {
      height: "50px",
      border: "none",
      borderLeft: "1.29px solid #3C3C3C",
      backgroundColor: "transparent",
      borderTopRightRadius: "37.47px",
      borderBottomRightRadius: "37.47px",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "15%",
      '& .comment-data': {
        fontFamily: "Inter-SemiBold,sans-serif",
        fontSize: "16px",
        lineHeight: "19.36px",
        color: "#fff2e3",
        borderBottom: "1px solid #fff2e3"
      }
    },
    '&.post-btn': {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#121212",
      backgroundColor: "#fff2e3",
      width: '84px',
      "&:hover": {
        backgroundColor: "#FFF2E3",
      },
      "&.disabled-btn": {
        backgroundColor: "#7d7d7d",
        color: "#B0B0B0",
        pointerEvents: "none",
      },
    }
  }
})(Button);

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-wrapper': {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: "start",
  },
  '& .MuiTab-wrapper': {
    alignItems: "start",
  },
  '& .MuiTabs-indicator': {
    height: '0px',

  },
  '& .Mui-selected': {
    borderBottom: '2px solid #FFF2E3'
  },
  '& .MuiTab-root': {
    minWidth: 'auto !important',
    marginRight: '4%'
  }
});

const StyledDialog = withStyles({
  paper: {
    backgroundColor: "transparent",
    marginTop: "100px !important",
    boxShadow: "none !important",
  },
})(Dialog);

const StyledDialogContent = withStyles({
  root: {
    backgroundColor: "#171717",
    borderRadius: "24px",
    boxShadow: "0px -6px 34.3px -9px #00000047",
    paddingLeft: "0",
    paddingRight: "0",
    overflow: "auto",
    scrollbarWidth: "none",
    MsOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
})(DialogContent);

const webStyle: Record<string, CSSProperties> = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: "100vh",
  },
  container: {
    width: "100%",
    backgroundColor: "#111111",
    display: "flex",
    flexDirection: "column" as "column",
  },
  titleImg: {
    width: "100%",
    maxWidth: "calc(1920px - 96px)",
    height: "307px",
    objectFit: "cover",
    margin: "76px auto 0",
  },
  mainClientWrap: {
    backgroundColor: "#111",
    fontFamily: "Inter, sans-serif",
    maxWidth: "calc(1920px - 96px)",
    color: "#fff2e3",
    overflow: "scroll",
    scrollbarWidth: "none",
    width: "min(100%, 1920px)",
    margin: "auto",
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    padding: "0",
    borderRadius: "8px",
    margin: "40px 0",
  },
  tag: {
    padding: "8px 16px",
    backgroundColor: "#111111",
    borderRadius: "20px",
    cursor: "pointer",
  },
  tagFont: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24.2px",
    color: "#8C8C8C",
    letterSpacing: "0.38px",
  },
  tagFontSelected: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24.2px",
    backgroundColor: '#222222',
    letterSpacing: "0.38px",
    columnGap: "10px",
  borderWidth: "1px",
  borderRadius: "18.2px",
  color: "#fff2e3",
  width: "max-content",
  border: "0.5px solid #fff2e3",
  cursor: "pointer",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  whiteSpace: "nowrap",
  paddingLeft: "22px",
  paddingRight: "22px",
  verticalAlign: "middle",
  justifyContent: "center"
  },
  projectTitleFont: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24.2px",
    color: "rgba(255, 242, 227, 1)",
    letterSpacing: "0.38px",
  },

  shareIdeaOuter: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#181818",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    color: "#fff",
  },
  topSection: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
  },
  profilePic: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  inputBox: {
    flex: 1,
    background: "none",
    border: "none",
    outline: "none",
    color: "#fff",
    fontSize: "16px",
    padding: "5px 10px",
    borderRadius: "5px",
    backgroundColor: "#282828",
  },
  mediaOptions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  mediaOptionsInternal: {
    display: "flex",
    gap: '25px',
    alignItems: 'center'
  },
  mediaButton: {
    display: "flex",
    alignItems: "center",
    color: "#bbb",
    fontSize: "14px",
    cursor: "pointer",
    gap: "16px"
  },
  icon: {
    marginRight: "5px",
  },
  visibility: {
    fontSize: "12px",
    color: "#888",
    display: "flex",
    alignItems: "center",
    cursor: 'pointer'
  },
  font16white600: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24.2px",
    color: "#FFF2E3",
  },
  font16gray600: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24.2px",
    color: "#8C8C8C",
  },
  closeIconStyle: {
    color: "white",
    cursor: 'pointer'
  },
  commentContainer: {
    margin: "0 auto",
    padding: "20px 0",
  },
  card: {
    backgroundColor: "transparent",
    color: "#fff",
    borderRadius: 16,
    border: "1px solid #535353"
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    marginBottom: 16,
  },
  headerInfo: {
    display: "flex",
    flexDirection: "column",
  },
  timestamp: {
    color: "#a1a1aa",
    fontSize: 14,
  },
  content: {
    color: "#d4d4d8",
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: 24,
    marginTop: '18px'
  },
  engagementBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #27272a",
    paddingTop: 16,
  },
  stats: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  statItem: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    color: "#a1a1aa",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    cursor: 'pointer'
  },
  iconButton: {
    color: "#a1a1aa",
    textTransform: "none",
  },
  commentButton: {
    backgroundColor: "#3f3f46",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#52525b",
    },
  },
  imageSliderContainer: {
    overflowX: "hidden",
    width: "100%",
    scrollbarWidth: 'none'
  },
  overlayItem: {
    position: "relative",
    cursor: "pointer",
    opacity: '0.5',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0
  },
  playIcon: {
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "white",
    width: "75px",
    height: "75px",
    cursor: 'pointer'
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40vw',
    border: 'none',
    focus: 'none',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: 'transparent',
  },
  cancelButton: {
    position: "absolute",
    top: "-7%",
    right: "9%",
    background: "transparent",
    color: "white",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    zIndex: 10,
  },

  ModalPlayerWrapper: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '52px'
  },
};
// Customizable Area End
