import {
    Box, Button, Dialog, Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import { styled } from '@material-ui/styles';

interface DeletePayMethodProps {
    open: boolean;
    handleClose: () => void;
    handleDelete: () => void;
}

interface StateProp {
    selectedIds: number[];
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "700px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px"
        },
    },
});



export default class DeletePayMethod extends Component<DeletePayMethodProps, StateProp> {
    constructor(props: DeletePayMethodProps) {
        super(props);
        this.state = {
            selectedIds: []
        };
    }

    render() {
        const { handleClose, open, handleDelete } = this.props;
        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} data-test-id="close-modal">
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px", padding: "56px 40px" }}>
                    <Typography
                        style={{
                            fontFamily: "inter",
                            color: "#FFF2E3",
                            fontSize: "20px",
                            fontWeight: 600,
                            textAlign: "center",
                        }}
                    >Removing Withdrawal Method?</Typography>
                    <Typography
                        style={{
                            fontFamily: "inter",
                            color: "#FFF2E3",
                            fontSize: "14px",
                            fontWeight: 400,
                            textAlign: "center",
                            padding: "36px 0px"
                        }}
                    >
                        This payment method will no longer be available for future transactions.
                    </Typography>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Button
                            data-test-id="close-modal"
                            style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                borderRadius: "7px",
                                padding: "10px 30px",
                                fontFamily: "'Inter', sans-serif",
                                textTransform: "none",
                                cursor: "pointer",
                                marginRight: "15px",
                                background: "#0D0D0D",
                                color: "#FFF2E3",
                            } as React.CSSProperties}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-test-id="delete-milestone-btn"
                            style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                borderRadius: "7px",
                                padding: "10px 30px",
                                fontFamily: "'Inter', sans-serif",
                                textTransform: "none",
                                cursor: "pointer",
                                background: "rgba(255, 242, 227, 1)",
                                color: "#121212",
                                marginRight: "10px",
                            } as React.CSSProperties}
                            onClick={() => {
                                handleClose();
                                handleDelete()
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
