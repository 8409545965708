import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Modal,
  Button,
  Typography,
  Menu
} from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles, styled } from "@material-ui/core/styles";
import { 
  searchIcon, 
  chatIcon, 
  notificationIcon, 
  userIcon, 
  logoIcon, 
  activeChatIcon, 
  activeNotificationIcon, 
  activeUserIcon,
  postOpportunityActive,
  postOpportunity,
  shareProjectActive,
  shareProject 
} from "./assets";
const typoWhite = "#FFF2E3"

const MyComponent = styled('div')({
  backgroundColor: '#171717',
  borderRadius: "24px",
  "& *": {
    boxSizing: "border-box"
  },
  "& .searchCon": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#252525",
    borderRadius: "24px",
    padding: "0 24px",
    height: "48px",
  },
  "& .searchIcon": {
    width: "16px",
    height: "16px",
    objectFit: "contain",
    flexShrink: 0,
    marginRight: ".5rem",
  },
  "& .searchBar": {
    outline: "none",
    border: "none",
    fontFamily: "Inter",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "transparent",
    color: typoWhite,
    "&::placeholder": {
      color: "#676767",
      fontFamily: "Inter",
    },
  },
  "& .searchTextCon": {
    paddingLeft: "1rem",
    paddingRight: ".4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .searchText": {
    textDecoration: "underline",
    fontFamily: "Inter-SemiBold",
    cursor: "pointer",
  },
  "& .tabsCon": {
    padding: "24px",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    borderBottom: "1px solid #434343",

  },
  "& .tabOuterCon": {
      border: '.5px solid #FFF2E3',
      borderRadius: "2rem",
      height: "2.5rem",
      width: "fit-content",
      cursor: "pointer",
    },
    "& .gradientCon": {
      border: '1px solid #FFF2E3',
    },
    "& .activeTab": {
      backgroundColor: "#313131",
    },
    "& .tab": {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "11px 22px"
    },
    "& .activeFont": {
      fontFamily: "Inter",
      fontSize: "14px",
    },
    "& .inActiveFont": {
      fontFamily: "Inter",
      color: typoWhite,
    },
    "& .suggestedUserCon": {
      paddingTop: "1rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingBottom: "2rem"
    },
    "& .suggestedUsers": {
      color: "#8D8D8D",
      marginBottom: "1rem"
    },
    "& .suggestionsCon":{
      display: "flex",
      flexDirection: "column" as "column",
      gap: "1rem"
    },
    "& .suggestedUserList": {
      cursor: "pointer",
    }
});

const CustomMenu = styled(Menu)({
  "& *": {
    boxSizing: "border-box",
  },
  "& .MuiMenu-paper": {
    backgroundColor: "#171717",
    width: "444px",
    height: "631px",
    marginTop: "59px",
    marginLeft: "14px",
    overflow: "hidden",
    borderRadius: "14.86px",
  },
  "& .notificationsCon": {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "85%",
    marginBottom: "2rem",
    scrollbarWidth: 'none',
    "&::-webkit-scrollbar": {
      width: "0px", 
      background: "transparent",
    },
  },
  "& .notificationCon": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    },
  "& .notificationViewAllCon": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
    padding: ".65rem 1.25rem .65rem 1.25rem",
  },
  "& .button": {
    color: "#FFF2E3",
    textTransform: "capitalize",
    lineHeight: "19.36px",
    fontSize: "1rem",
    fontFamily: "Inter-Semibold",
    textDecoration: "underline"
  },
  "& .gradient": {
    width: "21px",
    height: "21px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundImage: "linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)",
  },
  "& dayContentCon": {
    width: "100%",
  },
  "& .contentDateCon": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
    padding: ".65rem 1.25rem .65rem 1.25rem",
    transition: "ease .45s all",
    "&:hover": {
      backgroundColor: "#222222",
    },
    cursor: 'pointer'
  },
  "& .contentCon": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  "& .circle": {
    backgroundColor: "#3D3D3D",
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    flexShrink: 0,
  },
  "& .date": {
    color: "#888888",
    flexShrink: 0,
  }
})
const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: "transparent",
      color: "#FFF2E3",
      border: "none",
      fontFamily: "Inter, sans-serif",
      fontSize: "13px",
      lineHeight: "15.73px",
      textAlign: "left",
      width: "90%",
      borderBottom: "none",
      '&::placeholder': {
        color: "#676767 !important",
        opacity: 1 
      }
    },
    '&::before': {
      borderBottom: "none"
    },
    '&::after': {
      borderBottom: "none"
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: "none !important"
    }
  },
})(Input);

const ResponsiveBox = styled(Box)({
  background: "#1D1D1D",
  display: "flex",
  width: 'min(100%,1920px)',
  zIndex: 999,
  boxSizing: "border-box",
  paddingLeft: "48px",
  paddingRight: "48px",
  margin: "0 auto",
  "& *": {
    boxSizing: "border-box",
  }
})

const CustomBox = styled(Box)({
  background: "#1D1D1D",
    display: "flex",
    
    position: 'fixed',
    width: '100%',
    zIndex: 999,
    boxSizing: "border-box",
    "& *": {
       boxSizing: "border-box",
    }
})
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonElm = () => {
    return (
      <Button 
        data-test-id="tabButton"
        style={{...webStyle.buttonElm, ...(this.state.userType === "designer") ? webStyle.shareProjectBtn: webStyle.postOpportunityBtn, ...((this.state.activeTab === "share project") || (this.state.activeTab === "post opportunity")) ? ((this.state.activeTab === "post opportunity") ? webStyle.postOpportunityBtnActive: webStyle.shareProjectBtnActive): {}}}
        onClick={() => this.handleActiveTab((this.state.userType === "designer" ? "share project": "post opportunity"), this.state.userType === "designer" ? "ShareProject": "PostOpportunity")}
      />
    );
  }

  renderNotificationData = () => {
    if (this.state.notificationsList.length === 0) {
      return (
        <Box className="contentDateCon">
          <Typography variant="h4">{configJSON.noNotificationsFoundText}</Typography>
        </Box>
      )
    }
   return this.state.notificationsList.map((notification) => {
       return (
         <Box 
           className="dayContentCon" 
           key={notification.id}>
           <Box className="contentDateCon" data-test-id="notificationRedirection" onClick={()=>this.navigateToRoute(notification)}>
             <Box className="contentCon">
               <Box className="circle" />
               <Typography variant="body2">{notification.attributes.contents}</Typography>
             </Box>
             <Typography variant="caption" className="date">{this.getDate(notification.attributes.created_at)}</Typography>
           </Box>
         </Box>
       )
     }) 
  }

  renderSuggestionsList = () => <>
    {this.state.suggestionsList.length > 0 && <Typography variant="body2" className="suggestedUsers">{configJSON.suggestedUsers}</Typography>}
    <Box className="suggestionsCon">
      {this.state.suggestionsList.length > 0 ? this.state.suggestionsList.map(
        (user: string, idIndex: number) => (
          <Typography
            key={idIndex}
            data-testid="suggestedUserList"
            className="suggestedUserList"
            onClick={() => { this.handleUserNameNavigate(user) }}
          >
            {user}
          </Typography>
        )
      ) : <Typography>No Suggestions</Typography>
      }
    </Box>
  </>
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      <ThemeProvider theme={theme}>
        <CustomBox>
          <ResponsiveBox>
          <img 
            src={`${logoIcon}`} 
            style={webStyle.logoCenter}
            alt="logo icon"
            data-test-id="tabButton"
            onClick={() => this.handleActiveTab("", "LandingPage")}
          />
          <Box style={webStyle.navbar}>
            <Box style={webStyle.nav}>
              <Box 
                style={{...navItem, ...(this.state.activeTab === "find work" || this.state.activeTab === "my work") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => {{this.state.userType === "designer" ? this.handleActiveTab("find work", "FindWork"): this.handleActiveTab("my work", "MyWork")}}}
              >
                {this.state.userType === "designer" ? "Find": "My"} Work
              </Box>
              <Box 
                style={{...navItem, ...(this.state.activeTab === "contracts") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => this.handleActiveTab("contracts", "ContractPage")}
              >
                Contracts
              </Box>
              <Box 
              className={`${(this.state.activeTab === "Forum") ? "nav-active": ""}`}
                style={{...navItem, ...(this.state.activeTab === "Forum") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => this.handleActiveTab("Forum", "Forum")}
              >
                Forum
              </Box>
            </Box>
            <Box 
              data-testid="searchBarReadOnly"
              style={this.isSearchBarVisiable() ? webStyle.displayNone : webStyle.searchContainer} 
              onClick={this.toggleSearchFeild}>
              <img src={`${searchIcon}`} alt="search icom" />
              <CustomTextField placeholder="Search" readOnly/>
            </Box>
            <Modal 
              data-testid="modal"
              BackdropProps={{style: webStyle.modalCon}}
              onClose={this.toggleSearchFeild}
              open={this.state.isSearchFeildOpen}>
                <Box style={webStyle.searchCon}>
                  <MyComponent>
                <Box className={"searchCon"}>
                  <img src={searchIcon} className="searchIcon" alt="search icon" />
                  <input
                    data-testid="searchBar"
                    className="searchBar"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    value={this.state.search}
                    ref={this.inputRef}
                  />
                  <Box className="searchTextCon">
                    <Typography 
                      variant="body2"
                      className="searchText"  
                      data-testid="searchText"
                      onClick={this.handleSearchButtonClick}
                      >
                        {configJSON.searchText}
                    </Typography>
                  </Box>
                </Box>
                <Box className={"tabsCon"}>
                {this.state.tabsList.map(tabItem => (
                    <Box className={this.getActiveTabBorder(tabItem.tabId)} key={tabItem.tabId}>
                      <Box 
                        className={this.getActiveTabBackground(tabItem.tabId)} 
                        data-testid={"tabItem"+tabItem.tabName}
                        onClick={() => this.handleTabChange(tabItem.tabId)}>
                        <Typography variant="body2" className={this.getActiveTabFont(tabItem.tabId)}>{tabItem.tabName}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box className="suggestedUserCon">
                  {
                    this.renderSuggestionsList()
                  }
                </Box>
                </MyComponent>
                </Box>
            </Modal>
            <Box style={webStyle.endDataContainer}>
              {this.handleButtonElm()}
              <Box style={webStyle.iconContainer}>
                <img 
                  src={`${(this.getActiveTabName() === "chat") ?  activeChatIcon: chatIcon}`} 
                  onClick={() => this.handleActiveTab("chat", "Chat")}
                  data-test-id="tabButton"
                  alt="chat icon"
                  style={webStyle.iconCss}
                />
                <img 
                  src={`${(this.state.activeTab === "notification") ?  activeNotificationIcon: notificationIcon}`} 
                  onClick={this.handleNotificationOpen }
                  style={webStyle.mx2}
                  data-testid="notification"
                  alt="notification icon"
                />
                <img 
                  src={`${(this.state.activeTab === "user") ?  activeUserIcon: userIcon}`} 
                  onClick={() => this.handleActiveTab("user", "UserProfile")}
                  data-test-id="tabButton"
                  alt="user icon"  
                  style={webStyle.iconCss}
                />
                <CustomMenu 
                  open={Boolean(this.state.notificationAnchorEl)}
                  anchorEl={this.state.notificationAnchorEl}
                  onClose={this.handleNotificationClose}
                  data-testid="notificationModal"
                >
                  <Box className="notificationViewAllCon">
                    <Box className="notificationCon">
                      <img className="bellIcon" src={notificationIcon} alt={configJSON.bellIconText} />
                      <Typography variant="h4" data-testid="heading">{configJSON.notificationsText}</Typography>
                      <Box className="gradient">
                        <Typography variant="caption">{this.state.notificationCount}</Typography>
                      </Box>
                    </Box>
                    <Button className="button" data-testid="viewAllButton" onClick={this.handleNavigateToNotifications}>
                      {configJSON.viewAllText}
                    </Button>
                  </Box>
                  <Box className="notificationsCon">
                    {this.renderNotificationData()}
                  </Box>
                </CustomMenu>
              </Box>
            </Box>
          </Box>
          </ResponsiveBox>
        </CustomBox>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});

const webStyleSetToOneLine = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical" as "vertical",
  wordBreak:"break-word" as "break-word"
}

const navItem = {
  color: "#FFF2E3",
  borderWidth: 0,
  borderBottom: "2px solid",
  borderBottomColor: "transparent",
  borderImage: "none",
  backgroundColor: "transparent",
  backgroundImage: "none",
  height: "48px",
  paddingTop: "26px",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  lineHeight: "19.36px",
  width: "max-content",
  textAlign: "left",
  cursor: "pointer"
} as React.CSSProperties;

const activeNavItem = {
  borderImageSource: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  backgroundImage: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  WebkitBackgroundClip: "text",
  MozBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  borderImageSlice: 1,
  borderImageRepeat: "round",
  fontFamily: "Inter-SemiBold, sans-serif"
} as React.CSSProperties;

const webStyle = {
  modalCon: {
    width: "100%",
    height: "100%",
    position: "relative" as "relative",
  },
  displayNone: {
    display: "none"
  },
  logoCenter: {
    alignItems: "center",
    marginRight: "47px",
    width: "28px",
    height: "38.38px",
    cursor:'pointer',
    marginTop: "18px"
  },
  mx2: {
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer"
  },
  headerContainer: {
    background: "#1D1D1D",
    display: "flex",
    paddingLeft: "48px",
    paddingRight: "48px"
  },
  navbar: {
    height: "76px",
    display: "flex",
    flex: 1,
    justifyContent: "space-between"
  },
  nav: {
    width: "293px",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "2px"
  },
  buttonElm: {
    borderRadius: "7px",
    height: "44px",
    backgroundRepeat: "no-repeat",
    border: "none"
  },
  shareProjectBtnActive: {
    backgroundImage: `url(${shareProjectActive})`
  },
  postOpportunityBtnActive: {
    backgroundImage: `url(${postOpportunityActive})`
  },
  shareProjectBtn: {
    width: "155px",
    backgroundImage: `url(${shareProject})`
  },
  postOpportunityBtn: {
    width: "186px",
    backgroundImage: `url(${postOpportunity})`
  },
  searchCon: {
    position: "absolute" as "absolute",
    top: 16,
    left: `36%`,
    minWidth: "261px",
    maxWidth: "590px",
  },
  searchContainer: {
    height: "44px",
    alignSelf: "center",
    background: "#252525",
    borderRadius: "32px",
    display: "flex",
    alignItems: "center",
    maxWidth: "461px",
    minWidth: "261px",
    margin: "0 auto",
    gap: "7px",
    paddingLeft: "10px",
    flex: 1,
    marginLeft: "50px",
    marginRight: "50px",
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "152.33px"
  },
  endDataContainer: {
    width: "363.33px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2px"
  }, 
  iconCss:{
    cursor:"pointer"
  }
};
// Customizable Area End
