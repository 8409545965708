import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Badge
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { fileIcon, photoIcon, plusIcon, searchIcon, sendIcon, userProfile } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { UserMessage, FileMessage,  MessageType } from "@sendbird/chat/message";
interface ITab {
  tabName: string;
  tabId: string;
  count: number;
}


const typoWhite = "#FFF2E3"

const theme = createTheme({
  palette: {
    primary: {
      main: typoWhite,
      contrastText: "#8C8C8C",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});

enum EChatConStatus {
  noMessages = "no-messages",
  loading = "loading",
  addNewChannel = "add-new-channel",
  messages = "messages"
}
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNewMessagesCon = () => {
    const { classes } = this.props;
    return( 
      <Box className={`${classes.personChatCon} ${classes.newMessagesCon}`}>
        <Box data-testid="allProposals"
          className={'allPropsCon newMessagesHeading'}>
          <ArrowBackIcon data-test-id="closeNewChannel" fontSize="large" onClick={()=>{this.closeNewChannel()}} className={'cursonPointer'} />
          <Typography variant="h2" className="titleLeft">{configJSON.newMessage}</Typography>
        </Box>
        <Box className={this.props.classes.messageSearchBarCon}>
          <img src={searchIcon} className="icon" />
          <input 
            className="input" 
            data-testid="suggestedUsers"
            placeholder={configJSON.searchPlaceholder} 
            onChange={this.handleNickName} 
            value={this.state.nickname}
            onKeyDown={this.handleKeyPress}
          />
          {this.state.nickname.trim() !== "" && <IconButton data-testid="nicknameReset" onClick={this.resetNickName}>
            <CloseIcon className="closeIcon" />
          </IconButton>}
        </Box>
        <Typography data-testid="suggestedHeading" variant="h4" className="suggestionsHeading">{configJSON.suggestions}</Typography>
        <Box className="userListCon">
          {this.state.userList.map(
            (user,index) => ( 
            <div data-testid="userlist" ref={this.state.userList.length === (index+1) ? this.userListConRef : null} 
              className={this.props.classes.userCard} key={user.created_at} onClick={() => {this.addNewChannel(user)}}>
              <img data-testid="suggestedImage" onError={this.handleErrorImage} src={configJSON.baseUrl+user.profile_url} className="userImage" />
              <Box className="userConInfoDesCon">
                <Box className="userInfoCon">
                  <Typography variant="h4" className="userName">{user.nickname}</Typography>
                </Box>
                <Typography style={webStyleSetToOneLine}>{configJSON.startConversationText}</Typography>
              </Box>
            </div>)
          )}
          {this.state.isUserListLoading && this.renderCircularProgressBar()}
          {(this.state.userList.length === 0 && !this.state.isUserListLoading) &&  
          <Typography variant="h4">{configJSON.noUserListFound}</Typography>
          }
        </Box>
      </Box>
    )
  }

  renderMessagesCon = () => {
    return (
      <Box className={this.props.classes.messageCon}>
        <Box className={this.props.classes.messageIconCon}>
          <Typography data-testid='heading' variant="h1">{configJSON.messagesHeading}</Typography>
          <IconButton data-testid="addNewChannel" onClick={this.toggleNewChannel}>
            <img src={plusIcon} className="plusIcon" />
          </IconButton>
        </Box>
        <Box className={this.props.classes.messageSearchBarCon}>
          <img src={searchIcon} className="icon" />
          <input 
            className="input"  
            value={this.state.channelNameInput}
            data-testid="channelSearch"
            onChange={this.handleChannelInputChange}
            onKeyDown={this.handleChanneInputKeyDown}
            placeholder={configJSON.searchPlaceholder} />
            {this.state.channelNameInput.trim() !== "" && <IconButton data-testid="restChannel" onClick={this.resetTheChannelName}>
            <CloseIcon className="closeIcon" />
          </IconButton>}
        </Box>
        <Box className={this.props.classes.tabsCon}>
          {
            this.state.tabList.map(
              (tabData: ITab) => (
                <Box data-testid="tabItem" key={tabData.tabId} className={`tabCon`} onClick={this.handleActiveTab.bind(this, tabData.tabId)}>
                  <Typography variant="h4">{`${tabData.tabName} (${this.getCountOfUnreadChannels(tabData.tabId)})`}</Typography>
                  <Box data-testid="tabBorder" className={`gutter ${this.isActiveTab(tabData.tabId)}`} />
                </Box>)
            )
          }
        </Box>
        {this.renderUserCard()}
      </Box>
    )
  }

  renderUserCard = () => {
    const { classes } = this.props;
    return (
      <div className={this.props.classes.userCardsCon} ref={this.channelListReferenceRef}>
        {this.filterChannelList().map((channel,idIndex) => {
          return (
            <div 
              ref={this.state.channelList.length === (idIndex+1) ? this.channelListConRef : null} 
              data-testid="channelList" 
              key={channel.id} 
              className={channel.channel_url !== this.state.channelUrl ? classes.userCard : `${classes.userCard} activeCard`} 
              onClick={() => {this.updateChattingChannel(channel,idIndex)}}>
              <img 
                onError={this.handleErrorImage} 
                src={configJSON.baseUrl+this.getReceiversId(channel.members)?.profile_url} 
                className="userImage" />
              <Box className="userConInfoDesCon">
                <Box className="userInfoCon">
                  <Typography variant="h4" className="userName">{this.getReceiversId(channel.members)?.nickname}</Typography>
                  <Typography className="activeTime">
                    {!!this.getLastMessageTime(channel.last_message?.created_at) ? 
                    this.getLastMessageTime(channel.last_message?.created_at) : 
                    <Typography variant="caption">{configJSON.newText}</Typography>}                    
                  </Typography>
                </Box>
                <Box className="lastMessageBadgeCon">
                  <Typography style={webStyleSetToOneLine}>{channel.last_message?.message}</Typography>
                  <Badge anchorOrigin={{horizontal: "left",vertical:"top"}} color="primary" max={9} className="badge" badgeContent={channel.unread_message_count}>
                    <Box />
                  </Badge>
                </Box>
              </Box>
            </div>
          )
        })}
        {this.state.isChannelListLoading && this.renderCircularProgressBar()}
        {(this.filterChannelList().length === 0 && !this.state.isChannelListLoading) && <Typography variant="h4">{configJSON.noMessagesText}</Typography>}
      </div>
    )
  }
  
  renderChattigInputCon = () => {
    const { classes } = this.props;
    return (
      this.state.messagesConStatus !== EChatConStatus.addNewChannel && <Box className={classes.chattingCon}>
        <IconButton 
          onClick={this.handleFilesMenu}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          data-testid="insertLink"
        >
          <InsertLinkIcon className="insertLink" />
        </IconButton>
        <Menu
          id="long-menu" 
          data-testid="lisertFiles"
          className='menuBar'
          anchorEl={this.state.fileUploadAnchorEl}
          open={Boolean(this.state.fileUploadAnchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          PaperProps={{style: styles.paperItemCon }}
          MenuListProps={{style: styles.menuMenuItemCon }}
        >
          <MenuItem className={classes.menuMenuItemCon} >
            <input data-testid="imageUpload" onChange={this.handleVideoImagesUpload} type="file" accept={configJSON.imageVideoType} hidden id="videosUpload" />
            <label htmlFor="videosUpload" className={classes.labelCon}>
              <img src={photoIcon} />
              <Typography>{configJSON.photosAndVideosText}</Typography>
            </label>
          </MenuItem>
          <MenuItem className={`${classes.menuMenuItemCon} ${classes.blackMenuItem}`}>
            <input onChange={this.handleVideoImagesUpload} id="fileUploader" hidden type="file" accept=".pdf,.docx,.txt,.xlsx" />
            <label htmlFor="fileUploader" className={classes.labelCon}>
              <img src={fileIcon} />
              <Typography>{configJSON.documentText}</Typography>
            </label>
          </MenuItem>
        </Menu>
        <Box className="chatInputCon">
          <textarea
            data-testid="messageInput"
            className="chatInput"
            placeholder={configJSON.typeMessagePlaceholder}
            onChange={this.handleChatInputChange}
            onKeyDown={this.handleChatInputKeyDown}
            value={this.state.input}
          />
        </Box>
        <IconButton onClick={this.sendMessageToChannel} data-testid="sendButton">
          <img src={sendIcon} />
        </IconButton>
      </Box>
    )
  }

  renderEmptyMessages = () => {
    const { classes } = this.props
    return(
      <Box className={classes.emptyMessagesCon}>
        <Typography variant="h4">{configJSON.intiateConversationText}</Typography>
        <Typography variant="body1">{configJSON.startConversationTextOne}</Typography>
        <Typography data-testid="composeButton" variant="h4" onClick={this.toggleNewChannel} className={classes.composeTextStyle}>{configJSON.composeText}</Typography>
      </Box>
    )
  }

  renderPersonChatCon = () => {
    const { classes } = this.props
    return(
      <Box className={classes.personChatCon}>
        <Box className={this.props.classes.chatHeaderCon}>
          <Box className="userProfileNameCon">
            <img  src={configJSON.baseUrl+this.state.receiverData?.profileUrl} onError={this.handleErrorImage} className="userProfile" />
            <Box className="userNameCon">
              <Typography variant="h4" className="userName">{this.state.receiverData?.nickname}</Typography>
              <Typography className="userStatus">{`${this.state.receiverData?.connectionStatus === "online" ? 
                configJSON.onlineText : 
                configJSON.offlineText}`}</Typography>
            </Box>
          </Box>
          <Box className={classes.contractsMoreIconCon}>
            {this.state.channel?.customType === "contract" &&
            <Box className={classes.contractsCon}>
              <Typography className="composeText">{configJSON.onGoingContract}</Typography>
              <Typography className="componyName">{configJSON.companyName}</Typography>
            </Box>}
            <IconButton data-testid='deleteCloseIcon' onClick={this.handleMoreVertIcon} >
              <MoreVertIcon className="icon" />
            </IconButton>
          </Box>
          <Menu
            id="long-menu"
            className='menuBar'
            data-testid="deleteCloseMenu"
            anchorEl={this.state.deleleClearAnchorEl}
            open={Boolean(this.state.deleleClearAnchorEl)}
            onClose={this.handleDeleteClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{ style: { ...styles.paperItemCon, ...styles.paperItemTwoCon, } }}
            MenuListProps={{ style: styles.menuMenuItemCon }}
          >
            <MenuItem 
              className={`${classes.menuItem}`} 
              data-testid="clearChat"
              onClick={this.clearChatHistory} >
              <Typography>{configJSON.clearChattext}</Typography>
            </MenuItem>
            <MenuItem 
              onClick={this.deleteChannel} 
              data-testid="deleteChat"
              className={`${classes.menuItem} ${classes.blackMenuItem}`}>
              <Typography>{configJSON.deleteChattext}</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <div data-testid="chatHistoryConRef" ref={this.chatHistoryConRef} className={this.props.classes.chatHistoryCon}>
          {this.renderMessages()}
          <div ref={this.lastMessageRef} key="new-ref" />
        </div>
      </Box>
    )
  }

  renderMessages = () => {
    let lastMessage: string | null = null;
    const renderData = this.state.messages.length > 0 ? this.state.messages.map((message) => {
      const messageDate = this.getDateString(message.createdAt);
      const showDate = lastMessage !== messageDate;
      lastMessage = messageDate;
      return (
        <Box className="chatWithDateCon">
          {showDate && <Box className="dateCon">
            <Box className="horizontalLine" />
            <Typography>{this.getDateString(message.createdAt)}</Typography>
            <Box className="horizontalLine" />
          </Box>}
          {
            (this.state.userId === message.sender?.userId ?
              this.renderRightChat(message) :
              this.renderLeftChat(message))
          }
        </Box>
      )
    }) : <Box />
    return renderData;
  }

  renderLeftChat = (message: UserMessage | FileMessage) => {
    const { classes } = this.props;

    if ((message as UserMessage).messageType === "file") {
      const newMessage = message as FileMessage
      if (this.isDocumentType(newMessage.type)) {
        return (<Box
          key={message.createdAt}
          data-testid="chatCon"
          className={`${classes.chatDataCon} ${classes.chatLeft}`}
          onClick={() => this.handleVideoDownload(newMessage.url)}
        >
          <img onError={this.handleErrorImage} src={configJSON.baseUrl + message.sender?.profileUrl} className="image" />
          <Box className="textCon">
            <Box className="flexCon">
              <IconButton>
                <GetAppIcon className="icon" />
              </IconButton>
              <Typography>{newMessage.name}</Typography>
            </Box>
            <Typography className="timeStampText" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>
          </Box>
        </Box>)
      }
      return (
        <Box 
          data-testid="chatCon"
          key={message.createdAt}
          className={`${classes.chatDataCon} ${classes.chatLeft}`} 
          onClick={() => {newMessage.type.startsWith("video/") ? this.handleVideoDownload(newMessage.url) : this.previewTheImageAndVideo(newMessage)}}
        >
        <img onError={this.handleErrorImage} src={configJSON.baseUrl+message.sender?.profileUrl} className="image" />
            <Box className="textCon videoImageCon">
            {
              newMessage.type.startsWith("video/")  && <IconButton className="iconButton">
                  <GetAppIcon className="icon" />
                </IconButton>
              }
              <img className="thumbnailImage" src={newMessage.type.startsWith("video/") ? (newMessage).thumbnails[0]?.url : newMessage.url} />
              <Typography className="imageTimeStamp" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>  
            </Box>
        </Box>
      )
    }

    if (message.messageType !== MessageType.USER) {
      return (<Box data-testid="chatCon" key={message.createdAt} className={`${classes.chatDataCon} ${classes.chatCenter}`}>
        <Box className="textCon middleTextCon">
          <Typography>{(message as UserMessage).message}</Typography>
          <Typography className="timeStampText" variant="caption">{this.getTime(message.createdAt)}</Typography>
        </Box>
      </Box>)
    }

    return (
      <Box data-testid="chatCon" key={message.createdAt} className={`${classes.chatDataCon} ${classes.chatLeft}`}>
        <img onError={this.handleErrorImage} src={configJSON.baseUrl + message.sender?.profileUrl} className="image" />
        <Box className="textCon">
          <Typography>{(message as UserMessage).message}</Typography>
          <Typography className="timeStampText" variant="caption">{this.getTime(message.createdAt)}</Typography>
        </Box>
      </Box>
    )
  }

  renderPreviwImageOrVideo = () => {
    const { classes } = this.props
    let imageEle: React.ReactElement = <Box />
    if (!!this.state.previewMessage) {
      imageEle = <img className="image" src={this.state.previewMessage.url} />
    }
    return (
      <Modal data-testid="modalCon" className={classes.modalCon} open={Boolean(this.state.previewMessage)} onClose={this.handleCloseModal}>
        <Box className={classes.modalTextCon}>
          <IconButton  data-testid="closeButton" className="icon closeIcon" onClick={this.handleCloseModal}>
            <CloseIcon  />
          </IconButton>
          {imageEle}
        </Box>
      </Modal>
    )
  }
  renderRightChat = (message: UserMessage | FileMessage) => {
    const { classes } = this.props;
   
    if ((message as UserMessage).messageType === "file") {
      const newMessage = message as FileMessage
      if (this.isDocumentType(newMessage.type)) {
        return (<Box
          key={message.createdAt}
          className={`${classes.chatDataCon} ${classes.chatRight}`}
          data-testid="chatCon"
        >
          <Box className="textCon">
            <Box className="flexCon">
              <IconButton data-testid="fileDownload" onClick={() => this.handleVideoDownload(newMessage.url)}>
                <GetAppIcon className="icon" />
              </IconButton>
              <Typography>{newMessage.name}</Typography>
            </Box>
            <Typography className="timeStampText" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>
          </Box>
          <img onError={this.handleErrorImage} src={configJSON.baseUrl + message.sender?.profileUrl} className="image" />
        </Box>)
      }
      return (
        <Box 
          key={message.createdAt}
          data-testid="chatCon"
          className={`${classes.chatDataCon} ${classes.chatRight}`} 
          onClick={() => {newMessage.type.startsWith("video/") ? this.handleVideoDownload(newMessage.url) : this.previewTheImageAndVideo(newMessage)}}         
        >
            <Box className="textCon videoImageCon">
             {
              newMessage.type.startsWith("video/")  && <IconButton className="iconButton">
                  <GetAppIcon className="icon" />
                </IconButton>
              }
              <img className="thumbnailImage" src={newMessage.type.startsWith("video/") ? (newMessage).thumbnails[0]?.url : newMessage.url} />
              <Typography className="imageTimeStamp" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>  
            </Box>
        <img onError={this.handleErrorImage} src={configJSON.baseUrl+message.sender?.profileUrl} className="image" />
        </Box>
      )
    }

    if (message.messageType !== MessageType.USER) {
      return (<Box key={message.createdAt} data-testid="chatCon" className={`${classes.chatDataCon} ${classes.chatCenter}`}>
        <Box className="textCon middleTextCon">
          <Typography data-testid="rightMessages">{(message as UserMessage).message}</Typography>
          <Typography className="timeStampText" variant="caption">{this.getTime(message.createdAt)}</Typography>
        </Box>
      </Box>)
    }

    return (
      <Box key={message.createdAt} data-testid="chatCon" className={`${classes.chatDataCon} ${classes.chatRight}`}>
        <Box className="textCon rightTextCon">
          <Typography data-testid="rightMessages">{(message as UserMessage).message}</Typography>
          <Typography className="timeStampText" variant="caption">{this.getTime(message.createdAt)}</Typography>
        </Box>
        <img onError={this.handleErrorImage} src={configJSON.baseUrl+message.sender?.profileUrl} className="image" />
      </Box>
    )
  }

  renderCircularProgressBar = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.progressCon}>
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        {this.renderCircularProgressBar()}
      </Box>
    )
  }

  renderContentInChatCon = () => {
    switch (this.state.messagesConStatus) {
      case EChatConStatus.addNewChannel: {
        return this.renderNewMessagesCon()
      }
      case EChatConStatus.messages: {
        return this.renderPersonChatCon()
      }
      case EChatConStatus.noMessages: {
        return this.renderEmptyMessages()
      }   
      case EChatConStatus.loading: {
        return this.renderBackDrop()
      }
    }
  }
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={styles.newResponseCon}>
        <NavigationMenu {...this.props} />
        <ThemeProvider theme={theme}>
          <Box className={this.props.classes.container}>
            <Box className={this.props.classes.responsiveCon}>
              {this.renderMessagesCon()}
              <Box className={this.props.classes.chatCon}>
                {
                  this.renderContentInChatCon()
                }
                {this.state.messagesConStatus !== EChatConStatus.noMessages && this.renderChattigInputCon()}
              </Box>
              {this.renderPreviwImageOrVideo()}
            </Box>
          </Box>
          <Box>
          </Box>
        </ThemeProvider>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyleSetToOneLine = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical" as "vertical",
  wordBreak:"break-word" as "break-word"
}

const styles = {
  newResponseCon: {
    height: "100vh",
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#111111",
  },
  progressCon: {
    width: "100%",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  modalCon: {
    display: "flex",
    justifyContent: "center",
    alingItems: "center",
  },
  modalTextCon: {
    width: "100%",
    height: "100%",
    padding: "2rem",
    position: "relative" as "relative",
    margin: "auto",
    borderRadius: ".5rem",
    display: "flex",
    "& .icon": {
      color: typoWhite,
    },
   
    "& .closeIcon": {
      position: "absolute",
      top: "1rem",
      right: "1rem",
      zIndex: 2,
    },
    "& .image": {
      width: "400px",
      height: "80%",
      objectFit: "cover",
      borderRadius: "inherit",
      margin: "auto",
    },
    "& .video-thumbnail": {
      position: "relative",
      "& img": {
        width: "100%",
        heght: "100%",
        objectFit: "cover",
        borderRadius: "inherit"
      },
      "& .downloadIcon": {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
      },
    }
  },
  contractsCon: {
    backgroundColor: "#222222",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    padding: "1rem",
    height: "calc(100% + 2rem)",
    "& .composeText": {
      WebkitBackgroundClip: 'text' as "text",
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitTextFillColor: 'transparent',
    },
    "& .componyName": {
      fontFamily: "Inter-SemiBold, sans-serif",
      textDecoration: "underline",
    }
  },
  contractsMoreIconCon: {
    display: "flex",
    alignItems: "center",
    gap: ".3rem",
    height: "100%",
  },
  composeTextStyle: {
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
    WebkitBackgroundClip: 'text' as "text",
    WebkitTextFillColor: 'transparent',
    fontSize: '24px',
    position: "relative" as "relative",
    cursor: "pointer",
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -2,
      height: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      width: "100%",
      zIndex: 1,
    },
  },  
  emptyMessagesCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "calc(100vh - 76px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: '100%',
    display: "flex",
    height: "calc(100% - 76px)",
    overflow: "hidden",
    paddingTop: "76px",
    "& *": {
      boxSizing: "border-box",
    }
  },
  responsiveCon: {
    width: "min(100%,1920px)",
    height: "100%",
    margin: "0 auto",
    padding: "2rem 3rem",
    display: "flex",
    gap: "1rem",
  },
  messageCon: {
    backgroundColor: "#171717",
    width: "min(100%,550px)",
    borderRadius: "24px",
    padding: "1rem",
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    flexShrink: 0,
    "& .plusIcon": {
      width: "44px",
      height: "44px",
    }
  },
  messageIconCon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messageSearchBarCon: {
    flexShrink: 0,
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    backgroundColor: "#252525",
    height: "43px",
    width: "100%",
    borderRadius: "43px",
    padding: "0 1rem",
    "& .icon": {
      width: "12px",
      height: "12.84px",
      objectFit: "cover",
  
    },
    "& .closeIcon": {
      color: typoWhite,
      cursor: "pointer",
      fontSize: "1rem",
    },
    "& .input": {
      outline: "none",
      border: "none",
      height: "100%",
      backgroundColor: "transparent",
      flexGrow: 1,
      color: typoWhite,
      "&::placeholder": {
        color: "#676767",
        fontFamily: "Inter",
      }
    }
  },
  tabsCon: {
    display: "flex",
    borderBottom: `1px solid #FFF2E3`,
    margin: "2rem 1rem 0 1rem",
    "& h4": {},
    "& .tabCon": {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      flexBasis: "33.333333%",
      cursor: "pointer"
    },

    "& .gutter": {
      height: 0,
      width: "100%",
      border: "3px solid transparent",
      borderRadius: "1rem",
      marginTop: "1rem",
      flexGrow: 1,
    },
    "& .activeTab": {
      border: "3px solid #FFF2E3",
    },
  },
  userCard: {
    backgroundColor: "#222222",
    borderRadius: "12px",
    padding: "1rem 1.25rem",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    cursor: "pointer",
    "& .lastMessageBadgeCon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "96%"
    },
    "& .badge": {
      marginLeft: "-10px"
    },
    "& .userInfoCon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "fit-content",
      gap: "1rem",
    },
    "& .userImage": {
      height: "65px",
      width: "65px",
      objectFit: "cover",
      aspectRatio: "1/1",
      borderRadius: "50%",
    }, 
    "& .userName": {
      fontFamily: "Inter-SemiBold, sans-serif",
      position: "relative" as "relative",
      paddingRight: "1rem",
      "&:before": {
        content: '"."',
        position: "absolute" as "absolute",
        right: 0,
        top: "-12%",
      }  
    },
    "& .activeTime": {
      fontFamily: "Inter-SemiBold, sans-serif",
      color: "#8C8C8C",
    },
    "& .userConInfoDesCon": {
      display: "flex",
      gap: ".5rem",
      flexDirection: "column" as "column",
      width: "100%",
    }
  },
  chatCon: {
    border: "1px solid #292929",
    borderRadius: "13px",
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    height: "100%",
  },
  chatHeaderCon: {
    marginBottom: "auto",
    backgroundColor: "#171717",
    borderTopLeftRadius: "inherit", 
    borderTopRightRadius: "inherit", 
    height: "91px",
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    padding: "1rem",
    "& .userProfile": {
      width: "59px",
      height: "59px",
      objectFit: "cover",
      borderRadius: "50%"
    },
    "& .userName": {
      fontFamily: "Inter-SemiBold, sans-serif",
      position: "relative" as "relative",
    },
    "& .userProfileNameCon": {
      display: "flex",
      gap: "1rem",
      alignItems: "center"
    },
    "& .userNameCon": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: ".5rem"
    },
    "& .userStatus": {
      position: "relative",
      paddingLeft: ".75rem",
      color: "#535353",
      "&:before": {
        content: '"."',
        position: "absolute" as "absolute",
        left: 0,
        top: "-60%",
        fontSize: "2.25rem"
      }
    },
    "& .icon": {
      color: "#D9D9D9",
    }
  },
  chattingCon: {
    height: "89px",
    marginTop: "auto",
    backgroundColor: "#171717",
    borderBottomLeftRadius: "inherit",
    borderBottomRightRadius: "inherit",
    padding: "1rem",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    "& .menuBar": {
      marginTop: "5rem",
    },
    "& .insertLink": {
      color: "#888888",
      transform: "rotateZ(-45deg)",
      fontSize: "2rem",
    },
    "& .chatInputCon": {
      padding: "1rem",
      borderRadius: "1rem",
      backgroundColor: "#111111",
      minHeight: "49px",
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    "& .chatInput": {
      width: "100%",
      flexShrink: 0,
      outline: "none",
      border: "none",
      resize: "none",
      backgroundColor: "#111111",
      flexGrow: 1,
      paddingTop: ".5rem",
      color: typoWhite,
      fontFamily: "Inter, sans-serif",
      "&::placeholder": {
        color: "#7D7D7D",
        fontSize: ".875rem",
        fontFamily: "Inter, sans-serif",
      },
      "&::-webkit-scrollbar": {
      width: "0px", 
      background: "transparent",
    },
    scrollbarWidth: "none" as "none",
    msOverflowStyle: "none" as "none",
    }
  },
  chatLeft: {
    marginRight: "auto"
  },
  chatCenter: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  chatRight: {
    marginLeft: "auto",
    justifyContent: "flex-end",
  },
  chatDataCon: {
    display: "flex",
    gap: ".5rem",
    width: "60%",
    "& .icon": {
      color: typoWhite,
    },
    "& .image": {
      alignSelf: "flex-end",
      height: "34px",
      width: "34px",
      objectFit: "cover",
      borderRadius: "50%"
    },
    "& .thumbnailImage": {
      width: "200px",
      height: "300px",
      objectFit: "cover",
      cursor: "pointer",
      borderRadius: "inherit"
    },
    "& .textCon": {
      position: "relative" as "relative",
      backgroundColor: "#323232",
      padding: "1rem",
      borderRadius: "1rem 1rem 1rem 0"
    },
    "& .videoImageCon": {
      padding: ".2rem .2rem 2rem .2rem",
      borderRadius: ".2rem .2rem .2rem 0"
    },
    "& .flexCon": {
      display: "flex",
      alignItems: "center",
    },
    "& .iconButton": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      border: `2px solid ${typoWhite}`
    },
    "& .middleTextCon": {
      borderRadius: "1rem",
      backgroundColor: "#171717",
    },
    "& .timeStampText": {
      float: "right",
      right: ".5rem",
      bottom: ".5rem",
      color: "#8C8C8C",
      backgroundColor: "inherit",
      marginTop: ".2rem",
    },
    "& .imageTimeStamp": {
      position: "absolute",
      right: ".2rem",
      bottom: ".5rem",
      color: "#8C8C8C",
      zIndex: 2,
      "&::after": {
        content: '"',
        clear: "both",
        marginTop: ".25rem"
      }
    },
    "& .rightTextCon": {
      backgroundColor: "#222222",
      borderRadius: "1rem 1rem 0rem 1rem"
    }
  },
  userCardsCon: {
    marginTop: "1rem",
    width: "100%",
    flexGrow: 1,
    overflow: "scroll",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    "&::-webkit-scrollbar": {
      width: "0px", 
      background: "transparent",
    },
    scrollbarWidth: "none" as "none",
    msOverflowStyle: "none" as "none",
    "& .activeCard": {
      backgroundColor: "#535353"
    }
  },
  chatHistoryCon: {
    height: "calc(100% - 91px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    margin: ".5rem 0",
    padding: "1rem",
    "&::-webkit-scrollbar": {
      width: "0px", 
      background: "transparent",
    },
    scrollbarWidth: "none" as "none",
    msOverflowStyle: "none" as "none",
    "& .chatWithDateCon": {
      width: "100%",
    },
    "& .dateCon": {
      display: "flex",
      gap: ".5rem",
      alignItems: "center",
      width: "calc(100% + 2rem)",
      marginLeft: "-1rem",
      marginBottom: "1rem",
    },
    "& .horizontalLine": {
      height: 0,
      borderBottom: `1px solid #292929`,
      flexGrow: 1,
    }
  },

  newMessagesCon: {
    padding: "1.5rem 2rem",
    "& .allPropsCon": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      alignSelf: "flex-start"
    },
    "& .cursonPointer": {
      cursor: 'pointer',
      color: '#FFF2E3'
    },
    "& .newMessagesHeading": {
      marginBottom: "1.8rem",
    },
    "& .titleLeft": {
      marginLeft: "15px",
    },
    "& .suggestionsHeading": {
      marginTop: "1.5rem",
      marginBottom: "1rem",
    },
    "& .userListCon": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "1rem",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "0px", 
        background: "transparent",
      },
      scrollbarWidth: "none" as "none",
      msOverflowStyle: "none",
    }
  },
  personChatCon: {
    display: "flex",
    flexDirection: "column" as "column",
    flexGrow: 1,
    height: "calc(100% - 89px)",
    width: "100%",
    borderTopLeftRadius: "inherit", 
    borderTopRightRadius: "inherit", 
  },
  paperItemCon: {
    backgroundColor: "#111111",
    border: "1px solid #5F5F5F",
    borderRadius: "10px",
    padding: 0,
    marginTop: "-4.75rem",
    marginLeft: "3.25%"
  },
  paperItemConOne: {
    border: "1px solid #5F5F5F",
    backgroundColor: "#111111",
    padding: 0,
    borderRadius: "10px",
    marginTop: "17rem",
    marginLeft: "-3.25%"
  },

  paperItemTwoCon: {
    margin: "10rem 0 0 -4%",
  },

  menuMenuItemCon: {
    padding: 0,
  },
  menuItem: {
    padding: "1.25rem 2rem"
  },
  blackMenuItem: {
    backgroundColor: "#222222",
    "&:hover": {
      backgroundColor: "#222222",
    }
  },
  labelCon: {
    display: "flex",
    alignItem: "center",
    gap: "1rem",
    justifyContent: "flex-start",
    padding: "1.25rem 2rem",
    cursor: "pointer",
  }
};

const NewChat = withStyles(styles)(Chat);
export { NewChat }
// Customizable Area End
