Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";

exports.Category = "Category";
exports.Description = "Description";
exports.Price = "Price";
exports.ProductName = "Product Name";
exports.CreatePost = "Create Post";
exports.UpdatePost = "Update Post";
exports.Error = "Error";
exports.FieldsErrorMassage = "Please enter all mandatory fields";
exports.Warning = "Warning";
exports.MessageForDelete = "Are you sure for delete this post?";
exports.PostName = "Post Name";
exports.Cost = "Cost";
exports.PostID = "Post ID";
exports.Delete = "Delete";
exports.Edit = "Edit";
exports.NewPost = "Add new Post";
exports.PostUpdated = "Post is updated!";
exports.PostCreated = "Post is created!";
exports.talentMayFollowApiEndPoint = "bx_block_posts/forums/may_follow_list?"
exports.topProjectSkillApiEndPoint = "bx_block_posts/forums/top_project_skills?"
exports.projectSkillApiEndPoint = "bx_block_posts/forums/project_list?"
exports.postForumPostApiEndPoint = "bx_block_posts/forum_posts"
exports.postViewApiEndPoint = "bx_block_posts/forum_posts/forum_post_view"
exports.postLikeApiEndPoint = "bx_block_posts/forum_posts/forum_post_like"
exports.postCommentApiEndPoint = "bx_block_posts/forum_posts/forum_post_comment"
exports.getCommentDataApiEndPoint = "/bx_block_posts/forum_posts/list_forum_post_comments?"
exports.forumDislikeApiEndPoint = "bx_block_posts/forum_posts/forum_post_dislike"
exports.commentsEndPoint="bx_block_comments/comments";
exports.TagsEndPoint="bx_block_posts/forum_posts/top_project_tags";
exports.shareNotificationApiEndPoint = "bx_block_posts/forum_posts/share_notification"
// Customizable Area End