import {
    Box, Dialog,
} from '@material-ui/core';
import React, { Component } from 'react';
import { Milestone } from '../ContractDetailsController.web';
import { styled } from '@material-ui/styles';

interface ViewRequestedChangesModalProps {
    open: boolean;
    handleClose: () => void;
    milestonedata: Milestone;
    handleOpenViewSubmission: (data: any, activeIndex: number) => void;
    changeString: string;
    activeIndex: number;
}

interface StateProp {
    textField: string;
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "700px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px"
        },
    },
});

export default class ViewRequestedChangesModal extends Component<ViewRequestedChangesModalProps, StateProp> {
    constructor(props: ViewRequestedChangesModalProps) {
        super(props);
        this.state = {
            textField: ""
        };
    }

    render() {
        const { open, handleClose, handleOpenViewSubmission, milestonedata, changeString, activeIndex } = this.props;

        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} data-test-id="close-modal">
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box>
                        <Box style={{ display: "flex", justifyContent: "space-between", padding: "24px 40px", fontFamily: "inter" }}>
                            <span style={{ color: "#FFF2E3", fontSize: "20px", fontWeight: 600 }}>
                                Request changes
                            </span>
                            <span
                                data-test-id="open-view-submission"
                                onClick={() => { handleOpenViewSubmission(milestonedata, activeIndex) }}
                                style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, textDecoration: "underline", cursor: "pointer" }}>
                                View submission
                            </span>
                        </Box>
                        <Box style={{ background: "transparent", borderRadius: "10px", border: "1px solid #494949", margin: "24px 40px" }}>
                            <Box style={{
                                background: "#2F2F2F",
                                border: "10px",
                                borderRadius: "10px",
                                padding: "20px"
                            }}>
                                <span style={{
                                    color: "#FFF2E3",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    fontFamily: "inter"
                                }}>
                                   {changeString}
                                </span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
