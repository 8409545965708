import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
const config = require("../../../framework/src/config.js");
import  { getBase64Image, fileToDataURL, compressedImageData,processVideos } from "../../../components/src/Base64Conversion";
let tokenData = "";
import React from "react";

import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

export interface TagsRoot {
  project_tags: ProjectTag[]
  message: string
}

export interface ProjectTag {
  name: string
  projects_count: number
  id: any
}

export interface ReplyComment {
  meta: ReplyCommentMeta
  data: ReplyCommentData[]
}

export interface ReplyCommentData {
  type: string
  id: string
  attributes: ReplyCommentDataAttributes
}

export interface ReplyCommentDataAttributes {
  id: number
  account_id: number
  commentable_type: string
  comment: string
  commentable_id: number
  updated_at: string
  is_delete: boolean
  profile_photo: any
  created_at: string
  background_color_code: string
  account_user_name: string
  has_reply: boolean
  account_full_name: string
  reply_count: number
  user_role: string
  commented_time_ago: string
}

export interface ReplyCommentMeta {
  success: boolean
  message: string
}

export interface CommentRoot {
  data: CommentData[]
  meta: CommentRootMeta
}

export interface CommentData {
  id: string
  type: string
  attributes: CommentDataAttributes
}

export interface CommentDataAttributes {
  id: number
  account_id: number
  commentable_id: number
  commentable_type: string
  comment: string
  updated_at: string
  created_at: string
  is_delete: boolean
  profile_photo: string | null | undefined
  background_color_code: string | null | undefined
  has_reply: boolean
  account_user_name: string
  account_full_name: string
  user_role: string
  reply_count: number
  commented_time_ago: string
  commentReplyData: ReplyCommentData[]
}

export interface CommentRootMeta {
  message: string
  page: number
  comments_count: number
}


export interface ForumPost {
  data: ForumPostData[]
  meta: ForumPostMeta
}

export interface ForumPostData {
  id: string
  type: string
  attributes: ForumPostDataAttributes
}

export interface Video {
  video_url: string
  video_thumbnail: string
}

export interface ForumPostDataAttributes {
  id: number
  post_description: string
  who_can_view: string
  created_at: string
  updated_at: string
  images: string[]
  create_time: string
  videos: Video[]
  user_details: ForumPostDataAttributesUserDetails
  likes: number
  views: number
  comments: number
  is_like: boolean
  like_id: number
  media: {type: string, src: string, videoUrl: string}[]
  commentData: CommentData[]
  commentPage: number
  commentTotalPage: number
}

export interface ForumPostDataAttributesUserDetails {
  name: string
  email: string
  id: number
  profile_id: number
  user_location: any
  profile_photo: string
  background_color: any
  full_name: string
  profile_cover_image: string
  role: string
}

export interface ForumPostMeta {
  message: string
  forum_posts_count: number
  page: number
}


interface ListFileType {
  data: any
  filename: string,
  file: File
}

interface MayFollowListItemAttributesSkill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}

interface MayFollowListItemAttributesUserDetails {
  name: string;
  email: string;
  id: number;
  profile_id: number;
  user_location: string | null;
  profile_photo: string | null;
  background_color: string | null;
  full_name: string;
  profile_cover_image: string | null;
  role: string
}

interface MayFollowListItemAttributes {
  views: number;
  likes: number;
  skills: MayFollowListItemAttributesSkill[];
  average_rate_per_hour: number;
  user_details: MayFollowListItemAttributesUserDetails;
  ratings: number;
}

interface MayFollowListItem {
  id: string;
  type: string;
  attributes: MayFollowListItemAttributes;
}

interface MayFollowListMeta {
  total_account: number;
  page: number;
  message: string;
}

interface MayFollowListResponse {
  data?: MayFollowListItem[];
  meta?: MayFollowListMeta;
}

interface FinalMayFollowList {
  attribute: {
    city: string | null;
    photo: string | null;
    background_color: string | null;
    skills: MayFollowListItemAttributesSkill[];
    profile_cover_image: string | null;
    ratings: number;
    views: number;
    likes: number;
    user_name: string | null;
    average_rate_per_hour: number;
    user_role: {
      role_id: number|string;
      role_name: string;
    };
  };
}

export interface SkillItem {
  name: string;
  projects_count: number;
  id: string | null;
}

interface SkillListResponse {
  skills: SkillItem[];
  skill_count: number;
  page: number;
  message: string;
}

interface ProjectListResponseData {
  id: string;
  type: string;
  attributes: ProjectListResponseDataAttributes;
}

interface ProjectListResponseDataAttributes {
  project_name: string;
  start_date: string | null;
  end_date: string | null;
  url: string | null;
  description: string;
  category_id: number;
  skills: Skill[];
  category_details: ProjectAttributesCategoryDetails;
  collection_details: ProjectAttributesCollectionDetails;
  username: string;
  account_id: number;
  profile_photo: string | null;
  background_color: string;
  cover_image_url: string;
  project_images: string[];
  likes: number|string;
  views: number|string;
  saved_count: number;
  collection_name: string;
  is_liked: boolean | null;
  is_saved: boolean | null;
}

interface Skill {
  id: number;
  name: string;
}

interface ProjectAttributesCategoryDetails {
  category_name: string;
  category_id: number;
}

interface ProjectAttributesCollectionDetails {
  collection_name: string;
  collection_id: number;
}

interface ProjectListResponse {
  data: ProjectListResponseData[];
  meta: MetaData;
}

interface MetaData {
  message: string;
  project_count: number;
  page: number;
}

interface Response {
  id: string | number, 
  cover_image_url: string, 
  likes: string | number, 
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileDataList: MayFollowListResponse;
  isOpen: boolean;
  isIdSelected: number;
  mayFollowList: FinalMayFollowList[];
  openedData: FinalMayFollowList;
  isLoading: boolean;
  isFirstTimeLoading: boolean;
  page: number;
  totalPage: number;
  perPage: number;
  skillPage: number;
  skillPerPage: number;
  skillTotal: number;
  skillItem: SkillItem[];
  ActiveSkill: string;
  projectList: ProjectListResponseData[];
  projectPerPage:number;
  projectPage:number;
  projectTotal:number;
  showDetails: boolean;
  selectedProject: string | number,
  specificProjectList: Response[],
  token: string,
  userInfo: {
    profile_photo: string | undefined,
    profile_backgroud_color: string | undefined
    userId: number
  },
  everyoneModal: boolean,
  everyoneValue: string
  tabsValue: number,
  commentTextArray: { [key: string]: string };
  shareIdeaText: string
  sharePostLoader: boolean;
  showAll: { [key: string]: boolean }
  open: boolean
  message: string
  action: string
  loadedImage: number
  isFileLoading: boolean
  profileImage: ListFileType[]
  profileVideo: ListFileType[]
  pageOfPost: number
  perPageOfPost: number
  totalPostofForum: number
  forumData: ForumPostData[]
  commentPerPage: number
  openVideo: boolean
  selectedVideoSrc: string
  showReply: string | number
  selectedCommentforReply: string | number
  replyText: string
  offViewApiCall: boolean
  commentOpenArray: { [key: string]: boolean };
  viewReplyId: string
  tags: ProjectTag[]
  selectedTag: string
  forumIdModal: boolean
  shareDialog: boolean
  shareSelectedProjectId: string|number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  talentMayFollowApiCallId: string = "";
  topProjectSkillApiCallId: string = "";
  skillProjectApiCallId: string = ""; 
  postForumApiCallId: string = "";
  getPostForumApiCallId: string = "";
  getPostForumScrollApiCallId: string = "";
  postViewForumApiCallId: string = "";
  postComentForumApiCallId: string = ""
  getCommentForumApiCallId: string = ""
  getCommentForumScrollApiCallId: string = ""
  dislikeForumApiCallId: string = ""
  replyCommentApiCallId: string = ""
  apiGetReplyCallId: string = ""
  getTagApiCallId: string = ""
  shareSendInAppNotificationApiCallId: string = ""
  itemsContainerRef: React.RefObject<HTMLDivElement>;
  refElmFile: React.RefObject<HTMLInputElement>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      profileDataList: {},
      isOpen: false,
      isIdSelected: 0,
      mayFollowList: [],
      openedData: {
        attribute: {
          average_rate_per_hour: 0,
          background_color: null,
          city: "",
          likes: 0,
          photo: null,
          profile_cover_image: null,
          ratings: 0,
          skills: [],
          user_name: "",
          views: 0,
          user_role:{
            role_id:0,
            role_name:''
          }
        },
      },
      isLoading: false,
      isFirstTimeLoading: true,
      page: 1,
      perPage: 4,
      totalPage: 0,
      skillPage: 1,
      skillPerPage: 5,
      skillTotal: 0,
      skillItem: [],
      ActiveSkill: "",
      projectList: [],
      projectPerPage: 4,
      projectPage: 1,
      projectTotal: 0,
      showDetails: false,
      selectedProject: "",
      specificProjectList: [],
      token: '',
      userInfo: {
        profile_backgroud_color: '',
        profile_photo: '',
        userId: 0
      },
      everyoneModal: false,
      everyoneValue: 'everyone',
      tabsValue: 0,
      commentTextArray: {},
      shareIdeaText: '',
      sharePostLoader: false,
      showAll: {},
      action: '',
      message: '',
      open: false,
      loadedImage: 0,
      isFileLoading: false,
      profileImage: [],
      profileVideo: [],
      pageOfPost: 1,
      perPageOfPost: 4,
      totalPostofForum: 0,
      forumData: [],
      commentPerPage: 5,
      openVideo: false,
      selectedVideoSrc: '',
      selectedCommentforReply: '',
      showReply: '',
      replyText: '',
      offViewApiCall: false,
      commentOpenArray: {},
      viewReplyId: '',
      tags: [],
      selectedTag: '',
      forumIdModal: false,
      shareDialog: false,
      shareSelectedProjectId: ''
    };
    this.itemsContainerRef = React.createRef<HTMLDivElement>();
    this.refElmFile = React.createRef<HTMLInputElement>();
    this.fileInputRef = React.createRef<HTMLInputElement>();
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);

    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
      this.setState({
        userInfo: {
          profile_backgroud_color: storeData.data.attributes.background_color,
          profile_photo: storeData.data.attributes.profile_image,
          userId: storeData.meta.id
        }
      })
    }
    this.setState({ isLoading: true, isFirstTimeLoading: true });
    this.getTalentMayFollow();
    this.topProjectSkill();
    this.getTagsList()

    const projectId = this.props.navigation.getParam("id") || null;
    if (projectId !== null) { 
      this.setState({ forumIdModal: true, shareSelectedProjectId: Number(projectId) },()=>{
        this.getForumIdPost()
        this.getProjectIDForumPostContent()
      })
    } else {
      this.getPostForum();
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const item = message.getData(getName(MessageEnum.PostDetailDataMessage));
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson) {
        this.parseApiErrorResponse(responseJson.errors);

        switch (apiRequestCallId) {
          case this.talentMayFollowApiCallId:
            this.handleManageFollowList(responseJson);
            break;

          case this.topProjectSkillApiCallId:
            this.handleManageSkills(responseJson);
            break;

          case this.skillProjectApiCallId:
            this.handleProjectList(responseJson);
            break;

          case this.postForumApiCallId:
            this.handleCreatePost(responseJson);
            break;

          case this.getPostForumApiCallId:
            this.handleForumGetPost(responseJson);
            break;

          case this.postViewForumApiCallId:
            this.handleLikeAndGetPost(responseJson);
            break;

          case this.getPostForumScrollApiCallId:
            this.handleForumGetPostScroll(responseJson);
            break;

          case this.postComentForumApiCallId:
            this.handleCommentResponse(responseJson);
            break;

          case this.getCommentForumApiCallId:
            this.handleSetCommnetData(responseJson);
            break;

          case this.getCommentForumScrollApiCallId:
            this.handleSetCommnetDataScroll(responseJson);
            break;

          case this.dislikeForumApiCallId:
            this.handleLikeAndGetPost(responseJson);
            break;

          case this.replyCommentApiCallId:
            this.handleLikeAndGetPost(responseJson);
            break;

          case this.apiGetReplyCallId:
            this.handleReplyGetCommentData(responseJson);
            break;

          case this.getTagApiCallId:
            this.handleTags(responseJson);
            break;

          default:
            break;
        }
      }
    }
  }

  HandleNavigations = async (item: {
    user_role: { role_id:string|number,role_name: string|number, };
  }) => {
    let localdata = {
      account_id: item.user_role.role_id,
      user_role: item.user_role.role_name,
    };

    await setStorageData("PublicId", JSON.stringify(localdata));
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PublicUserProfile"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    if (this.state.isOpen) {
      return;
    }
    this.setState(
      { isIdSelected: id, openedData: this.state.mayFollowList[id] },
      () => {
        this.setState({ isOpen: true });
      }
    );
  };

  profileClose = () => {
    this.setState({ isOpen: false });
  };

  handleClose = () => {
    this.setState({ open: false });
  }

  handleNavigation = (path: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  processProfileData = (data: MayFollowListItem[]) => {
    return data.map((item) => {
      const { attributes } = item;
      const {
        user_details,
        skills,
        views,
        likes,
        average_rate_per_hour,
        ratings,
      } = attributes;

      return {
        attribute: {
          city: user_details.user_location,
          photo: user_details.profile_photo,
          background_color: user_details.background_color,
          skills: skills,
          profile_cover_image: user_details.profile_cover_image,
          ratings: ratings,
          views: views,
          likes: likes,
          user_name: user_details.name,
          average_rate_per_hour: average_rate_per_hour,
          user_role: {
            role_id: user_details.id,
            role_name: user_details.role
          }
        },
      };
    });
  };

  tags = [
    "#Inktober",
    "#100DaysofDesignChalleng",
    "#DesignChallenge",
    "#100DaysChallenge",
    "#Inktober",
    "#100DaysofDesignChallenge",
    "#DesignChallenge",
  ];

  imageLinks = [
    "https://picsum.photos/seed/1/800/600",
    "https://picsum.photos/seed/2/800/600",
    "https://picsum.photos/seed/3/800/600",
    "https://picsum.photos/seed/4/800/600",
    "https://picsum.photos/seed/5/800/600",
    "https://picsum.photos/seed/6/800/600",
    "https://picsum.photos/seed/7/800/600",
    "https://picsum.photos/seed/8/800/600",
    "https://picsum.photos/seed/9/800/600",
    "https://picsum.photos/seed/10/800/600"
  ];  

  handleScroll = () => {
    const loader = this.itemsContainerRef.current;
    if (loader) {
      const { scrollTop, scrollHeight, clientHeight } = loader;
      if (
        scrollTop + clientHeight + 25 >= scrollHeight &&
        !this.state.isLoading
      ) {
        this.setState(
          (prevState) => ({ page: prevState.page + 1 }),
          () => {
            if (
              Math.ceil(this.state.mayFollowList.length / this.state.perPage) <
              this.state.totalPage
            ) {
              this.setState({ isLoading: true });
              this.getTalentMayFollow();
            }
          }
        );
      }
    }
  };

  handleManageFollowList = (responseJson: MayFollowListResponse) => {
    if (responseJson.data && responseJson.data.length) {
      this.setState({
        profileDataList: responseJson,
        isLoading: false,
        isFirstTimeLoading: false,
      });
      if (responseJson.meta) {
        this.setState({
          totalPage: Math.ceil(
            responseJson.meta?.total_account / this.state.perPage
          ),
        });
      }
      const final: FinalMayFollowList[] = this.processProfileData(
        responseJson.data
      );
      this.setState({ mayFollowList: [...this.state.mayFollowList, ...final] });
    } else {
      this.setState({
        profileDataList: {
          data: [],
          meta: {
            message: "",
            page: 0,
            total_account: 0,
          },
        },
      });
    }
  };

  handleManageSkills = (responseJson: SkillListResponse) => {
    if (responseJson && responseJson.skills.length) {
      this.setState({
        skillItem: responseJson.skills,
        skillTotal: responseJson.skill_count,
      });
      if (this.state.ActiveSkill === "") {
        this.handleActiveSkill(responseJson.skills[0].name)
      }
    }
  };

  handleProjectList = (responseJson: ProjectListResponse) => {
    if (responseJson.data && responseJson.data.length) {
      this.setState({
        projectList: responseJson.data,
        projectTotal: responseJson.meta.project_count
      })
    } else {
      this.setState({
        projectList: [],
        projectTotal: 0
      })
    }
  }

  handleCreatePost = (responseJson: { meta: { message: string } }) => {
    if (responseJson.meta.message === "Forum post created") {
      this.setState({
        open: true,
        message: responseJson.meta.message,
        action: "success",
        shareIdeaText: '',
        profileImage: [],
        profileVideo: [],
        everyoneValue: "everyone"
      });
    }
  }

  handleForumGetPost = (responseJson: ForumPost) => {
    if (responseJson.data) {
      const transformedData: ForumPostData[] = responseJson.data.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          media: [
            ...(item.attributes.videos || []).map((vid) => ({ type: "video", src: vid.video_thumbnail, videoUrl: vid.video_url })),
            ...(item.attributes.images || []).map((img) => ({ type: "image", src: img, videoUrl: '' }))
          ],
          commentData: [],
          commentPage: 1,
          commentTotalPage: 0
        }
      }));
      this.setState({
        forumData: transformedData,
        totalPostofForum: responseJson.meta.forum_posts_count
      }, () => {
        if (this.state.offViewApiCall) {
          responseJson.data.forEach((item) => {
            this.postViewLike(item.attributes.id, "view")
          })
        }
      })
    } else {
      this.setState({
        forumData: [],
        totalPostofForum: 0
      })
    }
  }

  getProjectIDForumPostContent = () => {
    const data = this.state.forumData.filter((item)=>(item.attributes.id === this.state.shareSelectedProjectId))
    const name = data.length > 0 ? data[0].attributes.post_description : '';
    return name
  }

  handleLikeAndGetPost = (responseJson: { meta?: { message: string } } & { message?: string }) => {
    const projectId = this.props.navigation.getParam("id") || null;
    if (
      responseJson.meta?.message === "Forum post liked successfully" ||
      responseJson.meta?.message === "Comment created." ||
      responseJson.message === "Successfully dislike forum post"
    ) {
      if (projectId !== null) { 
        this.setState({ forumIdModal: true, shareSelectedProjectId: Number(projectId) }, () => {
          this.getForumIdPost()
        })
      } else {
        this.getPostForum(1);
      }
    }
  };

  handleForumGetPostScroll = (responseJson: ForumPost) => {
    this.setState({
      pageOfPost: this.state.pageOfPost + 1
    })
    if (responseJson.data) {
      const transformedData: ForumPostData[] = responseJson.data.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          media: [
            ...(item.attributes.images || []).map((img) => ({ 
              type: "image", 
              src: img, 
              videoUrl: '' 
            })),
            ...(item.attributes.videos || []).map((vid) => ({ type: "video", src: vid.video_thumbnail, videoUrl: vid.video_url }))
          ],
          commentData: [],
          commentPage: 1,
          commentTotalPage: 0
        }
      }));
      this.setState((prevState) => ({
        forumData: [...prevState.forumData, ...transformedData],
        totalPostofForum: responseJson.meta.forum_posts_count,
      }), () => {
        responseJson.data.forEach((item) => {
          this.postViewLike(item.attributes.id, "view")
        })
      })
    } else {
      this.setState({
        forumData: [],
        totalPostofForum: 0
      })
    }
  }

  handleCommentResponse = (responseJson: { "meta": { "success": boolean, "message": string } }) => {
    const projectId = this.props.navigation.getParam("id") || null;
    if (responseJson.meta.success && responseJson.meta.message === "Comment created.") {
      this.setState({
        open: true,
        message: responseJson.meta.message,
        action: "success",
        commentOpenArray: {}
      })
      if (projectId !== null) { 
        this.setState({ forumIdModal: true, shareSelectedProjectId: Number(projectId) }, () => {
          this.getForumIdPost()
        })
      } else {
        this.getPostForum(1);
      }
    }
  }

  handleSetCommnetData = (responseJson: CommentRoot) => {
    if (responseJson.data) {
      let postId = responseJson.data[0].attributes.commentable_id
      const updatedComments = responseJson.data.map(comment => ({
        ...comment,
        attributes: {
          ...comment.attributes,
          commentReplyData: []
        }
      }));
      this.setState({ selectedCommentforReply: "" });
      this.setState((prevState) => ({
        forumData: prevState.forumData.map((post) =>
          post.id === String(postId)
            ? {
              ...post,
              attributes: {
                ...post.attributes,
                commentData: updatedComments,
                commentTotalPage: responseJson.meta.comments_count
              },
            }
            : post
        ),
      }));
    }
  }

  handleSetCommnetDataScroll = (responseJson: CommentRoot) => {
    if (responseJson.data) {
      let postId = responseJson.data[0].attributes.commentable_id

      this.setState((prevState) => ({
        forumData: prevState.forumData.map((post) =>
          post.id === String(postId)
            ? {
              ...post,
              attributes: {
                ...post.attributes,
                commentData: [...post.attributes.commentData, ...responseJson.data],
                commentPage: post.attributes.commentPage + 1
              },
            }
            : post
        ),
      }));
    }
  }
  

  handleReplyGetCommentData = (responseJson: ReplyComment) => {
    if (responseJson.data) {
      let postId = responseJson.data[0].attributes.commentable_id
      this.setState((prevState) => ({
        forumData: prevState.forumData.map((post) =>
          Number(post.attributes.id) == Number(this.state.selectedCommentforReply)
            ? {
              ...post,
              attributes: {
                ...post.attributes,
                commentData: post.attributes.commentData.map((comment) =>
                  Number(comment.attributes.id) == Number(postId)
                    ? {
                      ...comment,
                      attributes: {
                        ...comment.attributes,
                        commentReplyData: responseJson.data
                      }
                    }
                    : comment
                ),
              },
            }
            : post
        ),
      }));
    }
  }

  handleTags = (responseJson: TagsRoot) => {
    if (responseJson.project_tags) {
      this.setState({tags: responseJson.project_tags})
    } else {
      this.setState({tags: []})
    }
  }

  showMoreSkills = () => {
    if (
      Math.ceil(this.state.skillItem.length / this.state.skillPerPage) <
      this.state.skillTotal
    ) {
      this.setState(
        (prevState) => ({
          skillPage: prevState.skillPage + 1,
        }),
        () => this.topProjectSkill()
      );
    }
  };

  showLessSkills = () => {
    this.setState(
      (prevState) => ({
        skillPage: prevState.skillPage - 1,
      }),
      () => this.topProjectSkill()
    );
  };

  handleActiveSkill = (data: string) => {
    if (this.state.ActiveSkill === data) {
      return;
    }
  
    this.setState({ ActiveSkill: data, projectPage: 1 }, () => {
      this.skillProject();
    });
  }

  showMoreProjects = () => {
    if (
      Math.ceil(this.state.projectList.length / this.state.projectPerPage) <
      this.state.projectTotal
    ) {
      this.setState(
        (prevState) => ({
          projectPage: prevState.projectPage + 1,
        }),
        () => this.skillProject()
      );
    }
  };

  showLessProjects = () => {
    this.setState(
      (prevState) => ({
        projectPage: prevState.projectPage - 1,
      }),
      () => this.skillProject()
    );
  };

  openModal = (data: any) => {
    this.setState({
      showDetails: true
    });
    this.handleSelectedProject(data);
  }

  handleReplyAdded = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | React.KeyboardEvent<HTMLDivElement>, id: number) => {
    if (event.key === 'Enter') {
      this.addReply();
      this.setState({
        selectedCommentforReply: id,
        replyText: '',
        showReply: '',
      })
      this.setState((prevState) => {
        const { commentOpenArray } = prevState;
        const updatedShowAll = { ...commentOpenArray };
        if (id in updatedShowAll) {
          delete updatedShowAll[id];
        } else {
          updatedShowAll[id] = true;
        }
        return { commentOpenArray: updatedShowAll, showReply: "" };
      }) 
    }
  }

  handleCancelReply = () => {
    this.setState({
      replyText: '',
      showReply: ''
    });
  }

  transformProjectData = (data:ProjectListResponseData) => {
    return {
      id: Number(data.id),
      project_name: data.attributes.project_name,
      cover_image_url: config.baseURL + data.attributes.cover_image_url,
      username: data.attributes.username,
      likes: data.attributes.likes,
      views: data.attributes.views,
      saved_count: data.attributes.saved_count
    };
  };

  handleSelectedProject = (data: ProjectListResponseData) => {
    const transformedData = this.transformProjectData(data);
    const allData = [];
    for (const project of this.state.projectList) {
      if (project.id !== data.id) {
        const transformedDataNew = this.transformProjectData(project);
        allData.push(transformedDataNew);
      }
      if (allData.length === 3) {
        break;
      }
    }
    transformedData.cover_image_url && allData.push(transformedData);
    this.setState({
      selectedProject: transformedData.id,
      specificProjectList: [...allData],
      token: tokenData
    });
  }

  handleReplyClick = (id: string | number) => {
    this.setState({
      showReply: id,
      selectedCommentforReply: ''
    });
  }

  handleReplyChange = (data: string) => {
    this.setState({
      replyText: data
    });
  }

  closeModal = (likes:number|string, views:number|string) => {
    this.handleChangeLikeCount(likes,views)
    this.setState({
      specificProjectList: [],
      selectedProject: '',
      showDetails: false
    })
  }

  handleCloseForumModal = () => {
    this.setState({forumIdModal: false})
    this.handleNavigation("Forum")
    this.getPostForum();
  }
  
  handleShareModal = (id?: number) => {
    if (id) {
      this.sendInAppNotificactionShare(id)
      this.setState({shareSelectedProjectId: id})
    }
    this.setState({shareDialog: !this.state.shareDialog})
  }

  handleChangeLikeCount = (likes:number|string, views:number|string) =>{
    let value = this.state.projectList.map((element:ProjectListResponseData)=>{
      if(element.id == this.state.selectedProject){
        element.attributes.likes = likes
        element.attributes.views = views
      }
      return element
    })
    this.setState({projectList:value})
  }

  HandleNavigation = (comment: { userId: number | string, user_role: string, collection_name: string, collection_id: number | string }, userId: number | string) => {
    let path = userId == comment.userId ? "UserProfile" : "PublicUserProfile"
    let localdata = {
      account_id: comment.userId,
      user_role: comment.user_role,
      collection_name: comment.collection_name ?? "",
      collection_id: comment.collection_id ?? 0
    }
    if (path == "PublicUserProfile") {
      setStorageData("PublicId", JSON.stringify(localdata));
    }

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  manageEveryoneModal = () => {
    this.setState({
      everyoneModal: !this.state.everyoneModal
    })
  }

  handleSelectChange = (value: string) => {
    this.setState({ everyoneValue: value, everyoneModal: false })
  };

  handleTabValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabsValue: newValue, pageOfPost: 1 },()=>{
      this.getPostForum()
    })
  };

  handleShareIdea = (data: string) => {
    this.setState({
      shareIdeaText: data
    })
  }

  handleComment = (id: number, text: string) => {
    this.setState((prevState) => ({
      commentTextArray: {
        ...prevState.commentTextArray,
        [id]: text,
      },
    }));
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,id:number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.postComment(id);
    }
  };

  handleShowAll = (id: number) => {
    this.setState((prevState) => ({
      showAll: {
        ...prevState.showAll,
        [id]: true,
      },
    }));
  };

  handleUploadFileClick = () => {
    if (this.refElmFile.current) {
      this.refElmFile.current.click()
    }
  }

  handleUploadFileVideoClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click()
    }
  }

  validFileFormat = (fileData: File) => {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validImageTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid image file (jpg, png).",
        action: "danger"
      });
      return false;
    }
    return true
  }

  validVideoFormat = (fileData: File) => {
    const validImageTypes = ['video/mp4'];
    if (!validImageTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid video file (mp4).",
        action: "danger"
      });
      return false;
    }
    return true
  }

  loadedData = (loaded: number) => {
    this.setState({ loadedImage: loaded });
  }

  selectTag = (name: string) => {
    if (name === this.state.selectedTag) {
      this.setState({ selectedTag: "", pageOfPost: 1, projectPage: 1, tabsValue: 0 }, () => {
        this.skillProject()
        this.getPostForum()
      })
    } else {
      this.setState({ selectedTag: name, projectPage: 1, pageOfPost: 1, tabsValue: 0 }, () => {
        this.skillProject()
        this.getPostForum()
      })
    }
  }

  handleProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileData = event.target.files[0];
      if (!this.validFileFormat(fileData)) {
        return;
      }
      const maxFileSizeMB = 20;
      if (fileData.size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 20MB. Please upload a smaller file."
        });
        return;
      }
      const compressedFile = await compressedImageData(fileData, 0, 0, 0.7);
      this.setState({
        isFileLoading: true,
      });
      const urlData: any = await fileToDataURL(compressedFile);
      await getBase64Image(urlData, this.loadedData);
      this.setState((prevState) => ({
        isFileLoading: false,
        profileImage: [
          ...prevState.profileImage,
          { data: urlData || '', filename: fileData.name, file: compressedFile }
        ]
      }));
    }
  }

  handleVideoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileData = event.target.files[0];
      if (!this.validVideoFormat(fileData)) {
        return;
      }
      const maxFileSizeMB = 50;
      if (fileData.size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 50MB. Please upload a smaller file."
        });
        return;
      }

      const videoFiles = Array.from(event.target.files);
      try {
        const thumbnails = await processVideos(videoFiles);

        this.setState((prevState) => ({
          profileVideo: [
            ...prevState.profileVideo,
            ...videoFiles.map((file, index) => ({
              data: thumbnails[index] || '',
              filename: file.name,
              file: file
            }))
          ]
        }));
      } catch (error) {
        console.error("Error processing videos:", error);
      }
    }
  };

  enablePostWorkOpportunity = () => {
    if (
      this.state.shareIdeaText.trim()
    ) {
      return true;
    } else {
      return false;
    }
  }
  
  enablebuttonText = () => {
    return this.enablePostWorkOpportunity() ? "" : "disabled-btn";
  }
  
  enableCommentBtn = (id: number) => {
    return !!this.state.commentTextArray?.[id]?.trim();
  }

  handleModelCloseOnCross = () => {
    this.setState({ openVideo: false, selectedVideoSrc: '' })
  }

  handleReply = (id: string | number) => { return this.state.viewReplyId === String(id); }

  handleModelOpen = (src: string) => {
    this.setState({ openVideo: true, selectedVideoSrc: src })
  }

  handleURL = (file: ListFileType) => { return file.data || file.file.name }

  deleteProfile = (data: number) => {
    let dataList = this.state.profileImage.filter((item, index) => index !== data);
    this.setState({ profileImage: [...dataList] });
  }

  deleteVideo = (data: number) => {
    let dataList = this.state.profileVideo.filter((item, index) => index !== data);
    this.setState({ profileVideo: [...dataList] });
  }

  getTalentMayFollow = () => {
    let token = tokenData;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.talentMayFollowApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.talentMayFollowApiEndPoint +
      `page=${this.state.page}&per_page=${this.state.perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  topProjectSkill = () => {
    let token = tokenData;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.topProjectSkillApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topProjectSkillApiEndPoint +
      `page=${this.state.skillPage}&per_page=${this.state.skillPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  skillProject = () => {
    let token = tokenData;
    let tagname = ''
    if (this.state.selectedTag !== '') {
      tagname = "%23" + this.state.selectedTag.substring(1)
    } else {
      tagname = ""
    }
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.skillProjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectSkillApiEndPoint +
      `page=${this.state.projectPage}&per_page=${this.state.projectPerPage}&skill_name=${this.state.ActiveSkill}&tag_name=${tagname}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postForum = () => {
    let token = tokenData;
    const header = {
      token: token,
    };
    let submitFormData = new FormData();
    submitFormData.append("forum_post[post_description]", this.state.shareIdeaText);
    {
      this.state.profileImage?.length > 0 &&
        this.state.profileImage.forEach((item) => {
          submitFormData.append("forum_post[images][]", item.file);
        })
    }
    {
      this.state.profileVideo?.length > 0 &&
        this.state.profileVideo.forEach((item) => {
          submitFormData.append("forum_post[videos][]", item.file);
        })
    }
    submitFormData.append("forum_post[who_can_view]", this.state.everyoneValue);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postForumPostApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      submitFormData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPostForum = (id?:number) => {
    if (id){
      this.setState({offViewApiCall:false})
    } else {
      this.setState({offViewApiCall:true})
    }
    let token = tokenData;
    let filter = '';
    let tagname = ''
    const header = {
      token: token,
    };
    if (this.state.tabsValue === 0) {
      filter = "for_you"
    } else if (this.state.tabsValue === 1) {
      filter = "following"
    } else {
      filter = "your_activity"
    }
    if (this.state.selectedTag !== '') {
      tagname = "%23" + this.state.selectedTag.substring(1)
    } else {
      tagname = ""
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      id ? configJSON.postForumPostApiEndPoint + `?page=${1}&per_page=${this.state.perPageOfPost * this.state.pageOfPost}&filter=${filter}&tag_name=${tagname}`
      : configJSON.postForumPostApiEndPoint + `?page=${this.state.pageOfPost}&per_page=${this.state.perPageOfPost}&filter=${filter}&tag_name=${tagname}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPostForumScroll = () => {
    let token = tokenData;
    let filter = '';
    let tagname = ''
    if (this.state.selectedTag !== '') {
      tagname = "%23" + this.state.selectedTag.substring(1)
    } else {
      tagname = ""
    }
    const header = {
      token: token,
    };
    if (this.state.tabsValue === 0) {
      filter = "for_you"
    } else if (this.state.tabsValue === 1) {
      filter = "following"
    } else {
      filter = "your_activity"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostForumScrollApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postForumPostApiEndPoint + `?page=${this.state.pageOfPost + 1}&per_page=${this.state.perPageOfPost}&filter=${filter}&tag_name=${tagname}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postViewLike = (id: number, type: string) => {
    this.setState((prevState) => {
      const { commentOpenArray } = prevState;
      const updatedShowAll = { ...commentOpenArray };
      if (id in updatedShowAll) { delete updatedShowAll[id]; }
      return {
        commentOpenArray: updatedShowAll, showReply: "",
      }
    })
    let token = tokenData;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    }
    let dataView = {
      "data": {
        "attributes": {
          "viewable_id": id,
          "viewable_type": "BxBlockPosts::ForumPost"
        }
      }
    }
    let dataLike = {
      "data": {
        "attributes": {
          "likeable_id": id,
          "likeable_type": "BxBlockPosts::ForumPost"
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postViewForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type === "view" ? configJSON.postViewApiEndPoint : configJSON.postLikeApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "view" ? JSON.stringify(dataView) : JSON.stringify(dataLike)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postComment = (id: number) => {
    let token = tokenData;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    }
    let data = {
      "comment": {
        "comment": this.state.commentTextArray[id],
        "commentable_id": id,
        "commentable_type": "BxBlockPosts::ForumPost"
      }
    }
    this.setState((prevState) => ({
      commentTextArray: {
        ...prevState.commentTextArray,
        [id]: '',
      },
    }));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postComentForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getComment = (index: number, id: number) => {
    if (id) {
      this.setState((prevState) => {
        const { commentOpenArray, forumData } = prevState;
        const updatedShowAll = { ...commentOpenArray };
        if (id in updatedShowAll) { delete updatedShowAll[id]; }
        else { updatedShowAll[id] = true; }
        return {
          commentOpenArray: updatedShowAll, showReply: "",
          forumData: forumData.map((post) =>
            Number(post.id) === Number(id) && !(id in commentOpenArray)
              ? {
                ...post,
                attributes: {
                  ...post.attributes,
                  commentData: [...post.attributes.commentData],
                  commentPage: 1,
                  commentTotalPage: 0
                },
              }
              : post
          ),
        };
      }, () => {
        if (this.state.commentOpenArray[id]) {
          let token = tokenData;
          const header = { token: token }
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getCommentForumApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCommentDataApiEndPoint + `id=${id}&page=${this.state.forumData[index].attributes.commentPage}&per_page=${this.state.commentPerPage}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PostApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        }
      });
    }
  };

  getCommentScroll = (index: number, id: number) => {
    let token = tokenData;
    const header = {
      token: token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentForumScrollApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommentDataApiEndPoint + `id=${id}&page=${this.state.forumData[index].attributes.commentPage + 1}&per_page=${this.state.commentPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  disLikeProject = (id: number, arrayId: number) => {
    this.setState((prevState) => {
      const { commentOpenArray } = prevState;
      const updatedShowAll = { ...commentOpenArray };
      if (arrayId in updatedShowAll) { delete updatedShowAll[arrayId]; }
      return {
        commentOpenArray: updatedShowAll, showReply: "",
      }
    })
    let token = tokenData;
    const header = {
      token: token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dislikeForumApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.forumDislikeApiEndPoint + `?id=${id}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addReply = () => {
    let token = tokenData;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    }
    const bodyData = {
      "comment": {
        "comment": this.state.replyText,
        "commentable_id": this.state.showReply,
        "commentable_type": "BxBlockComments::Comment"
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replyCommentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.postCommentApiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.PostAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getReplyList = (id: string | number, postId: number) => {
    let token = tokenData;
    if (this.state.viewReplyId == id) {
      this.setState({ viewReplyId: '' })
    } else {
      this.setState({
        selectedCommentforReply: postId,
        viewReplyId: String(id),
      }, () => {
        const header = {
          token: token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.commentsEndPoint + `/${id}`);
        this.apiGetReplyCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.PostApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, 
          requestMessage
        );
        return true;
      })
    }
  }

  getTagsList = () => {
    let token = tokenData;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTagApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TagsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getForumIdPost = () => {
    let token = tokenData;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostForumApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postForumPostApiEndPoint}/${this.state.shareSelectedProjectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendInAppNotificactionShare = (id: number) => {
    let token = tokenData;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    }
    const bodyData = {
      "id": id 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shareSendInAppNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.shareNotificationApiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.PostAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;    
  }
  // Customizable Area End
}
