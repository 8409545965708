import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getToken, getUserProfile } from "../../../components/src/Base64Conversion";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  tabValues:number;
  findWorkSearch:string;
  selectedSort:{
    name:string;
    value:string;
  };
  isSearched:boolean;
  filterDataActive:{key:number;name:string;select:boolean;value:string}[];
  filterDataArchive:{key:number;name:string;select:boolean;value:string}[];
  token:string;
  userType:string;
  activeData:any;
  archiveData:any
  sortData:{value:string;name:string;key:number}[];
  page:number;
  totalData:number;
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContractController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  offereContractActiveApiId:string="";
  offereContractArchiveApiId:string="";
  offereContractActiveApiIdInitial:string="";
  offereContractArchiveApiIdInitial:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      tabValues:0,
      page:0,
      loading:false,
      totalData:0,
      isSearched:false,
      activeData:[],
      archiveData:[],
      findWorkSearch:"",
      token:"",
      userType:"",
      sortData:[
        {key:0,name:"Oldest to newest",value:"oldest_to_newest"},
        {key:1,name:"Newest to oldest",value:"newest_to_oldest"},
        {key:2,name:"Timeline: High to low",value:"timeline_high_to_low"},
        {key:3,name:"Timeline: Low to high",value:"timeline_low_to_high"},
        {key:4,name:"Budget: High to low",value:"budget_high_to_low"},
        {key:5,name:"Budget: Low to high",value:"budget_low_to_high"},

      ],
      filterDataActive:[
        {key:0,name:"New Notification",select:false,value:"new_notification"},
        {key:1,name:"New message",select:false,value:"new_message"},
        {key:2,name:"Pending approval on submission",select:false,value:"submission_approval_pending"},
        {key:3,name:"Awaiting milestone activation",select:false,value:"awaiting_milestone_activation"},
        {key:4,name:"Submission pending",select:false,value:"submission_pending"},
      ],
      filterDataArchive:[
        {key:0,name:"Ended mid contract",select:false,value:"ended_mid_contract"},
        {key:1,name:"Ended successfully",select:false,value:"ended_successfully"},
      ],
      selectedSort:{
        name: "Recently Listed",
        value:'recently_listed'
        
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  this.setState({loading:false});

      if(apiRequestCallId===this.offereContractActiveApiIdInitial){
        if(!responseJson.error && !responseJson.errors){
        this.setState({
          activeData: responseJson?.contracts?.data,
                    totalData:responseJson?.contracts_count,
                    page:responseJson?.page
        })
      }
      else{
        this.setState({
          activeData: [],
                    totalData:0,
                    page:0
        })
      }
      }
      if(apiRequestCallId===this.offereContractArchiveApiIdInitial){
        if(!responseJson.error&&!responseJson.errors){
        this.setState({
          archiveData: responseJson?.contracts?.data,
                    totalData:responseJson?.contracts_count,
                    page:responseJson?.page
        })
      }
      else{
        this.setState({
          archiveData: [],
                    totalData:0,
                    page:0
        })
      }
      }
      if(apiRequestCallId==this.offereContractArchiveApiId){
        if(!responseJson.error&&!responseJson.errors){
          this.setState((prevState)=> ({
            archiveData: [...prevState.archiveData, ...responseJson?.contracts?.data],
            totalData:responseJson?.contracts_count,
            page:responseJson?.page
           }))
        }
        else{
          this.setState({
            archiveData: [],
                      totalData:0,
                      page:0
          })
        }
      } 
      if(apiRequestCallId==this.offereContractActiveApiId){
        if(!responseJson.error&&!responseJson.errors){
          this.setState((prevState)=> ({
            activeData: [...prevState.activeData, ...responseJson?.contracts?.data],
            totalData:responseJson?.contracts_count,
            page:responseJson?.page
           }))
        }
        else{
          this.setState({
            activeData: [],
                      totalData:0,
                      page:0
          })
        }
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    await this.getTokenData()
    const newValue =await getStorageData("contractTab")
    if(newValue==="1"){
      this.setState({ tabValues: 1,page:0 ,activeData:[],archiveData:[],findWorkSearch:"" });
      this.getDataListInitial(1,"recently_listed",this.state.filterDataArchive,"")
    }
    else{
      this.setState({ tabValues: 0,page:0 ,activeData:[],archiveData:[],findWorkSearch:"" });
      this.getDataListInitial(0,"recently_listed",this.state.filterDataActive,"")
    }
    removeStorageData("contractTab")
  }
 
  handleSortSelect = (name:string,value:string) =>{
    this.setState({selectedSort:{name,value}})
    if(this.state.tabValues==0){
      this.getDataListInitial(this.state.tabValues,value,this.state.filterDataActive,this.state.findWorkSearch)
    }
    else{
      this.getDataListInitial(this.state.tabValues,value,this.state.filterDataArchive,this.state.findWorkSearch)

    }

  }

  handleFindWorkSearch=(event:any)=>{
    this.setState({findWorkSearch:event.target.value})
this.getDataListInitial(this.state.tabValues,this.state.selectedSort.value,this.state.filterDataArchive,event.target.value)
  }
 
  handleTabValueChange = (event:any, newValue: number) => {
    this.setState({ tabValues: newValue,page:0 ,activeData:[],archiveData:[],findWorkSearch:"" });
    this.setState({
      filterDataActive: [
        {
          key: 0,
          select: false,
          name: "New Notification",

          value: "new_notification"
        },
        {
          key: 1,
          select: false,
          name: "New message",
          value: "new_message"
        },
        {
          key: 2,
          select: false,
          name: "Pending approval on submission",
          value: "submission_approval_pending"
        },
        {
          key: 3,
          select: false,
          name: "Awaiting milestone activation",
          value: "awaiting_milestone_activation"
        },
        {
          key: 4,
          select: false,
          name: "Submission pending",
          value: "submission_pending"
        },
      ],
      filterDataArchive: [
        {
          key: 0,
          select: false,
          name: "Ended mid contract",
          value: "ended_mid_contract"
        },
        {
          key: 1,
          select: false,
          name: "Ended successfully",
          value: "ended_successfully"
        },
      ],
      selectedSort:{
        name: "Recently Listed",
        value:'recently_listed'
        
      }
    })
    if(newValue==0){
      this.getDataListInitial(newValue,"recently_listed",this.state.filterDataActive,"")
    }
    else{
      this.getDataListInitial(newValue,"recently_listed",this.state.filterDataArchive,"")
    }

  };
  a11yProps23(index:number, label:string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: label,
    };
  }

  handleStyle=(select:boolean)=>{
    return select?
      {
        background: "rgba(50, 50, 50, 1)",
        color: "rgba(255, 242, 227, 1)",
        border: '1px solid rgba(255, 242, 227, 1)',
        fontFamily: 'Inter',
        fontSize: "12px",
        fontWeight: 600,
        gap: '4px',
        lineHeight: "14.52px",
      } :
      {
        background: "rgba(17, 17, 17, 1)",
        gap: '4px',
        color: "rgba(255, 242, 227, 1)",
        border: '1px solid rgba(255, 242, 227, 1)',
        fontFamily: 'Inter',
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "14.52px",
      }
  }
  handleNavigation(path:string,id:number) {
 this.props.navigation.navigate(path,{id:id})
 
  }
  toggleSelectArchive = (key:number) => {
    const updatedFilterData = this.state.filterDataArchive.map((item) =>
      item.key === key ? { ...item, select: !item.select } : item
    );
  
    this.setState({ filterDataArchive: updatedFilterData });
    this.getDataListInitial(this.state.tabValues,this.state.selectedSort.value,updatedFilterData,this.state.findWorkSearch)

  };
  toggleSelect = (key:number) => {
    const updatedFilterData = this.state.filterDataActive.map((item) =>
      item.key === key ? { ...item, select: !item.select } : item
    );
  
    this.setState({ filterDataActive: updatedFilterData });
    this.getDataListInitial(this.state.tabValues,this.state.selectedSort.value,updatedFilterData,this.state.findWorkSearch)

  };

  navigateContractDetail = (ID: number) => {
    this.state.userType == "designer" ? this.handleNavigation("ActiveContractPage", ID)
      : this.handleNavigation("ContractDetails", ID)
  }

  navigateArchiveContract = (ID: number) => {
    this.state.userType == "designer" ? this.handleNavigation("ArchiveDesignerContractPage", ID)
      : this.handleNavigation("ArchiveClientContractPage", ID)
  }

  handleSearchKey=(searchKey:string)=>{
    if(searchKey){
      return `&search=${searchKey}`
    }
    return "";

  }
  handleFilter = (filter: { key: number; name: string; select: boolean; value: string }[]) => {
    const queryString = filter
      .filter((item) => item.select) 
      .map((item) => `filter[]=${item.value}`) 
      .join("&"); 
  
    const finalQueryString = queryString ? `&${queryString}` : "";
  return finalQueryString
  };
  
  handleSort=(sortValue:string)=>{
    if(sortValue!=="recently_listed"){
      return `&sort=${sortValue}`
    }
    return "";
  }
  handleEndPoint=(tabValues:number,sortValue:string,filter:{key:number;name:string;select:boolean;value:string}[],searchKey:string,page:number)=>{
    if(tabValues==0){
  if(this.state.userType=="designer"){
    return `bx_block_cfdesignersidecontractmanagement/designers_contracts/designer_activate_contracts_list?page=${page+1}&per_page=8${this.handleSort(sortValue)}${this.handleSearchKey(searchKey)}${this.handleFilter(filter)}`
  }
  else{
    return `/bx_block_dashboard/contracts/client_view_contracts?page=${page+1}&per_page=8${this.handleSearchKey(searchKey)}${this.handleSort(sortValue)}${this.handleFilter(filter)}`
  }
}
else{
  if(this.state.userType=="designer"){
    return `bx_block_cfdesignersidecontractmanagement/designers_contracts/designer_archive_contracts_list?page=${page+1}&per_page=8${this.handleSearchKey(searchKey)}${this.handleSort(sortValue)}${this.handleFilter(filter)}`
  }
  else{
    return `/bx_block_cfdesignersidecontractmanagement/client_contracts/archive_contract_listing?page=${page+1}&per_page=8${this.handleSearchKey(searchKey)}${this.handleSort(sortValue)}${this.handleFilter(filter)}`
  }
}
  }

  getTokenData = async () => {
    const token = await getToken();
     let userInfo= await getStorageData("userInfo")
     let UserType=JSON.parse(userInfo)
    this.setState({ token: token,userType:UserType?.data?.attributes?.role_name})
};
getDataListInitial = async (tabValues:number,sortValue:string,filter:{key:number;name:string;select:boolean;value:string}[],searchKey:string) => {
  this.setState({loading:true});
  const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
  };
  const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  if(tabValues==0){

    this.offereContractActiveApiIdInitial = getValidationsMsg.messageId
  }
  else{
    this.offereContractArchiveApiIdInitial= getValidationsMsg.messageId
  }
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.handleEndPoint(tabValues,sortValue,filter,searchKey,0))
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}

  getDataList = async (tabValues:number,sortValue:string,filter:{key:number;name:string;select:boolean;value:string}[],searchKey:string,page:number) => {
  this.setState({loading:true});
    
    const headers = {
        "Content-Type": "application/json",
        token: this.state.token,
    };
    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    if(tabValues==0){

      this.offereContractActiveApiId = getValidationsMsg.messageId
    }
    else{
      this.offereContractArchiveApiId= getValidationsMsg.messageId
    }
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.handleEndPoint(tabValues,sortValue,filter,searchKey,page))
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
  // Customizable Area End
}