import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

interface Faqs {
  id: string;
  type: "faq";
  attributes: {
    question: string;
    answer: string;
    created_at: string;
    updated_at: string;
  };
}

const pathMap :Record<string, string>= {
  TermsConditions: "terms-and-condition",
  CodeOfConduct: "code-of-conduct",
  PrivacyPolicy: "privacy-policy",
  WhyUs: "why-us",
  AboutUs: "about-us",
  Faqs: "faqs"
};

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageData: {
    id: number;
    page_name: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
  }
  isFaq:boolean
  faqData:Faqs[]
  expanded: string | false;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPagesApiCallId :string = ""
  getFaqPageApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageData:{
        id: 0,
        page_name: "",
        title: "",
        description: "",
        created_at: "",
        updated_at: ""
      },
      isFaq:false,
      faqData:[],
      expanded: false as string | false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let pathName = new URL(document.URL).pathname.split('/')[1];
    pathName = pathMap[pathName]  || pathName;

if (pathName === "faqs") {
    this.handleGetFaqs();
    this.setState({ isFaq: true });
} else {
    this.handleGetPages(pathName);
}
  }
  handleGetPages= async (pathName:string)=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pageAPiEndPoint+pathName
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getApiMethod
    );
    this.getPagesApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleGetFaqs= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/faqs?page=1&per_page=100"
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getApiMethod
    );
    this.getFaqPageApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getPagesApiCallId) {
      if (!responseJson.error) {
        this.setState({
          pageData:responseJson.data
        })
      }
    }
    if (apiRequestCallId === this.getFaqPageApiCallId) {
      if (!responseJson.error) {
        this.setState({
          faqData:responseJson.data
        })
      }
    }
    // Customizable Area End
  }
}
