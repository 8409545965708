import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


interface IUpcomingSubmission {
  upcoming_submission: {
    data: {
      id: string;
      type: string;
      attributes: {
        contract_id: number;
        contract_name: string;
        milestone_name: string | null;
        milestone_due_date: string | null;
        client_name?: string;
        client_location?: string;
        client_profile_photo?: string;
        client_profile_background_color?: string | null;
        activate_milestone_name?: string
        activate_milestone_amount?: number
        active_submission_milestone_name?:string
        designer_name?: string
        designer_location?: string
        designer_profile_photo?: string | null
        designer_profile_background_color?: string | null
      };
    }[];
  };
  ongoing_contract_count: number;
};

interface IGraphData {
  graph_data: {
    January: number;
    February: number;
    March: number;
    April: number;
    May: number;
    June: number;
    July: number;
    August: number;
    September: number;
    October: number;
    November: number;
    December: number;
  };
  ongoing_contract_count: number;
  completed_contract_count: number;
}

interface IUserWorkedWith {
  data: {
    id: string;
    type: string;
    attributes: {
      profile_image: string;
      full_name: string;
      city: string;
      contracts: string[];
      earnings: number;
      background_color?:string
      user_role?: string
      user_id?:number
    };
  }[];
}

interface IReview {
  id: string;
  type: string;
  attributes: {
    id: number;
    review: string | null;
    review_by: string;
    account_id: number;
    user_designation? : {
      id: number,
      name: string,
      created_at : string,
      updated_at : string
    }
  };
}

interface IReviewsData {
  rating: number;
  reviews_count: number;
  reviews: {
    data: IReview[];
  };
}

interface Skill {
  id: number;
  name: string;
}

interface CategoryDetails {
  category_name: string;
  category_id: number;
}

interface CollectionDetails {
  collection_name: string;
  collection_id: number;
}

interface ProjectAttributes {
  project_name: string;
  start_date: string | null;
  end_date: string | null;
  url: string | null;
  description: string;
  category_id: number;
  skills: Skill[];
  tags: string[];
  category_details: CategoryDetails;
  collection_details: CollectionDetails;
  username: string;
  account_id: number;
  profile_photo: string;
  background_color: string | null;
  cover_image_url: string;
  project_images: string[];
  likes: number;
  views: number;
  saved_count: number;
  collection_name: string;
  is_liked: boolean | null;
  is_saved: boolean | null;
}

interface Project {
  id: string;
  type: string;
  attributes: ProjectAttributes;
}

interface IMeta {
  message: string;
}

interface IProjectData {
  data: Project[];
  meta: IMeta | undefined;
}

interface Collection {
  id: number;
  name: string;
  account_id: number;
  created_at: string;
  updated_at: string;
  project_count: number;
  likes_count: number;
  views_count: number;
  save_count: number;
}

interface ICollectionData {
  data: Collection[];
  message: string;
}
interface ContractSkillAttributes {
  skill_name: string;
  contract_count: number;
  work_opportunities_count: number;
  calculate_percentag: number;
}

interface ContractSkill {
  id: null | number; 
  type: string;
  attributes: ContractSkillAttributes;
}

interface IContractSkillData {
  data: ContractSkill[];
  meta: {
    message: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentNav:string;
  contractHighLightToggle:string;
  txtInputValue: string;
  upcomingSubmission: IUpcomingSubmission;
  contractAnalytics: IGraphData;
  workWith: IUserWorkedWith;
  rettingAndReview: IReviewsData;
  topRatedProject:IProjectData;
  topRatedCollection:ICollectionData
  topContractSkill:IContractSkillData
  contractHighlight:any
  userRole:string
  ratingPage:number
  showDetails:boolean
  token:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  upcomingSubmissionCallId :string = ""
  contractsAnalyticsCallId :string = ""
  rettingAndReviewCallId :string = ""
  workWithCallId :string = ""
  contractHighlightCallId :string = ""
  topRatedProjectCallId :string = ""
  topRatedCollectionCallId :string = ""
  topContractSkillsCallId :string = ""
  baseUrlString:string = configJSON.baseURL.baseURL;
  baseColor=configJSON.colors
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token :"",
      userRole:'string',
      currentNav:'Contracts',
      txtInputValue: '',
      contractHighLightToggle:'Contracts',
      upcomingSubmission:{
        upcoming_submission: {
          data: []
        },
        ongoing_contract_count: 0
      },
      contractAnalytics:{
        graph_data: {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0
        },
        ongoing_contract_count: 0,
        completed_contract_count: 0
      },
      workWith:{
        data: []
      },
      rettingAndReview:{
        rating: 0,
        reviews_count: 0,
        reviews: {
          data: []
        }
      },
      topRatedProject:{
        data: [],
        meta: undefined
      },
      topRatedCollection:{
        data: [],
        message: ""
      },
      topContractSkill:{
        data: [],
        meta: {
          message: ""
        }
      },
      contractHighlight:"",
      ratingPage:2,
      showDetails:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId1) {
      case this.upcomingSubmissionCallId:
        this.handleUpcomingSubmissionApiCall(responseJson1);
        break;
      case this.contractsAnalyticsCallId:
        this.handleContractsAnalyticsApiCall(responseJson1);
        break;
      case this.rettingAndReviewCallId:
        this.handleRettingAndReviewApiCall(responseJson1);
        break;
      case this.workWithCallId:
        this.handleWorkWithApiCall(responseJson1);
        break;
      case this.contractHighlightCallId:
        this.handleContractHighlightApiCall(responseJson1);
        break;
      case this.topRatedProjectCallId:
        this.handleTopRatedProjectApiCall(responseJson1);
        break;
      case this.topRatedCollectionCallId:
        this.handleTopRatedCollectionApiCall(responseJson1);
        break;
      case this.topContractSkillsCallId:
        this.handleTopContractSkillsApiCall(responseJson1);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  async componentDidMount(){
    const userInfo = await getStorageData("userInfo",true)
    const token = userInfo.meta.token
    this.setState({userRole:userInfo.data.attributes.role_name,token:token})
    if(userInfo.data.attributes.role_name === 'designer'){
      this.handleTopRatedProject(token)
      this.handleTopRatedCollection(token)
      this.handleTopContractSkills(token)
    }
    this.handleUpcomingSubmission(token,userInfo.data.attributes.role_name === 'designer')
    this.handleContractsAnalytics(token)
    this.handleRattingAndReviews(token)
    this.handleWorkWith(token,userInfo.data.attributes.role_name === 'designer')
    this.handleContractHighlight(token)
  }
  goBack = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfile");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }
  
  handleChangeCurrentNavData = () => {
    const { currentNav } = this.state
    if (currentNav === 'Earnings') {
      this.setState({ currentNav: "Contracts" })
    } else {
      this.setState({ currentNav: "Earnings" })
    }
  }
  handleContractHighLight = () => {
    const { contractHighLightToggle } = this.state
    if (contractHighLightToggle === 'Earnings') {
      this.setState({ contractHighLightToggle: "Contracts" })
    } else {
      this.setState({ contractHighLightToggle: "Earnings" })
    }
  }
  handleTopContractSkillsApiCall =  (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        topContractSkill:responseJson1
      })
    }
  }
  handleTopRatedCollectionApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        topRatedCollection:responseJson1
      })
    }
  }
  handleTopRatedProjectApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        topRatedProject:responseJson1
      })
    }
  }
  handleContractHighlightApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        contractHighlight:responseJson1
      })
    }
  }
  handleWorkWithApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        workWith:responseJson1
      })
    }
  }
  handleRettingAndReviewApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        rettingAndReview:responseJson1
      })
    }
  }
  handleContractsAnalyticsApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        contractAnalytics:responseJson1
      })
    }
  }
  handleUpcomingSubmissionApiCall = (responseJson1:any)=>{
    if (!responseJson1.error) {
      this.setState({
        upcomingSubmission:responseJson1
      })
    }
  }
  handleUpcomingSubmission= (token:string,role:boolean)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      role ? configJSON.designerUpcomingSubmissionsDetailsApiCall : configJSON.clientMileStoneApiCall
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.upcomingSubmissionCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleContractsAnalytics= (token:string)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contractsAnalyticsApiCall+`2025`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.contractsAnalyticsCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleRattingAndReviews= (token:string)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rattingAndReviewApiCall +`?page=1&per_page=${this.state.ratingPage}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.rettingAndReviewCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleWorkWith= (token:string,role:boolean)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      role ? configJSON.workWithApiCall : configJSON.designersWorkWithApiCall
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.workWithCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleContractHighlight= (token:string)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contractHighlightApiCall+`longest_duration`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.contractHighlightCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleTopRatedProject= (token:string)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topRatedProjectApiCall
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.topRatedProjectCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleTopRatedCollection= (token:string)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topRatedCollectionApiCall
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.topRatedCollectionCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleTopContractSkills= (token:string)=> {
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topContractSkillsApiCall
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.topContractSkillsCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  formatContractsList(contracts:string[]) {
    if (!Array.isArray(contracts) || contracts.length === 0) return ""
    const displayedContracts = contracts.slice(0, 3).join(", ");
    const remainingCount = contracts.length - 2;
    return remainingCount > 0 ? `${displayedContracts}, +${remainingCount}` : displayedContracts;
  }
calculateCompletedPercentage = () => {
  const { contractAnalytics } = this.state;
  const totalContracts =
    contractAnalytics.ongoing_contract_count +
    contractAnalytics.completed_contract_count;

  return totalContracts > 0
    ? (contractAnalytics.completed_contract_count / totalContracts) * 100
    : 0;
};
redirectTo = (url: string) => {
  const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}
getMilestoneAmount(activate_milestone_amount: number | string | undefined): number {
  return typeof activate_milestone_amount === 'number' 
      ? activate_milestone_amount 
      : 0;
}
handleUpComingMileStoneNavigation = (url:string)=>{
  this.props.navigation.navigate(this.state.userRole === 'designer' ? "ActiveContractPage":"ContractDetails",{id:url})
}
  handleLoadMoreRatings = async () => {
    const tokens = await getStorageData("userInfo",true);
    const token = tokens.meta.token
    this.setState({ ratingPage: this.state.ratingPage + 2 })
    this.handleRattingAndReviews(token)
  }
  HandleNavigation = (comment: any, userId: any) => {
    let path = userId == comment.userId ? "UserProfile" : "PublicUserProfile"
    let localdata = {
      account_id: comment.userId,
      user_role: comment.user_role,
      collection_name: comment.collection_name ?? "",
      collection_id: comment.collection_id ?? 0
    }
    if (path == "PublicUserProfile") {
      setStorageData("PublicId", JSON.stringify(localdata));
    }

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  navigateTo = (to: string, id: string) => {
    this.props.navigation.navigate(to, { id });
  }
  closeModal = () => {
    this.setState({
      showDetails: false
    })
  }
  handleProjectDetail = () => {
    this.setState({
      showDetails: true
    })
  }
  // Customizable Area End
}
