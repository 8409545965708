// Customizable Area Start
import React from "react";

import {
  Box,
  styled,
  Modal,
  Button,
  Typography
} from "@material-ui/core";
import ToastMSG from '../../../../components/src/ToastMSG.web';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WorkexpCard from '../../../../components/src/WorkexpCard.web'
const baseURL = require("../../../../framework/src/config.js");
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import ProfessionalExperience, {
  Props
} from "./ProfessionalExpController";
import WorkExperienceForm from "./WorkExperienceForm.web";
import DKexperienceCard from "../../../../components/src/DKexperienceCard";

export default class ProfessionalExp extends ProfessionalExperience {
   
  MainWrapper = styled(Box)(({ theme }) => ({
    '& .cursor-pointer' : {
      cursor: 'pointer',
    },
    
'& .MyboardbackBtn' :{
marginRight:"20px",

},
'& .WorkDKExp-active':{
  width:'50%',
  textAlign:"center",
  alignContent:"center",
  backgroundColor:"#171717",
  borderRadius:"50px",
  fontWeight:700,
  fontSize:"20px",
  cursor: "pointer",
  border: '2px solid transparent',
    background: 'linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)',
    backgroundClip: 'border-box' 
    
},
'& .workExp-professional' :{
  
  textAlign:"center",
  alignContent:"center",
},
'& .workExp-Contents' :{
  backgroundColor: '#171717',
  padding: '16px',
  borderRadius: '50px',
},
'& .WorkExp-first' :{
  background:"black",
  width:"70%",
  height:"auto",
  padding:'10px',
  display:'flex',
  borderRadius:"50px",
  marginTop:"30px",
  marginBottom:"15px"
},
'& .inactiveExpBoard' :{
  width:"50%",
  fontWeight:400,
  fontSize:'20px',
  cursor:'pointer',
  color:'#8C8C8C',
  alignContent:'center',

},
'& .BackBtnMy' :{
cursor: 'pointer',
height:"25px"
},

'& .shareicon' : {
  width:"25px",
  height:"22px",
  marginLeft:"10px",
  cursor: "pointer",
},
'& .MoreVectorIcon' :{
  textAlign: 'center',
},
'& .MoreVectorIcons svg' :{
  width:"50px",
  height:"50px",
  background:"#171717",
  borderRadius:"50px"
},
'& .no_projects_container' :{
  textAlign: 'center',
  width:"100%"},

'& .inactiveBoard' :{
  width:"50%",
  fontWeight:400,
  fontSize:'20px',
  cursor:'pointer',
  color:'#8C8C8C',
  alignContent:'center',
},

'& .Inspirationlist':{
  display: 'flex',
},
'& .Multiplemain' :{
  display: 'flex',
  justifyContent:'flex-start',
  width:'100%'

},
'& .FollowingBoardHeader':{
  display:'flex',
  marginTop:"2%",
  marginBottom:"2%",
},
'& .BackIcon' :{
  width:"4%",
  paddingTop:"15px",
},
'& .NoBoardBox':{
  display:'flex',
  flexDirection:'column',
  gap:'20px',
  alignItems:'center',
  marginTop:'10%'
},

'& .NoBoardHeader' :{
  fontSize:'20px',
  fontWeight:600,
  color:'#FFF2E3',
  textAlign:'center',
  fontFamily:"Inter,sans-serif",

},
'& .NoBoardContent':{
fontSize:'16px',
fontWeight:400,
color:'#FFF2E3',
fontFamily:"Inter,sans-serif",
textAlign:'center'
},
'& .FindworkBtn':{
  fontFamily:'Inter,sans-serif',
  fontSize:'20px',
  fontWeight:600,
  borderBottom:'2px solid'
},
'& .brandingContents' :{
  width:"60%"
},

'& .MoreVectorIcons' :{
  textAlign: 'center',
  marginTop:"70px"
},
'& .NoProjectsfnd':{
  textAlign: 'center',
  width:"100%",
  marginTop:"30px"
},
'& .AddWorkExp' :{
  padding:"10px 10px",
  fontFamily:"Inter,sans-serif",
  fontWeight:600,
  fontSize:"16px",
  cursor:'pointer',
  borderRadius:"7px"
}


  })
)
  constructor(props: Props) {
    super(props);
  }

  deleteModalPopup =()=>{
    return (
      <Modal
              open={this.state.deletemodal}
              data-testId="DeleteModal"
              onClose={this.handleCloseDeleteModal}
              aria-describedby="modal to delete profile"
              aria-labelledby="delete-project-modal"
            >
              <Box sx={webStyle.paperProfilesgroup}>
                <Box
                  style={{
                    margin: "0 auto",
                    padding: "32px",
                    textAlign: "center",
                  }}
                >
                  <Box justifyContent="center" display="flex"  alignItems="center">
                    <Typography
                      style={webStyle.modalHeaders}
                      data-testId="DeleteModal"
                    >

                      Delete Confirmation
                    </Typography>
                    
                  </Box>
                  <Box>
                  <Typography
                      style={webStyle.modalHeaders2}
                      data-testId="DeleteModal"
                    >

                      Are you sure you want to delete this work experience?
                    </Typography>
                  </Box>
                 
                  <Box style={webStyle.btnsGroups}>
                    
                    <Button
                      style={{ ...webStyle.DeleteBtns, textTransform: "none" }}
                      
                      onClick={()=>this.deleteprofessionalexp()}
                      data-testId="DeleteCollecion"
                    >
                      Delete
                    </Button>
                    <Button
                      style={{textTransform: "none", ...webStyle.cancelsBtns }}
                      data-testId="cancelDelete"
                      onClick={this.handleCloseDeleteModal}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
    )
   }
   handleAddForm = () =>{
    return(
        this.state.Addform &&(
          <WorkExperienceForm id={this.state.selectedId ? this.state.selectedId.toString() : ""} navigation={undefined} EditProfile={this.state.selectedId ? true : false} handleCloseEditProfile={this.closeFormModal} />
        ))
      }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <this.MainWrapper >
        <ToastMSG open={this.state.ToastStatusdelet} data-testId="tostmessage" close={() => this.handleToastClose()} message={this.state.ToastMessage} action={this.state.ToastActiondelete} />
     

            <Box>
            <Box className="WorkExp-first">
                 <div onClick={()=>this.handleWorkactiveBoard('DK Contracts')} data-testId="activeDkContract" className={this.state.workActiveBord == 'DK Contracts' ? 'WorkDKExp-active':"inactiveExpBoard"}>
                    <div data-testId="activeMyboardchange"  className={this.state.workActiveBord=="DK Contracts"?"workExp-Contents":"workExp-professional"}>

                    DK Contracts
                    </div>
                    
                    </div>
                    <div data-testId="activeProfessional" onClick={()=>this.handleWorkactiveBoard('Professional Experience')} className={this.state.workActiveBord=="Professional Experience"?"WorkDKExp-active":'inactiveExpBoard'}>

                    <div className={this.state.workActiveBord=="Professional Experience"?"workExp-Contents":"workExp-professional"}> Professional Experience</div>
                    </div>
                </Box>

            {this.state.workActiveBord =='DK Contracts'?(
              <>
              {this.state.DkContracts.length > 0 ? this.state.DkContracts.map((data) => <DKexperienceCard data={data}/>)
            :(
              <Box className="NoBoardBox">
                <Typography  className="NoBoardHeader" >Build Your Work Portfolio</Typography>
                <Typography className="NoBoardContent" >Empty Work Experience! Find work now. Showcase your contracts by adding <br />
                them to your work experience.</Typography>
                {
                  this.props.EditProfile && (

                    <Typography data-testId='NavigateFindWork' onClick={()=>this.handlenavigatetoFindwork()} className="active-typography FindworkBtn">Find Work</Typography>
                  )
                }            
              </Box>
            )}
              </>)
            :(
              <>
            {this.handleAddForm()}
            <Box>
              
            <Box style={{marginTop:'3%'}}>
              {(this.props.EditProfile && this.state.professionalExplist.length > 0) && (
                  <button onClick={()=>this.openformmodal()} data-testId='AddWorkExpBtn' className="AddWorkExp">
                  Add Work Experience
                </button>
              )}
              
            </Box>
            <Box style={{marginTop:'5%'}}>

            {this.state.professionalExplist.length > 0 ?
            this.state.professionalExplist.map((data:any,index:number)=>{
              return(

                <WorkexpCard token={this.state.token} data-test-id="work-exp-test-id" hadledeltemodal={this.HandledeleteModal} handleEditModel={this.handleEditModel} data={data} isPublic={this.state.isPublic} navigation={this.props.navigation}/>
              )
            }) :(
              <>
              <Box className="NoBoardBox">
                <Typography  className="NoBoardHeader" >Enhance Your Profile: Add Experience</Typography>
                <Typography className="NoBoardContent" >Empty Work Experience! Add now. Elevate your profile by showcasing your <br />
                professional journey and valuable work experiences.</Typography>
                {
                  this.props.EditProfile && (

                    <Typography onClick={()=>this.openformmodal()} data-testId='AddWorkExpBtn' className="active-typography FindworkBtn">Add Work Experience</Typography>
                  )
                }  

              </Box>
              </>
            )
            }

            </Box>
           
          </Box>
              </>
            ) }
            </Box>


        {this.deleteModalPopup()}
          
        </this.MainWrapper>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  NoworkExp :{
    textAlign:'center',
    fontFamily:'Inter,sans-serif'
  } as React.CSSProperties,
  
  paperProfilesgroup:{
    width:488,
    backgroundColor: '#222222',
    boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
    position: 'absolute',
    borderRadius: '7px',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
   
  },
  modalHeaders: {
    fontSize: "20px",
    fontFamily: "Inter, sans-serif",   
    color: "#FFF2E3",
    fontWeight: 600,
    lineHeight:"24.2px",
    width:"100%",     
  },
  modalHeaders2: {
    fontSize: "14px",
    color: "#FFF2E3",
    fontFamily: "Inter, sans-serif",   
    lineHeight:"24.2px",
    fontWeight: 600,
    marginTop:"10px" ,   
    width:"100%", 
  },
  cancelsBtns:{
    fontFamily: "Inter, sans-serif",      
    fontSize: "16px",
    linehHeigth: "24px",
    color: '#FFF2E3',
    fontWeight: 500,
    padding:"10px 30px 10px 30px",  
    backgroundColor:'#0D0D0D',       
    width:"max-content",     
    borderRadius:"7px",  
    },
    btnsGroups:{
      marginTop:"40px",
      gap:"24px" , 
      display:"flex",
      justifyContent:"center"
    },
    DeleteBtns:{
      fontSize: "16px",
      fontFamily: "Inter, sans-serif",      
      fontWeight: 500,
      linehHeigth: "24px",
      backgroundColor:'#FFF2E3',       
      padding:"10px 30px 10px 30px",      
      width:"max-content",       
      color: '#121212',
      borderRadius:"7px",  
         }
      

};

// Customizable Area End
