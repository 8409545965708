import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { backgroundImage, imgPasswordInVisible, imgPasswordVisible, userLogo } from "./assets";
import { getStorageData,removeStorageData,setStorageData } from "framework/src/Utilities";
import { createRef, RefObject } from "react";
interface Education {
  id: number;
  qualification: string;
  year_from: string;
  year_to: string;
  description: string;
}

export interface ProfileDetails {
  id: number;
  country: string;
  address: string;
  city: string;
  postal_code: string;
  account_id: number;
  photo: string | undefined;
  background_color: string;
  name: string;
  website_link: string;
  about: string;
  expertise_category:any | [];
  skills:any | [];
  languages:any | [];
  min_per_hour: number;
  max_per_hour: number;
  dribble: string;
  instagram: string;
  linkedin: string;
  member_since: string;
  followers_count: number;
  following_count: number;
  work_experience: {
    company_name: string;
    role:string;
    duration: string;
    total_years_worked:string ;
  }[];
  profile_cover_image: string | undefined;
  company_details:any;
  
}

interface ProfileData {
  profile_details: ProfileDetails;
  educations: Education[];
  profile_views: number;
  profile_likes: number;
  companyName:string,
  designation: string,
  industry: string,
  open_opportunity_count:any
  
}


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  shareProfile:boolean;
  openModal:boolean;
  openProfile:boolean;
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  showEditProfile:boolean;
  editProfileData:any;
  expertiseTempData:any[];
  skillsTempData:any[];
  profileImageData:any;
  selectedFile: File | null;
  combineImg:string | null | unknown;
  selectedProFile: File | null;
  updateProImage:string | null | unknown;
  saveImage:string | '' |unknown;
  saveProfileImage:string |'' |unknown
  UserInfoData:any;
  userName: string;
  userRole:string;
  followOpen:boolean;
  Following:any[];
  ActiveFollow:string;
  toastopen:boolean;
  action:string;
  message:string
  total_followersCount:number;
  Followers_page:number;
  moreBtnstatus:boolean;
  scrollActive: boolean
  userActivity: boolean
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userProfileImageGetApiCallId:string = "";
  deleteProfileImageGetApiCallId:string = "";
  updateImageAPICallId:string = "";
  userAttr: any;
  UserFollowersApiCallId:string="";
  UserFollowDeleteApiCallId:string="";
  UserFollowPostApiCallId:string='';
  UserFollowingApiCallId:string="";
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  updateSendbirdDetailsId: string = "";
  containerRef: RefObject<HTMLDivElement> = createRef();
  updateStateCallBack: null | Function = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [ 
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.openPictureUploadInSideMain = this.openPictureUploadInSideMain.bind(this);
    this.openProfileUploadInSideMain = this.openProfileUploadInSideMain.bind(this);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      scrollActive: true,
      profileImageData:  {
            companyName:"",
            designation:"",
            industry:"",
            profile_details: {
              country: "",
              id: 0,
                average_rate:0,
                address: "",
                city: "",
                account_id: 0,
                postal_code: "",
                photo: "",
                background_color: "",
                name: "",
                website_link: "",
                expertise_category: [],
                about: "",
                skills: [],
                languages: [],
                min_per_hour: 0,
                max_per_hour: 0,
                instagram: "",
                dribble: "",
                linkedin: "",
                followers_count: 0,
                member_since: "",
                following_count: 0,
                work_experience: [{
                  company_name: '',
                role:'',
                duration: '',
                total_years_worked:'' 
                }],
                profile_cover_image: "",
                company_details:{}  ,
                
                  professional_experience: {
                    latest_record: [{
                      company_name: "",
                      starting_from_year: "",
                      position: "",
                      ending_in_year: "",
                      calculate_years: ""
                    }],
                    professional_experiences_count: 0
                  }
                
            },
            educations: [
                {
                    id: 0,
                    qualification: "",
                    year_from: "",
                    year_to: "",
                    description: ""
                }
            ],
            open_opportunity_count:0,
            profile_views: 0,
            profile_likes: 0 ,
                 
    },
    shareProfile:false,
    openModal:false,
    openProfile:false,
    followOpen:false,
    selectedFile:null,
    combineImg:null,
    updateProImage:'',
    selectedProFile:null,
    total_followersCount:0,
    saveImage: '',
    saveProfileImage:'',
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      UserInfoData:"",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      showEditProfile:false,
      expertiseTempData:["Graphic Design","UX Design","UI design","UI design","UI design","UI design","UI design"],
      skillsTempData:["Illustrator","Photoshop","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's"],
     
      editProfileData:{name:"",website:"",insta:"",linkdin:"",dribble:"",location:"",
        about:"",rateMin:"",rateMax:"",expertise:[],
        education:[{org:null,start:null,end:null,course:null}],language:[]},
        userName: "",
        userRole:'',
        Following:[],
        ActiveFollow:'',
        toastopen:false,
        action:'',
        message:'',
        Followers_page:1,
        moreBtnstatus:false,
        userActivity: false,
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    this.userProfile(message)
    this.deleteProfileImage(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.userUpdateImage(message,responseJson)
      
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleFollowApirequest(apiRequestCallId,responseJson)
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }
   

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  handleOpenModal = () => {
    this.setState({ openModal: true });
  };
   handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  handleOpenProfile = () => {
    this.setState({ openProfile: true });
  };
  CloseFollowModal =() =>{
    this.getUserProfileImage()
    this.setState({followOpen:false,total_followersCount:0,moreBtnstatus:false,Followers_page:1, scrollActive: true, Following: []})
  }
  handleFollowingCount =()=>{
    if (this.state.ActiveFollow === 'Following'){
      this.getFollowing()
    }else {
      this.getFollowers()
    }
  }
  handleFollowApirequest = (apiCallId:string,response:any) =>{
    if (apiCallId === this.UserFollowersApiCallId) {
      if(!response.errors) {
          this.setState(prevState => ({
            Following: [...prevState.Following, ...response?.data],
            total_followersCount:response.meta.follower_count
            ,followOpen:true,
            Followers_page: prevState.Followers_page + 1
        }))
        
          if(response?.data?.length <10 ){
            this.setState({
              scrollActive: false, Followers_page: 1
            })
          }
        
      }else {
        this.setState({scrollActive: false, Followers_page: 1})
      }
    }
    if (apiCallId === this.UserFollowingApiCallId) {
      console.log("handleFollowingCount", response)
      this.handleFollowingFun(response)
      
    }
    this.handleFollowdeleteandUpdate(apiCallId,response)
   
  }
  
  handleFollowingFun =(response:any)=>{
    if(!response.errors) {
        this.setState(prevState => ({
          Following: [...prevState.Following, ...response?.data],
          total_followersCount:response.meta.follower_count
          ,followOpen:true,
          Followers_page: prevState.Followers_page + 1
      }));
      if(response?.data?.length < 10){
        this.setState({scrollActive: false, Followers_page: 1 })
      }
    }else {
      this.setState({scrollActive: false, Followers_page: 1 })
    }
  }

  handleClose =()=>{
    this.setState({toastopen:false})
  }

  handleFollowdeleteandUpdate =(apiCallId:string,response:any) =>{
    if (apiCallId === this.UserFollowDeleteApiCallId) {
      if(!response.error) {
      this.setState({toastopen:true,action:'sucess',message:'Sucessfully Unfollowed'})
      }
    }
    if(apiCallId === this.UserFollowPostApiCallId) {
      if(!response.error) {
        this.setState({toastopen:true,action:'sucess',message:'Sucessfully followed'})
      }
    }
  }

  FollowStatus = (data:any, index:number) => {
    const updatedFollowing = [...this.state.Following];
    const currentStatus = updatedFollowing[index].attributes.follow_status;
    const updatedUser = {
      ...updatedFollowing[index],
      attributes: {
        ...updatedFollowing[index].attributes,
        follow_status: currentStatus === 'following' ? 'follow' : 'following'
      }
    };
    updatedFollowing[index] = updatedUser;
    
    this.setState({
      Following: updatedFollowing
    });
    
    if (currentStatus === 'following') {
      this.DeleteFollow(this.state.ActiveFollow === 'Following' ? data.account_id:data.current_user_id);
    } else {
      this.FollowingUser(this.state.ActiveFollow === 'Following' ? data.account_id:data.current_user_id);
    }
  };

  FollowingUser = async(Id:number) =>{
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token} } = newToken; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserFollowPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteFollowApiEndPoint
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      'token': token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "data": {
          "attributes": {
            "account_id": Id
          }
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  DeleteFollow = async(Id:number)=>{
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token} } = newToken; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserFollowDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteFollowApiEndPoint+`${Id}`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      'token': token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFollowers =async()=> {
    this.setState({ActiveFollow:'Followers'})
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token} } = newToken; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserFollowersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowersApiEndPoint+`page=${this.state.Followers_page}&per_page=10`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      'token': token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFollowing =async() =>{
    this.setState({ActiveFollow:'Following'})
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token} } = newToken; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserFollowingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowingApiEndPoint+`page=${this.state.Followers_page}&per_page=10`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      'token': token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };
  handleCloseFollowModal =()=>{
    this.setState({followOpen:false})
  }
  userProfile = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const profileImageJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.userProfileImageGetApiCallId) {
        this.setState({
          profileImageData: profileImageJson.profile_data,
        },()=>{ this.HandlelocalData(profileImageJson.profile_data)})
      }
    }
  }
HandlelocalData =async (response:any) =>{
  let data = JSON.parse(this.state.UserInfoData)
  data.data.attributes.profile_image = response.profile_details.photo
  await setStorageData('userInfo',JSON.stringify(data));

}
  userUpdateImage = async (message: Message, response:any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
           if (apiRequestCallId === this.updateImageAPICallId) {   
            this.updateSendbirdDetails();
            let existingData = await getStorageData('userInfo'); 
            const parsedData = existingData ? JSON.parse(existingData) : { type: '', attributes: {} }; 
            parsedData.data.attributes.first_name = response.data.attributes.name;
            parsedData.data.attributes.profile_image = response.data.attributes.photo;
            parsedData.data.attributes.country = response.data.attributes.country;
            parsedData.data.attributes.state = response.data.attributes.state;
            await setStorageData('userInfo', JSON.stringify(parsedData)); 
          this.getUserProfileImage()        
      }
    }
  }

  updateSendbirdDetails =  () => {
    const userParseData: {meta: {sendbird_user_id: string}} = JSON.parse(this.state.UserInfoData);
    const endpoint = `${configJSON.updateSendbirdDetailsEndpoint}?sendbird_user_id=${userParseData.meta.sendbird_user_id}`
    const updateSendbirdMessage =  this.createMessage(endpoint,configJSON.apiUpdateUserType);
    this.updateSendbirdDetailsId = updateSendbirdMessage.messageId;
    runEngine.sendMessage(updateSendbirdMessage.id,updateSendbirdMessage);
  }

  createMessage =  (endPoint: string, method: string) => {
    const userParseData: {meta: {sendbird_user_id: string,token: string}} = JSON.parse(this.state.UserInfoData);
   
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: userParseData.meta.token,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  getUserProfileImage = async () => {
    const tokens = await getStorageData("userInfo");
    this.setState({UserInfoData:tokens})
    let newToken = JSON.parse(tokens)
    const { meta: { token ,id} } = newToken;   
   const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileImageGetApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserImageProfile + `${id}`  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile 
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  async componentDidMount() {   
    this.getUserProfileImage();  
    this.getUserName();
    removeStorageData("PublicId")
    removeStorageData("isPublicProfile")
  }

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    console.log("dataBox", dataBox)
    if(this.state.scrollActive && dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight){
        this.handleFollowingCount();
    };
};
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
     onChangeText: (text:string) => 
     {
       if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
     
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
       }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType:"tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps  = {
    value: "",
    editable: true,
    onChangeText: (text:string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps  = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }
  handlejobs = () =>{
    const jobsp = this.state.profileImageData.opportunity_count ?this.state.profileImageData.opportunity_count: 0
    return jobsp
  }
  btnPasswordShowHideButtonProps = {
    onPress: () => { 
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ 
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry });
        this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
        this.imgNewPasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText:(text:string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({ 
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry });
        this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
        this.imgReTypePasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  handleOpenEditProfile=()=>{
    this.setState({showEditProfile:true})
  }

  activeOpportunity = () =>{
    const activeOpportunity = this.state.profileImageData.open_opportunity_count?this.state.profileImageData.open_opportunity_count: 0
    return activeOpportunity
  }
  handleCloseEditProfile=()=>{
    this.setState({showEditProfile:false}, () => {  

       this.getUserProfileImage()
    })   
  }
  openPictureUpload = () => {
    const inputAddPensionOne = document.createElement('input');
    inputAddPensionOne.type = 'file';
    inputAddPensionOne.accept = "image/png, image/jpeg";
    inputAddPensionOne.multiple = false
    inputAddPensionOne.click();
    inputAddPensionOne.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => { 
      this.openPictureUploadInSideMain(event);
    }
      
  }
  convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  openPictureUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    const base64 = await this.convertBase64(file);
    this.setState({
      selectedFile: file,
      combineImg: base64
    });
  }
  openProfileUpload = () => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = "image/png, image/jpeg";
    inputAddPensionTwo.multiple = false
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSideMain(event);
    }
  }

  openProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
  
    if (!files || files.length === 0) {
      return; // No files selected, handle this case as needed
    }
  
    const file = files[0];
    const base64 = await this.convertBase64(file);
  
    this.setState({
      selectedProFile: file,
      updateProImage: base64
    });
    
  } 
  updateImage = async () => {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token ,id} } = newToken;
    const formData = new FormData();
       this.state.selectedFile    &&formData.append('profile[photo]',this.state.selectedFile || '',`${this.state.selectedFile.name}`);
    this.state.selectedProFile&&formData.append('profile[profile_cover_image]', this.state.selectedProFile||'');  
    const headers = {
      token: token,     
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateImageAPICallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserImageProfile + `${id}`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  deleteProfileImage = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    if (apiRequestCallId === this.deleteProfileImageGetApiCallId) { 
      this.setState({
        saveProfileImage: '',
        combineImg: '',
        selectedProFile: null,
        selectedFile: null, 
      }, () => {
        this.handleCloseProfile();
        this.getUserProfileImage();
      });
           
      }
    }
  }
 
  deleteUserProfileImage = async () => {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token ,profile_id} } = newToken;      
    const headers = {
      token: token,     
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProfileImageGetApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserImageProfile + `${profile_id}` + configJSON.endPointDeleteImageProfile)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteUserType
    );   
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }   
  handleSave = () => {   
   this.updateImage()
    this.setState({
      saveImage: this.state.updateProImage,
      openModal: false      
    })
  }
  handleSavePhoto =() => {
    this.updateImage()
    this.setState({
      saveProfileImage: this.state.combineImg,
    }, this.handleCloseProfile);    
  } 
  imageUrl = () => {
    const profileCoverImage = this.state.profileImageData?.profile_details?.profile_cover_image;
    const imageUrl = this.state.saveImage || (profileCoverImage ? `${this.baseUrlStirng}${profileCoverImage}` : backgroundImage);
    return imageUrl;
  }
  imageProfile =() =>{
    const profilePhoto = this.state.profileImageData?.profile_details?.photo;
    const profileImageUrl = this.state.saveProfileImage || (profilePhoto ? `${this.baseUrlStirng}${profilePhoto}` : userLogo);
    return profileImageUrl
  }
  imgbackUpdate = () => {
    const profileCoverImage2 = this.state.profileImageData?.profile_details?.profile_cover_image;
    const backgroundImageUrl = this.state.updateProImage || (profileCoverImage2 ? `${this.baseUrlStirng}${profileCoverImage2}` : backgroundImage);
    return backgroundImageUrl
  }
  imgproUpdate = () => {
    const profileCoverImage3 =  this.state.profileImageData?.profile_details?.photo;
    const profileupdateImageUrl =  this.state.combineImg ||  (profileCoverImage3 ? `${this.baseUrlStirng}${profileCoverImage3}` : userLogo) 
    return profileupdateImageUrl
  }

  getUserName = async () => {
    let userProfileName = await getStorageData("userInfo");

    userProfileName = JSON.parse(userProfileName);
    let Roles =userProfileName.data.attributes.role_name 
    userProfileName = userProfileName.data.attributes.first_name + this.handleNullvalue(userProfileName.data.attributes.last_name )
     
     if (userProfileName) {
       this.setState({ userName: userProfileName,userRole: Roles});
     };
   
  }
  handleNullvalue = (value: string | null)=>{
    if(value === null){
      return "";
    }else{
      return value;
    }
  };
  websideShow =() => {
    const websideName =this.state.profileImageData.profile_details?.website_link ? this.state.profileImageData.profile_details.website_link:"Add website" 
    return websideName
  }
  memberSince = () => {
   const menberName = this.state.profileImageData.profile_details.member_since ? this.state.profileImageData.profile_details.member_since:"DK Member Since 2019" 
    return menberName
  }
  showLoaction = () => {
    const { city, country } = this.state.profileImageData.profile_details || {};
    if (!city || !country) {
      return "Add Location";
    }
    const location = city === country ? city : `${city}, ${country}`;
    return location;
  }
  
  showAboutInfo  = () => {
   const showAboutInfo = this.state.profileImageData?.profile_details.about != "" ? this.state.profileImageData.profile_details.about:"Add Info" 
   
   
   return showAboutInfo
  }
  aboutRoleShow = () =>{
    const showRole = this.state.userRole === 'designer' ? "About Designer" : "About Client";
    return showRole;
  }
  profileView = () => {
   const  showProfile = this.state.profileImageData.profile_views?this.state.profileImageData.profile_views: '-'
    return  showProfile
  }
  CompanyName = () =>{
    let compananyName = this.state.profileImageData.profile_details.company_details?.data?.attributes.company_name
    
    const showCompanyName = compananyName? compananyName: 'CompanyName'
    return showCompanyName
  } 
  showIndustry = () =>{
    let Industry  = this.state.profileImageData.profile_details.company_details?.data?.attributes.industry.name
    const shouldShowIndustry = Industry? Industry:"SelectIndustry"
    return shouldShowIndustry
  }
  showDesignation = () =>{ 
    let designationName = this.state.profileImageData.profile_details.company_details?.data?.attributes.designation.name
    const shouldShowDesignation = designationName? designationName:"SelectDesignation"
    return shouldShowDesignation
  } 
  showLike = () => {
    const likefollow = this.state.profileImageData.profile_likes?this.state.profileImageData.profile_likes: '-'
    return likefollow
  }
  avgRate = () => {
   const showAvfRate = this.state.profileImageData.profile_details.min_per_hour + this.state.profileImageData.profile_details.max_per_hour / 2 
   const AvgRate =  showAvfRate ? `₹${showAvfRate}` :'-'
   return this.state.profileImageData.profile_details.average_rate
  }
  showFollowing =  () => {
    const following =this.state.profileImageData.profile_details?.following_count?this.state.profileImageData.profile_details?.following_count: 0
    return following
  }
  followers = () => {
  const showFollower =this.state.profileImageData.profile_details?.followers_count?this.state.profileImageData.profile_details?.followers_count: 0
  return showFollower
}
totalWorkExp = () => {
  const workExp  =this.state.profileImageData.profile_details.work_experience
    ? this.state.profileImageData.profile_details.work_experience.length
    : 0
    return workExp
}
deleteProfile = () => {
  this.deleteUserProfileImage()
}
formatYears = (yearFrom:string|number, yearTo:string|number) => {
  return yearFrom && yearTo ? `${yearFrom} - ${yearTo}` : '';
}

  handleNavigationToHighlights = async () => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), `CarouselDisplay`);
    this.send(navigateMessage);
  }

  handleManageViewActivityClose = () => {
    this.setState({
      userActivity: false
    })
  }

  handleManageViewActivityOpen = () => {
    this.setState({
      userActivity: true
    })
  }

  handleWorkExperience = () => {
    setStorageData("subTab", "Professional Experience")
    if(this.updateStateCallBack) this.updateStateCallBack(3,"Work Experience");
    if(this.containerRef.current) 
      this.containerRef.current.scrollIntoView({behavior: "smooth" , block: "start"})
    this.setState({
      userActivity: false
    })
  }

  receiveChildFunction = (updateState: Function) => {
    this.updateStateCallBack = updateState;
  }


  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }

  handleShareDialog = () =>{
    this.setState({
        shareProfile:!this.state.shareProfile
    })
}

  // Customizable Area End

}
