import React, { Component, createRef, RefObject } from "react";
import {
    Box, 
    Typography,
    IconButton,
} from "@material-ui/core";

import GetAppIcon from '@material-ui/icons/GetApp';
import { UserMessage, FileMessage,  MessageType } from "@sendbird/chat/message";
import { withStyles } from "@material-ui/core/styles";
const userProfile = require("../../../assets/user-profile.png");
const baseUrl =  require("../../../../framework/src/config.js").baseURL;

interface Props {
    updateLastMessageRef?: (element: HTMLDivElement) => void;
    updateChatHistoryConRef?: (element: HTMLDivElement) => void;
    messages?: (UserMessage | FileMessage)[];
    userId: string;
    classes: Record<string, string>;
    previewMessage: FileMessage | null;
    handlePreviewVideo: (file:FileMessage) => void;
    handlePreviewVideoClose: () => void;
    handleFilesMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
    fileUploadAnchorEl: null | HTMLElement;
    handleVideoImagesUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChatInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    sendMessageToChannel: () => void;
    input: string;
    handleChatInputKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

interface S {

}

class ChatRoomComponent extends Component<Props, S> {
    constructor(props: Props) {
        super(props)
    }

    lastMessageRef: RefObject<HTMLDivElement> = createRef();
    chatHistoryConRef: RefObject<HTMLDivElement> = createRef();
    async componentDidMount() {
        this.handleRef();
    }

    handleRef = () => {
        const { updateChatHistoryConRef, updateLastMessageRef } = this.props;
        if (this.chatHistoryConRef.current) {
            if (updateChatHistoryConRef) updateChatHistoryConRef(this.chatHistoryConRef.current);
        }

        if (this.lastMessageRef.current) {
            if (updateLastMessageRef) updateLastMessageRef(this.lastMessageRef.current);
        }
    }

    getISTTime = (timeStamp: number) => {
        const dateObj = new Date(timeStamp);
        const currentOffset = dateObj.getTimezoneOffset();
        const ISTOffset = 330;
        const ISTTime = new Date(dateObj.getTime() + (ISTOffset + currentOffset) * 60000);
        return ISTTime;
    }

    getDateString = (timeStamp: number) => {
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const ISTTime = this.getISTTime(timeStamp)
        return `${ISTTime.getDate()}/${ISTTime.getMonth()+1}/${ISTTime.getFullYear()} ${weekdays[ISTTime.getDay()]}`
      }
    
    handleErrorImage = (event: React.SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = userProfile;
    }

    isDocumentType = (type: string) => {
        const documentMimeTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'
        ];
        return documentMimeTypes.includes(type)
    }

    handleVideoDownload = (downloadLink: string) => {
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.href = downloadLink;
        anchor.download = downloadLink;
        anchor.click();
        document.body.removeChild(anchor)
    }

    getTime = (timeStamp: number) => {
        const dateObject = this.getISTTime(timeStamp);
        const hoursValue = dateObject.getHours() % 12;
        const hours = (hoursValue === 0 ? 12 : hoursValue).toString().padStart(2, "0");
        const minutes = (dateObject.getMinutes()).toString().padStart(2, "0");
        const isAm = dateObject.getHours() >= 12 ? 'PM' : 'AM';
        return (`${hours}:${minutes} ${isAm}`);
    }


    renderRightChat = (message: UserMessage | FileMessage) => {
        const { classes } = this.props;

        if ((message as UserMessage).messageType === "file") {
            const newMessage = message as FileMessage
            if (this.isDocumentType(newMessage.type)) {
                return (<Box
                    key={message.createdAt}
                    className={`${classes.chatDataCon} ${classes.chatRight}`}
                    data-testid="chatCon"
                >
                    <Box className="textCon">
                        <Box className="flexCon">
                            <IconButton data-testid="fileDownload" onClick={() => this.handleVideoDownload(newMessage.url)}>
                                <GetAppIcon className="icon" />
                            </IconButton>
                            <Typography className="paragraph" >{newMessage.name}</Typography>
                        </Box>
                        <Typography className="timeStampText caption" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>
                    </Box>
                    <img onError={this.handleErrorImage} src={baseUrl + message.sender?.profileUrl} className="image" />
                </Box>)
            }
            return (
                <Box
                    key={message.createdAt}
                    data-testid="chatCon"
                    className={`${classes.chatDataCon} ${classes.chatRight}`}
                    onClick={() => { newMessage.type.startsWith("video/") ? this.handleVideoDownload(newMessage.url) : this.props.handlePreviewVideo(newMessage) }}
                >
                    <Box className="textCon videoImageCon">
                        {
                            newMessage.type.startsWith("video/") && <IconButton className="iconButton">
                                <GetAppIcon className="icon" />
                            </IconButton>
                        }
                        <img className="thumbnailImage" src={newMessage.type.startsWith("video/") ? (newMessage).thumbnails[0]?.url : newMessage.url} />
                        <Typography className="imageTimeStamp caption" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>
                    </Box>
                    <img onError={this.handleErrorImage} src={baseUrl + message.sender?.profileUrl} className="image" />
                </Box>
            )
        }

        if (message.messageType !== MessageType.USER) {
            return (<Box key={message.createdAt} data-testid="chatCon" className={`${classes.chatDataCon} ${classes.chatCenter}`}>
                <Box className="textCon middleTextCon">
                    <Typography className="paragraph"  data-testid="rightMessages">{(message as UserMessage).message}</Typography>
                    <Typography className="timeStampText caption" variant="caption">{this.getTime(message.createdAt)}</Typography>
                </Box>
            </Box>)
        }

        return (
            <Box key={message.createdAt} data-testid="chatCon" className={`${classes.chatDataCon} ${classes.chatRight}`}>
                <Box className="textCon rightTextCon">
                    <Typography className="paragraph"  data-testid="rightMessages">{(message as UserMessage).message}</Typography>
                    <Typography className="timeStampText caption" variant="caption">{this.getTime(message.createdAt)}</Typography>
                </Box>
                <img onError={this.handleErrorImage} src={baseUrl + message.sender?.profileUrl} className="image" />
            </Box>
        )
    }

    renderLeftChat = (message: UserMessage | FileMessage) => {
        const { classes, handlePreviewVideo } = this.props;

        if ((message as UserMessage).messageType === "file") {
            const newMessage = message as FileMessage
            if (this.isDocumentType(newMessage.type)) {
                return (<Box
                    key={message.createdAt}
                    data-testid="chatCon"
                    className={`${classes.chatDataCon} ${classes.chatLeft}`}
                    onClick={() => this.handleVideoDownload(newMessage.url)}
                >
                    <img onError={this.handleErrorImage} src={baseUrl + message.sender?.profileUrl} className="image" />
                    <Box className="textCon">
                        <Box className="flexCon">
                            <IconButton>
                                <GetAppIcon className="icon" />
                            </IconButton>
                            <Typography className="paragraph" >{newMessage.name}</Typography>
                        </Box>
                        <Typography className="timeStampText caption" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>
                    </Box>
                </Box>)
            }
            return (
                <Box
                    data-testid="chatCon"
                    key={message.createdAt}
                    className={`${classes.chatDataCon} ${classes.chatLeft}`}
                    onClick={() => { newMessage.type.startsWith("video/") ? this.handleVideoDownload(newMessage.url) : handlePreviewVideo(newMessage) }}
                >
                    <img onError={this.handleErrorImage} src={baseUrl + message.sender?.profileUrl} className="image" />
                    <Box className="textCon videoImageCon">
                        {
                            newMessage.type.startsWith("video/") && <IconButton className="iconButton">
                                <GetAppIcon className="icon" />
                            </IconButton>
                        }
                        <img className="thumbnailImage" src={newMessage.type.startsWith("video/") ? (newMessage).thumbnails[0]?.url : newMessage.url} />
                        <Typography className="imageTimeStamp caption" variant="caption">{this.getTime(newMessage.createdAt)}</Typography>
                    </Box>
                </Box>
            )
        }

        if (message.messageType !== MessageType.USER) {
            return (<Box data-testid="chatCon" key={message.createdAt} className={`${classes.chatDataCon} ${classes.chatCenter}`}>
                <Box className="textCon middleTextCon">
                    <Typography className="paragraph" >{(message as UserMessage).message}</Typography>
                    <Typography className="timeStampText caption" variant="caption">{this.getTime(message.createdAt)}</Typography>
                </Box>
            </Box>)
        }

        return (
            <Box data-testid="chatCon" key={message.createdAt} className={`${classes.chatDataCon} ${classes.chatLeft}`}>
                <img onError={this.handleErrorImage} src={baseUrl + message.sender?.profileUrl} className="image" />
                <Box className="textCon">
                    <Typography className="paragraph" >{(message as UserMessage).message}</Typography>
                    <Typography className="timeStampText caption" variant="caption">{this.getTime(message.createdAt)}</Typography>
                </Box>
            </Box>
        )
    }

    renderChattigInputCon = () => {
        const { classes, handleChatInputChange, sendMessageToChannel, input, handleChatInputKeyDown } = this.props;
        return (
          <Box className={classes.chattingCon}>
            <Box className="chatInputCon">
              <textarea
                data-testid="messageInput"
                className="chatInput"
                placeholder={"Type message"}
                onChange={handleChatInputChange}
                onKeyDown={handleChatInputKeyDown}
                value={input}
              />
            </Box>
                <IconButton onClick={sendMessageToChannel} data-testid="sendButton">
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.00999999 18L21 9L0.00999999 0L0 7L15 9L0 11L0.00999999 18Z" fill="url(#paint0_linear_22517_29072)" />
                        <defs>
                            <linearGradient id="paint0_linear_22517_29072" x1="21" y1="1.06695e-06" x2="3.27628" y2="20.7927" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FFC29F" />
                                <stop offset="0.161458" stop-color="#FFB9C7" />
                                <stop offset="0.338542" stop-color="#CF8CCC" />
                                <stop offset="0.505208" stop-color="#AB9EF5" />
                                <stop offset="0.677083" stop-color="#6C98EE" />
                                <stop offset="0.838542" stop-color="#1CBAE0" />
                                <stop offset="1" stop-color="#A8E5EC" />
                            </linearGradient>
                        </defs>
                    </svg>
                </IconButton>
          </Box>
        )
      }


    renderMessages = () => {
        let lastMessage: string | null = null;
        const renderData = (this.props.messages && this.props.messages?.length > 0) ? this.props?.messages?.map((message) => {
            const messageDate = this.getDateString(message.createdAt);
            const showDate = lastMessage !== messageDate;
            lastMessage = messageDate;
            return (
                <Box className="chatWithDateCon">
                    {showDate && <Box className="dateCon">
                        <Box className="horizontalLine" />
                        <Typography className="paragraph" >{this.getDateString(message.createdAt)}</Typography>
                        <Box className="horizontalLine" />
                    </Box>}
                    {
                        (this.props.userId === message.sender?.userId ?
                            this.renderRightChat(message) :
                            this.renderLeftChat(message))
                    }
                </Box>
            )
        }) : <Box />
        return renderData;
    }


    render() {
        return (
            <div style={{ borderBottom: "1px solid rgba(73, 73, 73, 1)" }}>
                <div data-testid="chatHistoryConRef"
                    ref={this.chatHistoryConRef}
                    className={this.props.classes.chatHistoryCon}
                    style={{ marginBottom: "0px", paddingBottom: "0px", minHeight: "236px", overflow: "scroll" }}
                >
                    {this.renderMessages()}
                    <div ref={this.lastMessageRef} key="new-ref" />
                </div>
                {this.renderChattigInputCon()}
            </div>
        )
    }
}

const typoWhite = "#FFF2E3";

const styles = {
    chatDataCon: {
        display: "flex",
        gap: ".5rem",
        width: "60%",
        "& .icon": {
          color: typoWhite,
        },
        "& .image": {
          alignSelf: "flex-end",
          height: "34px",
          width: "34px",
          objectFit: "cover",
          borderRadius: "50%"
        },
        "& .thumbnailImage": {
          width: "200px",
          height: "300px",
          objectFit: "cover",
          cursor: "pointer",
          borderRadius: "inherit"
        },
        "& .textCon": {
          position: "relative" as "relative",
          backgroundColor: "#323232",
          padding: "1rem",
          borderRadius: "1rem 1rem 1rem 0"
        },
        "& .videoImageCon": {
          padding: ".2rem .2rem 2rem .2rem",
          borderRadius: ".2rem .2rem .2rem 0"
        },
        "& .flexCon": {
          display: "flex",
          alignItems: "center",
        },
        "& .iconButton": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          border: `2px solid ${typoWhite}`
        },
        "& .middleTextCon": {
          borderRadius: "1rem",
          backgroundColor: "#171717",
        },
        "& .timeStampText": {
          float: "right",
          right: ".5rem",
          bottom: ".5rem",
          color: "#8C8C8C",
          backgroundColor: "inherit",
          marginTop: ".2rem",
        },
        "& .imageTimeStamp": {
          position: "absolute",
          right: ".2rem",
          bottom: ".5rem",
          color: "#8C8C8C",
          zIndex: 2,
          "&::after": {
            content: '"',
            clear: "both",
            marginTop: ".25rem"
          }
        },
        "& .rightTextCon": {
          backgroundColor: "#222222",
          borderRadius: "1rem 1rem 0rem 1rem"
        }
      },
      chatRight: {
        marginLeft: "auto",
        justifyContent: "flex-end",
      },
      chatCenter: {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
      },
      modalCon: {
        display: "flex",
        justifyContent: "center",
        alingItems: "center",
      },
      modalTextCon: {
        width: "100%",
        height: "100%",
        padding: "2rem",
        position: "relative" as "relative",
        margin: "auto",
        borderRadius: ".5rem",
        display: "flex",
        "& .icon": {
          color: typoWhite,
        },
       
        "& .closeIcon": {
          position: "absolute",
          top: "1rem",
          right: "1rem",
          zIndex: 2,
        },
        "& .image": {
          width: "400px",
          height: "80%",
          objectFit: "cover",
          borderRadius: "inherit",
          margin: "auto",
        },
        "& .video-thumbnail": {
          position: "relative",
          "& img": {
            width: "100%",
            heght: "100%",
            objectFit: "cover",
            borderRadius: "inherit"
          },
          "& .downloadIcon": {
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"
          },
        }
      },
      chatLeft: {
        marginRight: "auto"
      },
      chatHistoryCon: {
        maxHeight: "65vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "1rem",
        margin: ".5rem 0",
        padding: "1rem",
        "&::-webkit-scrollbar": {
          width: "0px", 
          background: "transparent",
        },
        scrollbarWidth: "none" as "none",
        msOverflowStyle: "none" as "none",
        "& .chatWithDateCon": {
          width: "100%",
        },
        "& .dateCon": {
          display: "flex",
          gap: ".5rem",
          alignItems: "center",
          width: "calc(100% + 2rem)",
          marginLeft: "-1rem",
          marginBottom: "1rem",
        },
        "& .horizontalLine": {
          height: 0,
          borderBottom: `1px solid #292929`,
          flexGrow: 1,
        },
        "& .paragraph": {
            color: typoWhite,
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "19.36px",
            textAlign: "left",
            wordWrap: "break-word",
            overflowWrap: "break-word",
        },
        "& .caption": {
            color: typoWhite,
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "14.52px",
            textAlign: "left",
            wordWrap: "break-word",
            overflowWrap: "break-word",
        }
      },
      paperItemCon: {
        backgroundColor: "#111111",
        border: "1px solid #5F5F5F",
        borderRadius: "10px",
        padding: 0,
        marginTop: "-4.75rem",
        marginLeft: "3.25%"
      },
      menuMenuItemCon: {
        padding: 0,
      },
      chattingCon: {
        height: "89px",
        marginTop: "auto",
        backgroundColor: "#171717",
        borderBottomLeftRadius: "inherit",
        borderBottomRightRadius: "inherit",
        padding: "1rem",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        "& .menuBar": {
          marginTop: "5rem",
        },
        "& .insertLink": {
          color: "#888888",
          transform: "rotateZ(-45deg)",
          fontSize: "2rem",
        },
        "& .chatInputCon": {
          padding: "1rem",
          borderRadius: "1rem",
          backgroundColor: "#111111",
          minHeight: "49px",
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
        },
        "& .chatInput": {
          width: "100%",
          flexShrink: 0,
          outline: "none",
          border: "none",
          resize: "none",
          backgroundColor: "#111111",
          flexGrow: 1,
          paddingTop: ".5rem",
          color: typoWhite,
          fontFamily: "Inter, sans-serif",
          "&::placeholder": {
            color: "#7D7D7D",
            fontSize: ".875rem",
            fontFamily: "Inter, sans-serif",
          },
          "&::-webkit-scrollbar": {
          width: "0px", 
          background: "transparent",
        },
        scrollbarWidth: "none" as "none",
        msOverflowStyle: "none" as "none",
        }
      },
}
const StyledChatRoomComponent = withStyles(styles)(ChatRoomComponent);
export default StyledChatRoomComponent;