import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divider } from "@mui/material";
import RangeSelector from "../../../../packages/components/src/RangeSelector";
import { createTheme, styled, withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
import { rightIcon } from "./assets";
import CircularProgress from '@mui/material/CircularProgress';
import ToastMSG from '../../../components/src/ToastMSG.web';
import LogOutModal from "../../../components/src/DeleteProjectModel.web"
import { profileIcon } from "../../../../packages/blocks/landingpage/src/assets";
import UserImage from "../../../../packages/components/src/UserImage";
import RazorPay from "../../stripepayments/src/components/RazorPay.web";
import { formatWalletAmount } from "../../../../packages/components/src/Utils";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StyledTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "8px",
  borderBottom: "#535353 1px solid",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#FFF2E3",
  transition: "color 0.3s",
  "&.active": {
    background: "#111111",
  },
}));
export const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: "transparent",
      color: "#FFF2E3",
      border: "none",
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      textAlign: "left",
      borderBottom: "none",
      '&::placeholder': {
        color: "#676767 !important",
        opacity: 1,
      },
      '&:focus': {
        outline: "none", // Removes outline on focus
      },
    },
    '&::before': {
      borderBottom: "none",
    },
    '&::after': {
      borderBottom: "none",
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: "none !important",
    },
  },
})(Input);


// Customizable Area End

import Settings2Controller, {
  Props,
  WithdrawelData,
  configJSON,
} from "./Settings2Controller";
import Location from "./Location/Location.web";
import DeletePayMethod from "./components/DeletePayMethod";
import AddWithdrawelModal from "../../../../packages/components/src/AddWithdrawelModal";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderWalletTab = () => {
    if ((this.state.activeTab !== "Wallet") || this.state.showTransactions) return null;

    const buttonStyle = {
      background: "#171717",
      fontFamily: "Inter",
      fontSize: "16px",
      borderRadius: "7px",
      textTransform: "none",
      fontWeight: "500",
      padding: "10px 24px",
    };

    return (
      <Box style={webStyle.subContainer}>
        <Box style={webStyle.subTextContainer}>
          <Typography style={webStyle.headerText}>Wallet</Typography>
          <Typography style={webStyle.mainHeaderText}>Wallet balance</Typography>
        </Box>

        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px 60px" }}>
          <Typography style={webStyle.headerText}>₹{formatWalletAmount(this.state.walletAmount)}</Typography>
          <RazorPay navigation={this.props.navigation} id={""} amountStatus={this.handleUpdateAmount}/>
        </Box>

        <Box style={{ display: "flex", flexDirection: "column", margin: "10px 60px", paddingBottom: "50px"}}>
          {
            this.state.lastTransactionData && <Box style={{ border: "1.15px solid #535353", borderRadius: "16px", padding: "24px" }}>
              <Typography className="dummyClass" style={{ ...webStyle.mainHeaderText, margin: 0 }}>Last Transaction</Typography>
              <Typography style={{ fontFamily: "Inter", color: "#FFF2E3", fontSize: "16px", fontWeight: 400, marginTop: "1px" }}>
                {this.state.lastTransactionData.attributes.description}
              </Typography>
              <Typography style={{ fontFamily: "Inter", color: "#8C8C8C", fontSize: "14px", fontWeight: 600, marginTop: "1px" }}>
                {this.state.lastTransactionData.attributes.created_date}
              </Typography>
              <Divider style={{ width: "100%", background: "#535353", margin: "10px 0px 8px 0px" }} />
              <Box onClick={this.handleShowTransaction} data-test-id="open-transaction-history" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "68px", borderRadius: "16px", background: "#171717", padding: "0px 14px", marginTop: "30px", cursor: "pointer" }}>
                <Typography style={{ fontSize: "20px", fontFamily: "Inter", fontWeight: 500, color: "#FFF2E3" }}>
                  View transaction history
                </Typography>
                <svg height="36" width="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="36" height="36" rx="8" fill="#222222" />
                  <path d="M19.061 18.0007L14.1113 13.0509L15.5255 11.6367L21.8895 18.0007L15.5255 24.3646L14.1113 22.9504L19.061 18.0007Z" fill="#FFF2E3" />
                </svg>
              </Box>
            </Box>
          }
          {
            this.renderPaymentMethod(buttonStyle)
          }
        </Box>
      </Box>
    );
  };

  renderTransactionHistory = () => {
    if (((this.state.activeTab === "Wallet" || this.state.activeTab === "Get Paid") && this.state.showTransactions)) {
      return <Box style={{...webStyle.subContainer,marginBottom:"30px",height:"auto"}}>
        <Box style={webStyle.subTextContainer}>
          <span data-test-id="close-transection-modal" style={{ ...webStyle.headerText, cursor: 'pointer' }} onClick={this.handleNotShowTransaction}>
            <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ paddingRight: "10px" }}>
              <path d="M5.09101 11.4556L14 20.3647L11.4546 22.9102L-1.00139e-06 11.4556L11.4546 0.00127815L14 2.54669L5.09101 11.4556Z" fill="#FFF2E3" />
            </svg>
            Transaction History
          </span>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingBottom: "20px",
              paddingTop: "5px"
            }}
          >
            <RangeSelector
              handleDateChange={this.handleDateChange}
              dateRange={this.state.dateRange}
            />
            <Button
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "16px",
                textTransform: "none",
                color: "#121212",
                background: "#FFF2E3",
                borderRadius: "7px",
                padding: "10px 30px",
                marginLeft: "30px"
              }}
              disabled={this.state.transactionHistoryList.length <= 0}
            >
              Download
            </Button>
          </Box>
          {
            <Box style={{ border: "1.15px solid #535353", borderRadius: "16px", padding: "24px" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={webStyle.tableheader}>Date</TableCell>
                    <TableCell align="left" style={{ ...webStyle.tableheader, width: "192px" }}>Description</TableCell>
                    <TableCell align="left" style={webStyle.tableheader}>Type</TableCell>
                    <TableCell align="left" style={webStyle.tableheader}>Designer</TableCell>
                    <TableCell align="left" style={webStyle.tableheader}>Amount</TableCell>
                    <TableCell align="left" style={webStyle.tableheader}>Ref ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.transactionHistoryList.length > 0 ? (
                    this.state.transactionHistoryList.map((transaction) => (
                      <TableRow key={transaction.id}>
                        <TableCell align="left" style={webStyle.tableRowWhite}>{transaction.attributes.created_date}</TableCell>
                        <TableCell align="left" style={webStyle.tableRowGrey}>{transaction.attributes.description}</TableCell>
                        <TableCell align="left" style={{ ...webStyle.tableRowGrey, color: "#61CA78" }}>{transaction.attributes.payment_type}</TableCell>
                        <TableCell align="left" style={webStyle.tableRowGrey}>-</TableCell>
                        <TableCell align="left" style={webStyle.tableRowWhite}>{transaction.attributes.payment_type === "debit" ? "-": ""}Rs. {transaction.attributes.wallet_amount}</TableCell>
                        <TableCell align="left" style={webStyle.tableRowGrey}>{transaction.attributes.id}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                        {
                          this.state.fetchingTransactions ? <TableCell colSpan={6} align="center" style={{ border: "none" }}>
                            <CircularProgress style={{
                              color: "#FFF2E3",
                            }} /></TableCell> : <TableCell colSpan={6} align="center" style={{ border: "none" }}>
                            <Typography style={{ color: "#FFF2E3" }}>
                              No Transactions Found
                            </Typography>
                          </TableCell>
                        }
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          }
        </Box>
      </Box>
    }
  }

  renderGetPaidTab = () => {
    if ((this.state.activeTab !== "Get Paid") || this.state.showTransactions) return null;

    const buttonStyle = {
      background: "#171717",
      fontFamily: "Inter",
      fontSize: "16px",
      borderRadius: "7px",
      textTransform: "none",
      fontWeight: "500",
      padding: "10px 24px",
    };

    return (
      <Box style={webStyle.subContainer}>
        <Box style={webStyle.subTextContainer}>
          <Typography style={webStyle.headerText}>Get Paid</Typography>
          <Typography style={webStyle.mainHeaderText}>Available balance in wallet</Typography>
        </Box>

        <Box style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          margin: "15px 60px",
          backgroundImage: "linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}>
          <Typography style={webStyle.headerText}>Rs. {this.state.walletAmount}</Typography>
          <RazorPay navigation={this.props.navigation} id={""} amountStatus={this.handleUpdateAmount}/>
        </Box>

        <Box style={{ display: "flex", flexDirection: "column", margin: "10px 60px", paddingBottom: "50px" }}>
          <Box style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}>
            {
              this.state.withdrawelData && <><Box style={{ border: "1.15px solid #535353", borderRadius: "16px", padding: "24px", flex: 1 }}>
                <Typography style={{ ...webStyle.mainHeaderText, margin: 0 }}>Withdrawal schedule</Typography>
                <Typography style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 400, fontFamily: "Inter", marginTop: "1px" }}>
                  Monthly (2nd Monday of each month)
                </Typography>
                <Divider style={{ background: "#535353", width: "100%", margin: "10px 0px 8px 0px" }} />
                <Typography style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "16px", color: "#FFF2E3" }}>
                  {("bank_account" in this.state.withdrawelData) ? `Direct to ${this.state.withdrawelData.bank_account.bank_name} Bank (INR) - Account ending in` + this.state.withdrawelData.bank_account.account_number.slice(-4)
                    : `Direct to your Bank (INR) through ${(this.state.withdrawelData as WithdrawelData).vpa.address}`}
                </Typography>
                <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: 600, color: "#61CA78", marginTop: "6px" }}>Active</Typography>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "68px", background: "transparent", padding: "0px", marginTop: "30px", cursor: "pointer" }}>
                  <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: 500, color: "#FFF2E3" }}>
                    {this.state.withdrawelData.second_monday}
                  </Typography>
                </Box>
              </Box>
                <Box style={{ width: "15px" }}></Box>
              </>
            }
           {
            this.state.lastTransactionData && <>

            <Box style={{ border: "1.15px solid #535353", borderRadius: "16px", padding: "24px", flex: 1 }}>
              <Typography style={{ ...webStyle.mainHeaderText, margin: 0 }}>Last Transaction</Typography>
              <Typography style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 400, fontFamily: "Inter", marginTop: "1px" }}>
                {this.state.lastTransactionData.attributes.description}
              </Typography>
              <Typography style={{ color: "#8C8C8C", fontSize: "14px", fontWeight: 600, fontFamily: "Inter", marginTop: "1px" }}>
              {this.state.lastTransactionData.attributes.created_date}
              </Typography>
              <Divider style={{ background: "#535353", width: "100%", margin: "10px 0px 8px 0px" }} />
              <Box data-test-id="open-transaction-history" onClick={this.handleShowTransaction} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "68px", borderRadius: "16px", background: "#171717", padding: "0px 14px", marginTop: "30px", cursor: "pointer" }}>
                <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: 500, color: "#FFF2E3" }}>
                  View transaction history
                </Typography>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="36" height="36" rx="8" fill="#222222" />
                  <path d="M19.061 18.0007L14.1113 13.0509L15.5255 11.6367L21.8895 18.0007L15.5255 24.3646L14.1113 22.9504L19.061 18.0007Z" fill="#FFF2E3" />
                </svg>
              </Box>
            </Box>
            </>
            }
          </Box>
          {
            this.renderPaymentMethod(buttonStyle)
          }
        </Box>
      </Box>
    );
  }

  renderPaymentMethod = (buttonStyle: {}) => <Box style={{ border: "1.15px solid #535353", borderRadius: "16px", padding: "24px", marginTop: "16px" }}>
    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "32px" }}>
      <Typography style={{ ...webStyle.mainHeaderText, margin: 0 }}>Withdrawal methods</Typography>
      <Button data-test-id="open-add-bank-details" onClick={this.handleOpenWithdrawelModal} style={{ ...buttonStyle, color: "#FFFFFF" } as React.CSSProperties}>Add a method</Button>
    </Box>
    {
      this.state.isGettingMethods ? <Box style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>
        <CircularProgress style={{
          color: "#FFF2E3",
        }} />
      </Box> :
      this.state.withdrawelMethods.length > 0 ? this.state.withdrawelMethods.map((item) => <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "20px" }}>
        <Box style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
          <Box style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
            <Typography>{item?.bank_account ? `Direct to ${item.bank_account.bank_name} Bank (INR) - Account ending in` + item.bank_account.account_number.slice(-4)
             : `Direct to your Bank (INR) through ${item.vpa!.address}`}</Typography>
          </Box>
          {item.active && <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: 600, color: "#61CA78", marginTop: "6px" }}>Active</Typography>}
        </Box>
        <Box>
          <Button data-test-id="remove-wthl-method" onClick={() => this.handleOpenDeleteModal(item.id)} style={{ ...buttonStyle, color: "#FF7878", marginLeft: "20px", fontWeight: 600 } as React.CSSProperties}>Remove</Button>
        </Box>
      </Box>) : <Box style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>
        No method added
      </Box>
    }
  </Box>

  // Customizable Area End

  render() {
    const leftTabs = this.state.userDetail.data.attributes.role_name === "designer" ? this.tabs : this.clientTabs;
    return (
      // Customizable Area Start
      <MainWrapper>
        <Box
          style={webStyle.newResponseCon}
          data-testid="outside">
          <NavigationMenu {...this.props} activeTab="user" />
          <div style={webStyle.mainClientWrap as React.CSSProperties}>
        <Box style={{display:"flex"}}>
        <Box style={webStyle.leftContainer}>
          <Box style={webStyle.userDetail}>
            <Box style={webStyle.userStyle}>
                    <UserImage
                      userDetail={this.state.userDetail}
                      configBaseURL={configJSON.baseURL.baseURL}
                      profileIcon={profileIcon}
                      webStyle={webStyle}
                    />
                <Box style={webStyle.userHeaderStyle}>
                  <Typography style={{...webStyle.userNameStyle,...webStyle.commonTextStyle}}>
                      {this.state.userDetail.data.attributes.first_name}
                  </Typography>
                  <Typography style={{...webStyle.userNameStyle,color:"#535353",fontSize:"16px"}}>
                      Online
                  </Typography>
                </Box>
            </Box>
              <Box style={{ marginTop: "26px" }}>
                <Typography style={{...webStyle.userNameStyle,...webStyle.commonTextStyle}}>
                  Settings
                </Typography>
                <Box style={webStyle.menuStyle}>
                  {leftTabs.map((tab: string, index: number) => (
                    <StyledTypography
                      data-test-id = "active-tab-id"
                      key={index}
                      className={this.state.activeTab === tab ? "active" : ""}
                      onClick={() => this.handleActiveTab(tab)}
                    >
                      {tab}
                    </StyledTypography>
                  ))}
                  <Typography data-test-id="log-out-id" style={webStyle.logOutStyle} onClick={this.handleLogOutPopup}>
                    Log Out
                  </Typography>
                </Box>
              </Box>
            </Box>
        </Box>
        {this.state.activeTab === "Password & Security" &&
          <Box style={webStyle.subContainer}>
            <Box style={webStyle.subTextContainer}>
              <Typography
                style={webStyle.headerText}>
                Password & Security
              </Typography>
              <Typography
                style={webStyle.mainHeaderText}>
                Login & Recovery
              </Typography>
              <Typography
                style={webStyle.subHeaderText}>
                Manage your passwords, login preferences and recovery methods
              </Typography>
              <Box style={webStyle.rightContainer}>
                <Box style={webStyle.rightBoxStyle}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Username:
                  </Typography>
                  <Typography
                    style={webStyle.rightTextStyle}>
                    {this.state.userDetail.data.attributes.user_name}
                  </Typography>
                </Box>
                <Box style={{ ...webStyle.rightBoxStyle,marginLeft: "110px" }}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Email id:
                  </Typography>
                  <Typography
                    style={webStyle.rightTextStyle}>
                    {this.state.userDetail.data.attributes.email}
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyle.rightContainer}>
                <Box style={webStyle.passwordContainer}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Change Password
                  </Typography>
                  <img data-test-id="openDialogId" style={webStyle.passwordImage} src={rightIcon} onClick={this.handleDialog}/>
                </Box>
              </Box>
            </Box>
          </Box>
        }
        {
          this.state.activeTab === "Location & Contact Info" && 
          <Box style={webStyle.subContainer}>
            <Location navigation={this.props.navigation} id={this.props.id} isLocation={true} updateLocation={this.setUpdatedStorageValue}/>
          </Box>
        }
        {
          this.state.activeTab === "About Company" && 
          <Box style={webStyle.subContainer}>
            <Location navigation={this.props.navigation} data-test-id="location" id={this.props.id} isLocation={false} updateLocation={this.setUpdatedStorageValue}/>
          </Box>
        }
          {
            this.state.activeTab === "Help & Support" &&
            <Box style={webStyle.subContainer}>
              <Box style={webStyle.subTextContainer}>
                <Typography
                  style={webStyle.headerText}>
                  Help & Support
                </Typography>
                <Typography
                  style={webStyle.mainHeaderText}>
                  Need Help? We're Here to Assist!
                </Typography>
                <div style={webStyle.emailGray} dangerouslySetInnerHTML={{ __html: this.state.helpAndSupport.data.description }} />
                <Typography
                  style={webStyle.emailGray}>
                  Email Contact :
                  <Typography style={webStyle.emailText} data-test-id="maillink" onClick={() => this.handleLinkClick(`mailto:${this.state.helpAndSupport.data.email}`,true)}>
                    {this.state.helpAndSupport.data.email}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          }
              {
                this.renderWalletTab()
              }
              {
                this.renderTransactionHistory()
              }
              {
                this.renderGetPaidTab()
              }
        </Box>
        <Dialog open={this.state.passwordPopUp} onClose={this.handlePasswordPopUp}>
          <Box style={webStyle.dialogContainer}>
            <Box style={{ width: "425px" }}>
              <Typography style={webStyle.dialogText}>Change Password</Typography>
              <Box style={webStyle.dialogMainContainer}>
                <Box style={webStyle.dialogHeaderContainer}>
                  <Typography style={webStyle.dialogText}>Current Password*</Typography>
                  {this.state.apiError && <Typography style={{ backgroundColor: "#363636", borderRadius: "24px", padding: "2px 15px", ...webStyle.dialogError }}>incorrect password</Typography>}
                </Box>
                <CustomTextField
                  type="password"
                  placeholder="Enter current password"
                  data-test-id="input-data-currentPassword"
                  value={this.state.currentPassword}
                  style={webStyle.dialogTextBox}
                  onChange={(event:any) => { this.handlePasswordChange("currentPassword", event.target.value); }} />
                {this.state.apiError && <Typography data-test-id="forgot-password-id" onClick={this.handleForgotPassword} style={webStyle.forgotPasswordStyle}>Forgot password ?</Typography>}
              </Box>
              <Box style={webStyle.dialogMainContainer}>
                <Typography style={webStyle.dialogText}>New Password*</Typography>
                <CustomTextField
                  type="password"
                  placeholder="Enter new password"
                  data-test-id="input-data-newPassword"
                  value={this.state.newPassword}
                  style={webStyle.dialogTextBox}
                  onChange={(event) => { this.handlePasswordChange("newPassword", event.target.value); }} />
                <Typography style={webStyle.dialogError}>{this.state.newPasswordError}</Typography>
              </Box>
              <Box style={webStyle.dialogMainContainer}>
                <Typography style={webStyle.dialogText}>Re-write New Password*</Typography>
                <CustomTextField
                  type="password"
                  placeholder="Re-enter new password"
                  data-test-id="input-data-confirmPassword"
                  value={this.state.confirmPassword}
                  style={webStyle.dialogTextBox}
                  onChange={(event) => { this.handleConfirmPassword(event.target.value) }} />
                <Typography style={webStyle.dialogError}>{this.state.confirmPasswordError}</Typography>
              </Box>
              <Box style={webStyle.dialogButtonContainer}>
                <Button 
                data-test-id='cancel-btn-id'
                style={{ ...webStyle.dialogButton, ...webStyle.dialogCancelBtn }} onClick={this.handlePasswordPopUp}>
                  Cancel
                </Button>
                <Button 
                disabled={this.checkIfDisabled()}
                data-test-id='save-btn-id'
                style={{ ...webStyle.dialogButton, ...webStyle.dialogSaveBtn }} onClick={this.handleChangePasswordCall}>
                  {this.state.postOpportunityLoader
                && <CircularProgress color='inherit' size={25} />}Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
        <LogOutModal
          open={this.state.logOutPopup}
          onClose={this.handleLogOutPopup}
          onDelete={this.handleLogOut}
          title="Are you Sure?"
          content="Please confirm if you want to log out."
          button1Text="Cancel"
          button2Text="Log Out"
          data-test-id="log-out-model" />
            <DeletePayMethod
              open={this.state.OpenDeleteModal}
              handleClose={this.handleCloseDeleteModal}
              handleDelete={this.removeWithdrawMethod}
            />
            <AddWithdrawelModal
              open={this.state.openAddWitdMOdal}
              handleClose={this.handleCloseWithdrawelModal}
              handleAddWithDrawmethod={this.handleAddWithDrawmethod}
            />
        <ToastMSG open={this.state.open} close={this.handleClose} message={this.state.message} action={this.state.action} data-test-id="tost-msg-dialog"/>
          </div>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .find_work_side_bar_container_menu": {
    backgroundColor: "#171717",
    width: "100%",
    margin: 0,
  },
  "& .find_work_main_container": {
    display: "flex",
    backgroundColor: "#111111",
    width: '100%',
  },
  "& .find_work_sidebar_accordian": {
    flexDirection: 'column',
    display: "flex",
    width: "100%"
  },
  "& .edit_profile_disco_bar": {
    marginLeft: '-17.5rem',
    marginBottom: 20,
    width: '100%',
  },
  "& .find_work_side_bar_container": {
    border: 1,
    width: "25%",
    backgroundColor: "#111111",
    padding: 20,
  },
  "& .find_work_right_main_container": {
    border: 1,
    width: "75%",
    padding: 20,
    marginTop: '162px'
  },
  "& .find_work_grid_section": {
    width: "98%",
  },
  "& .find_work_search_field": {
    borderRadius: 20, outline: "none", marginBottom: 10, width: "95%", marginTop: 45, backgroundColor: '#171717', "::placeholder": { color: '#66625C' }, color: '#66625C'
  },
  '&:focus, &:hover': {
    outline: 'none',
  },
  "& .gray_color": {
    fontFamily: "Inter , sans-serif",
    color: '#FFF2E3',
  },
  "& .right_side_grid_box": {
    padding: 2,
    width: "95%",
    marginTop: '45px'
  }, "& .main_container_search_button": { color: "#FFF2E3" },
  "& .tabs_css": {
    fontWeight: 700,
    color: "#FFF2E3",
    fontFamily: "Inter,sans-serif",
    textTransform: 'none',
    fontSize: 16
  },
  "& .apply_button": {
    backgroundColor: "#FFF2E3",
    fontFamily: "Inter , sans-serif",
  },
  '& .MuiTabs-indicator': {
    height: '3px',
    backgroundColor: "#FFF2E3",
    widht: "40% !important",
    marginLleft: '10%'
  },
  '& .pagination .MuiPaginationItem-root': {
    color: '#8C8C8C',
  },
  '& .pagination .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#F5F5DC',
    borderRadius: '50%',
    color: '#121212',
  },
  '& .find_work_search_field::placeholder': {
    color: '#66625C',
  },
  '& .find_work_search_field:focus': {
    outline: 'none',
  },
  "& .find_work_and_praposals_button": { display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: 6, backgroundColor: '#0D0D0D', borderRadius: 50, marginBottom: 20 }
  , "& .praposal_text": { color: '#8C8C8C', fontFamily: "Inter,sans-serif", fontSize: '20px', padding: '5% 10%', cursor: 'pointer' },
  "& .sidebar_menu_box": { backgroundColor: "#111111", padding: 2, marginTop: '60px' }, "& .sidebar_menu_list": { padding: 0 }, "& .sidebar_menu_item": { display: "flex", flexDirection: 'column', padding: 0 }, "& .accordian_details": { display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '12px' }, "& .divider": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 }, "& .divider_for_tabs": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 },
  '& .MuiSlider-track': {
    height: 8,
    border: 'none',
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
  },
  "& .MuiSlider-root": {
    width: "97%",
  },
  '& .MuiSlider-thumb': {
    height: 20,
    backgroundColor: '#fff',
    width: 20,
    border: '2px solid #171717',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-rail': {
    height: 8,
    backgroundColor: '#bfbfbf',
    opacity: 1,
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
  },
  '& .budget_min_max_box': {
    backgroundColor: '#222222', padding: '10 15', borderRadius: '5%'
  },
  '& .budget_count_box': {
    display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '35px'
  }, "& .budget_min_max_color": {
    color: '#797979', fontFamily: "Inter , sans-serif",
  }, '& .budget_count_color': {
    color: '#FFF2E3', fontFamily: "Inter , sans-serif",
  }, '& .to_padding': {
    padding: 10
  }, "& .MuiTab-wrapper": {
    display: 'flex',
    justifyContent: "start",
  }, "& ..MuiTab-root": {
    minWidth: 0,
  }, "& .font_weight_600": {
    fontWeight: 600
  }, "& .disabled-btn": {
    backgroundColor: 'rgb(157, 155, 153)',
    color: "black"
  }, "& .slider_container": {
    display: 'flex', flexDirection: 'column', flexGrow: 1, width: "100%", marginTop: -41
  },
  "& .opportunity_card_main_container": {
    padding: "22px 20px 22px 20px",
    borderRadius: 13,
    gap: 24,
    border: "1px solid #3E3E3E",
    cursor: "pointer",
  },
  "& .scroll::-webkit-scrollbar": {
    display: "none",
  },
  "& .opportunity_card_main_containerChangeColor": {
    cursor: "pointer",
    background: '#313131',
    position: 'relative',
    borderRadius: '13px',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontFamily: "Inter,sans-serif",
    padding: '22px 20px 22px 20px',
    fontSize: '20px',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '13px',
      padding: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMaskComposite: 'xor',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      maskComposite: 'exclude',
    }

  },
  "& .opportunity_card_person_city": {
    fontSize: "14px",
    color: "#FFF2E3",
    fontFamily: "Inter,sans-serif",
    fontWeight: 400,
  },
  "& .opportunity_card_price_duration": {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    backgroundColor: "#1D1D1D",
    borderRadius: "24px",
    fontFamily: "Inter,sans-serif",
  },
  "& .opportunity_card_price_color": {
    color: "#1CBAE0"
  }, "& .opportunity_card_duration_color": {
    marginLeft: 15,
    color: "#FFB9C7",
  },
  "& .opportunity_card__description": {
    fontSize: "12px",
    fontFamily: "Inter,sans-serif",
    height: 50,
    fontWeight: '400',
    color: "#FFF2E3",
    flexGrow: 1,
  },
  "& .opportunity_card_price_duration_box": {
    display: 'flex'
  }, "& .only_2_line": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1.5,
    maxHeight: "3em",
  },
  "& .opportunity_card_title_box": {
    borderColor: '#3E3E3E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "stretch",
    fontFamily: "Inter , sans-serif",
    gap: 14,
    justifyContent: "space-between",
  },
  "& .opportunity_card_divider_color": {
    borderColor: '#3E3E3E'
  },
  "& .opportunity_card_hour": {
    color: "#66625C",
    fontWeight: 400,
    fontSize: "11px",
    fontFamily: "Inter,sans-serif",
  },
  '& .MoreVectorIcon': {
    paddingBottom: '20px',
    textAlign: 'center',
  },
  '& .MoreVectorIcon svg': {
    height: "50px",
    width: "50px",
    background: "#171717",
    borderRadius: "50px",
    color: '#fff'
  },
  "& .opportunity_card_title": {
    fontSize: "20px",
    fontFamily: "Inter,sans-serif",
    fontWeight: 600,
    color: "#FFF2E3",
    height: 40
  },
  "& .opportunity_card_person_name": {
    fontSize: "14px",
    fontFamily: "Inter,sans-serif",
    fontWeight: 600,
    color: "#FFF2E3",
  }, "& .opportunity_card_person_detain_box": {
    display: "flex",
    alignItems: "baseline",
    justifyContent: 'space-between',
    marginTop: 10,
  },
}));
const webStyle = {
  tableheader : {
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 600,
    color: "#FFF2E3",
    backgroundColor: "transparent",
    padding: "10px 0px 30px 0px",
    verticalAlign: "top",
    borderBottom: "1px solid #2B2B2B"
  },
  tableRowWhite: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 600,
    color: "#FFF2E3",
    backgroundColor: "transparent",
    padding: "10px",
    verticalAlign: "top",
    borderBottom: "1px solid #2B2B2B"
  },
  tableRowGrey: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    color: "#FFF2E3",
    backgroundColor: "transparent",
    padding: "10px",
    verticalAlign: "top",
    borderBottom: "1px solid #2B2B2B"
  },
  dialogTextBox:{
    marginTop:"16px",
    width: "100%",backgroundColor:"#171717",borderRadius:"9px",border: 'none',
    paddingLeft: '15px',
    paddingRight: '27px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    height: '56px',
    outline: 'none',
    lineHeight: '18.15px',
  },
  forgotPasswordStyle:{
    display: "flex", justifyContent: "flex-end", width: "100%", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 300, fontSize: "14px", textDecoration: "underline", cursor: "pointer" 
  },
  dialogError:{
    color:"#FF7878",fontFamily:"Inter",fontWeight:400,fontSize:"14px"
  },
  dialogButtonContainer:{
    display:"flex",justifyContent:"center",gap:"24px",marginTop:"40px"
  },
  dialogText :{
    fontFamily:"Inter",fontWeight:600,fontSize:"20px",color:"#FFF2E3",display:"flex",justifyContent:"center" as "center"
  },
  dialogButton:{
    textTransform:"none" as "none",width:"115px",height:"44px",fontWeight:500,fontSize:"16px",letterSpacing:"0.38px",borderRadius:"7px"
  },
  dialogCancelBtn:{
    backgroundColor: "#0D0D0D", color: "#FFF2E3"
  },
  dialogSaveBtn:{
    backgroundColor: "#FFF2E3", color: "#121212"
  },
  dialogMainContainer:{
    marginTop: "32px", display: "flex", flexDirection: "column" as "column", alignItems: "flex-start"
  },
  dialogHeaderContainer:{
     display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" 
  },
  dialogContainer: {
    width: "572px", height: "maxContent",
    padding: "30px 0px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#222222",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    zIndex: 1000,
    position: "fixed" as "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  parentContainer:{ backgroundColor: "#111111" },
  leftContainer:{
    backgroundColor:"#171717",width:"500px",display:"flex",justifyContent:"center",height:"100vh"
  },
  userDetail:{
    width:"413px"
  },
  userStyle:{
    padding:"16px 0px",display:"flex",alignItems:"center",marginTop:"100px",width:"100%",height:"80px",backgroundColor:"#111111",borderRadius:"24px"
  },
  userHeaderStyle:{
    marginLeft:"16px",display:"flex",alignItems:"flex-start",flexDirection:"column" as "column"
  },
  commonTextStyle:{
    color:"#FFF2E3",fontSize:"20px"
  },
  menuStyle:{
    display:"flex",flexDirection:"column" as "column",gap:"16px",padding:"16px 0px"
  },
  logOutStyle:{
    width:"80px",cursor:"pointer",padding:"10px",fontFamily: "Inter", fontSize: "20px", fontWeight: 400, lineHeight: "24px", color: "#FF7878"
  },
  userNameStyle:{
    fontFamily:"Inter",fontWeight:600,lineHeight:"24px"
  },
  profileStyle:{
    marginLeft: "16px", height: "70", width: "70", borderRadius: "50%"
  },
  subContainer:{
    height: "100vh", width: "calc(100% - 500px)"
  },
  subTextContainer:{
    marginLeft: "60px", marginTop: "100px", marginRight: "60px"
  },
  headerText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "36px", letterSpacing: "0.38px"
  },
  subHeaderText:{
    color: "#8C8C8C", fontFamily: "Inter", fontWeight: 600, fontSize: "14px", letterSpacing: "0.38px"
  },
  emailGray:{
    color: "#8C8C8C", fontFamily: "Inter", fontWeight: 600, fontSize: "16px", letterSpacing: "0.38px", display: 'flex', alignItems: 'center', gap: '7px'
  },
  mainHeaderText:{
    marginTop: "40px", marginBottom: "12px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "24px", letterSpacing: "0.38px"
  },
  emailText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "16px", letterSpacing: "0.38px", textDecoration: "none", cursor: 'pointer'
  },
  rightContainer:{
    marginTop: "35px", paddingLeft: "16px", borderRadius: "16px", backgroundColor: "#171717", width: "100%", height: "72px", display: "flex"
  },
  passwordContainer:{
    width:"100%",display: "flex", alignItems: "center",justifyContent:"space-between"
  },
  passwordImage:{
    marginRight:"16px",cursor:"pointer"
  },
  rightBoxStyle:{
    display: "flex", alignItems: "center"
  },
  rightMainText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 500, fontSize: "20px"
  },
  rightTextStyle:{
    marginLeft: "10px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "24px", letterSpacing: "0.38px"
  },
  mainContainer:{ 
    display: 'flex',
  flexDirection: 'column' as 'column',
},
  navigationStyle:{
    position: 'fixed' as 'fixed',
    width: '100%',
    zIndex: 999,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  mainClientWrap:{
    backgroundColor: '#111',
    fontFamily: 'Inter, sans-serif',
    color: '#fff2e3',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    width: 'min(100%, 1920px)',
    margin: 'auto',
  },
};
// Customizable Area End
