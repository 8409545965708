// Customizable Area Start
import React from "react";

import {
  Box,
  styled,
  Typography,
  Grid,
  Tab,
  Tabs
  
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DropDownData } from '../JobListingController';
import { Settings, Insights} from "../assets";
const baseURL = require("../../../../framework/src/config.js");
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import WorkexpCard from '../../../../components/src/WorkexpCard.web'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import OpportunityClientController, {
  DesignerContract,
  Props
} from "./OpportunityClientController.web";
import Inspirations from "../../../portfoliomanagement/src/Inspirations/Inspirations.web";
import DKexperienceCard from "../../../../../packages/components/src/DKexperienceCard";

export default class OpportunityClient extends OpportunityClientController {
   
  MainWrapper = styled(Box)(({ theme }) => ({
  '& .tabs_css' :{
    color: "#FFF2E3",
    fontWeight: 700,
    fontFamily: "Inter,sans-serif",
    textTransform: 'none',
    fontSize: 16,
    textAlign: 'start',
    paddingLeft:'0px',
    cursor: 'pointer',
    paddingRight:'0px'
  },
  '& .Bulletpoint' :{
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'rgba(89, 189, 211, 1)',
    marginRight:"5px"
  
  },
  '& .Selectbox':{
    color: 'rgb(255, 242, 227)',
    backgroundColor: 'rgb(17, 17, 17)',
    fontSize: '24px',
    fontWeight: '600',
    border: 'none',
    width:"90%",
    fontFamily:'Inter, sans-serif'
    
  } ,
  '& .custom-dropdown': {
    display: 'inline-block',
  marginTop: '15px',
  width: '300px',
  position: "relative"
},
'& .select-box' :{
  display:"flex",
  alignItems:'center'
} ,
'& .CardContainer':{
  border:'1px solid rgba(72, 72, 72, 1)',
  borderRadius: '8px',
  padding: '10px 15px',
  marginTop:"4%"
},
'& .GridContainer':{
  maxHeight:"200px"
},
'& .CardHeader':{
  color:'#FFF2E3',
  fontFamily: "Inter,sans-serif",
  fontSize: "16px",
  fontWeight: 700,
  margin:"10px auto"
}  ,
'& .CardFirstContent':{
  display:'flex',
  gap:"10px",
  margin:'10px auto'
},
'& .textOverFolw':{
  overflow:"hidden",
  textWrap:"nowrap",
  textOverflow:"ellipsis"
},
'& .NoBoardClientBox':{
  display:'flex',
  flexDirection:'column',
  gap:'20px',
  alignItems:'center',
  marginTop:'10%'
},

'& .NoBoardClientHeader' :{
  fontSize:'20px',
  fontWeight:600,
  color:'#FFF2E3',
  textAlign:'center',
  fontFamily:"Inter,sans-serif",

},
'& .NoBoardClientContent':{
fontSize:'16px',
fontWeight:400,
color:'#FFF2E3',
fontFamily:"Inter,sans-serif",
textAlign:'center'
},
'& .FindworkBtnClient':{
  fontFamily:'Inter,sans-serif',
  fontSize:'20px',
  fontWeight:600,
  borderBottom:'2px solid'
},
'& .CardDescription':{
  fontFamily: "Inter, sans-serif",
  fontSize: "12px",
  fontWeight: 400,
  color: "#FFF2E3",
  margin: '10px auto',
  height: '18%',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
},
'& .DescriptionContent':{
  fontFamily: "Inter, sans-serif",
  fontSize: "12px",
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2, 
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxHeight: '3em',
  lineHeight: '1.5em', 
  
},
'& .CardFooter':{
  fontFamily: "Inter,sans-serif",
  fontSize:"11px",
  color:'#66625C',
  fontWeight: 400,
  margin: '10px auto',
},
'& .NoWorkOpportunity':{
  marginTop:"5%",
  display:"flex",
  width:"100%",
  justifyContent:"center",
  flexDirection:'column',
  alignItems:'center',
  gap:'20px'

},
'& .NoWorkOpportunityHeading':{
  fontSize:'20px',
  fontWeight:600,
  fontFamily:'Inter,sans-serif',
  color:'#FFF2E3'
},
'& .NoWorkOpportunityContent' :{
  fontSize:'16px',
  fontWeight:400,
  fontFamily:'Inter,sans-serif',
  color:'#FFF2E3',
  textAlign:'center'
},
'& .RateperHour':{
  background: '#363636',
  padding: '1px 10px',
  borderRadius: '20px',
  color: '#1CBAE0',
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Inter,sans-serif'
},
'& .timemonths':{
color:'#FFB9C7',
background: '#363636',
  padding: '1px 10px',
  borderRadius: '20px',
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Inter,sans-serif',
},
'& .MoreVectorIcons' :{
  textAlign: 'center',
  marginTop:"70px"
},
'& .MoreVectorIcons svg' :{
  width:"50px",
  height:"50px",
  background:"#171717",
  borderRadius:"50px"
},
'& .SettingsBtn' :{
      background: 'rgba(23, 23, 23, 1)',
      color: 'rgba(255, 242, 227, 1)',
      borderRadius:"8px",
      borderWidth:'0px',
      display:'flex',
      alignItems: 'center',
      padding:"6px",
      cursor:"pointer"
    },
    '& .FirstContainer' :{
      width:"100%",
      display: 'flex',
      justifyContent:"space-between",
      alignItems: 'center',

    },

    '& .PostOppBtn' :{
      padding:"10px 20px",
      fontWeight: 500,
      fontFamily: 'Inter,sans-serif',
      fontSize:"16px",
      borderRadius:'8px',
      cursor:'pointer'
    },
    '& .btnsDropdown' :{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      marginTop:'15px'
    }


  })
)
  constructor(props: Props) {
    super(props);
  }

  a11yProps = (index: number,activeCard:string) => {
    return ({
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: activeCard,
      className:"tabs_css",
      
    })
  }
  DropDownBox = styled(Box)({
    position:"relative",
    height: "53px",
    display: "flex",
    borderRadius: "6px",
    gap: "0px",
    border: "1px solid #595959",
    justifyContent: "space-between",
    alignItems: "center",
    cursor:"pointer",
    opacity: "0px",
    padding:"5px auto"

});
  DropDownBoxTypography = styled(Typography)({
    lineHeight: "29.05px",
    fontSize: "24px",
    fontWeight: 600,
    color: "#FFF2E3",
    textAlign: "left",
    fontFamily: "Inter,sans-serif",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    gap: "10px",
"@media (max-width:960px)": {
  lineHeight: "29.05px !important",
  fontSize: "15px !important",
  whiteSpace:"nowrap",
  fontWeight: "300px !important",
},
"@media (min-width:720px)": {

},

});
DropDownBoxSecondTypography = styled(Typography)({
  color: "white",
  fontSize: "20px",
  fontFamily: "Inter,sans-serif",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "left",
"@media (max-width:960px)": {
  fontWeight: "300px !important",
  fontSize: "15px !important",
  whiteSpace:"nowrap",
  lineHeight: "29.05px !important",

},
"@media (min-width:720px)": {

},

});
openDropDownBox = styled(Typography)({
  position:"absolute",
  width:"100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  opacity: "0px",
  backgroundColor: "#111111",
  border: "1px solid #595959",
  cursor: "pointer",
  borderRadius: "10px",
  top: "100%",
"@media (max-width:960px)": {
width: "fit-content",
},
"@media (min-width:720px)": {

},
});
  StyledTabs = styled(Tabs)({
    '& .MuiTabs-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: "start",
    },
    '& .MuiTab-wrapper' :{
      alignItems: "start",
    },
    '& .MuiTabs-indicator': {
      // backgroundColor: "#FFF2E3",
      height: '0px',
    
    },
    '& .Mui-selected':{
      borderBottom:'2px solid #FFF2E3'
    },
    '& .MuiTab-root':{
      minWidth:'auto !important',
      marginRight:'10%'
    }
  });
  renderList = () => {
    return (
      this.state.openDropDown && (
        <this.openDropDownBox >

          {this.state.dropDown.map((element: DropDownData, index: number) => {
            return (
              <Box className="" data-test-id={`selectedDropDown-${index}`} onClick={() => this.dropDownViewData(element)}
                style={{
                  
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "1rem",
                  cursor: "pointer"
                }}>
                <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: element.color }}></div>
                <this.DropDownBoxSecondTypography data-test-id={`Typography-${index}`} onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }} 
                >{element.name}</this.DropDownBoxSecondTypography>
              </Box>
            )
          })
          }
        </this.openDropDownBox>
      )
    )
  }
  ForDkcontracts =()=>{
return (
  <>
  {this.state.DkContracts.length > 0 ? <div style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "25px"
      }}>
        {
          this.state.DkContracts.map((item: DesignerContract, index: number) => <DKexperienceCard data={item} key={index} isClient={true}/>)
        }
      </div>
              
            :(
              <Box className="NoBoardClientBox">
                <Typography  className="NoBoardClientHeader" >Create Now</Typography>
                <Typography className="NoBoardClientContent" >Empty Contracts! Find designers. Create opportunities to add contracts on <br />
                Designer's Knot and build valuable working relationships.</Typography>
                {
                  this.props.isEditable && (

                    <Typography data-testId='NavigateFindWork' onClick={()=>this.handlenavigatetoFindwork()} className="active-typography FindworkBtnClient">Find Talent</Typography>
                  )
                }            
              </Box>
            )}
  </>
)
  }
 
  render() {
    return (
      <ThemeProvider theme={theme}>
        <this.MainWrapper >
          <Box >
           <Grid  container>
            <Box className="FirstContainer">
            <Grid sm={12} xs={9}  item>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <this.StyledTabs
                  id="tabs"
                  data-testId="Tabsvalues"
                  onChange={this.handleTabValueChange}
                  value={this.state.tabsValue}
                  indicatorColor="primary"
                  className="Mui-indicator"
                  style={{borderBottom:"1px solid grey",width:'100%'}}
                >
                  <Tab data-testId="Opportunities" onClick={()=>this.handleClientactivePort('Opportunities')} {...this.a11yProps(0, "Opportunities")} />
                  <Tab data-testId="Dk Contracts" onClick={()=>this.handleClientactivePort('DK Contracts')} {...this.a11yProps(1, "DK Contracts")} />
                  <Tab data-testId="Inspirations" onClick={()=>this.handleClientactivePort('Inspirations')} {...this.a11yProps(3, "Inspirations")} />
                </this.StyledTabs>
              </Box>
             
            </Box>
            </Grid>
            { this.props.isEditable && (
               <Grid  xs={3} sm={12} item>
               <Box style={webStyle.postclibtnstyles}>
                 <button  data-test-id="setting-test-id" onClick={this.handleSetting} style={{ marginRight: "4px" }} className="SettingsBtn" data-testId="SettingsBtn"
                 >
                   <span ><img  style={webStyle.spanImgpostcli} src={Settings} alt="" /></span>Settings
                 </button>
                 <button className="SettingsBtn" style={{marginLeft: "4px"}} data-testId="InsightsBtn" onClick={this.navigateInsitePage}>
                  <span><img style={webStyle.spanImgpostcli} src={Insights} alt="" /></span> Insights
                 </button>
               </Box>
               </Grid>
            )}    
            </Box>
           </Grid>
          
            {this.state.active_ClientTab === 'Opportunities' && (
                <>
                
                {(this.state.total_work_opportunity_count > 0 || this.state.total_workopportunity > 0)? (
                  <> 
                  <Box className="btnsDropdown">
                  <Box  className="custom-dropdown">
              
              <Box
                style={{
                  display: "flex",
                  gap:"5px",
                  flexDirection: "column",
                  opacity: "0px",
  
                }} >
  
                  <this.DropDownBox
                  data-testId="DropDownBox"
                
                  onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }}>
                    <this.DropDownBoxTypography >
                      <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: this.state.SelectedDropDownColor }}></div>
                      <Typography style={{
                        fontFamily: "Inter,sans-serif",
                        color: "white",
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontSize: "inherit",
                        letterSpacing: "0.37px",
                        textAlign: "left"
                      }}>{this.state.SelectedDropDownName}</Typography>
                    </this.DropDownBoxTypography>
  
                    <ArrowDropDownIcon style={{ width: "50px", height: "40px", color: "#D9D9D9" }} />
                  </this.DropDownBox>
                  {this.renderList()}
  
                </Box>
                 </Box>
                 {this.props.isEditable && (
  
                  <Box>
                    <button onClick={()=>this.handleNavigatePostOpportunity()} className="PostOppBtn" data-testId="PostOppBtn">
                      Post Opportunity
                    </button>
  
                  </Box>
                 )}
  
                  </Box> 
            <Box style={{marginTop:'2%'}}>
              <Grid container item spacing={3}>
                {this.state.work_Opportunities.length>0 ?
                this.state.work_Opportunities.map((data:any,index:number)=>{

                  return (
                    <Grid key={index} item xs={4} className="GridContainer">
                      <Box className="CardContainer">
                        
                        <Box>
                        <Typography className="CardHeader textOverFolw" >{data.attributes.title}</Typography>
                        </Box>
                        <Box className="CardFirstContent ">
                        <Typography className="RateperHour textOverFolw" >₹{this.handlerateAmount(data)+" "+ (data.attributes.rate_type == 'hourly'?'pr/hr':data.attributes.rate_type)}</Typography>
                        <Typography className="timemonths textOverFolw">{this.handleMonths(data)+' '+data.attributes.project_timeline_type}</Typography>
                        </Box>
                        <Box className="CardDescription">
                          <Typography className="DescriptionContent">{data.attributes.description}</Typography>
                        </Box>
                        <Box >
                          <Typography className="CardFooter">{data.attributes.posted_time_ago}</Typography>
                        </Box>

                        </Box>  
                </Grid>
                  )
                })
              :
              (
                <Box className="NoWorkOpportunity">
                  <Typography>No work opportunity</Typography>
                  </Box>
              
              )  
              }
                
              </Grid>
              {(this.state.work_Opportunities.length < this.state.total_workopportunity) && (this.state.work_Opportunities.length > 0) && (
                
                <Box className="MoreVectorIcons">
                <ExpandMoreRoundedIcon  onClick={()=>this.handlepagination()} data-testId="paginationTestid" />
                </Box>
              )}
            </Box>
                </>
                
                ) :(
                
                  <Box className="NoWorkOpportunity">
                  <Typography className="NoWorkOpportunityHeading">Create Work Opportunities: Post Now</Typography>
                  <Typography className="NoWorkOpportunityContent">Empty Work Opportunities! Post now. Share job offers, freelance gigs, and <br />
                   create opportunities for work within the community.</Typography>
                   {this.props.isEditable && (

                      <Box>
                        <button onClick={()=>this.handleNavigatePostOpportunity()} className="PostOppBtn" data-testId="PostOppBtn">
                          Post Opportunity
                        </button>

                      </Box>
                      )}

                  
                </Box>
                  
                )
                 }
                </>
            )}
            {this.state.active_ClientTab === 'Inspirations' && this.props.id && (
              <>       
              <Inspirations id={this.props.id} navigation={this.props.navigation} EditProfile={this.props.isEditable} />
              </>
            )}
            {this.state.active_ClientTab === 'DK Contracts' && (
              <>
              {this.ForDkcontracts()}
              
              </>
            )}
            
        </Box> 
         
          
        </this.MainWrapper>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  spanImgpostcli :{
    width:"20px",
    height:"20px",
    margin:"0px 4px"
  },
  postclibtnstyles:{
    display:'flex',
    justifyContent:"end",
    flexWrap:'wrap'
  } as React.CSSProperties,       

};
// Customizable Area End
