import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, Typography } from "@material-ui/core";
import RazorPayController, { Props } from "./RazorPayController";
import { CustomTextField } from '../../../settings2/src/Settings2.web'
import { Check, Close, PriorityHighRounded } from "@material-ui/icons";
import { Checkbox } from "@mui/material";
export const checkedIcon = require("../../assets/checkedIcon.svg");
export const unCheckedIcon = require("../../assets/unCheckedIcon.svg");
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class RazorPay extends RazorPayController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  addAmountButton = () => {
    return (
      <Box style={webStyles.button1Box}>
        <Button onClick={this.handlePopupClose} style={webStyles.buttonStyle}>Cancel</Button>
        <Button data-test-id="add-btn-test-id" disabled={this.state.amount === "" || this.state.amount === "0" || this.state.addMoneyLoader} onClick={() => this.handleAddMoneyCall()} style={{
          ...webStyles.button1Style, backgroundColor:
            this.state.amount === "" || this.state.amount === "0" || this.state.addMoneyLoader
              ? "gray"
              : webStyles.button1Style.backgroundColor
        }}>{this.state.addMoneyLoader && <CircularProgress color='inherit' size={25} style={{marginRight:"10px"}}/>}Next</Button>
      </Box>
    )
  }
  getPaidButton = () => {
    return (
      <Box style={webStyles.dialog2Box}>
        <Button onClick={this.handleGetPaidClose}
          style={webStyles.buttonStyle}>Cancel</Button>
        <Button 
        data-test-id = "get-paid-btn-test-id"
        onClick={this.handlePayout}
        disabled={this.state.selectedCheckbox === "" || this.state.isButtonDisable}
          style={{
            ...webStyles.button1Style,
            backgroundColor:
              this.state.selectedCheckbox === "" || this.state.isButtonDisable
                ? "gray"
                : webStyles.button1Style.backgroundColor
          }}>{this.state.getPaidLoader && <CircularProgress color='inherit' size={25} style={{marginRight:"10px"}}/>}{this.state.userRole === "client" ? "Withdraw" : "Get Paid"}</Button>
      </Box>
    )
  }
  renderIcon = () =>{
    if(this.state.paymentStatus === "success"){
      return <Check style={webStyles.iconStyle} />
    }
    if(this.state.paymentStatus === "fail"){
      return <Close style={webStyles.iconStyle} />
    }
    if(this.state.paymentStatus === "cancel"){
      return <PriorityHighRounded style={webStyles.iconStyle} />
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <Box>
          {this.state.userRole === "client" ?
            <>
              <Button
                data-test-id="withdraw-tab-id"
                onClick={this.handleGetPaidClose}
                disabled={this.state.getPaidLoader1}
                style={webStyles.mainButtonStyle}>{this.state.getPaidLoader1 && <CircularProgress color='inherit' size={25} style={{marginRight:"10px"}}/>} Withdraw</Button>
              <Button
                data-test-id="active-tab-id"
                onClick={this.handlePopupClose}
                style={webStyles.addButtonStyle}>
                <Typography style={webStyles.buttonTextStyle}>
                  Add Money to Wallet
                </Typography>
              </Button>
            </> :
            <Button
            disabled={this.state.getPaidLoader1}
              data-test-id="getPaid-tab-id"
              onClick={this.handleGetPaidClose}
              style={webStyles.addButtonStyle}>
              <Typography style={webStyles.buttonTextStyle}>
              {this.state.getPaidLoader1 && <CircularProgress color='primary' size={25} style={{marginRight:"10px"}}/>} Get paid now
              </Typography>
            </Button>}
        </Box>
        <Dialog
          open={this.state.open}
          onClose={this.handlePopupClose}
          PaperProps={{ style: webStyles.dialogPaper }}
        >
          <DialogContent style={webStyles.mainDialog}>
            <Typography style={webStyles.dialog1Title}>Add money in wallet</Typography>
            <Box style={webStyles.mainBox}>
              <Typography style={webStyles.inputLabelStyle}>
                Enter amount you want to add in the wallet
              </Typography>
              <CustomTextField
                type="string"
                placeholder="Add amount"
                data-test-id="input-data-amount"
                value={this.state.amount}
                style={webStyles.dialogTextBox}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleAmount(event.target.value)
                }
              />
            </Box>
            <Divider style={webStyles.dividerStyle} />
            <Box style={webStyles.dialog1Contains}>
              <Box style={webStyles.dialog1Details}>
                <Typography style={webStyles.dialog1Text}>Wallet amount</Typography>
                <Typography style={webStyles.dialog1Text}>INR {this.state.amount}</Typography>
              </Box>
              <Box style={webStyles.dialog1Details}>
                <Typography style={webStyles.dialog1Text}>{`Processing fee ${this.state.configAddTax}%`}</Typography>
                <Typography style={webStyles.dialog1Text}>INR {this.calculateAmount(+this.state.amount, this.state.configAddTax, false)}</Typography>
              </Box>
              <Box style={webStyles.dialog1Details}>
                <Typography style={webStyles.dialog1Text}>{`Tax and GST ${this.state.configGst}%`}</Typography>
                <Typography style={webStyles.dialog1Text}>INR {this.calculateAmount(+this.state.amount, this.state.configGst, false)}</Typography>
              </Box>
              <Box style={{ marginTop: "18px", ...webStyles.dialog1Details }}>
                <Typography style={webStyles.dialog1Amount}>Total to be paid</Typography>
                <Typography style={webStyles.dialog1Amount}>INR {this.calculateAmount(+this.state.amount, this.state.configGst + this.state.configAddTax, true)}</Typography>
              </Box>
            </Box>
            {this.addAmountButton()}
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.getPaid}
          onClose={this.handleGetPaidClose}
          PaperProps={{ style: webStyles.dialogPaper1 }}
        >
          <DialogContent style={webStyles.mainDialog}>
            <Typography style={webStyles.dialog2Header}>Get paid now</Typography>
            <Typography style={webStyles.dialog2Amount}>{`Rs. ${this.state.fundAccount.wallet_balance}`}</Typography>
            <Box style={webStyles.dialog2MainBox}>
              <Typography style={webStyles.dialog2Text}>Confirm the withdrawal method for the payment </Typography>
              <Typography style={webStyles.dialog2Text}>of the above amount</Typography>
            </Box>
            <Box style={webStyles.amountBoxStyle}>
              {this.state.fundAccount.fund_accounts.items.length >
                0 ?
                this.state.fundAccount.fund_accounts.items.map((item, index) => {
                  const mainText: string = item.bank_account ? `Direct to Local Bank (INR) - Account ending in ${item.bank_account?.account_number.slice(-4)}` : `Direct to UPI (INR) - UPI ID ending in ${this.maskUPI(item.vpa?.address)}`
                  return (
                    item.active &&
                    <>
                      <Box style={webStyles.checkStyle}>
                        <Checkbox 
                          data-test-id="check-data-test-id"
                          style={webStyles.checkBoxStyle} checked={this.state.selectedCheckbox === item.id}
                          onChange={() => this.handleCheckboxChange(item.id)}
                          icon={<img src={unCheckedIcon} />}
                          checkedIcon={<img src={checkedIcon} />} />
                        <Typography style={{ fontWeight: this.state.selectedCheckbox === item.id ? 600 : 400, ...webStyles.accountText }}>{mainText}</Typography>
                      </Box>
                      {index !== this.state.fundAccount.fund_accounts.items.length - 1 && (
                        <Divider style={webStyles.dividerStyle1} />
                      )}
                    </>
                  )
                })
                : <Typography style={webStyles.emptyStyle}>Please add payment method</Typography>}
            </Box>
            {this.getPaidButton()}
          </DialogContent>
        </Dialog>

        <Dialog
          data-test-id="payment-pop-up"
          open={this.state.paymentPopUp}
          onClose={this.handlePaymentPopupClose}
          PaperProps={{ style: webStyles.dialogPaper2 }}
        >
          <DialogContent style={webStyles.mainDialog}>
            <Box style={{
              backgroundColor: this.state.statusColor,
              ...webStyles.dialog3MainBox
            }}>
              {this.renderIcon()}
            </Box>
            <Typography style={webStyles.dialog3Text1 as React.CSSProperties}>{this.state.statusText1}</Typography>
            <Typography style={webStyles.dialog3Text2}>{this.state.statusText2}</Typography>
          </DialogContent>
        </Dialog>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = {
  emptyStyle:{ font: "Inter", fontWeight: 600, fontSize: "16px", lineHeight: "100%", letterSpacing: "0%", color: "#FFF2E3" },
  accountText:{font: "Inter", fontSize: "16px", lineHeight: "100%", letterSpacing: "0%", color: "#FFF2E3"},
  checkBoxStyle:{ padding: "0px", margin: "0px" },
  checkStyle:{ display: "flex", alignItems: "center", gap:"20px" },
  amountBoxStyle:{ maxHeight:"100px",overflow:"auto",scrollbarWidth:"none" as"none",marginTop: "40px", width: "90%", borderRadius: "16px", display: "flex", flexDirection: "column" as "column", gap: "20px", padding: "30px", backgroundColor: "#171717" },
  iconStyle:{
    color: 'white', fontSize: '100px'
  },
  dialog3Text2:{fontFamily: "Inter", color: "white"},
  dialog3Text1:{
    fontWeight: 'bold', fontSize: "20px", color: "white", fontFamily: "Inter", marginTop: "25px"
  },
  dialog3MainBox: {
    display: 'inline-flex',
    borderRadius: '50%',
    padding: '8px'
  },
  dialogPaper: {
    backgroundColor: "#222222",
    padding: "58px",
    width: "500px",
    borderRadius: "20px",
  },
  dialogPaper1: {
    backgroundColor: "#222222",
    padding: "38px",
    width: "600px",
    borderRadius: "20px",
  },
  dialogPaper2: {
    backgroundColor: "#222222",
    padding: "38px",
    borderRadius: "20px",
  },
  mainDialog: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as "column",
  },
  dialogTextBox: {
    marginTop: "16px",
    width: "100%", backgroundColor: "#171717", borderRadius: "4px", border: 'none',
    paddingLeft: '15px',
    paddingRight: '27px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    height: '46px',
    outline: 'none',
    lineHeight: '18.15px',
  },
  mainBox: {
    width: "100%"
  },
  dialog1Title: {
    fontFamily: "Inter", fontWeight: 600, fontSize: "20px", lineHeight: "100%", letterSpacing: "0%", color: "#FFF2E3"
  },
  inputLabelStyle: {
    marginTop: "69px", fontFamily: "Inter", fontWeight: 600, fontSize: "16px", lineHeight: "16px", letterSpacing: "0.5px", color: "#FFF2E3"
  },
  dividerStyle: {
    width: "100%", marginTop: "50px", marginBottom: "27px", backgroundColor: "#525252"
  },
  dividerStyle1: {
    width: "100%", backgroundColor: "#382F2F"
  },
  dialog1Contains: {
    width: "100%", display: "flex", gap: "14px", flexDirection: "column" as "column"
  },
  dialog1Details: {
    display: "flex", justifyContent: "space-between"
  },
  dialog1Text: {
    fontFamily: "Inter", fontWeight: 400, fontSize: "16px", lineHeight: "16px", letterSpacing: "0.5", color: "#A2A2A2"
  },
  dialog1Amount: {
    fontFamily: "Inter", fontWeight: 600, fontSize: "24px", lineHeight: "16px", letterSpacing: "0.5", color: "#FFF2E3"
  },
  button1Box: {
    marginTop: "95px", display: "flex", gap: "18px"
  },
  buttonStyle: {
    fontFamily: "Inter", fontWeight: 500, fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px",
    textTransform: "none" as "none", color: "#FFF2E3", backgroundColor: "#0D0D0D", padding: "10px 30px", borderRadius: "7px"
  },
  button1Style: {
    fontFamily: "Inter", fontWeight: 500, fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px", textTransform: "none" as "none", color: "#121212", backgroundColor: "#FFF2E3", padding: "10px 30px", borderRadius: "7px"
  },
  dialog2Header: {
    fontFamily: "Inter", fontWeight: 600, fontSize: "24px", lineHeight: "100%", letterSpacing: "0%", color: "#FFF2E3"
  },
  dialog2Amount: {
    marginTop: "54px",
    backgroundImage: "linear-gradient(380deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%)",
    WebkitBackgroundClip: "text",
    MozBackgroundClip: "text",
    backgroundClip: "text",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "36px",
    letterSpacing: "0.38px",
    color: "transparent"
  },
  dialog2MainBox: { marginTop: "24px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" as "column" },
  dialog2Text: { fontFamily: "Inter", fontWeight: 400, fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px", color: "#FFF2E3" },
  dialog2Box: { marginTop: "68px", display: "flex", gap: "18px" },
  addButtonStyle: {
    background: "#171717",
    fontFamily: "Inter",
    fontSize: "16px",
    borderRadius: "7px",
    textTransform: "none" as "none",
    fontWeight: 500,
    marginLeft: "16px", padding: "11px 20px", color: "transparent",
  },
  mainButtonStyle:{
    fontFamily: "Inter",
    fontSize: "16px",
    borderRadius: "7px",
    textTransform: "none" as "none",    
    fontWeight: 500,
    background: "#0D0D0D", color: "#FFF2E3", padding: "10px 30px"
  },
  buttonTextStyle: {
    backgroundImage: "linear-gradient(90.83deg, #3FA7E1, #7699EF, #AE9DF2, #E8A5CA, #F1B2C6)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 600,
    display:"flex",
    alignItems:"center"
  }
};
// Customizable Area End
