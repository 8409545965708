Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.endPointApiIndusrtry ="bx_block_profile/profiles/get_industry_list"
exports.endPointApiDesignation = "bx_block_profile/profiles/get_designation_list"
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getAllProfiles="bx_block_profile/profiles";
exports.baseURL = require('../../../framework/src/config');
exports.getAllExpertise="bx_block_projectportfolio/get_categories";
exports.getAllSkills="bx_block_projectportfolio/skills_by_category?category_id="
exports.placeholderAbout=""
exports.endPointApiGetUserImageProfile="bx_block_profile/profiles/";
exports.endPointDeleteImageProfile="/delete_image";
exports.apiDeleteUserType = "DELETE";
exports.locationText = "Location"
exports.messageText = "Message";
exports.endPointGetLanguageListApi="/bx_block_profile/profiles/language_listing?page=1&per_page=43"
exports.updateSendbirdDetailsEndpoint = "/bx_block_chat/users/update_user"
exports.updateRazorpayDetailsEndpoint = "bx_block_payment_admin/payments/update_contact"
exports.followUnfollowApiEndPoint= "bx_block_followers/follows"
exports.profileViewApiEndPoint = "bx_block_dashboard/views/create_profile_view"
exports.getFollowersApiEndPoint = "bx_block_followers/follows/followers?"
exports.getFollowingApiEndPoint = "bx_block_followers/follows/following?"
exports.deleteFollowApiEndPoint = "bx_block_followers/follows/"
exports.postForumPostApiEndPoint = "bx_block_profile/profiles/get_user_activity"
exports.postViewApiEndPoint = "bx_block_posts/forum_posts/forum_post_view"
exports.postLikeApiEndPoint = "bx_block_posts/forum_posts/forum_post_like"
exports.postCommentApiEndPoint = "bx_block_posts/forum_posts/forum_post_comment"
exports.getCommentDataApiEndPoint = "bx_block_posts/forum_posts/list_forum_post_comments"
exports.forumDislikeApiEndPoint = "bx_block_posts/forum_posts/forum_post_dislike"
exports.commentsEndPoint="bx_block_comments/comments";
exports.shareNotificationApiEndPoint = "bx_block_posts/forum_posts/share_notification"
// Customizable Area End

