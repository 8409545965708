// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  styled,
  Grid,
  Menu,
  MenuItem,
  Modal
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ShareIcon,Backbtn,FollowBtn,UnfollowBtn,userLogo} from "../assets";
import PortfolioCard from '../../../../components/src/PortfolioCard.web';
import MultipleCardlap from "../../../../components/src/MultipleCardlap.web";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ToastMSG from '../../../../components/src/ToastMSG.web';
import ProjectDetails from "../../../landingpage/src/discoverproject/ProjectDeatils.web";
const baseURL = require("../../../../framework/src/config.js");
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import InspirationController, {
  Projectdata,
  Props
} from "./InspirationController";

export default class Inspirations extends InspirationController {
   
  MainWrapper = styled(Box)(({ theme }) => ({
    
    '& .collection':{
      fontWeight:700,
      color: 'rgba(255, 242, 227, 1)',
      fontSize:"20px",
    },
    
    '& .CollectionName' :{
      fontWeight:400,
      fontSize:"20px",
      color: 'rgba(140, 140, 140, 1)',
      cursor: 'pointer',

    },
    
    '& .cursor-pointer' : {
      cursor: 'pointer',
    },
    
  '& .Branding':{
    fontSize: '36px',
    fontWeight: 700,
    marginRight: '10px'
    
  },
  '& .noProjects' :{
    textAlign: 'center',
    marginTop:"5%",
    width:"100%"
  },
  
'& .thirdboxContainer' :{
  display: 'flex',
  justifyContent:"flex-start",
  alignItems: 'center',
 padding:"10px 0px"
},
'& .MyboardbackBtn' :{
marginRight:"20px",

},
'& .BackBtnMy' :{
cursor: 'pointer',
height:"25px"
},
'& .BrandingContainer' :{
  display:'flex',
  alignItems: 'center',
},
'& .shareicon' : {
  width:"25px",
  height:"22px",
  marginLeft:"10px",
  cursor: "pointer",
},
'& .MoreVectorIcon' :{
  textAlign: 'center',
},
'& .MoreVectorIcons svg' :{
  width:"50px",
  height:"50px",
  background:"#171717",
  borderRadius:"50px"
},
'& .no_projects_container' :{
  textAlign: 'center',
  width:"100%"},
'& .inspiration-first ' :{
  background:"black",
  width:"70%",
  height:"auto",
  padding:'10px',
  display:'flex',
  borderRadius:"50px",
  marginTop:"30px",
  marginBottom:"15px"
},
'& .inspiration-myBoard':{
  width:'50%',
  textAlign:"center",
  alignContent:"center",
  backgroundColor:"#171717",
  borderRadius:"50px",
  fontWeight:700,
  fontSize:"20px",
  cursor: "pointer",
  border: '2px solid transparent',
    background: 'linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)',
    backgroundClip: 'border-box' 
    
},
'& .inspiration-content' :{
  backgroundColor: '#171717',
  padding: '16px',
  borderRadius: '50px',
},
'& .inspiration-Followingboard' :{
  
  textAlign:"center",
  alignContent:"center",
},
'& .inactiveBoard' :{
  width:"50%",
  fontWeight:400,
  fontSize:'20px',
  cursor:'pointer',
  color:'#8C8C8C',
  alignContent:'center',

},
'& .ProjectCounts' :{
    display:'flex',
    width:'100%',
    justifyContent:'flex-start',
    marginBottom:"20px",
    gap:'20px'
},
'& .brandingdetails' :{
  width:"100%",
  display:'flex',
  alignItems:'center',
  gap:"15px"
},

'& .Inspirationlist':{
  display: 'flex',
},
'& .Multiplemain' :{
  display: 'flex',
  justifyContent:'flex-start',
  width:'100%'

},
'& .FollowingBoardHeader':{
  display:'flex',
  marginTop:"2%",
  marginBottom:"2%",
},
'& .BackIcon' :{
  width:"4%",
  paddingTop:"15px",
},
'& .brandingContents' :{
  width:"60%"
},
'& .Profiledetails' :{
  width:'36%',
  display:'flex',
  justifyContent:"flex-end",
  alignItems: 'center',
},
'& .NoInspirationBox':{
  display:'flex',
  flexDirection:'column',
  gap:'20px',
  alignItems:'center',
  marginTop:'10%'
},

'& .NoInspirationHeaderBox' :{
  fontSize:'20px',
  fontWeight:600,
  color:'#FFF2E3',
  textAlign:'center',
  fontFamily:"Inter,sans-serif",

},
'& .NoInspirationcontent':{
fontSize:'16px',
fontWeight:400,
color:'#FFF2E3',
fontFamily:"Inter,sans-serif",
textAlign:'center'
},
'& .FindDiscoverProject':{
  fontFamily:'Inter,sans-serif',
  fontSize:'20px',
  fontWeight:600,
  borderBottom:'2px solid'
},
'& .Followingctn' :{
  display:'flex',
  alignItems: 'center',
},
'& .Followingctncontainer' :{
  width:"30%"
},
'& .cursorPointer' :{
cursor:'pointer'
},
'& .ViewProfile' :{
  fintSize:"16px",
  fontWeight:600,
  cursor: "pointer",
  fontFamily: "Inter,sans-serif",
  textDecoration: 'underline'
},
'& .MoreVectorIcons' :{
  textAlign: 'center',
  marginTop:"70px"
},
'& .NoProjectsfnd':{
  textAlign: 'center',
  width:"100%",
  marginTop:"30px"
}
,
'& .ActiveHeader' : {
  position:"relative",
  color: 'transparent',
  fontWeight:600,
  textDecoration: 'underline',
  borderWidth:' 0px 0px 2px',
  backgroundColor: 'transparent',
  backgroundImage:'linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  width: 'max-content',
  textAlign: 'left',
  backgroundClip: 'text',
  marginLeft:'10px',
  alignContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
    height: '2px',
    background: 'linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)', // Color of the underline
  },
  
},
'& .InspirationBoardsBox' :{
margin:'20px 5px'
},
'& .InspirationBoardsHeading':{
  fontSize:'36px',
  fontWeight:700,
  color:'#FFF2E3',
  fontFamily:'Inter,sans-serif'
},
'& .ActiveFollow' :{
  display:'flex'
}




  })
)
  constructor(props: Props) {
    super(props);
  }

  imgProfile =(profilePic:string | null ,backgroundColor:string | null) =>{
    if(profilePic){
      return (
      <div >
        <img style={webStyle.ProfileImg} src={this.baseUrlStirng + profilePic}/>
      </div>)
  } else if(backgroundColor){

      return(
        <div >

          <Box style={{...webStyle.profileImageBoxes,backgroundColor:`${backgroundColor}`}}></Box>
        </div>
      )
  } else {
      return(
        <div >
            <img style={webStyle.ProfileImg}  src={userLogo}/>
        </div>
      ) 
  }
 }
 deleteModalPopup =()=>{
  return (
    <Modal
            open={this.state.deleteModal}
            data-testId="DeleteModal"
            onClose={this.handleCloseDeleteModal}
            aria-describedby="modal to delete profile"
            aria-labelledby="delete-project-modal"
          >
            <Box sx={webStyle.paperProfiles}>
              <Box
                style={{
                  margin: "0 auto",
                  padding: "32px",
                  textAlign: "center",
                }}
              >
                <Box justifyContent="center" display="flex"  alignItems="center">
                  <Typography
                    data-testId="DeleteModal"
                    style={webStyle.modalTitles}
                  >
                   {this.state.InspirationDeleteModal?'Delete inspiration?':'Delete Project from Inspiration?'} 
                  </Typography>
                  
                </Box>
               
                <Box style={webStyle.btnBoxes}>
                  <Button
                    style={{textTransform: "none", ...webStyle.canBtns }}
                    data-testId="cancelDelete"
                    onClick={this.handleCloseDeleteModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ ...webStyle.saveBtns, textTransform: "none" }}
                    onClick={()=>{this.state.InspirationDeleteModal?this.handleDeleteInspiration():this.hanledeleteMyBoardProject()}}
                    data-testId="DeleteCollecion"
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
  )
 }

  renderProfileCard = () => {
    return (
      <Box data-test-id={"projectDetailsModal"}>
        {this.state.showDetails && <ProjectDetails handlenavigation={this.HandleNavigation} selectedProject={this.state.selectedProject} token={this.state.token} open={this.state.showDetails} navigateTo={this.navigateTo} closeModal={this.closeModal} allprojectList={this.state.specificProjectList} data-testid="projectDetails" />}
      </Box>
    )
  }

  renderFolloworEdit =() =>{
    return (
      <>
      <Box className="FollowingBoardHeader">
                <Box className="BackIcon">
                  <img className="BackBtnMy" data-testId='closemodalBtn' onClick={()=>this.handleCloseFollowingModal()}  src={Backbtn} alt='backicon'/>
                </Box>
                <Box className="Branding brandingContents">
                  <Typography className="Branding">

                    {this.handlePortfolioName()}
                  </Typography>
                  <Box className="brandingdetails">
                    
                    <Typography style={{maxWidth:'25%'}}>{this.state.ProjectsCount}{' '}Projects</Typography>
                    <Typography style={{maxWidth:"25%"}}>{this.state.FollowersCount} {' '}Followers</Typography>
                    <Box className="Followingctncontainer">
                        {this.state.followStatus ? 
                      <>

                      <Box className="ActiveFollow" >
                      <img data-testId="UnfollowBtn" className="cursorPointer" onClick={()=>this.handleUnfollow(this.state.PortfolioData.id)} src={FollowBtn} alt="follow"/>
                      <Typography className="Followingctn ActiveHeader">Following</Typography>
                      </Box>
                      </>
                      :
                      <>
                       <Typography className="Followingctn"><span style={{marginRight:"10px"}}>
                      <img className="cursorPointer" src={UnfollowBtn} data-testId='FollowBtn' onClick={()=>this.handleFollowBtn(this.state.PortfolioData.id)} alt="follow"/></span>Follow</Typography>
                      </>  
                      }
                    </Box>

                  </Box>
                </Box>
                
                <Box className="Profiledetails">
                  
                  {this.imgProfile(this.state.PortfolioData.profile_photo,this.state.backGroundFollowingColor)}
                  
                  <Box style={webStyle.Profiledetails}>
                    <Typography>{this.state.followingUserName}</Typography>
                    
                    <Typography data-testId="ProfileView" onClick={()=>this.handleNavigation()} className="ViewProfile">View Profile</Typography>
                  </Box>
                </Box>

              </Box>
              <Box className="Inspirationlist">
              <Grid container spacing={4}>
                  
                  {this.state.PortfolioData?.project?.data.length > 0 ? (
                      this.state.PortfolioData.project?.data.map((item:any,index:number)=>{
                        return (
                          <Grid item key={index} xs={4} sm={12} md={4} lg={4} xl={3} style={webStyle.PortfolioCard} data-test-id="my-board-project" onClick={() => this.openModal(item)}> 
                          <PortfolioCard data={item} deleteIcon={false} data-testId='portfoliofollowingboard' handledeletefn={undefined}/>
                          </Grid>
                        )
                      })     
                    ) :(
                      <Box className="NoProjectsfnd">
                        <Typography>No Projects Found</Typography>
                      </Box>
                    )
                    }   
                     </Grid>   
              </Box>
              {
                this.state.PortfolioData?.project?.data.length > 0 && this.projectYouMayLikeUi()
              }
      </>
    )
  }
  ActiveBoardCheck =() =>{
    return (
      <>
      <div onClick={()=>this.handleactiveBoard('My Board')} data-testId="activeMyBoard" className={this.state.activeBoard == 'My Board' ? 'inspiration-myBoard':"inactiveBoard"}>
                    <div data-testId="activeMyboardchange"  onClick={()=>this.handleInspirations()} className={this.state.activeBoard=="My Board"?"inspiration-content":"inspiration-Followingboard"}>

                    My Boards
                    </div>
                    
                    </div>
                    <div data-testId="activeFollowingboard" onClick={()=>this.handleactiveBoard('Following Boards')} className={this.state.activeBoard=="Following Boards"?"inspiration-myBoard":'inactiveBoard'}>

                    <div className={this.state.activeBoard=="Following Boards"?"inspiration-content":"inspiration-Followingboard"}> Following Boards</div>
                    </div>

      </>
    )
  }
  projectYouMayLikeUi = () => <Grid container spacing={5} style={{ margin: "0px", paddingTop: "50px" }}>
    <Grid item sm={12} style={{ margin: "0px !important", paddingTop: "50px", paddingBottom: "0px" }}>
      <Typography style={{
        fontFamily: "inter",
        fontWeight: 600,
        fontSize: "20px",
        color: "#FFF2E3"
      }}>
        Projects we think you might like
      </Typography>
    </Grid>
    <Grid item container spacing={5} style={{ margin: "0px !important", paddingTop: "0px" }}>
      {
        this.state.ProjectsMayULike.length > 0 ? (
          this.state.ProjectsMayULike.map((item: Projectdata, index: number) => {
            return (
              <Grid key={index} style={webStyle.PortfolioCard} xs={4} sm={12} md={4} lg={4} xl={3} item data-test-id="follow-board-one" onClick={() => this.openModal(item)}>
                <PortfolioCard showDeleteIcon={true} data={item} deleteIcon={this.props.EditProfile} data-testId='deletesinglepjt-one' handledeletefn={(event: {stopPropagation: () => void}) => this.handledeletesingleproject(event, item)} />
              </Grid>
            )
          })
        ) : (<Box className="noProjects">
          <Typography>No Projects Found</Typography>
        </Box>)
      }
    </Grid>
    {
      (this.state.page <= this.state.totalPage) && this.state.ProjectsMayULike.length > 0 && <Grid item sm={12} style={{ margin: "0px !important", paddingTop: "50px", paddingBottom: "0px", display: "flex", justifyContent: "center" }}>
        <Button style={{
          fontFamily: "inter",
          fontWeight: 600,
          fontSize: "20px",
          color: "#FFF2E3",
          textTransform: "none"
        }}
        data-test-id="view-more-btn"
          onClick={this.handleUpdatePage}
        >
          View More
        </Button>
      </Grid>
    }
  </Grid>
  render() {
  
    
    return (
      <ThemeProvider theme={theme}>
        <this.MainWrapper >
        <ToastMSG open={this.state.toastStatus} data-testId="tostmessage" close={() => this.handleToastClose()} message={this.state.toastMsg} action={this.state.toastAction} />
     
          <Box >
            {this.props.EditProfile ? (
              <Box className="inspiration-first">
               {this.ActiveBoardCheck()}
                 
                </Box>  
              ):
              (
                <Box className="InspirationBoardsBox">
                  <Typography className="InspirationBoardsHeading" >Inspiration Boards</Typography>
                  </Box>
              )
              }
      {
        (!this.state.InspirationModal) && (
          <>  
        
           <Grid item container spacing={4}>
        {this.state.inspirationMyboard?.length>0 ? 
        this.state.inspirationMyboard.map((data:any,index:number)=>{
          return (         
            <>
            <Grid key={index} data-testId='Inspirationdata' sm={12} md={4}  lg={3} className="Multiplemain" onClick={()=>this.handleInspirationsProject(data.attributes)} item>
          <MultipleCardlap  data={data} />
          </Grid>
            </>
          )
        })
        :
        (
        <Box className="noProjects">
          
          {this.state.activeBoard === "My Board" ? (
            <>
            <Box className="NoInspirationBox">
                <Typography  className="NoInspirationHeaderBox" >Craft Your Inspiration Board</Typography>
                <Typography className="NoInspirationcontent" >Empty Board! Create now. Collect projects, craft a personalized inspiration <br />
                 board by exploring our diverse range of projects.</Typography>
                {
                  this.props.EditProfile && (

                    <Typography data-testId='NavigateDiscoveryProject' onClick={()=>this.handleNavigateDiscover()} className="active-typography FindDiscoverProject">Discover Projects</Typography>
                  )
                }            
              </Box>
            </>
          ) :
          
          (<>
          <Box className="NoInspirationBox">
                <Typography  className="NoInspirationHeaderBox" >Craft Your Following Board</Typography>
                <Typography className="NoInspirationcontent" >Empty Board! Create now. Follow projects, craft a personalized inspiration <br />
                 board by exploring our diverse range of projects.</Typography>
                {
                  this.props.EditProfile && (

                    <Typography   className="active-typography FindDiscoverProject" data-testId='NavigateDiscoveryProject' onClick={()=>this.handleNavigateDiscover()}>Discover Projects</Typography>
                  )
                }            
              </Box>
          </>)
          
        }
        </Box>
        )
        }
      </Grid>  
      { (this.state.total_inspiraitions > this.state.inspirationMyboard?.length ) && (
         <Box className="MoreVectorIcons">

         <ExpandMoreRoundedIcon  onClick={()=>{
          this.setState({following_page: this.state.following_page + 1})
          this.handlePublicInspirations()
         }} data-testId="paginationTestid" />
      </Box>    
      )}
                
          
          </>
        )
      }
      

      { this.state.InspirationModal && 
        (
          <>
          {(this.state.activeBoard == 'My Board' && this.props.EditProfile) && (
            <Box>
            <Box className="thirdboxContainer">
            <Box className="MyboardbackBtn">
                  <img className="BackBtnMy" data-testId='BackBtnMyBoard' onClick={()=>this.handleCloseInspirationModal()} src={Backbtn} alt='backicon'/>
                </Box>
              <Box className="BrandingContainer">
             
                <Typography className="Branding">
                  {this.handlePortfolioName()}
                </Typography>
                
                <div>
                  {this.props.EditProfile &&
                  
                <MoreVertIcon data-testId="MorebtnIcon" onClick={this.handleOpenListMenu} className="cursor-pointer" />
                  }
                <Menu
                  id="long-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={this.state.Openmenu}
                  onClose={this.handleCloseListMenu}
                  data-testId="closeMenuTestId"
                  PaperProps={{
                    style: {
                      width: '200px',
                      border: '1px solid rgb(89, 89, 89)',
                      color: 'rgba(255, 242, 227, 1)',
                      background: 'rgba(17, 17, 17, 1)'
                    },
                  }}
                >
                  <MenuItem data-testId="rename_collectionBtn" onClick={()=>this.handleRenameInspiration()}>
                  Rename Inspiration
                  </MenuItem>
                  <MenuItem data-testId="delete_collectionBtn"  
                  onClick={()=>this.setState({InspirationDeleteModal:true,deleteModal:true})}
                  >
                  Delete Inspiration
                  </MenuItem>
                </Menu>
              </div>
                <img className="shareicon" src={ShareIcon} alt="" />
              </Box>
             
            </Box>
            
            <Box className="ProjectCounts">
                <Typography>
                 {this.state.ProjectsCount} Projects 
                </Typography>
                <Typography>
                  {this.state.FollowersCount} Followers
                </Typography>
  
                </Box>
            <Box className="Inspirationlist">
              <Grid container spacing={5}>
                  
              {this.state.PortfolioData?.project?.data.length > 0 ? (
                  this.state.PortfolioData.project?.data.map((item:any,index:number)=>{
                    return (
                      <Grid key={index}  style={webStyle.PortfolioCard} xs={4} sm={12} md={4} lg={4} xl={3} item data-test-id="follow-board" onClick={() => this.openModal(item)}> 
                      <PortfolioCard data={item} deleteIcon={this.props.EditProfile} data-testId='deletesinglepjt'  handledeletefn={(e:any)=>this.handledeletesingleproject(e,item)}/>
                      </Grid>
                    )
                  })
                ) :(
                  <Box className="noProjects">
                    <Typography>No Projects Found</Typography>
                  </Box>
                )
                }
                 </Grid>
            </Box>
              {
                this.state.PortfolioData?.project?.data.length > 0 && this.projectYouMayLikeUi()
              }
            </Box>
          )}
          {(this.state.activeBoard == 'Following Boards' || !this.props.EditProfile ) && (

            <Box>
              {this.renderFolloworEdit()}
            </Box>
          )}
          
          </>
        )
      }


      </Box> 
          <Modal
            open={this.state.openCollection}
            data-testId="proModal"
            onClose={this.handleCloseProfile}
            aria-describedby="modal to edit profile"
            aria-labelledby="edit-profile-modal"
          >
            <Box sx={webStyle.paperProfiles}>
              <Box
                style={{
                  margin: "0 auto",
                  padding: "32px",
                  textAlign: "center",
                }}
              >
                <Box justifyContent="center" display="flex"  alignItems="center">
                  <Typography
                    data-testId="editprofile"
                    style={webStyle.modalTitles}
                  >
                    Rename Inspiration
                  </Typography>
                  
                </Box>
                <Box>
                <input
                    type="text"
                    maxLength={25}
                    data-testId= "inputcollectionName"
                    name="name"
                    id="profile_name"
                    placeholder="Name"
                    style={webStyle.CollectionInputs}
                    className="inputCommanProfileEdit"
                    value={this.state.inspName}
                    onChange={(e) =>this.handleUpdateCollectionName(e)}
                    
                    
                  />
                </Box>
              
                <Box style={webStyle.btnBoxes}>
                  <Button
                    style={{textTransform: "none", ...webStyle.canBtns }}
                    data-testId="submitsave"
                    onClick={this.handleCloseProfile}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ ...webStyle.saveBtns, textTransform: "none" }}
                    onClick={()=>this.hanleupdateInspirationName()}
                    data-testId="saveCollecion"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          {this.deleteModalPopup()}
          {this.renderProfileCard()}
        </this.MainWrapper>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  PortfolioCard:{
    maxHeight:"300px",
    marginTop:"15px"
  },
  ProfileImg:{
    width:"76px",
    height:"76px",
    borderRadius:'50%',
    marginRight:'15px'
  },
  Profiledetails:{
    textAlign:"start",
    alignItems:"center"
  } as React.CSSProperties,
  
  paperProfiles:{
    width:488,
    position: 'absolute',
    backgroundColor: '#222222',
    boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
    borderRadius: '7px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
   
  },
  modalTitles: {
    color: "#FFF2E3",
    fontSize: "20px",
    lineHeight:"24.2px",
    fontWeight: 600,
    width:"100%",     
    fontFamily: "Inter, sans-serif",   
  },
  canBtns:{
    fontFamily: "Inter, sans-serif",      
    fontSize: "16px",
    linehHeigth: "24px",
    color: '#FFF2E3',
    fontWeight: 500,
    padding:"10px 30px 10px 30px",  
    backgroundColor:'#0D0D0D',       
    width:"max-content",     
    borderRadius:"7px",  
    },
    btnBoxes:{
      marginTop:"40px",
      gap:"24px" , 
      display:"flex",
      justifyContent:"center"
    },
    CollectionInputs :{
      marginTop:"40px",
      width:"100%",
      textAlign:"center",
      height:"50px",
    } as React.CSSProperties,
  saveBtns:{
    fontFamily: "Inter, sans-serif",      
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor:'#FFF2E3',       
    linehHeigth: "24px",
    padding:"10px 30px 10px 30px",      
    color: '#121212',
    width:"max-content",       
    borderRadius:"7px",  
       },
       profileImageBoxes :{
        width:"76px",
        height:"76px",
        borderRadius:'50%',
    marginRight:'15px'
      } as any,
      

};

// Customizable Area End
