import {
    Box, Button, Dialog, DialogContent, Divider, Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import { Deliverable, Milestone } from '../ContractDetailsController.web';
import { styled } from '@material-ui/styles';

interface ApproveMlestoneModalProps {
    open: boolean;
    handleClose: () => void;
    milestone: Milestone;
    sendApprovalMilestoneApiCall: (contractId: string, milestoneId: number) => void;
    contractId: string;
    index: number;
    handleViewSubmissionClick: (mileston: Milestone, index: number, contractId: number | string) => void;
}

interface StateProp {
    selectedIds: number[];
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "700px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px"
        },
    },
});



export default class ApproveMlestoneModal extends Component<ApproveMlestoneModalProps, StateProp> {
    constructor(props: ApproveMlestoneModalProps) {
        super(props);
        this.state = {
            selectedIds: []
        };
    }

    handleChangeSelectedIds = (id: number) => {
        if (this.state.selectedIds.includes(id)) {
            this.setState({
                selectedIds: this.state.selectedIds.filter((arrayId: number) => arrayId !== id)
            })
        } else {
            this.setState({
                selectedIds: [...this.state.selectedIds, id]
            })
        }
    }

    render() {
        const { handleClose, milestone, open, sendApprovalMilestoneApiCall, contractId, handleViewSubmissionClick, index } = this.props;

        let count = 0
        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography
                            style={{
                                fontSize: "20px",
                                fontWeight: "600",
                                color: "#FFF2E3",
                                fontFamily: "inter",
                                padding: "30px 0px 18px 0px"
                            }}
                        >
                            Approve Milestone
                        </Typography>
                    </Box>
                    <Divider style={{ height: "1px", width: "100%", background: "#494949" }} />
                    <DialogContent>
                        <Box
                            style={{
                                padding: "40px 136px 40px 136px"
                            }}
                        >
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontFamily: "inter" }}>
                                <span
                                    style={{ color: "#7D7D7D", fontSize: "16px", fontWeight: 400 }}
                                >
                                    Mark deliverables as approved</span>
                                <span
                                data-test-id="open-view-sub-modal"
                                onClick={() => handleViewSubmissionClick(milestone, index, contractId)}
                                style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, textDecoration: "underline", fontFamily: "inter", cursor: "pointer" }}>
                                    View submission
                                </span>
                            </Box>
                            <Box style={{
                                background: "#2F2F2F",
                                borderRadius: "14px",
                                margin: "32px 0px"
                            }}>
                                {
                                    milestone.deliverables.map((item: Deliverable, index: number) => {
                                        if (item.deliverable) {
                                            count += 1
                                            return (
                                                <>
                                                    <span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "flex-start",
                                                            padding: "15px",
                                                            cursor: "pointer",
                                                        }}
                                                        data-test-id="toggle-selection"
                                                        onClick={() => this.handleChangeSelectedIds(item.id)}
                                                    >
                                                        {!this.state.selectedIds.includes(item.id) ? <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.43114 25.3233C5.0098 24.2637 3.81109 22.9345 2.90345 21.4116C1.99581 19.8887 1.39701 18.202 1.14124 16.4477C0.885464 14.6934 0.977732 12.9059 1.41277 11.1872C1.84781 9.46859 2.6171 7.85247 3.67672 6.43114C4.73634 5.0098 6.06553 3.81109 7.58841 2.90345C9.11129 1.99581 10.798 1.39701 12.5523 1.14124C14.3066 0.885464 16.0941 0.977732 17.8128 1.41277C19.5314 1.84781 21.1475 2.6171 22.5689 3.67672C23.9902 4.73634 25.1889 6.06553 26.0965 7.58841C27.0042 9.11129 27.603 10.798 27.8588 12.5523C28.1145 14.3066 28.0223 16.0941 27.5872 17.8128C27.1522 19.5314 26.3829 21.1475 25.3233 22.5689C24.2637 23.9902 22.9345 25.1889 21.4116 26.0966C19.8887 27.0042 18.202 27.603 16.4477 27.8588C14.6934 28.1145 12.9059 28.0223 11.1872 27.5872C9.46859 27.1522 7.85247 26.3829 6.43113 25.3233L6.43114 25.3233Z" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" />
                                                            <path d="M20.5 10.5L15.1031 16.9763C14.1197 18.1563 13.628 18.7464 12.9672 18.7763C12.3063 18.8063 11.7632 18.2632 10.6771 17.1771L8.5 15" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" />
                                                        </svg> : <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="15.5" cy="15.5" r="15.0303" fill="url(#paint0_linear_5010_52859)" stroke="#FFF2E3" stroke-width="0.939394" />
                                                            <path d="M8.45508 15.5395L13.4283 20.6635L22.546 11.2695" stroke="#FFF2E3" stroke-width="1.87879" stroke-linecap="round" stroke-linejoin="round" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear_5010_52859" x1="31" y1="1.83752e-06" x2="0.08623" y2="31.0858" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#FFC29F" />
                                                                    <stop offset="0.161458" stop-color="#FFB9C7" />
                                                                    <stop offset="0.338542" stop-color="#CF8CCC" />
                                                                    <stop offset="0.505208" stop-color="#AB9EF5" />
                                                                    <stop offset="0.677083" stop-color="#6C98EE" />
                                                                    <stop offset="0.838542" stop-color="#1CBAE0" />
                                                                    <stop offset="1" stop-color="#A8E5EC" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                        }
                                                        &nbsp;
                                                        &nbsp;
                                                        <span
                                                            style={{
                                                                fontFamily: "inter",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                color: "#FFF2E3"
                                                            }}
                                                        >
                                                            {count}. {item.deliverable}
                                                        </span>
                                                    </span>
                                                    {milestone.deliverables.length !== (index + 1) && <Divider style={{ height: "1px", width: "100%", background: "#545454" }} />}
                                                </>
                                            )
                                        }
                                    })
                                }
                            </Box>
                            <Box
                                style={{
                                    paddingTop: "36px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Button
                                    data-test-id="close-modal"
                                    style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        borderRadius: "7px",
                                        padding: "10px 30px",
                                        fontFamily: "'Inter', sans-serif",
                                        textTransform: "none",
                                        cursor: "pointer",
                                        marginRight: "15px",
                                        background: "#0D0D0D",
                                        color: "#FFF2E3",
                                    } as React.CSSProperties}
                                    onClick={() => {
                                        this.setState({
                                            selectedIds: []
                                        });
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    data-test-id="approve_submission"
                                    disabled={this.state.selectedIds.length !== milestone.deliverables.length }
                                    style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        borderRadius: "7px",
                                        padding: "10px 30px",
                                        fontFamily: "'Inter', sans-serif",
                                        textTransform: "none",
                                        cursor: "pointer",
                                        background: `${this.state.selectedIds.length !== milestone.deliverables.length ? "#7D7D7D" : "rgba(255, 242, 227, 1)"}`,
                                        color: "#121212",
                                        marginRight: "10px",
                                    } as React.CSSProperties}
                                    onClick={() => {
                                        sendApprovalMilestoneApiCall(contractId, milestone.id)
                                        this.setState({
                                            selectedIds: []
                                        });
                                        handleClose();
                                    }}
                                >
                                    Approve
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
            </StyledDialog>
        );
    }
}
