import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    InputBase,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, Component } from 'react';
import { styled } from '@material-ui/styles';
import NativeSelect from '@material-ui/core/NativeSelect';

interface ExtendContractModalProps {
    open: boolean;
    handleClose: () => void;
    contractId: string;
    extendContract: (contractId: number | string, type: string, timeline: number) => void;
}

interface StateProp {
    number: number,
    weekOrMonth: string,
    error: string
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        minWidth: "700px",
        borderRadius: "10px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "400px",
            minWidth: "500px",
        },
    },
});

const InputField = styled(TextField)({
    background: "#2F2F2F",
    border: "none",
    outline: "none",
    borderRadius: "14px",
    "& .MuiNativeSelect-icon": {
        color: "re#D9D9D9",
    },
    "& .MuiOutlinedInput-input": {
        paddingLeft: "2px !important"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none !important",
        },
        "&:hover fieldset": {
            border: "none !important",
        },
        "&.Mui-focused fieldset": {
            border: "none !important",
        },
    },

    "& .MuiInputBase-root": {
        color: "rgba(255, 242, 227, 1)",
        border: "unset",
    },

    "& .MuiInputBase-input": {
        padding: "14px",
        fontWeight: "600",
        fontStyle: "italic",
        fontFamily: "Inter",
        fontSize: "16px",
        color: "rgba(255, 242, 227, 1)",
        paddingBottom: "16px",
        width: "100px"
    },

    "& .MuiInputBase-input::placeholder": {
        color: "#7D7D7D",
        fontSize: "16px",
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: "400 !important",
        opacity: 1,
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        boxShadow: "none",
    },

    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
        border: "none !important",
    },

    "& .MuiOutlinedInput-root.Mui-error fieldset": {
        border: "none !important",
    },

    "& .MuiOutlinedInput-root.Mui-disabled fieldset": {
        border: "none !important",
    },
    "& .MuiInput-underline:after": {
        border: "0px !important"
    },
    "& .MuiInput-underline:before": {
        border: "0px !important"
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },
});

const BootstrapInput = styled(InputBase)({
    borderRadius: "6px",
    position: "relative",
    backgroundColor: "transparent",
    border: "1px solid #66625C !important",
    fontSize: "16px",
    padding: "0px",
    fontFamily: "Inter",
    "& .MuiInputBase-input": {
        padding: "14px",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Inter",
        fontSize: "14px",
        color: "rgba(255, 242, 227, 1)",
        width: "75px"
    },
});

const StyledNativeSelect = styled(NativeSelect)({
    color: "#FFF2E3",
    fontSize: "14px",
    fontFamily: "Inter",
    border: "1px solid #66625C",
    borderRadius: "6px",
    backgroundColor: "transparent",
    "& .MuiNativeSelect-icon": {
        color: "#D9D9D9"
    },
});



export default class ExtendContractModal extends Component<ExtendContractModalProps, StateProp> {
    constructor(props: ExtendContractModalProps) {
        super(props);
        this.state = {
            number: 1,
            weekOrMonth: "week",
            error: ""
        };
    }

    handleValidation = (number: number, unit: string): string => {
        if (number <= 0) return "Number of Months or Weeks cannot be 0.";
        if (unit === "month" && number > 12) return "Number of months cannot be more than 12.";
        if (unit === "week" && number > 52) return "Number of weeks cannot be more than 52.";
        return "";
    };
    
    handleChangeTitle = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = +event.target.value;
        const errorMessage = this.handleValidation(newValue, this.state.weekOrMonth);
        this.setState({ number: newValue, error: errorMessage });
    };
    
    handleWeekChange = (event: React.ChangeEvent<{ value: string }>) => {
        const newUnit = event.target.value;
        const errorMessage = this.handleValidation(this.state.number, newUnit);
        this.setState({ weekOrMonth: newUnit, error: errorMessage });
    };

    render() {
        const { handleClose, open, contractId, extendContract } = this.props;
        const { number, weekOrMonth, error } = this.state;

        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} id="dialog-id">
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography
                            style={{
                                fontSize: "20px",
                                fontWeight: "600",
                                color: "#FFF2E3",
                                fontFamily: "inter",
                                padding: "30px 0px 18px 0px"
                            }}
                        >
                            Extend Contract
                        </Typography>
                    </Box>
                    <Divider style={{ height: "1px", width: "100%", background: "#494949" }} />
                    <DialogContent>
                        <Box
                            style={{
                                padding: "40px",
                                paddingBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <InputField
                                data-test-id="Add-number-title"
                                onChange={this.handleChangeTitle}
                                placeholder="Add number"
                                value={number}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <div style={{ margin: "8px" }}>
                                            <StyledNativeSelect
                                                id="demo-customized-select-native"
                                                value={weekOrMonth}
                                                onChange={this.handleWeekChange}
                                                input={<BootstrapInput />}
                                            >
                                                <option value="week" style={{ background: "#222222" }}>Weeks</option>
                                                <option value="month" style={{ background: "#222222" }}>Months</option>
                                            </StyledNativeSelect>
                                        </div>
                                    ),
                                }}
                            />
                        </Box>
                        {error && (
                            <Typography style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
                                {error}
                            </Typography>
                        )}
                    </DialogContent>
                    <Divider style={{ height: "1px", width: "100%", background: "#494949" }} />
                    <Box style={{ padding: "40px 136px 40px 136px" }}>
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button
                                data-test-id="close-modal"
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    borderRadius: "7px",
                                    padding: "10px 30px",
                                    fontFamily: "'Inter', sans-serif",
                                    textTransform: "none",
                                    cursor: "pointer",
                                    marginRight: "15px",
                                    background: "#0D0D0D",
                                    color: "#FFF2E3",
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-test-id="approve_submission"
                                disabled={!!error}
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    borderRadius: "7px",
                                    padding: "10px 30px",
                                    fontFamily: "'Inter', sans-serif",
                                    textTransform: "none",
                                    cursor: !!error ? "not-allowed" : "pointer",
                                    background: !!error ? "#7D7D7D" : "rgba(255, 242, 227, 1)",
                                    color: "#121212",
                                    marginRight: "10px",
                                }}
                                onClick={() => {
                                    extendContract(contractId, weekOrMonth, number);
                                    handleClose();
                                }}
                            >
                                Extend
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
