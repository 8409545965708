import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, Component } from 'react';
import { styled } from '@material-ui/styles';

interface AddResourceLinkModalProps {
    open: boolean;
    handleClose: () => void;
    contractId: string;
    addResourceLinksApiCall: (id: number | string, items: { title: string, link: string }[]) => void;
}

interface StateProp {
    resources: {
        id: number,
        title: string,
        link: string
    }[]
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        minWidth: "700px",
        borderRadius: "10px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "400px",
            minWidth: "500px",
        },
    },
});

const InputField = styled(TextField)(() => ({
    background: "#2F2F2F",
    border: "none",
    outline: "none",
    borderRadius: "14px",
    "& .MuiOutlinedInput-input": {
        paddingLeft: "2px !important"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none !important",
        },
        "&:hover fieldset": {
            border: "none !important",
        },
        "&.Mui-focused fieldset": {
            border: "none !important",
        },
    },

    "& .MuiInputBase-root": {
        color: "rgba(255, 242, 227, 1)",
        border: "unset",
    },

    "& .MuiInputBase-input": {
        padding: "14px",
        fontWeight: "600",
        fontStyle: "italic",
        fontFamily: "Inter",
        fontSize: "16px",
        color: "rgba(255, 242, 227, 1)",
        paddingBottom: "16px"
    },

    "& .MuiInputBase-input::placeholder": {
        color: "#7D7D7D",
        fontSize: "16px",
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: "400 !important",
        opacity: 1,
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        boxShadow: "none",
    },

    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
        border: "none !important",
    },

    "& .MuiOutlinedInput-root.Mui-error fieldset": {
        border: "none !important",
    },

    "& .MuiOutlinedInput-root.Mui-disabled fieldset": {
        border: "none !important",
    },
    "& .MuiInput-underline:after": {
        border: "0px !important"
    },
    "& .MuiInput-underline:before": {
        border: "0px !important"
    }
}));

const StyledTextField = styled(TextField)(() => ({
    background: "transparent",
    borderRadius: "0px", // No border radius to avoid corners
    outline: "none",

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none", // Remove all sides border
        },
        "&:hover fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },

    "& .MuiInputBase-input": {
        color: "#528ADF",
        fontSize: "16px",
        borderBottom: "1px solid #323232", // Keep only bottom border
        paddingBottom: "4px", // Adjust padding to align properly
    },

    "& .MuiInputBase-input::placeholder": {
        color: "#7D7D7D",
        fontSize: "16px",
        fontStyle: "italic",
        fontWeight: "400",
        opacity: 1,
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none", // Remove outline when focused
    },
}));


export default class AddResourceLinkModal extends Component<AddResourceLinkModalProps, StateProp> {
    constructor(props: AddResourceLinkModalProps) {
        super(props);
        this.state = {
            resources: [{
                id: 0,
                title: "",
                link: ""
            }]
        };
    }

    addResource = () => {
        this.setState({
            resources: [...this.state.resources, {
                id: this.state.resources.length + 1,
                title: "",
                link: ""
            }]
        })
    }

    handleChangeTitle = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newResources = [...this.state.resources];
        newResources[index].title = event.target.value;
        this.setState({ resources: newResources });
    };

    handleChangeLink = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newResources = [...this.state.resources];
        newResources[index].link = event.target.value;
        this.setState({ resources: newResources });
    };

    hasValidResource = (): boolean => {
        return this.state.resources.some(resource => resource.title.trim() !== "" && resource.link.trim() !== "");
    };



    render() {
        const { handleClose, open, contractId, addResourceLinksApiCall } = this.props;

        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} id="dialog-id">
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography
                            style={{
                                fontSize: "20px",
                                fontWeight: "600",
                                color: "#FFF2E3",
                                fontFamily: "inter",
                                padding: "30px 0px 18px 0px"
                            }}
                        >
                            Add resource links
                        </Typography>
                    </Box>
                    <Divider style={{ height: "1px", width: "100%", background: "#494949" }} />
                    <DialogContent>
                        <Box
                            style={{
                                padding: "40px",
                                paddingBottom: "10px"
                            }}
                        >
                            {
                                this.state.resources.map((item, index) => <Box key={item.id} style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingBottom: "30px"
                                }}>
                                    <InputField
                                        data-test-id="link-title"
                                        onChange={(event) => this.handleChangeTitle(index, event)}
                                        placeholder="Resource name"
                                        style={{
                                            flex: 1
                                        }}
                                        value={this.state.resources[index].title}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <StyledTextField
                                        placeholder="Add link"
                                        data-test-id="link-field"
                                        variant="outlined"
                                        style={{
                                            flex: 2
                                        }}
                                        onChange={(event) => this.handleChangeLink(index, event)}
                                        value={this.state.resources[index].link}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    {
                                        (this.state.resources.length - 1 === index) ? <IconButton
                                            onClick={this.addResource}
                                            style={{
                                                paddingBottom: "0px"
                                            }}>
                                            <svg height="31" viewBox="0 0 31 31" fill="none" width="31" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="15.5" cy="15.5" r="15.375" fill="#545454" />
                                                <path stroke-linecap="round" d="M15.5 8.66797L15.5 22.3346" stroke="#FFF2E3" stroke-width="1.5" />
                                                <path stroke-linecap="round" d="M22.334 15.5L8.66732 15.5" stroke="#FFF2E3" stroke-width="1.5" />
                                            </svg>
                                        </IconButton> : <IconButton
                                            style={{
                                                padding: "25px"
                                            }}>
                                        </IconButton>
                                    }
                                </Box>)
                            }
                        </Box>
                    </DialogContent>
                    <Divider style={{ height: "1px", width: "100%", background: "#494949" }} />
                    <Box
                        style={{
                            padding: "40px 136px 40px 136px"
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <Button
                                data-test-id="close-modal"
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    borderRadius: "7px",
                                    padding: "10px 30px",
                                    fontFamily: "'Inter', sans-serif",
                                    textTransform: "none",
                                    cursor: "pointer",
                                    marginRight: "15px",
                                    background: "#0D0D0D",
                                    color: "#FFF2E3",
                                } as React.CSSProperties}
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-test-id="approve_submission"
                                disabled={!this.hasValidResource()}
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    borderRadius: "7px",
                                    padding: "10px 30px",
                                    fontFamily: "'Inter', sans-serif",
                                    textTransform: "none",
                                    cursor: "pointer",
                                    background: `${!this.hasValidResource() ? "#7D7D7D" : "rgba(255, 242, 227, 1)"}`,
                                    color: "#121212",
                                    marginRight: "10px",
                                } as React.CSSProperties}
                                onClick={() => {
                                    addResourceLinksApiCall(contractId, this.state.resources.filter(resource =>
                                        resource.title.trim() !== "" && resource.link.trim() !== ""
                                    ))
                                    handleClose();
                                }}
                            >
                                Done
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
