import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";

interface VPA {
  username: string;
  handle: string;
  address: string;
}

interface BankAccount {
  ifsc: string;
  bank_name: string;
  name: string;
  notes: any[];
  account_number: string;
}

interface FundAccount {
  id: string;
  entity: string;
  contact_id: string;
  account_type: 'vpa' | 'bank_account';
  batch_id: string | null;
  vpa?: VPA;
  bank_account?: BankAccount;
  active: boolean;
  created_at: number;
}

export interface FundAccountsResponse {
  fund_accounts: {
    entity: string;
    count: number;
    items: FundAccount[];
  };
  wallet_balance: number;
  message: string;
}

interface ErrorDetails {
  source: string | null;
  reason: string | null;
  description: string | null;
  code: string;
  step: string;
  metadata: Record<string, any>;
}

interface StatusDetails {
  reason: string | null;
  description: string | null;
  source: string | null;
}

interface Payout {
  id: string;
  entity: string;
  fund_account_id: string;
  amount: number;
  currency: string;
  notes: any[];
  fees: number;
  tax: number;
  status: string;
  purpose: string;
  utr: string | null;
  mode: string;
  reference_id: string;
  narration: string;
  batch_id: string | null;
  failure_reason: string | null;
  created_at: number;
  fee_type: string | null;
  status_details: StatusDetails;
  merchant_id: string;
  status_details_id: string | null;
  error: ErrorDetails;
}

interface WalletTransaction {
  id: number;
  account_id: number;
  total_amount: number;
  gst_amount: number | null;
  processing_fee_amount: number;
  wallet_amount: number;
  razorpay_order_id: string | null;
  payment_id: string | null;
  payment_status: string;
  description: string;
  wallet_type: string;
  payment_type: string;
  payment_method: string;
  error_description: string | null;
  error_reason: string | null;
  created_at: string;
  updated_at: string;
}

interface PayoutResponse {
  payout: Payout;
  wallet_refund: WalletTransaction;
  wallet_withdrwal_fee: WalletTransaction;
  message: string;
}

interface RazorpayPayOutError {
  error: {
    code: string;
    description: string;
    source: string;
    step: string | null;
    reason: string;
    metadata: Record<string, any>;
  };
}

interface TaxConfiguration {
  id: number;
  title: string;
  tax: number;
  created_at: string; 
  updated_at: string;
}

interface TaxConfigurationsResponse {
  message: string;
  tax_configuration: TaxConfiguration[];
}

interface RazorpayOrderAttributes {
  amount: number;
  amount_due: number;
  amount_paid: number;
  attempts: number;
  created_at: number;
  currency: string;
  entity: string;
  id: string;
  notes: string[];
  offer_id: string | null;
  receipt: string;
  status: string;
}

interface RazorpayOrder {
  attributes: RazorpayOrderAttributes;
}

interface Wallet {
  id: number;
  account_id: number;
  total_amount: number;
  gst_amount: number;
  processing_fee_amount: number;
  wallet_amount: number;
  razorpay_order_id: string;
  payment_id: string | null;
  payment_status: string;
  description: string;
  wallet_type: string;
  payment_type: string;
  payment_method: string;
  error_description: string | null;
  error_reason: string | null;
  created_at: string;
  updated_at: string;
}

interface ApiResponse {
  razorpay_order: RazorpayOrder;
  wallet: Wallet;
  message: string;
}

interface ErrorResponse {
  error: string;
}

export interface RazorpayPaymentSuccessResponse {
  razorpay_order_id: string;
  razorpay_payment_id: string;
  razorpay_signature: string;
}

export interface ErrorMetadata {
  order_id: string;
  payment_id: string;
}

export interface RazorpayPaymentFailResponse {
  error: {
    code: string;
    description: string;
    metadata: ErrorMetadata;
    reason: string;
    source: string;
    step: string;
  };
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  amountStatus:(amount:boolean)=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean
  getPaid: boolean
  paymentPopUp: boolean
  amount: string
  paymentStatus: string
  statusText1: string
  statusText2: string
  statusColor: string
  amountStatus:boolean
  userRole:string
  configGst:number
  configAddTax:number
  configWithdrawTax:number
  fundAccount:FundAccountsResponse
  getPaidLoader1:boolean
  addMoneyLoader:boolean
  selectedCheckbox:string
  getPaidLoader:boolean
  isButtonDisable:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RazorPayController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addMountApiCallId :string = ""
  successApiCallId:string=""
  failApiCallId:string=""
  taxConfigurationApiCallId:string=""
  fetchFundAccountApiCallId:string=""
  payoutApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      addMoneyLoader:false,
      getPaidLoader1:false,
      open: false,
      getPaid:false,
      paymentPopUp: false,
      amount: "",
      paymentStatus: "",
      statusText1: "",
      statusText2: "",
      statusColor: "",
      amountStatus:false,
      userRole:"",
      configGst:0,
      configAddTax:0,
      configWithdrawTax:0,
      fundAccount:{
        fund_accounts: {
          entity: '',
          count: 0,
          items: []
        },
        wallet_balance:0,
        message: ''
      }  ,
      selectedCheckbox:"",
      getPaidLoader:false,
      isButtonDisable:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId1 === this.addMountApiCallId) {
      this.handleAddMoneyApiCall(responseJson1)
    }
    if (apiRequestCallId1 === this.taxConfigurationApiCallId){
      if(!responseJson1.error){
        this.handleTaxConfigurationApiCall(responseJson1)
      }
    }
    if(apiRequestCallId1 === this.fetchFundAccountApiCallId){
      if(!responseJson1.error){
        this.handleFetchFundAccountApiCall(responseJson1)
      }
      else{
        this.handleAfterPayment("cancel", "Please add withdrawal method", "", "red",false);
        this.setState({getPaidLoader1:false})
      }
    }
    if(apiRequestCallId1 === this.payoutApiCallId){
        this.handleAfterPayout(responseJson1)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    if (!(window as any).Razorpay) {
      this.loadRazorpay();
    }
    let role = await getStorageData("userInfo",true);
    this.setState({userRole:role.data.attributes.role_name})
    this.handleTaxConfiguration()
  }

  loadRazorpay = () => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.body.appendChild(script);
  };

  handlePopupClose = () => {
    this.setState({ open: !this.state.open, amount: "" })
  }

  openGetPaidPopup= ()=>{
    if(this.state.fundAccount.wallet_balance === 0){
      this.handleAfterPayment("fail", "Please add funds to your wallet to proceed", "", "red",false);
    }else if(this.state.fundAccount.fund_accounts.items?.every((account: any) => !account.active)){
      this.setState({getPaidLoader1:false})
      this.handleAfterPayment("cancel", "Please add withdrawal method", "", "red",false);
    }
    else{
      this.setState({ getPaid: !this.state.getPaid, selectedCheckbox: "" })
    }
  }

  handleGetPaidClose = () => {
    if(this.state.getPaid === false){
      this.setState({getPaidLoader1:true})
      this.handleFetchFuncAccounts()
      
    }else{
      this.setState({ getPaid: !this.state.getPaid, selectedCheckbox: "" })
    }
  }

  handlePaymentPopupClose = () => {
    this.setState({ paymentPopUp: !this.state.paymentPopUp })
  }

  isNumericVal = (data: string) => data === "" || /^[0-9\b]+$/.test(data);

  handleAmount = (amount: string) => {
    if (this.isNumericVal(amount) && (/^\d{0,7}$/.test(amount))) {
      this.setState({ amount: amount })
    }
  }

  handleAddMoneyApiCall = (responseJson1:ErrorResponse | ApiResponse) =>{
    if ('error' in responseJson1) {
      this.setState({ open: false,addMoneyLoader:false });
      this.handleAfterPayment("cancel", "Your payment was not initiate", "Please try again", "red",false);
    } else {
      this.setState({ open: false,addMoneyLoader:false });
      this.handlePayment(responseJson1);
    }
  }
  handleAfterPayout = (responseJson1:PayoutResponse | RazorpayPayOutError)=>{
    this.setState({getPaidLoader:false,isButtonDisable:false,getPaid: false, selectedCheckbox: ""})
    if ('error' in responseJson1) {
      this.handleAfterPayment("cancel", "Your payment was not initiate", "Please try again", "red",false);
    } else {
      this.handleAfterPayment("success", "Your payment is currently in progress", "Please wait a moment", "green",true);
    }
  }

  handleTaxConfigurationApiCall = (responseJson1: TaxConfigurationsResponse) => {
    responseJson1.tax_configuration.forEach((item) => {
      if (item.id === 3) this.setState({ configGst: item.tax });
      if (item.id === 1) this.setState({ configAddTax: item.tax });
      if (item.id === 2) this.setState({ configWithdrawTax: item.tax });
    });
  };  
  handleFetchFundAccountApiCall = (responseJson1:FundAccountsResponse)=>{
    this.setState({fundAccount:responseJson1},()=>{
      this.openGetPaidPopup()
      this.setState({getPaidLoader1:false})
    })
  }
  handleCheckboxChange = (id:string)=>{
    this.setState({selectedCheckbox:id})
  }
  maskUPI(upiId: string | undefined) {
    if (upiId) {
      const parts = upiId.split('@');
      if (parts.length === 2) {
        return `****@${parts[1]}`;
      }
      return upiId;
    }
  }
  handleAddMoneyCall= async ()=> {
    this.setState({addMoneyLoader:true})
    const tokens = await getStorageData("userInfo",true);   
    const token = tokens.meta.token
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
        "input_price": +this.state.amount,
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAmountApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.confirmPaymentMethod
    );
    this.addMountApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSuccessCall= async (response:RazorpayPaymentSuccessResponse)=> {
    const tokens = await getStorageData("userInfo",true); 
    const token = tokens.meta.token  
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      razorpay_order_id: response.razorpay_order_id,
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_signature: response.razorpay_signature
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.successPaymentEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.confirmPaymentMethod
    );
    this.successApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleFailCall= async (response:RazorpayPaymentFailResponse)=> {
    const tokens = await getStorageData("userInfo",true);
    const token = tokens.meta.token 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      razorpay_order_id: response.error.metadata.order_id,
      razorpay_payment_id: response.error.metadata.payment_id
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.failPaymentEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.confirmPaymentMethod
    );
    this.failApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleTaxConfiguration= async ()=> {
    const tokens = await getStorageData("userInfo",true);   
    const token = tokens.meta.token
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taxConfigurationEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.confirmPaymentGetMethod
    );
    this.taxConfigurationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  
  handleFetchFuncAccounts= async ()=> {
    const tokens = await getStorageData("userInfo",true);   
    const token = tokens.meta.token
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchFundAccountEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.confirmPaymentGetMethod
    );
    this.fetchFundAccountApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handlePayout = async ()=> {
    this.setState({getPaidLoader:true,isButtonDisable:true})
    const result = this.state.fundAccount.fund_accounts.items.find(item => item.id === this.state.selectedCheckbox);
    const tokens = await getStorageData("userInfo",true);   
    const token = tokens.meta.token
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      "fund_account_id": result?.id,
      "mode": result?.account_type === "bank_account" ? "NEFT":"UPI"
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.payoutApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.confirmPaymentMethod
    );
    this.payoutApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  calculateAmount = (amount: number, percentage: number, totalAmount: boolean): number => {
    const result = totalAmount 
      ? amount + (amount * percentage / 100) 
      : (amount * percentage / 100);
  
    return parseFloat(result.toFixed(2));
  };

  handleAfterPayment = (status: string, param1: string, param2: string,bgColor:string,amountStatus:boolean) => {
    this.setState({
      paymentPopUp:true,
      paymentStatus:status,
      statusText1:param1,
      statusText2:param2,
      statusColor:bgColor,
      amountStatus:amountStatus
    })
    if(amountStatus){
      this.props.amountStatus(amountStatus)
    }
  };

  handlePayment = (responseJson1:ApiResponse) => {
    const options = {
      key: configJSON.apiKey,
      amount: responseJson1.razorpay_order.attributes.amount,
      currency: responseJson1.razorpay_order.attributes.currency,
      order_id: responseJson1.razorpay_order.attributes.id,
      handler: (response:RazorpayPaymentSuccessResponse) => {
        this.handleAfterPayment("success", "Your payment was successful", "Thank you for your payment", "green",true);
        this.handleSuccessCall(response)
      },
      modal: {
        ondismiss: () => {
          this.handleAfterPayment("cancel", "Your payment was canceled", "Please try again", "red",false);
        }
      }
    };
    const razorpay = new (window as any).Razorpay(options);
    razorpay.on('payment.failed', (response: RazorpayPaymentFailResponse) => {
      this.handleFailCall(response)
      this.handleAfterPayment("fail", "Your payment was failed", "Please try again", "red",false);
    });
    razorpay.open();
  };
  // Customizable Area End
}
