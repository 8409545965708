// Customizable Area Start
import React from "react";
import { insta, linkdin, dribble, share, edit, profileImg, rating, expertise, rightArrow,userLogo, closeIcon,addImgeIcon} from "./assets"; 
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import "./styles.css";
import ProfileCard from "../../../components/src/ProfileCard.web"
import UserProfileBasicController, { Props,ProfileDetails } from "./UserProfileBasicController";
import EditProfile from "./editProfile/EditProfile.web"
import { Avatar, Box, Button, Grid, Modal, Typography } from "@material-ui/core";
import PortfolioManagement from "../../portfoliomanagement/src/PortfolioManagement.web"
import OpportunityClient from "../../joblisting/src/OpportunityClients/OpportunityClient.web";
import ToastMSG from '../../../components/src/ToastMSG.web';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ShareDialog from "../../landingpage/src/components/ShareDialog";
import UserActivity from "../../../components/src/UserActivity.web";
let baseURL = require('../../../framework/src/config');
interface Skill {
    id: number;
    name: string;
    select: boolean;
  }

const logoBox = (profile_details:ProfileDetails)=>{ 
    return (
    <div className="social-media-container">
        {profile_details.dribble ? <a target="_blank" href={profile_details.dribble}><img src={dribble} alt="go to dribble" /></a> : ""}
        {profile_details.instagram ? <a target="_blank" href={profile_details.instagram}><img src={insta} alt="go to instagram" /></a> : ""}
        {profile_details.linkedin ?  <a target="_blank" href={profile_details.linkedin}><img src={linkdin} alt="go to linkdin" /></a> : ""}
    </div>)
}

class UserProfile extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }
    imgProfile =() =>{
        return (
            <div className="profile-main-image">
               
                {this.state.profileImageData.profile_details.photo === null &&  (
                  this.state.profileImageData.profile_details.background_color !== null ? (
                    <div  onClick={this.handleOpenProfile} style={{...webStyle.profileImageBox,background:`${this.state.profileImageData.profile_details.background_color}`}}  > </div>
                  )
                    : (
                    
                    <img
                    className="highlight-username-profile"
                    style={{...webStyle.profileImageBox }}
                    src={userLogo}
                    onClick={this.handleOpenProfile}
                  />
                    )

                )}
                
                {this.state.profileImageData.profile_details?.photo && (
                 <img
                              onClick={this.handleOpenProfile}
                            src={this.imageProfile()}
                            style={webStyle.photoStyle}
                          
                            alt="profile icon"
                            data-test-id="profilebtn"
                            />          
                )}
            </div>
        )
    }

    imgProfileFollow =(profilePic:string | null ,backgroundColor:string | null) =>{
      if(profilePic){
        return (
        <div  style={webStyle.Imgprofiles}>

          <img style={webStyle.photoStyleFollow} src={this.baseUrlStirng + profilePic}/>
        </div>)
    } else if(backgroundColor){

        return(
          <div style={webStyle.Imgprofiles}>

            <Box style={{...webStyle.profileImageBoxesFollow,backgroundColor:`${backgroundColor}`}}></Box>
          </div>
        )
    } else {
        return(
          <div style={webStyle.Imgprofiles}>
              <img style={webStyle.photoStyleFollow}  src={userLogo}/>
          </div>
        ) 
    }
   }

   FollowModalDetails =() =>{
    return (
      <>
      {this.state.Following?.length >0 ? (
                this.state.Following.map((data:any,index:number)=>{
                 return(
                  <Box key={index} style={{display:'flex',marginTop:'20px',alignItems:'center',justifyContent:'space-between'}}>
                  <Box className="FollowImgUser">                    
                  {this.imgProfileFollow(data.attributes.account_details?.profile_photo,data.attributes.account_details?.profile_backgroud_color)}
                  <Typography style={webStyle.Username}>{data.attributes.account_details.profile_user_name}</Typography>
                  </Box>
                  <Box>
                  
                    {data.attributes.follow_status ==='following' ? (
                      <>
                    <Typography className="active-typography" style={webStyle.activeFollowing}  onClick={()=>this.FollowStatus(data.attributes,index)} >Following</Typography>
                    </>): (
                      <>
                      <Typography onClick={()=>this.FollowStatus(data.attributes,index)} data-testId='FollowingBtns'  style={{...webStyle.activeFollowing,color:'#FFF2E3'}}>Follow</Typography>
                      </>
                    )}

                  </Box>

                </Box>
                 )
                })
              ):(
                <></>
              )
              
              }

      </>
    )
   }

  shareProfile = () => {
    const attribute = {
      "id": this.state.profileImageData.profile_details.id,
      "city": this.state.profileImageData.profile_details.city,
      "account_id": this.state.profileImageData.profile_details.account_id,
      "photo": this.state.profileImageData.profile_details.photo,
      "background_color": this.state.profileImageData.profile_details.background_color,
      "skills": this.state.profileImageData.profile_details.skills,
      "min_per_hour": this.state.profileImageData.profile_details.min_per_hour,
      "max_per_hour": this.state.profileImageData.profile_details.max_per_hour,
      "profile_cover_image": this.state.profileImageData.profile_details.profile_cover_image,
      "ratings": this.state.profileImageData.profile_details?.feedback_data?.ratings,
      "views": this.state.profileImageData.profile_details.views ?? 0,
      "likes": this.state.profileImageData.profile_details.likes ?? 0,
      "user_name": this.state.profileImageData.profile_details.user_name,
      "average_rate_per_hour": this.state.profileImageData.profile_details.average_rate_per_hour ?? 0,
    }

    return (
      <ProfileCard
        data-test-id="profilecard"
        completedSkills={[]}
        handleNavigation={() => { }}
        data={attribute}
        handleOpen={() => { }}
        isOpen={false}
        index={0}
      />
    )
  }

    EditProfileModal = () =>{
        return ( 
            <>
            
             {(this.state.profileImageData.profile_details.photo || this.state.selectedFile)  && (
                <Box  onClick={() => {this.openPictureUpload()}}
                data-test-id="profileUpateImage1" >
                <img
                src={this.imgproUpdate()}
                alt="profile icon"
                style={webStyle.imgBox2} 
                />   
                 
                </Box>        
                )}
             {(this.state.profileImageData.profile_details.photo == null && this.state.selectedFile == null) && (

             this.state.profileImageData.profile_details.background_color === null ? (
                 <img
                    className="highlight-username-profile"
                    style={{...webStyle.imgBox2 }}
                    src={userLogo}
                    onClick={this.openPictureUpload}
                  />
              ): (
                <div  onClick={this.openPictureUpload} style={{...webStyle.imgBox2,background:`${this.state.profileImageData.profile_details.background_color}`}}  > </div>
              )
              )}
            
            </>
           
        )
    }
    forClientDetails = () =>{
        return(
            <div>
                <Box className="forClientDetails">
                    <Box className="Keysforthelist">Company Name</Box>
                    <Box style={{fontSize:'16px',textAlign:'end',fontWeight:500,color:'#757171'}} >{this.CompanyName()}</Box>
                </Box>
                <Box className="forClientDetails forClientDetails-designation">
                    <Box className="Keysforthelist" >Designation</Box>
                    <Box className="CompanyDetails" >{this.showDesignation()}</Box>
                </Box>
                <Box className="forClientDetails">
                    <Box className="Keysforthelist">Industry</Box>
                    <Box className="CompanyDetails" >{this.showIndustry()}</Box>
                </Box>
            </div>
        )
    }
    userInfoContainer = () =>{
        return(
            <div className="info-container">
                {this.state.userRole === 'designer' ? 
                (
                    <>
                        <div className="part-data border-right">
                            <div className="info-data" style={webStyle.infoData}>
                                <div className="label-text" style={webStyle.lableText} >Profile Views</div>
                                
                                <div className="value-text" style={webStyle.lableText}>{this.profileView()}</div>
                            </div>
                        </div>
                        <div className="part-data border-right">
                            <div className="info-data" style={webStyle.infoData}>
                                <div className="label-text" style={webStyle.lableText}>Likes</div>
                                <div className="value-text" style={webStyle.lableText}>{this.showLike()}</div>
                            </div>
                        </div>
                        <div className="part-data border-right p-0">
                            <div className="info-data" style={webStyle.infoData}>
                                <div className="label-text" style={webStyle.lableText}>Avg paid rate</div>
                                <div className="value-text" style={webStyle.lableText}>{this.avgRate()+" "+"pr/hr"}</div>
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <div className="part-data-client border-right">
                            <div style={webStyle.infoData} className="info-data" >
                                <div className="label-text" style={webStyle.lableText}>Jobs Posted</div>
                                
                                <div className="value-text" style={webStyle.lableText}>{this.handlejobs()}</div>
                            </div>
                        </div>
                )}
                
                <div className={`${this.state.userRole === 'designer' ? 'part-data ':'part-data-client'} left-text`}>
                    <div className="info-data"  style={webStyle.infoData}>
                        <div className="label-text" style={webStyle.lableText}>Ratings</div>
                        <div className="value-text" style={webStyle.lableText}>
                            <div className="rating-text dummy-class">{this.state.profileImageData.profile_details.feedback_data?.ratings}<img src={rating} alt="rating"/></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    handleLanguages = () => {
      return (
        <div className="group-container d-flex flex-column w-100 h-auto p-0">
                    <div className="d-flex flex-column g-16 p-16">
                      <div className="text-elm heading-data">Languages</div>
                      <div className="Languages-flex d-flex w-100 g-8 row-gap-12">
                        {this.state.profileImageData.profile_details
                          .languages &&
                        this.state.profileImageData.profile_details.languages
                          .length > 0 ? (
                          this.state.profileImageData.profile_details.languages.map(
                            (language:any, i:any) => (
                              <div key={i} className="skill-chip">
                                {language}
                              </div>
                            )
                          )
                        ) : (
                          <div style={webStyle.defaultText}>
                            No Languages added
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
      )
    } 

    handleUserFollowers =()=>{
      return (
        <>
        
        {this.state.userRole === "designer" && (
          <>
            <div className="group-container p-0">
              <div
                className="rate-container"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="text-elm heading-data">Rate</div>
                <div className="rate-chip">
                  {this.state.profileImageData.profile_details
                    ?.min_per_hour &&
                  this.state.profileImageData.profile_details
                    ?.max_per_hour ? (
                    `${this.state.profileImageData.profile_details.min_per_hour} - ${this.state.profileImageData.profile_details.max_per_hour} ₹/hr`
                  ) : (
                    `600 - 2000 ₹/hr`
                  )}
                </div>
              </div>
            </div>
            <div className="group-container d-flex flex-column w-100 h-auto p-0">
              <div className="d-flex flex-column g-16 p-16 pb-0">
                <div className="text-elm heading-data expertise-heading d-flex">
                  <img src={expertise} alt="Expertise" />
                  Expertise
                </div>
                <div style={{flexWrap:'wrap'}} className="d-flex  w-100 g-10 py-11">
                  {this.state.profileImageData.profile_details
                    .expertise_category &&
                  this.state.profileImageData.profile_details
                    .expertise_category.length > 0 ? (
                    this.state.profileImageData.profile_details.expertise_category.map(
                      (expertise:any, ind:any) => (
                        <div key={ind} className="expertise-data">
                          {expertise}
                        </div>
                      )
                    )
                  ) : (
                    <div style={webStyle.defaultText}>Add info</div>
                  )}
                </div>
                <hr className="p-0 line-elm" />
              </div>
              <div className="d-flex flex-column g-16 p-16 pt-0">
                <div className="text-elm heading-data">Skills</div>
                <div
                  className=""
                  style={{
                    ...webStyle.skillContainer,
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.profileImageData.profile_details
                    .skills &&
                  this.state.profileImageData.profile_details.skills
                    .length > 0 ? (
                    this.state.profileImageData.profile_details.skills.map(
                      (skill: Skill, index:any) => (
                        <div
                          key={index}
                          className="skill-chip"
                          style={webStyle.skillChip}
                        >
                          {skill.name}
                        </div>
                      )
                    )
                  ) : (
                    <div style={webStyle.defaultText}>Add info</div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) }
        </>
      )
    }

  userActivity = () => {
    return (
      <>
        <div
          className="group-container d-flex flex-column w-100 h-auto p-0"
          style={{ width: '100%', backgroundColor: "#222222" }}>
          <div
            className="d-flex flex-column g-16 p-16">
            <div
              className="education-data history-data" style={{ justifyContent: "normal", height: '40px', gap: "10px" }}>
              <button className="view-details" data-test-id="useractivity" style={{ backgroundColor: "#171717" }} onClick={this.handleManageViewActivityClose}>
                <img
                  src={rightArrow} alt="view history" style={{ rotate: '0deg' }} />
              </button>
              <div className="text-elm heading-data text-secondary normal-weight">User’s Activity</div>
            </div>
          </div>
        </div>
        <div>
          <UserActivity navigation={this.props.navigation} id="" />
        </div>
      </>
    )
  }

  editProject = () => {
    return (
      this.state.showEditProfile && (
        <EditProfile
          {...this.props}
          handleCloseEditProfile={this.handleCloseEditProfile}
        />
      )
    )
  }

  activeOpportunityData = () => {
    return (
      this.state.userRole === 'client' && (
        <div className="ActiveOpportunity">
          <Box className="ActiveOpportunity-box">
            <Box className="ActiveOpportunity-content">
              <Typography className="ActiveOpportunity-content">Active Opportunities</Typography>
            </Box>
            <Box >
              <Typography className="ActiveOpportunity-content" >{this.activeOpportunity()}</Typography>
            </Box>
          </Box>
        </div>
      )
    )
  }

  workExperience = () => {
    return (
      this.state.profileImageData.profile_details.professional_experience.latest_record.length > 0 ? (
        this.state.profileImageData.profile_details.professional_experience.latest_record.map(
          (workexps: any, indexs: any) => {
            return (
              <Box key={indexs}>
                <div className="flex-header " style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                  <div style={{ maxWidth: "42%", overflow: "hidden", textAlign: "start", }}>
                    <Typography
                    data-test-id="company-name-wr-exp"
                     style={{
                      fontFamily: "inter",
                      fontWeight: "600",
                      fontSize: "16px",
                      color:"#757171",
                      wordBreak: "break-word",
                    }}>
                      {workexps.company_name}
                    </Typography>
                  </div>
                  <div className="experience-right-text" >
                    <span style={{fontFamily: "inter", fontSize: "14px", fontWeight: 500}}>
                      {workexps.starting_from_year} - {workexps.ending_in_year || "Present"}
                    </span>
                    <span style={{ padding: "0px 5px" }}>
                      &bull;
                    </span>
                    <span style={{fontFamily: "inter", fontWeight: 500, fontSize: "14px",}}>
                      {workexps.calculate_years}

                    </span>
                  </div>
                </div>
                <div style={{ padding: "16px 0px" }} className="experience-text ">
                  {workexps.position}
                </div>
                <hr className="p-0 line-elm" />
              </Box>
            );
          }
        )
      ) : (
        <div style={webStyle.defaultText}>
          {" "}
          No Work Experience added

        </div>
      )
    )
  }

  education = () => {
    return (
      this.state.profileImageData.educations[0] !== null &&
        this.state.profileImageData.educations.length > 0 ? (
        this.state.profileImageData.educations.map(
          (education: any, index: any) => (
            <React.Fragment key={index}>
              {education !== null && (
                <div className="education-data">
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Inter",
                      lineHeight: "19.36px",
                      width: "30%"
                    }}
                  >
                    {education.description}
                  </div>

                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Inter",
                      lineHeight: "16.94px",
                      color: "#666666",
                      width: "70%",
                      textAlign: "end",
                    }}
                  >
                    {education.qualification}{" "}
                    {this.formatYears(
                      education.year_from,
                      education.year_to
                    )}{" "}
                  </div>
                </div>
              )}

            </React.Fragment>
          )
        )
      ) : (
        <div style={webStyle.defaultText}>
          No Education added
        </div>
      )
    )
  }

  viewHistory = () => {
    return (
      this.state.userRole === "designer" && (
        <>
          <div data-testid="viewHighLightsHistory" className="education-data history-data p-16" onClick={this.handleNavigationToHighlights}>
            <div className="text-elm heading-data text-secondary normal-weight">
              View Highlights History
            </div>
            <button className="view-details cursorPointer">
              <img src={rightArrow} alt="view history" />
            </button>
          </div>
          <hr className="p-0 line-elm" />
        </>
      )
    )
  }

  opportunity = () => {
    return (
      this.state.userRole === 'client' ? (
        <OpportunityClient
          navigation={this.props.navigation}
          id={this.state.profileImageData.profile_details.account_id}
          isEditable={true}
        />
      ) : null
    )
  }

  loadData = () => {
    return (
      this.state.userRole === 'designer' && this.state.profileImageData.profile_details.account_id ? (
        <PortfolioManagement
          data-testid="portfolioManagement"
          handleTabChange={this.receiveChildFunction}
          Isedit={true}
          navigation={this.props.navigation}
          id={this.state.profileImageData.profile_details.account_id}
        />
      ) : this.opportunity()
    )
  }

  loadMainData = () => {
    return (
      !this.state.userActivity ? (
        this.loadData()
      ) : 
        this.userActivity()
    )
  }

    render() {       
      return (
        <Box
          style={webStyle.newResponseCon}
          data-testid="outsideMultiClick">
          <NavigationMenu {...this.props} activeTab="user" />
          <Box style={webStyle.container}>
          <div className="user-profile-container" ref={this.containerRef}>
          <div className="user-profile-header">
          </div>
          {this.editProject()}
          <div className="body-container">
            <Grid container xs={12} >
           <Grid item xs={12} sm={4} md={4} lg={4}>
            <div className="profile-section flex-column">
              <div className="profile-bg-container profile-image">
                
                <div
                className="ImgprofileResp"
                  style={{
                    maxWidth: "100%",
                    width: "32vw",
                    height: "220px",
                    objectFit: "cover",
                    backgroundImage: `url(${this.imageUrl()})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "24px 24px 0 0"
                  }}
                  
                >
                  
                </div>
                <img
    src={this.imageUrl()}
    alt="Background"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover", 
      borderRadius: "24px 24px 0 0", 
    }}
     />
                <div className="icon-elm-container">
                  <div className="header-icon-container">
                    {logoBox(this.state.profileImageData?.profile_details)}
                    <img src={share} alt="share profile" data-test-id="share-btn-id" onClick={this.handleShareDialog} style={{cursor:"pointer"}}/>
                  </div>
                  <div className="edit-profile cursorPointer">
                    <img
                      src={edit}
                      alt="edit profile"
                      onClick={this.handleOpenModal}
                      style={webStyle.editIcon}
                      data-testId="editBtn"
                    />
                  </div>
                </div>
              </div>
              <div className="user-basic-info flex-column">
                {this.imgProfile()}

                <div className="profile-data flex-column profile-image-data">
                  <div className="max-data text-elm name">
                  {this.state.profileImageData.profile_details.name}
                  </div>
                  <div className="max-data mt-12 text-elm site-data">
                    {this.websideShow()}
                  </div>
                  <div className="max-data mt-8 text-elm member-data">
                    {this.memberSince()}
                  </div>
                  <div className="max-data mt-16 text-elm location-data">
                    {" "}
                    {this.showLoaction()}
                  </div>
                </div>
              </div>
              <div className="details-section flex-column page-color" style={{width:"98%"}}>
                <div
                  className="text-elm"
                  style={{ display: "flex", width: "98%" }}
                >
                  <div
                    className={
                      this.state.userRole === "designer"
                        ? "profile-details"
                        : "profile-details-client"
                    }
                  >
                    {this.state.userRole === "designer" && "Profile Details"}
                    <img
                      src={`${edit}`}
                      alt="edit profile details"
                      onClick={this.handleOpenEditProfile}
                      className="cursorPointer"
                    />
                  </div>
                </div>
                <div
                  className=" flex-column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    alignItems:"center",
                    width:'100%'
                  }}
                >
                  <div style={{width:"88%"}} className="flex-column group-container" >
                    <Box>
                      <div className="text-elm heading-data">
                        {this.aboutRoleShow()}
                      </div>
                      <div className="text-elm about-data mt-16">
                        {this.showAboutInfo()}
                      </div>
                      {this.state.userRole === "client" &&
                        this.forClientDetails()}
                    </Box>
                  </div>
                  {this.userInfoContainer()}

                  {this.handleUserFollowers()}
                 

                  <div className="group-container d-flex w-100 h-auto p-0 mt-16">
                    <div className="flex-elm flex-column g-16">
                      <div className="text-elm heading-data">Followers</div>
                      <div data-testId="FollowersBtn" onClick={()=>this.getFollowers()} style={{cursor:"pointer"}} className="text-elm heading-data text-secondary">
                        {this.followers()}
                      </div>
                    </div>
                    <div className="flex-elm flex-column g-16">
                      <div className="text-elm heading-data">Following</div>
                      <div data-testId='FollowingBtn' onClick={()=>this.getFollowing()} style={{cursor:"pointer"}} className="text-elm heading-data text-secondary">
                        {this.showFollowing()}
                      </div>
                    </div>
                  </div>
                  {this.activeOpportunityData()}
                  <div className="group-container w-100 h-auto p-0">
                    <div className="d-flex flex-column g-40 p-16 experience-head">
                      <div className="d-flex w-100 g-16 heading-space-data text-elm heading-data">
                        <div className="text-elm heading-data g-24">
                          Ratings
                          <div className="value-text">
                            <div className="rating-text">
                              {this.state.profileImageData.profile_details.feedback_data?.ratings}
                              <img src={rating} alt="rating" />
                            </div>
                          </div>
                        </div>
                        {this.state.profileImageData.profile_details.feedback_data?.reviews_count} Reviews
                      </div>
                      <div className="d-flex w-100 g-24 flex-column scroll-hide-container" style={{maxHeight: "400px", overflowY: "scroll"}}>
                                {
                                  this.state.profileImageData.profile_details.feedback_data?.data.map((item: {
                                    id: number;
                                    review: string;
                                    review_by: string;
                                    user_designation: { name: string } | null;
                                    profile_image?: string;
                                    background_color?: string;
                                  }) => <React.Fragment key={item.id}>
                                      <div className="d-flex g-16 flex-column">
                                        <div className="rating-experience-text">
                                          {item.review}
                                        </div>
                                        <div className="experience-left-text justify-left">
                                          <Avatar src={baseURL.baseURL + item?.profile_image} style={{
                                            width: "32px",
                                            height: "32px",
                                            borderRadius: "50%",
                                            backgroundColor: item?.background_color
                                          }}>
                                            {item.review_by[0]}
                                          </Avatar>
                                          {item.review_by} {item.user_designation?.name ? "(" + item.user_designation.name + ")" : ""}
                                        </div>
                                      </div>
                                      <hr className="p-0 line-elm" />
                                    </React.Fragment>)
                                }
                      </div>
                    </div>
                  </div>
                  {this.state.userRole === "designer" && (
                    <>
                      <div className="group-container w-100 h-auto p-0">
                        <div className="d-flex flex-column g-40 p-16">
                          <div className="d-flex experience-head g-16">
                            <div className="text-elm heading-data">
                              Work Experience ({this.state.profileImageData.profile_details.professional_experience.professional_experiences_count})
                            </div>
                            {this.state.profileImageData.profile_details.professional_experience.latest_record.length > 0 && (

                              <div className="view-all" 
                                data-testid="viewAll"
                                onClick={this.handleWorkExperience}
                                style={webStyle.cursor}
                                >
                                <div className="text-data">view all</div>

                                <img
                                  src={rightArrow}
                                  alt="view more work experience"
                                />
                              </div>
                            )}
                          </div>
                          
                          <div className="d-flex w-100 g-24 flex-column">
                            <div className="d-flex g-16 flex-column">
                                  {this.workExperience()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-container d-flex flex-column w-100 h-auto p-0">
                        <div className="d-flex flex-column g-16 p-16">
                          <div className="text-elm heading-data">Education</div>
                        
                          {this.education()}
                        </div>
                      </div>
                    </>
                  )}
                  {this.handleLanguages()}
                  
                  <div className="group-container d-flex flex-column w-100 h-auto p-0">
                    <div className="d-flex flex-column g-8">
                        {this.viewHistory()}
                      
                      <div className="education-data history-data p-16" data-test-id="useractivity" onClick={this.handleManageViewActivityOpen}>
                        <div className="text-elm heading-data text-secondary normal-weight">
                          View Activity
                        </div>
                        <button className="view-details">
                          <img src={rightArrow} alt="view history" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} style={{ paddingLeft: "25px" }} className="PortfolioContainer">
              {this.loadMainData()}
           </Grid>
            </Grid>
          </div>
          <Modal
            open={this.state.openModal}
            onClose={this.handleCloseModal}
            data-testId="editbgModal"
            aria-labelledby="edit-profile-modal"
            aria-describedby="modal to edit profile"
          >
            <Box sx={webStyle.paper}>
              <Box
                style={{
                  width: "643px",
                  height: "220px",
                  margin: "0 auto",
                  textAlign: "center",
                  padding: "56px",
                }}
              >
                <Typography
                  style={webStyle.modalTitle}
                  data-testId="editbackgroundtext"
                >
                  {" "}
                  Edit Background
                </Typography>
                <Typography style={webStyle.subTitle}>
                  {" "}
                  Add a background to your profile to tell more about yourself
                </Typography>
                <Box style={{ ...webStyle.imgBox, position: "relative" }}>
                  <img
                    style={{objectFit: 'cover'}}
                    width="643px"
                    height="220px"
                    src={this.imgbackUpdate()}
                    alt="backgroundimage"
                    data-testId="backgroundimgModal"
                  />

                  <img
                    src={addImgeIcon}
                    alt="add icon"
                    height="82px"
                    width="87px"
                    data-testId="backgroundUpload"
                    onClick={this.openProfileUpload}
                    style={{ ...webStyle.addImage, position: "absolute" }}
                  />
                </Box>
                <Box style={webStyle.btnBox}>
                  <Button
                    onClick={this.handleCloseModal}
                    data-testId="editClose"
                    style={{ ...webStyle.canBtn, textTransform: "none" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ ...webStyle.saveBtn, textTransform: "none" }}
                    data-testId="submitImge"
                    onClick={this.handleSave}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={this.state.openProfile}
            onClose={this.handleCloseProfile}
            data-testId="proModal"
            aria-labelledby="edit-profile-modal"
            aria-describedby="modal to edit profile"
          >
            <Box sx={webStyle.paperProfile}>
              <Box
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  padding: "32px",
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    style={webStyle.modalTitle}
                    data-testId="editprofile"
                  >
                    Edit Profile Photo
                  </Typography>
                  <img
                    src={closeIcon}
                    alt="Close"
                    style={webStyle.closeIcon}
                    data-testId="closeProfile"
                    onClick={this.handleCloseProfile}
                  />
                </Box>
                <Box>{this.EditProfileModal()}</Box>
                <Box style={webStyle.btnBox}>
                  <Button
                    onClick={this.handleSavePhoto}
                    data-testId="submitsave"
                    style={{ ...webStyle.canBtn, textTransform: "none" }}
                  >
                    Add photo
                  </Button>
                  <Button
                    style={{ ...webStyle.saveBtn, textTransform: "none" }}
                    data-testId="deleteProfile"
                    onClick={this.deleteProfile}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
          open={this.state.followOpen}
          onClose={this.CloseFollowModal}
          data-testId="FollowModal"
          aria-labelledby="Follow-modal"
          aria-describedby="FollowModal"
          >
             <Box>
            <ToastMSG open={this.state.toastopen} data-testId="toastMessage" close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
          <Box >
          <Box >
            </Box>
          <Box sx={webStyle.paperFollow}>
            <Box>
              <Box style={{width:"100%",height:"19px"}}>
              <img src={closeIcon} alt="Close" style={webStyle.closeIconStyle} data-testId="closeFollowProfile" onClick={()=>this.CloseFollowModal()}/>
                </Box>
                  <Box style={webStyle.FollowingBox}>     
                    <Typography style={webStyle.FollowingHeading}>{this.state.ActiveFollow}{' '} ({this.state.Following?.length}) </Typography>
                      <div style={{height:"400px",overflowY:"scroll", scrollbarWidth: "none"}} id="scrollableDiv" data-test-id="followingContainerRef" onScroll={() => this.handleScroll()}>
                        {this.FollowModalDetails()}  
                      </div>
                    </Box>
                  </Box>   
                </Box>
              </Box> 
            </Box>
          </Modal>
        </div>
          </Box>
          <ShareDialog
                    data-test-id="share-dialog-id"
                    open={this.state.shareProfile}
                    onClose={this.handleShareDialog}
                    url={`${document.location.origin}/PublicUserProfile/${this.state.profileImageData?.profile_details?.account_id}/${this.state.profileImageData?.profile_details?.user_role?.role_name}`}
                    title={"Deziner's knot"}
                    description={"Designer's Profile"}
                    profile={this.shareProfile}
                />
        </Box>
      );
    }
}

export default UserProfile;

const webStyle ={
  cursor: {
    cursor: "pointer",
  },
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
  },
  activeFollowing :{ 
    fontFamily:'Inter,sans-serif',
    fontSize:'16px',
    fontWeight:600,
    borderBottom:'2px solid',
    marginRight:"20px"
  },
  FollowingHeading:{
    fontSize:'20px',
    fontWeight:600,
    fontFamily:'Inter',
    color:'#FFF2E3'
  },
  Username :{
    fontSize:'16px',
    fontWeight:'400',
    fontFamily:'Inter',
    color:'#FFF2E3',
     
  },
  FollowingBox:{
    borderRadius: '7px',
    backgroundColor: '#222222',
    padding:'20px',
    position: "relative" as "relative"
  },
  profileImageBoxesFollow :{
    height:"60px",
    width:"60px",
    borderRadius:"75px",
  } as any,
  Imgprofiles : {
    position:"relative",
  } as React.CSSProperties,
  
  FoollowCloseImg :{
    textAlign:'end'
  },
  FollowCloseIcon:{
    width: '10px',
    height: '10px',
    marginTop:'-15px',
    position:'relative',
      marginLeft: 'auto',
      cursor: 'pointer',
  }as React.CSSProperties,
  lableText:{
    padding:"10px 0px"
  },
  infoData:{
    padding:"2px 4px"
  },
  paperFollow :{
    borderRadius: '7px',
    position: 'absolute',
    width:488,
    overflowY:'scroll',
    height:497,
    scrollbarWidth:'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
    paperProfile:{
        position: 'absolute',
        width:488,
        height:497,
        backgroundColor: '#222222',
        border: '2px solid #000',
        boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
        borderRadius: '7px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
       
      },
    paper: {
        position: 'absolute',
        width:1141,
        height:513,
        backgroundColor: '#222222',
        border: '2px solid #000',
        boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
        borderRadius: '7px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
       
      },
      modalTitle: {
        fontSize: "20px",
        color: "#FFF2E3",
        fontWeight: 600,
        lineHeight:"24.2px",
        fontFamily: "Inter",   
        width:"100%"     
      },
      profileImageBox :{
        width:"100px",
        height:"100px",
        borderRadius:"50px",
      } as any,
      subTitle:{
        marginTop:"16px",
        fontSize: "16px",
        color: "#FFF2E3",
        fontWeight: 400,
        fontFamily: "Inter",
        lineHeight:"24.88px",
      },
      imgBox: {
          width: '100%',
          marginTop: '32px',
        height: 'auto',       
      },
      addImage: {        
        top: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100px', 
        height: '100px', 
      },
      canBtn:{
        fontFamily: "Inter",      
        fontSize: "16px",
        linehHeigth: "24px",
        fontWeight: 500,
        color: '#FFF2E3',
        backgroundColor:'#0D0D0D',       
        borderRadius:"7px",  
        padding:"10px 30px 10px 30px",  
        width:"max-content",     
        },
      saveBtn:{
        fontFamily: "Inter",      
        fontSize: "16px",
        fontWeight: 500,
        backgroundColor:'#FFF2E3',       
        linehHeigth: "24px",
        color: '#121212',
        borderRadius:"7px",  
        width:"max-content",       
        padding:"10px 30px 10px 30px",      
           },
      btnBox:{marginTop:"40px" , display:"flex", gap:"24px" , justifyContent:"center"},
      imgBox2:{
        marginTop: '47px',
        marginLeft:"auto",
        marginRight:"auto",
        width: '226px',
        height: '226px',
        borderRadius:"50%"
      },
      closeIcon:{
        marginLeft: 'auto',
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        cursar:"pointer"
      },
      photoStyle:{
        width:"100px",height:"100px" ,borderRadius:"50%"
      },
      photoStyleFollow:{
        width:"60px",height:"60px" ,borderRadius:"50%"
      },
      imageBackground:{
        borderRadius:"24px 24px 0px 0px",
        width:"100%",
        heigth:"220px"
      },
      editIcon:{
        cursor:"pointer"
      },
      defaultText:{
        fontFamily: "Inter",     
        fontSize: "16px",
        linehHeigth: "24px",
        fontWeight: 500,
        color: '#535353',        
      },
      skillContainer:{
        display: 'flex',
        gap: '8px',
        width: '100%',        
    },
    skillChip:{
        minWidth: 'max-content',        
    },
    closeIconStyle: { 
      position: "absolute" as "absolute", 
      left:"97%",
      zIndex:50, 
      cursor: "pointer" 
    }
}
// Customizable Area End



    
