import {
    Box, Button, Dialog, Divider, Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import { Deliverable, Milestone } from '../ContractDetailsController.web';
import { styled } from '@material-ui/styles';

interface DeleteMilestoneModalProps {
    open: boolean;
    handleClose: () => void;
    milestone: Milestone;
    deleteMilestoneApiCall: (contractId: string, milestoneId: number) => void;
    contractId: string;
    index: number
}

interface StateProp {
    selectedIds: number[];
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "700px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px"
        },
    },
});



export default class DeleteMilestoneModal extends Component<DeleteMilestoneModalProps, StateProp> {
    constructor(props: DeleteMilestoneModalProps) {
        super(props);
        this.state = {
            selectedIds: []
        };
    }

    render() {
        const { handleClose, milestone, open, deleteMilestoneApiCall, contractId, index } = this.props;
        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px", padding: "40px 20px" }}>
                    <Typography
                        style={{
                            fontFamily: "inter",
                            color: "#FFF2E3",
                            fontSize: "20px",
                            fontWeight: "600",
                            textAlign: "center",
                            paddingBottom: "24px"
                        }}
                    >Delete Milestone</Typography>
                    <Typography
                        style={{
                            fontFamily: "inter",
                            color: "#FFF2E3",
                            fontSize: "16px",
                            fontWeight: "600",
                            textAlign: "center"
                        }}
                    >Are you sure you want to delete this milestone? This</Typography>
                    <Typography
                        style={{
                            color: "#FFF2E3",
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "600",
                            fontFamily: "inter",
                        }}
                    > action can’t be undone</Typography>
                    <Box style={{ background: "transparent", borderRadius: "10px", border: "1px solid #494949", margin: "24px 40px" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                            <span style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <span style={{
                                    height: "30px",
                                    width: "30px",
                                    boxShadow: "-4px 4px 4px 0px #00000040",
                                    borderRadius: "50%",
                                    background: "#FFF2E3",
                                    color: "#2F2F2F",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontFamily: "inter"
                                }}>
                                    {index}
                                </span>
                                <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, paddingLeft: "10px", paddingRight: "5px", fontFamily: "inter", }}>
                                    {milestone.name}
                                </span>
                            </span>
                            <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 25px" }}>
                                    <svg style={{ paddingRight: "10px" }} width="22" height="24" viewBox="0 0 22 24" fill="none">
                                        <rect x="1" y="4.66797" width="19.9127" height="18.3333" rx="2" stroke="#FFF2E3" />
                                        <path d="M1 8.66797C1 6.78235 1 5.83954 1.58579 5.25376C2.17157 4.66797 3.11438 4.66797 5 4.66797H16.9127C18.7983 4.66797 19.7411 4.66797 20.3269 5.25376C20.9127 5.83954 20.9127 6.78235 20.9127 8.66797V9.55686H1V8.66797Z" fill="#FFF2E3" />
                                    </svg>
                                    <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                        {milestone.due_date}
                                    </span>
                                </span>
                                <span style={{ color: "#FFF2E3", fontSize: "16px", fontWeight: 600, fontFamily: "inter", }}>
                                    ₹ {milestone.amount}
                                </span>
                            </span>
                        </Box>
                        <Divider style={{ width: "100%", height: "1px", background: "#494949" }} />
                        <Typography style={{
                            fontFamily: "inter",
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#FFF2E3",
                            padding: "20px 15px"
                        }}>
                            {milestone.description}
                        </Typography>
                        <Box style={{ padding: "15px", paddingTop: "0px" }}>
                            <Typography style={{ color: "#7D7D7D", fontSize: "12px", fontWeight: 400, fontFamily: "inter" }}>
                                Deliverables:
                            </Typography>
                            {milestone.deliverables.map((item: Deliverable, index: number) => (
                                <Typography key={item.id} style={{ paddingTop: "10px", fontSize: "12px", fontWeight: 400, color: "#FFF2E3", fontFamily: "inter", }}>
                                    {index + 1}. {item.deliverable}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        style={{
                            paddingTop: "26px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "20px"
                        }}>
                        <Button
                            data-test-id="close-modal"
                            style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                borderRadius: "7px",
                                padding: "10px 30px",
                                fontFamily: "'Inter', sans-serif",
                                textTransform: "none",
                                cursor: "pointer",
                                marginRight: "15px",
                                background: "#0D0D0D",
                                color: "#FFF2E3",
                            } as React.CSSProperties}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-test-id="delete-milestone-btn"
                            style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                borderRadius: "7px",
                                padding: "10px 30px",
                                fontFamily: "'Inter', sans-serif",
                                textTransform: "none",
                                cursor: "pointer",
                                background: "rgba(255, 242, 227, 1)",
                                color: "#121212",
                                marginRight: "10px",
                            } as React.CSSProperties}
                            onClick={() => {
                                deleteMilestoneApiCall(contractId, milestone.id);
                                handleClose();
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
