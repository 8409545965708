import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import storage from "../../../framework/src/StorageProvider.web";
import { RefObject, createRef } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { SendbirdData } from "../../notifications/src/NotificationsController";
import { DropDownElement } from "../../joblisting/src/JobListingController";

export const config = require("../../joblisting/src/config");

interface ProposalData {
  id: number
  name: string
  value: string
  work_opportunity_count: number
  color?: string
}

import _ from "lodash";
interface INotification {
  id: string;
  type: string;
  attributes: {
      id: number;
      created_by: number;
      headings: string;
      contents: string;
      navigation_id: number | null;
      app_url: string;
      is_read: boolean;
      read_at: string | null;
      created_at: string;
      updated_at: string;
      proposal_data: ProposalData;
      sendbird_data: SendbirdData|''
      profile_image: string | null
      background_colour: string | null
      user_role: string | null
      current_status: string | null
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  activeTab?: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  activeTab: string;
  userType: string;
  isSearchFeildOpen: boolean;
  tabsList: {
    tabName: string;
    tabId: string;
  }[],
  suggestionsList: string[]
  newActiveTab: string;
  search: string;
  dezinerToken: string;
  notificationsList: INotification[];
  notificationCount: number
  notificationAnchorEl: null | HTMLImageElement;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  getNotificationsListId: string = "";
  viewNotificationApiCallId: string = ""
  getSuggestionsListAPIcallId: string =  "";
  inputRef: RefObject<HTMLInputElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      activeTab: "",
      userType: "designer",
      isSearchFeildOpen: false,
      tabsList: configJSON.tabsData,
      newActiveTab: "designer",
      search: "",
      dezinerToken: "",
      notificationsList: [],
      notificationCount: 0,
      notificationAnchorEl: null,
      suggestionsList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
     if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== "" &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ drawerItems: responseJson });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.handleReceiveMessages(message);
    this.handleReceiveMessagesX(message);

    // Customizable Area End
  }

  // Customizable Area Start

  handleActiveTab(tab: string, path: string) {
    setStorageData("shareProject",new URL(document.URL).pathname.split('/')[1] );
    this.setState({activeTab: tab});
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNavigation = async (path:string) => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    this.send(navigateMessage);
  }

  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    let tokenDetail = await getStorageData("userInfo");
    if(tokenDetail === null){
      this.handleNavigation("EmailAccountLoginBlock")
    }
    this.getTokenFromLocalStorage();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    storage.get("userInfo").then(res => {
      let storeData = JSON.parse(res);
      if (storeData && storeData.data) {
        this.setState({ userType: storeData.data.attributes.role_name, activeTab: this.props.activeTab || ""});
      }
    }) 
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getActiveTabName = () => {
    const urlList = window.location.href.split("/")
    let temp = urlList[urlList.length - 1] || "";
    return temp.toLowerCase()
  }

  toggleSearchFeild = () => {
    this.setState(prev => ({ isSearchFeildOpen: !prev.isSearchFeildOpen }))
  }

  getActiveTabBorder = (tabName: string) => {
    return this.state.newActiveTab === tabName ? "tabOuterCon gradientCon" : "tabOuterCon"
  }

  getActiveTabBackground = (tabName: string) => {
    return this.state.newActiveTab === tabName ? "activeTab tab" : "tab"
  }

  getActiveTabFont = (tabName: string) => {
    return this.state.newActiveTab === tabName ? "activeFont" : "inActiveFont"
  }


  handleTabChange = (tabName: string) => {
    this.setState({newActiveTab: tabName}, () => this.getSuggestionsList(this.state.search))
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
      search: event.target.value,
    });
    this.getSuggestionsList(event.target.value);
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter" && !event.shiftKey){
      this.handleSearchButtonClick();
    }
  }

  handleUserNameNavigate = (name: string) => {
    this.setState({search: name},this.handleSearchButtonClick)
  }

  handleSearchButtonClick = async () => {
    await storage.set("advancedSearch", JSON.stringify({ role: this.state.newActiveTab, search: this.state.search }));
    setTimeout(() => {
      const message: Message =
        new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),
        "AdvancedSearch");
      message.addData(getName(MessageEnum.NavigationPropsMessage),
        this.props);
      this.send(message);
    }, 200)
  }

  focusInputElement = () => {
    setTimeout(()=>{
      if(!!this.inputRef.current){
        this.inputRef.current.focus()
      }
    },100) 
  }

 componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
   if(this.state.isSearchFeildOpen){
    this.focusInputElement();
   }
 }

 isSearchBarVisiable = () => {
  return window.location.href.includes("AdvancedSearch") || this.state.isSearchFeildOpen;
 }

  getTokenFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    let token: string = "";
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token);
      this.setState({ dezinerToken: token, }, () => {
        this.getNotificationsListData()
      })
    }
    if(tokenParseData === null){
      this.handleNavigation("EmailAccountLoginBlock")
    }
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.dezinerToken,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  getNotificationsListData = () => {
    const endPoint = `${configJSON.getNotificationEndpoint}`;
    const message = this.createMessage(endPoint, configJSON.validationApiMethodType);
    this.getNotificationsListId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getSuggestionsList = _.debounce((search: string) => {
    if(search.trim() === "") {
      this.setState({
        suggestionsList: []
      })
      return
    }
    const endPoint = `/bx_block_advanced_search/search/suggested_search?filter=${this.state.newActiveTab}&search=${search}`;
    const message = this.createMessage(endPoint, configJSON.validationApiMethodType);
    this.getSuggestionsListAPIcallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }, 300)

  getResponseFromMessage = (message: Message) => {
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    return ({ requestCallId, response })
  }

  handleReceiveMessages = (message: Message) => {
    const { requestCallId } = this.getResponseFromMessage(message)
    if (requestCallId === this.getNotificationsListId) {
      this.handleNotificationsList(message);
    }
  }

  handleReceiveMessagesX = (message: Message) => {
    const { requestCallId } = this.getResponseFromMessage(message)
    if (requestCallId === this.getSuggestionsListAPIcallId) {
      this.handleSuggestionsList(message);
    }
  }

  handleSuggestionsList = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if("data" in response){
      this.setState({
        suggestionsList: response.data
      });
    } else {
      this.setState({
        suggestionsList: []
      })
    }
  }

  handleNotificationsList = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (!!response.notifications &&
      !!response.notifications.data &&
      Array.isArray(response.notifications.data) &&
      !isNaN(response.notification_count)
    ) {
      this.setState({
        notificationsList: response.notifications.data,
        notificationCount: response.notification_count
      })
    }
    if(response.errors[0].token==="Token has Expired" || response.errors[0].token==="Invalid token" ){
      this.handleNavigation("EmailAccountLoginBlock")
    }
  }

  getDate = (date: string) => {
    const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateObj = new Date(date);

    return `${shortMonths[dateObj.getMonth()]} ${dateObj.getDate()}`
  }

  handleNotificationOpen = (event: React.MouseEvent<HTMLImageElement>) => {
    this.setState({ notificationAnchorEl: event.currentTarget })
  }

  handleNotificationClose = () => {
    this.setState({ notificationAnchorEl: null })
  }
  handleNavigateToNotifications = () => {
    this.handleActiveTab("notification", "Notifications")
  }

  handleNavigationToChatScreen = async (data: any) => {
    setStorageData("navigateUserId", JSON.stringify(data));
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Chat");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }



  viewNotification = (id: number) => {
    const endPoint = `${configJSON.getNotificationEndpoint}/${id}`
    const message = this.createMessage(endPoint, configJSON.getDataMethod);
    this.viewNotificationApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  navigateCodeBreak = async (notification: INotification) => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);

    if (notification.attributes.headings === "Chat") {
      this.handleNavigationToChatScreen(notification.attributes.sendbird_data)
    }

    if (notification.attributes.headings === "Forum Post" || notification.attributes.headings === "Forum") {
      this.props.navigation.navigate("Forum", { id: notification.attributes.navigation_id })
    }

    if (
      notification.attributes.headings === "Offer Contract" ||
      notification.attributes.headings === "Proposal" ||
      notification.attributes.headings === "Work Opportuntiy"
    ) {
      if (tokenParseData.data.attributes.role_name === "designer") {
        let localdata = { designerProposalTab: notification.attributes.current_status }
        setStorageData("designerProposalTab", JSON.stringify(localdata));
        this.props.navigation.navigate("FindWork")
      } else if (tokenParseData.data.attributes.role_name === "client") {
        let opportunityData = notification.attributes.proposal_data
        const matchedItem = config.dropDownDataStatic.find((item: DropDownElement) => item.value === opportunityData.value);
        if (matchedItem) { opportunityData.color = matchedItem.color; }
        setStorageData("CardId", JSON.stringify(notification.attributes.navigation_id));
        setStorageData("dropdown", JSON.stringify(opportunityData));
        this.props.navigation.navigate("MyWork")
      }
    }
  }

  navigateToRoute = async (notification: INotification) => {
    this.viewNotification(notification.attributes.id)
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);

    if (notification.attributes.headings === "Project") {
      this.props.navigation.navigate("LandingPage", { id: notification.attributes.navigation_id })
    }

    if (notification.attributes.headings === "Archived Contract") {
      if (notification.attributes.contents.includes("Happy Voyage") ||
        tokenParseData.data.attributes.role_name === "designer"
      ) {
        this.props.navigation.navigate("ArchiveDesignerContractPage", { id: notification.attributes.navigation_id })
      }
      else if (notification.attributes.contents.includes("Exciting update") ||
        tokenParseData.data.attributes.role_name === "client"
      ) {
        this.props.navigation.navigate("ArchiveClientContractPage", { id: notification.attributes.navigation_id })
      }
    }

    this.navigationCodeBreak2(notification)
    this.navigateCodeBreak(notification)
  }

  navigationCodeBreak2 = async (notification: INotification) => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);

    if (notification.attributes.headings === "Active Contract" || notification.attributes.headings === "Contract") {
      if (tokenParseData.data.attributes.role_name === "designer") {
        this.props.navigation.navigate("ActiveContractPage", { id: notification.attributes.navigation_id })
      } else if (tokenParseData.data.attributes.role_name === "client") {
        this.props.navigation.navigate("ContractDetails", { id: notification.attributes.navigation_id })
      }
    }

    if (notification.attributes.headings === "Profile") {
      let localdata = {
        account_id: notification.attributes.navigation_id,
        user_role: notification.attributes.user_role
      }
      if (notification.attributes.contents.includes("inspiration")) {
        let localdata = {
          designerProposalTab: "inspiration"
        }
        setStorageData("designerProposalTab", JSON.stringify(localdata));
      }
      setStorageData("PublicId", JSON.stringify(localdata));
      this.props.navigation.navigate("PublicUserProfile")
    }
  }

  // Customizable Area End
}
