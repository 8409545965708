Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.designerUpcomingSubmissionsDetailsApiCall = "/bx_block_cfanalyticsboards/analytics/designer_upcomming_submissions_details"
exports.contractsAnalyticsApiCall='bx_block_cfanalyticsboards/analytics/contracts_analytics?year='
exports.rattingAndReviewApiCall='bx_block_cfanalyticsboards/analytics/ratings_and_reviews'
exports.workWithApiCall='bx_block_cfanalyticsboards/analytics/clients_worked_with'
exports.contractHighlightApiCall='bx_block_cfanalyticsboards/analytics/contract_highlights?filter='
exports.topRatedProjectApiCall='bx_block_cfanalyticsboards/analytics/top_rated_project'
exports.topRatedCollectionApiCall = 'bx_block_cfanalyticsboards/analytics/top_rated_collection'
exports.topContractSkillsApiCall = 'bx_block_cfanalyticsboards/analytics/top_contract_skills'
exports.clientMileStoneApiCall='bx_block_cfanalyticsboards/analytics/client_milestones_and_submissions_details'
exports.designersWorkWithApiCall ='bx_block_cfanalyticsboards/analytics/designers_worked_with'
exports.baseURL = require('../../../framework/src/config');
exports.btnExampleTitle = "CLICK ME";

exports.monthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
exports.colors=["#A8E5EC","#1CBAE0","#6C98EE","#AB9EF5","#CF8CCC","#FFC29F"]
// Customizable Area End