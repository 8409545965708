import { Box, styled, Typography, Divider } from '@material-ui/core';
import { BlockComponent } from 'framework/src/BlockComponent'
import React from 'react'
import { DesignerContract } from '../../../packages/blocks/joblisting/src/OpportunityClients/OpportunityClientController.web';

export interface Props {
    data: DesignerContract;
    isClient?: boolean
}

interface S {
}

interface SS {
}
export default class DKexperienceCard extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props)
        this.state = {
        }

    }

    MainWrapper = styled(Box)(() => ({
        '& .CardHeaders': {
            display: 'flex',
            justifyContent: "space-between",
            margin: '10px 0px'
        },
        '& .Allcardsmargins': {
            margin: "20px 0px"
        },

        '& .CardIdentity': {
            fontSize: "20px",
            fontWeight: 600,
            fontFamily: 'Inter,sans-serif',
            color: '#FFF2E3'
        },
        '& .Company_Name': {
            fontFamily: 'Inter,sans-serif',
            fontSize: '16px',
            fontWeight: 500,
            color: '#757171'
        },
        '& .IdentityPlace': {
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Inter,sans-serif",
            color: "#FFFFFF",
            background: "#2F2F2F",
            borderRadius: "25px",
            padding: "2px 20px"
        },
        '& .IdentityActive': {
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Inter,sans-serif",
            color: "#FFC29F",
            background: "#2F2F2F",
            borderRadius: "25px",
            padding: "2px 20px"
        },
        '& .IdetitySince': {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Inter,sans-serif",
            color: "#FFFFFF",
            textAlign: 'end',

        },
        '& .RightsideIdentity': {
            display: "flex",
            gap: '10px'
        },
        '& .CardDescription': {
            fontSize: "16px",
            fontWeight: 600,
            color: "#8C8C8C",
            fontFamily: "Inter,sans-serif",
        }
        ,
        '& .IdentitySkills': {
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Inter,sans-serif",
            color: '#535353',
            marginBottom: "10px"
        },
        '& .SkillListStyles': {
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
            overflowY: 'scroll',
            scrollbarWidth: 'none',

        },
        '& .IdentitySkillList': {
            fontSize: '12px',
            fontWeight: 600,
            fontFamily: "Inter,sans-serif",
            background: '#1F1F1F',
            borderRadius: '25px',
            padding: "10px 10px",
            width: 'fit-content',
            whiteSpace: 'nowrap',
            height: '20px'
        },
        '& .Identitylinks': {
            fontSize: "20px",
            fontWeight: 600,
            fontFamily: "Inter,sans-serif",
            color: "#498EFF",
            textDecorationLine: "underline"
        },
        '& .cursor-pointer': {
            cursor: 'pointer',
        },
        '& .deviceStyle': {
            backgroundColor: "#222222"
        }

    }));

    handleEndYear = (ending_in_year: string | null) => {
        let endYear = ending_in_year ? ending_in_year.split("-")[0] : 'Present';
        return endYear
    }

    handleReturnMonth = (fullDate: string) => {
        const date = new Date(fullDate);
        return date.toLocaleString("en-US", { month: "short" }); 
    }

    render() {
        let { attributes: { work_opportunity_description, calculate_years, work_opportunity_title, designer_details: { skills_data, location, company_name }, duration: { contract_start_date, contract_end_date } } } = this.props.data
        let isClient = !!this.props?.isClient
        return (
            <this.MainWrapper>
                <Box>
                    <Box className='CardHeaders'>
                        <Box >
                            <Typography className='CardIdentity'>{work_opportunity_title}</Typography>
                            <Typography className='Company_Name'>{company_name}</Typography>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Box>
                                <Box className='RightsideIdentity'>
                                    <Typography className='IdentityPlace'>{location}</Typography>
                                    <Typography className='IdentityActive'>{this.handleReturnMonth(contract_start_date)} {contract_start_date.split("-")[0]} - {contract_end_date && this.handleReturnMonth(contract_end_date)} {this.handleEndYear(contract_end_date)}</Typography>
                                </Box>
                                <Box style={{ margin: '15px 0px' }}>
                                    <Typography className='IdetitySince'>{calculate_years} </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='Allcardsmargins'>
                        <Typography className='CardDescription'>{work_opportunity_description}</Typography>
                    </Box>
                    {
                    !isClient && <Box className='Allcardsmargins'>
                        <Typography className='IdentitySkills'>Skills</Typography>
                        <Box className='SkillListStyles'>
                            {skills_data.length > 0 ?
                                (
                                    skills_data.map((item, index: number) => {
                                        return (
                                            <Typography key={index} className='IdentitySkillList'>{item.name}</Typography>
                                        )
                                    })
                                ) : (
                                    <>
                                        <Typography className='IdentitySkillList'>No skills added</Typography>
                                    </>
                                )
                            }
                        </Box>
                    </Box>
                    }
                    <Box style={{ marginTop: "3%", marginBottom: "3%" }}>
                        <Divider className='deviceStyle' />
                    </Box>
                </Box>
            </this.MainWrapper>
        )
    }
}
