import React, { Component } from "react";
import { Box } from "@mui/material";

interface UserAttributes {
  profile_image?: string;
  background_color?: string;
}

interface UserDetail {
  data: {
    attributes: UserAttributes;
  };
}

interface Props {
  userDetail: UserDetail;
  configBaseURL: string;
  profileIcon: string;
  webStyle: {
    profileStyle: React.CSSProperties;
  };
  className?: string;
}

class UserImage extends Component<Props> {
  render() {
    const { userDetail, configBaseURL, profileIcon, webStyle, className } = this.props;
    const { profile_image, background_color } = userDetail.data.attributes;

    return (
      <div className={className}>
        {profile_image ? (
          <img
            style={webStyle.profileStyle}
            src={`${configBaseURL}${profile_image}`}
            alt="Profile"
          />
        ) : background_color ? (
          <Box
            style={{ ...webStyle.profileStyle, backgroundColor: background_color }}
          />
        ) : (
          <img style={webStyle.profileStyle} src={profileIcon} alt="Default Profile" />
        )}
      </div>
    );
  }
}

export default UserImage;
