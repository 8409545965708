import React, { Component } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import {
  Dialog,
  DialogContent,
  IconButton,
  styled,
  Box,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  InstagramIcon,
  GmailIcon,
  WhatsappIcon,
  FacebookIcon,
  XIcon,
} from "../assets";

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  url: string;
  title: string;
  description?: string;
  coverImage?: string;
  ForumPostContent?: string
  profile?: any
}

const StyledIconButton = styled(IconButton)({
  padding: "0px !important",
});

const StyledButton = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

const StyledText = styled(Typography)({
  fontStyle: "Inter",
  fontWeight: 400,
  fontSize: "14px",
  color: "#7D7D7D",
});
interface ShareDialogState {
  copyDialogOpen: boolean;
}

class ShareDialog extends Component<ShareDialogProps, ShareDialogState> {
  constructor(props: ShareDialogProps) {
    super(props);
    this.state = {
      copyDialogOpen: false,
    };
  }

  handleCopy = async () => {
    this.setState({ copyDialogOpen: true });
    await navigator.clipboard.writeText(this.props.url);
  };

  handleCloseCopyDialog = () => {
    this.setState({ copyDialogOpen: false });
  };
  render() {
    const { copyDialogOpen } = this.state;
    const { open, onClose, url, title, description, coverImage, ForumPostContent,profile } = this.props;

    const handleLinkClick = async (url: string, target: boolean) => {
      const link = document.createElement("a");
      link.href = url;
      if (target) {
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      await navigator.clipboard.writeText(url);
    };

    return (
      <>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{ style: webStyles.dialogPaper }}
        >
          <Box style={webStyles.closeBox} onClick={onClose}>
            <CloseIcon style={webStyles.closeIconStyle} />
          </Box>
          <DialogContent style={webStyles.mainDialog}>
            {
              profile && <div>
                {profile()}
              </div>
            }
            {!profile && coverImage !== '' ?
              <img
                style={webStyles.coverImageStyle}
                src={coverImage}
                alt="cover image"
              />
              :
              <Typography style={webStyles.only10Line as React.CSSProperties}>{ForumPostContent}</Typography>
            }
            <Box style={webStyles.headerContainer}>
              <Typography style={webStyles.headerText}>
                Share in your community
              </Typography>
              <Typography
                style={{ ...webStyles.copyButton, ...webStyles.headerText }}
                onClick={this.handleCopy}
                data-test-id="copy-btn-test-id"
              >
                Copy link
              </Typography>
            </Box>
            <Box style={webStyles.mainBox}>
              <StyledIconButton
                data-test-id="ig-btn-id"
                onClick={() =>
                  handleLinkClick("https://www.instagram.com/", true)
                }
              >
                <StyledButton>
                  <img src={InstagramIcon} style={webStyles.igIcon} />
                  <StyledText>Instagram</StyledText>
                </StyledButton>
              </StyledIconButton>
              <EmailShareButton url={url} subject={title} body={description}>
                <StyledButton>
                  <img src={GmailIcon} style={webStyles.mainIconStyle} />
                  <StyledText>Mail</StyledText>
                </StyledButton>
              </EmailShareButton>
              <WhatsappShareButton url={url} title={title}>
                <StyledButton>
                  <img src={WhatsappIcon} style={webStyles.wpIcon} />
                  <StyledText>Whatsapp</StyledText>
                </StyledButton>
              </WhatsappShareButton>
              <FacebookShareButton url={url}>
                <StyledButton>
                  <img src={FacebookIcon} style={webStyles.fbIcon} />
                  <StyledText>Facebook</StyledText>
                </StyledButton>
              </FacebookShareButton>
              <TwitterShareButton url={url} title={title}>
                <StyledButton>
                  <img src={XIcon} style={webStyles.twitterIconStyle} />
                  <StyledText>X</StyledText>
                </StyledButton>
              </TwitterShareButton>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={copyDialogOpen}
          onClose={this.handleCloseCopyDialog}
          PaperProps={{ style: webStyles.dialogPaper1 }}
        >
          <DialogContent style={webStyles.mainDialog1}>
            <Box style={webStyles.headerContainer1}>
              <Typography style={webStyles.headerText1}>Success!</Typography>
              <CloseIcon
                style={webStyles.closeIconStyle}
                data-test-id="close-dialog-id"
                onClick={this.handleCloseCopyDialog}
              />
            </Box>
            <Typography style={webStyles.headerText2}>
              Link has been copied to clipboard
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default ShareDialog;

const webStyles = {
  dialogPaper: {
    backgroundColor: "#303030",
    padding: "20px",
    width: "525px",
    height: "545px",
    borderRadius: "20px",
  },
  dialogPaper1: {
    backgroundColor: "#303030",
    width: "525px",
    borderRadius: "20px",
  },
  only10Line: {
    fontSize: "12px",
    fontFamily: "Inter,sans-serif",
    height: 50,
    fontWeight: '400',
    color: "#FFF2E3",
    flexGrow: 1,
    display: "-webkit-box",
    WebkitLineClamp: 10,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 2,
    maxHeight: "240px",
  },
  closeBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIconStyle: {
    color: "white",
  },
  mainDialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  mainDialog1: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  coverImageStyle: {
    width: "419",
    height: "267px",
  },
  headerContainer: {
    width: "89%",
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "50px 0px",
  },
  headerContainer1: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    color: "#FFF2E3",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "20px",
  },
  headerText1: {
    color: "#61CA78",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "20px",
  },
  headerText2: {
    color: "#7D7D7D",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    paddingBottom: "20px",
  },
  copyButton: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "89%",
  },
  igIcon: {
    width: "43px",
    height: "43px",
  },
  mainIconStyle: {
    width: "47px",
    height: "43px",
  },
  wpIcon: {
    width: "46px",
    height: "43px",
  },
  fbIcon: {
    width: "44px",
    height: "43px",
  },
  twitterIconStyle: {
    width: "45px",
    height: "43px",
  },
};
