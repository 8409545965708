import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Instance as FlatpickrInstance } from "flatpickr/dist/types/instance";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface StateType {
  passwordError: string;
  currentPassword:string;
  newPassword:string
  newPasswordError: string;
}

export interface HelpAndSupport {
  data: HelpAndSupportData
  message: string
}

export interface HelpAndSupportData {
  id: number
  description: string
  email: string
  created_at: string
  updated_at: string
}

export interface TransactionAttributes {
  id: number;
  description: string;
  processing_fee_amount: number;
  gst_amount: number | null;
  wallet_amount: number;
  total_amount: number;
  error_description: string | null;
  error_reason: string | null;
  razorpay_order_id: string | null;
  payment_id: string | null;
  payment_status: string;
  payment_type: string;
  created_at: string;
  updated_at: string;
  created_date: string;
}

export interface Transaction {
  id: string;
  type: string;
  attributes: TransactionAttributes;
}

interface VPA {
  username: string;
  handle: string;
  address: string;
}

export interface BankAccount {
  ifsc: string;
  bank_name: string;
  name: string;
  notes: string[];
  account_number: string;
}
export interface FundAccount {
  id: string;
  entity: string;
  contact_id: string;
  account_type: string;
  batch_id: string | null;
  vpa?: VPA;
  bank_account?: BankAccount;
  active: boolean;
  created_at: number;
}

export interface WalletTransaction {
  id: string;
  type: string;
  attributes: {
    id: number;
    description: string;
    processing_fee_amount: number;
    gst_amount: number | null;
    wallet_amount: number;
    total_amount: number;
    error_description: string | null;
    error_reason: string | null;
    razorpay_order_id: string | null;
    payment_id: string | null;
    payment_status: string;
    payment_type: string;
    created_at: string;
    updated_at: string;
    created_date: string;
  };
}

export interface WithdrawelData {
  "id": string,
  "entity": string,
  "contact_id": string,
  "account_type": string,
  "bank_account": {
      "ifsc": string,
      "bank_name": string,
      "name": string,
      "notes": [],
      "account_number": string
  },
  "vpa": {
    "address": string
  }
  "batch_id": null,
  "active": boolean,
  "created_at": number,
  "second_monday": string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  apiError:boolean
  logOutPopup:boolean
  postOpportunityLoader:boolean
  newPasswordError:string,
  passwordError:string
  confirmPasswordError:string
  currentPassword: string
  newPassword:string
  confirmPassword:string
  passwordPopUp:boolean
  open:boolean
  action:string
  message:string
  activeTab:string
  helpAndSupport: HelpAndSupport
  userDetail:{
    data:{
      type: string,
      attributes: {
        first_name: string,
        last_name: string | null,
        email: string,
        activated: boolean,
        user_name: string,
        background_color: string,
        city: string,
        country: string,
        profile_image: any,
        role_id: number,
        role_name: string,
      },
    }
  },
  showTransactions: boolean,
  dateRange: string | number | Date | readonly (string | number | Date)[] | undefined,
  formattedRange: string,
  transactionHistoryList: Transaction[],
  fetchingTransactions: boolean,
  withdrawelMethods: FundAccount[],
  lastTransactionData: WalletTransaction | null,
  walletAmount: number | string;
  OpenDeleteModal: boolean;
  deleteFundAccId: string | number;
  openAddWitdMOdal: boolean;
  withdrawelData: WithdrawelData | null;
  isGettingMethods: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tabs = [
    "Password & Security",
    "Location & Contact Info",
    "Get Paid",
    "Tax Information",
    "Help & Support",
  ];
  clientTabs = [
    "About Company",
    "Password & Security",
    "Location & Contact Info",
    "Wallet",
    "Tax Information",
    "Help & Support",
  ];
  passwordChangeApiCallId :string = ""
  helpAndSupportApiCallId: string = ""
  getAllTransactionsApiCallId: string = ""
  getWithdrawalMethodsApiCallId: string = ""
  getLastTransactionDetailAPIcallId: string = ""
  getWithdrawelApiCallID: string = ""
  removeWithdrawalMethodApiCallId: string = ""
  addWithdrawelMethodApiCallId: string = ""
  logOutApiCallId:string=""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      logOutPopup:false,
      apiError:false,
      message:"",
      action:"",
      open:false,
      postOpportunityLoader:false,
      newPasswordError:"",
      passwordError:"",
      confirmPasswordError:"",
      confirmPassword:"",
      currentPassword:"",
      newPassword:"",
      passwordPopUp:false,
      activeTab:"Password & Security",
      helpAndSupport: {
        data: {
          created_at: '',
          description: '',
          email: '',
          id: 0,
          updated_at: ''
        },
        message: ''
      },
      userDetail:{
        data :{
          type: "",
          attributes: {
            first_name: "",
            last_name: "",
            email: "",
            activated: true,
            user_name: "",
            background_color: "",
            city: "",
            country: "",
            profile_image: "",
            role_id: 0,
            role_name: "",
          },
        }
      },
      showTransactions: false,
      dateRange: "",
      formattedRange: "",
      transactionHistoryList: [],
      fetchingTransactions: false,
      withdrawelMethods: [],
      lastTransactionData: null,
      walletAmount: 0,
      OpenDeleteModal: false,
      deleteFundAccId: "",
      openAddWitdMOdal: false,
      withdrawelData: null,
      isGettingMethods: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleResponses(message)
    if (apiRequestCallId1 === this.passwordChangeApiCallId) {
      
      this.handlePasswordChangeApiCall(responseJson1)
    }
    if (apiRequestCallId1 === this.helpAndSupportApiCallId) {
      
      this.handleHelpAndSupport(responseJson1)
    }
    if (apiRequestCallId1 === this.logOutApiCallId) {
      if (!responseJson1.errors) {
        removeStorageData("userInfo")
        removeStorageData("saveUserInfo")
        removeStorageData("authToken")
        removeStorageData("userProfile")
        removeStorageData("PublicId")
        removeStorageData("isPublicProfile")
        this.setState({
          logOutPopup:false,
        })
        setTimeout(() => {
           this.handleNavigation("EmailAccountLoginBlock");
        }, 1000)
      } else {
        this.setState({
          logOutPopup:false,
          open: true,
          action: "danger",
          message: responseJson1.errors[0],
        })
      }
    }
    // Customizable Area End
  }

  handlePasswordChangeApiCall = (responseJson1:any) =>{
    if (!responseJson1.error) {
      this.handlePasswordPopUp()
      this.setState({
        open: true,
        action: "success",
        message: "Password changed successfully",
      })
      setTimeout(() => {
        this.handleNavigation("EmailAccountLoginBlock");
    }, 1000)
    this.handleLogOutApiCall()
    } else {
      this.setState({
        apiError: responseJson1.error==="New password cannot be the same as the current password" ?false:true,
        postOpportunityLoader: false,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        passwordError: "",
        newPasswordError: responseJson1.error==="New password cannot be the same as the current password" ?"New password cannot be the same as the current password":"" ,
        confirmPasswordError: "",
      })
    }
  }

  handleHelpAndSupport = (responseJson: HelpAndSupport) => {
    if (responseJson.data) {
      this.setState({
        helpAndSupport: responseJson
      })
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.getWithdrowalMethods();
    this.getLastTransactionData();
    this.getWithdrawelData();
    const userInfo = await getStorageData("userInfo",true)
    if(userInfo.data.attributes.role_name !== "designer"){
      this.setState({
        activeTab:"About Company"
      })
    }
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    
    this.setState({
      userDetail:userInfo,
      dateRange: [oneMonthAgo, today].map(date => formatter.format(date)),
    })
  }

  handleResponses = (message: Message) => {
    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.getAllTransactionsApiCallId:
        if ("data" in response) {
          this.setState({
            transactionHistoryList: response.data,
            fetchingTransactions: false,
          });
        } else {
          this.setState({
            transactionHistoryList: [],
            fetchingTransactions: false,
          });
        }
        break;

      case this.getWithdrawalMethodsApiCallId:
        this.setState({
          isGettingMethods: false,
          withdrawelMethods: "fund_accounts" in response ? (response.fund_accounts.items).filter((item: FundAccount) => item.active) : [],
        });
        break;

      case this.getLastTransactionDetailAPIcallId:
        this.handleLastTransactionResponse(response)
        break;

      case this.removeWithdrawalMethodApiCallId:
        this.handleRemoveWithdrawelResponse(response)
        break;

      case this.addWithdrawelMethodApiCallId:
        this.handleAddWithDraweldata(response)
        break;

      case this.getWithdrawelApiCallID:
        this.handleGetWithdrawelData(response);
        break;

      default:
        break;
    }
  }
  

  handleLastTransactionResponse = (response: {data: WalletTransaction, meta: {wallet_balance: number | string}}) => {
    this.setState({
      lastTransactionData: "data" in response ? response.data : null,
      walletAmount: "meta" in response ? response.meta.wallet_balance : 0
    },()=>{console.log("walletAmount==>",this.state.walletAmount)});
  }

  handleAddWithDraweldata = (response: {error?: string}) => {
    if ("error" in response) {
      this.setState({
        open: true,
        action: "danger",
        message: "Something went wrong please try again later"
      })
    } else {
      this.setState({
        open: true,
        action: "success",
        message: "Withdrawal method added successfully",
      });
      this.getWithdrowalMethods();
      this.getWithdrawelData();
    }
  }

  handleGetWithdrawelData = (response: 
    {
      active_fund_account: string | WithdrawelData,
      second_monday: string
    }
  ) => {
    if (typeof response.active_fund_account === "string") {
      this.setState({
        withdrawelData: null
      })
    } else {
      this.setState({
        withdrawelData: {...response.active_fund_account, second_monday: response.second_monday}
      })
    }
  }

  handleRemoveWithdrawelResponse = (response: {message?: string}) => {
    if ("message" in response) {
      if (response.message === "Fund account deactivated successfully") {
        this.getWithdrowalMethods();
        this.getWithdrawelData();
        this.setState({
          open: true,
          action: "success",
          message: "Withdrawal method removed successfully",
        });
      } else {
        this.setState({
          open: true,
          action: "danger",
          message: "Something went wrong please try again later",
        });
      }
    }
  }

  handleShowTransaction = () => {
    this.setState({
      showTransactions: true
    });
    this.getAllTransactionHistory();
  }
  
  handleNotShowTransaction = () => {
    this.setState({
      showTransactions: false
    })
  }

  handleDateChange = (selectedDates: Date[], _dateStr: string, _instance: FlatpickrInstance) => {
    this.setState({
      dateRange: selectedDates
    }, () => {
      this.getAllTransactionHistory()
    })
  };


  setUpdatedStorageValue = async() => {
    const userInfo = await getStorageData("userInfo",true)
    this.setState({
      userDetail:userInfo
    })
  }
  handleActiveTab = (tab:string) =>{
    this.setState({
      activeTab:tab
    },()=>{
      if (this.state.activeTab === "Help & Support" && this.state.helpAndSupport != null) {
        this.helpAndSupport()
      }
    })
  }
  handleLinkClick = (url: string,target:boolean) => {
    const link = document.createElement("a");
    link.href = url;
    if(target) link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };
  handleDialog = () => {
    this.setState({
      passwordPopUp: !this.state.passwordPopUp
    })
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  updatePasswordError = (
    password: string,
    passwordRegex: RegExp,
    errorMessage: string,
    errorStateKey: "passwordError" | "newPasswordError"
  ) => {
    let error = "";
    if (password !== "") {
      error = passwordRegex.test(password) ? "" : errorMessage;
    }
    this.setState({ [errorStateKey]: error } as Pick<StateType, "passwordError" | "newPasswordError">);
  };
  

  validatePassword = (password: string, type: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    const errorMessage =
      "Password must be 8-15 characters and include at least one uppercase letter, one lowercase letter, one digit, and one special character.";
    if (type === "currentPassword") {
      this.updatePasswordError(password, passwordRegex, errorMessage, "passwordError")
    } else if (type === "newPassword") {
      this.updatePasswordError(password, passwordRegex, errorMessage, "newPasswordError")
    }
  };

  validateConfirmPassword = (password: string) => {
    const confirmPasswordError = 
      password !== "" && this.state.newPassword !== this.state.confirmPassword
        ? "The Password doesn't match"
        : "";
  
    this.setState({ confirmPasswordError });
  };
  handleNavigation = async (path:string) => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    this.send(navigateMessage);
  }
  handleForgotPassword = () =>{
      this.handleNavigation("ForgotPassword")
  }
  handleLogOutPopup = () =>{
    this.setState({logOutPopup:!this.state.logOutPopup})
  }
  handleLogOut = () => {
    this.handleLogOutApiCall()
  }
  handlePasswordChange = (key: "currentPassword" | "newPassword", value: string) => {
    this.setState(
      { [key]: value } as Pick<StateType, "currentPassword" | "newPassword">,
      () => {
        this.validatePassword(value, key);
      }
    );
  };
  handleConfirmPassword = (value: string) => {
    this.setState({
      confirmPassword: value
    },
      () => {
        this.validateConfirmPassword(this.state.confirmPassword);
      }
    );
  };
  handlePasswordPopUp = () => {
    this.setState({
      currentPassword:"",
      newPassword:"",
      confirmPassword:"",
      apiError:false,
      passwordError:"",
      newPasswordError:"",
      confirmPasswordError:"",
      passwordPopUp:false,
      postOpportunityLoader:false
    })
  }
  checkIfDisabled = () => {
    const { confirmPassword, currentPassword, newPassword, confirmPasswordError, passwordError, newPasswordError } = this.state;
    return !confirmPassword || 
           !currentPassword || 
           !newPassword || 
           confirmPasswordError ||  
           newPasswordError ? true : false;
  }
  handleChangePasswordCall= async ()=> {
    this.setState({postOpportunityLoader:true})
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
        "current_password": this.state.currentPassword,
        "new_password": this.state.newPassword,
        "confirm_password": this.state.confirmPassword
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePasswordApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.putApiMethod
    );
    this.passwordChangeApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleLogOutApiCall= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logOutApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.logOutApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  helpAndSupport= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.helpAndSupportAPiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.helpAndSupportApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getAllTransactionHistory = async () => {
    this.setState({
      fetchingTransactions: true
    })
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;
    const headers = {
      token: token,
    };

    let dateArray = (this.state.dateRange as Date[]).map(dateString => {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
    
      return `${year}-${month}-${day}`;
    })

    let URL = `/bx_block_payment_admin/payments/wallet_list?page=1&per_page=20000000`

    if (Array.isArray(dateArray) && dateArray.length > 0) {
      URL = URL + `&end_date=${dateArray[0]}`
      URL = URL + `&end_date=${dateArray[1]}`
    }
    const getAllTransactionsList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAllTransactionsList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    )
    getAllTransactionsList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllTransactionsList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getAllTransactionsApiCallId = getAllTransactionsList.messageId
    runEngine.sendMessage(getAllTransactionsList.id, getAllTransactionsList);
  };

  getWithdrowalMethods= async ()=> {
    this.setState({
      isGettingMethods: true
    })
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      token: token,
    };
    const getWithdrawelMethodsApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getWithdrawelMethodsApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_payment_admin/payments/fetch_fund_accounts_based_on_contact"
    )
    getWithdrawelMethodsApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getWithdrawelMethodsApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getWithdrawalMethodsApiCallId = getWithdrawelMethodsApi.messageId
    runEngine.sendMessage(getWithdrawelMethodsApi.id, getWithdrawelMethodsApi);
  }

  getLastTransactionData = async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      token: token,
    };
    const getLasttransactionDataApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getLasttransactionDataApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_payment_admin/payments/last_transaction"
    )
    getLasttransactionDataApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getLasttransactionDataApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getLastTransactionDetailAPIcallId = getLasttransactionDataApi.messageId
    runEngine.sendMessage(getLasttransactionDataApi.id, getLasttransactionDataApi);
  }

  getWithdrawelData = async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      token: token,
    };
    const getWithdrawelDataAPIcall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getWithdrawelDataAPIcall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_payment_admin/payments/get_paid"
    )
    getWithdrawelDataAPIcall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getWithdrawelDataAPIcall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getWithdrawelApiCallID = getWithdrawelDataAPIcall.messageId
    runEngine.sendMessage(getWithdrawelDataAPIcall.id, getWithdrawelDataAPIcall);
  }

  removeWithdrawMethod = async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      token: token,
    };
    let body = {
       "fund_account_id": this.state.deleteFundAccId
    }
    const removeMethodApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_payment_admin/payments/inactive_fund_account?fund_account_id=${this.state.deleteFundAccId}`
    )
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PUT"
    );
    this.removeWithdrawalMethodApiCallId = removeMethodApiCall.messageId
    runEngine.sendMessage(removeMethodApiCall.id, removeMethodApiCall);
  }

  handleAddWithDrawmethod = async (body: {})=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      token: token,
      "Content-Type": "application/json",
    };
    const removeMethodApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_payment_admin/payments/fund_accounts`
    )
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    removeMethodApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "POST"
    );
    this.addWithdrawelMethodApiCallId = removeMethodApiCall.messageId
    runEngine.sendMessage(removeMethodApiCall.id, removeMethodApiCall);
  }

  handleOpenDeleteModal = (fundAccId: number | string) => {
    this.setState({
      OpenDeleteModal: true,
      deleteFundAccId: fundAccId
    })
  }

  handleCloseDeleteModal = () => {
    this.setState({
      OpenDeleteModal: false,
    })
  }
  handleOpenWithdrawelModal = () => {
    this.setState({
      openAddWitdMOdal: true
    })
  }
  handleCloseWithdrawelModal = () => {
    this.setState({
      openAddWitdMOdal: false
    })
  }
  handleUpdateAmount = (amount:boolean) =>{
    if(amount){
      setTimeout(()=>{
        this.getLastTransactionData()
      },3500)
    }
  }
  // Customizable Area End
}
