import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export const configJSONChat = require("../../chat/src/config.js");

import dayjs, { Dayjs } from "dayjs";
import SendbirdChat, { BaseChannel, SendbirdChatWith, SendbirdError, User } from "@sendbird/chat";
import { SendBird_Info } from "../../../../packages/blocks/chat/src/ChatController";
import { GroupChannel, GroupChannelHandler, GroupChannelModule, SendbirdGroupChat } from "@sendbird/chat/groupChannel";
import { BaseMessage, FileMessage, FileMessageCreateParams, PreviousMessageListQuery, PreviousMessageListQueryParams, UserMessage, UserMessageCreateParams } from "@sendbird/chat/message";
import { ProposalData, WorkOpportunityData } from "./CfActiveContractForDesignerController";
import React, { RefObject, createRef } from "react"
import { collChatFunctions } from "../../../components/src/Base64Conversion";
import { RazorpayPaymentFailResponse, RazorpayPaymentSuccessResponse } from "../../../../packages/blocks/stripepayments/src/components/RazorPayController";
const config = require("../../../framework/src/config.js")
let tokenData = "";
let cardId = "";

export interface RazorpayOrderAttributes {
  amount: number;
  amount_due: number;
  amount_paid: number;
  attempts: number;
  created_at: number;
  currency: string;
  entity: string;
  id: string;
  notes: [];
  receipt: string;
  status: string;
};

type RazorpayOrder = {
  attributes: RazorpayOrderAttributes;
};

export interface Wallet {
  id: number;
  account_id: number;
  total_amount: number;
  gst_amount: number;
  processing_fee_amount: number;
  wallet_amount: number;
  razorpay_order_id: string;
  payment_id: null | string; 
  payment_status: string;
  description: string;
  wallet_type: string;
  payment_type: string;
  payment_method: string;
  error_description: null | string;
  error_reason: null | string;
  created_at: string;
  updated_at: string;
};

export interface RazorpayOrderResponse {
  razorpay_order: RazorpayOrder;
  wallet: Wallet;
  message: string;
};

export interface DesignerWorkSubmission {
  id: string;
  type: "designer_work_submissions";
  attributes: {
    id: number;
    contract_id: number;
    account_id: number;
    submission_links: string[];
    message: string;
    contract_milestone_id: number;
    contract_milestone_type: string;
    created_at: string;
    updated_at: string;
    submissio_approval: boolean;
    files: {
      file_type: string;
      file_name: string;
      url: string;
    }[];
    submission_time: string;
    milestone_details: null;
  };
};

export interface MilestoneDetails {
  id: number;
  contract_id: number;
  name: string;
  description: string;
  amount: number;
  due_date: string;
  created_at: string;
  updated_at: string;
  milestone_status: string;
  activate: boolean;
  payment_done: boolean;
  client_approval: boolean;
  action_taken_by: null;
  is_approved: boolean;
  after_activate_name: null;
  after_activate_description: null;
  after_activate_amount: null;
  after_activate_due_date: null;
};

export interface WorkSubmissionsResponse {
  data: DesignerWorkSubmission[];
  meta: {
    milestone_details: MilestoneDetails;
    message: string;
  };
};


export interface ContractDetailMain {
  contracts?: ContractDetailContracts
  dispute_raise_details?: {}
}

export interface ContractDetailContracts {
  data: ContractDetailContractsData
}

export interface ContractDetailContractsData {
  id: string
  type: string
  attributes: ContractDetailContractsDataAttributes
}

export interface ContractDetailContractsDataAttributes {
  id: number
  proposal_id: number
  work_opportunity_id: number
  account_id: number
  created_at: string
  updated_at: string
  title: string
  wallet: number
  earnings: number
  notification_count: number
  transactions: number
  work_budget: number
  progress_percentage: number
  duration: string | number
  start_date: string
  created_time_ago: string
  project_timeline: number
  project_timeline_type: string
  profile_details: ContractDetailContractsDataAttributesProfileDetails
  active_milestone_details: ContractDetailContractsDataAttributesActiveMilestoneDetail[]
  sendbird_data: ContractDetailContractsDataAttributesSendbirdData
  milestone_status: string,
  contract_end_date:string,
  contract_end_text:string
}

export interface ContractDetailContractsDataAttributesProfileDetails {
  profile_photo: string
  profile_location: string
  profile_user_name: string
  profile_backgroud_color: string
}

export interface ContractDetailContractsDataAttributesSendbirdData {
  next: string
  ts: number
  channels: Channel[]
}
export interface UserType {
  user_id: string;
  profile_url: string;
  require_auth_for_profile_image: boolean;
  nickname: string;
  metadata: Record<string, any>;
  is_blocked_by_me: boolean;
  role: string;
  is_active: boolean;
}

export interface LastMessage {
  type: string;
  message_id: number;
  message: string;
  data: string;
  custom_type: string;
  file: Record<string, any>;
  created_at: number;
  user: UserType;
  channel_url: string;
  updated_at: number;
  message_survival_seconds: number;
  mentioned_users: UserType[];
  mention_type: string;
  silent: boolean;
  message_retention_hour: number;
  channel_type: string;
  translations: Record<string, string>;
  is_removed: boolean;
  req_id: string;
  is_op_msg: boolean;
  message_events: {
    send_push_notification: string;
    update_unread_count: boolean;
    update_mention_count: boolean;
    update_last_message: boolean;
  };
}

export interface DisappearingMessage {
  is_triggered_by_message_read: boolean;
  message_survival_seconds: number;
}

export interface SmsFallback {
  wait_seconds: number;
  exclude_user_ids: string[];
}

export interface ChannelInfo {
  channel_url: string;
  name: string;
  cover_url: string;
  data: string;
  created_at: number;
  custom_type: string;
  max_length_message: number;
  member_count: number;
}

export interface Channel {
  channel_url: string;
  name: string;
  cover_url: string;
  data: string;
  member_count: number;
  joined_member_count: number;
  max_length_message: number;
  created_at: number;
  custom_type: string;
  is_distinct: boolean;
  is_super: boolean;
  is_broadcast: boolean;
  is_public: boolean;
  is_discoverable: boolean;
  freeze: boolean;
  is_ephemeral: boolean;
  unread_message_count: number;
  unread_mention_count: number;
  ignore_profanity_filter: boolean;
  has_ai_bot: boolean;
  has_bot: boolean;
  id: string;
  channel: ChannelInfo;
  count_preference: string;
  created_by: any;
  disappearing_message: DisappearingMessage;
  is_access_code_required: boolean;
  is_exclusive: boolean;
  is_muted: boolean;
  is_push_enabled: boolean;
  member_state: string;
  message_survival_seconds: number;
  my_role: string;
  push_trigger_option: string;
  sms_fallback: SmsFallback;
  ts_message_offset: number;
  user_last_read: number;
  inviter: any;
  invited_at: number;
  is_hidden: boolean;
  hidden_state: string;
  last_message: LastMessage;
  joined_ts: number;
  last_queried_message: any;
}

export interface ContractDetailContractsDataAttributesActiveMilestoneDetail {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  milestone_status: string
  activate: boolean
  deliverables: ContractDetailContractsDataAttributesActiveMilestoneDetailDeliverable[]
  milestone_type: string
}

export interface ContractDetailContractsDataAttributesActiveMilestoneDetailDeliverable {
  id: number
  deliverable: string
  deliverable_type: string
}

export interface AddMilstoneResponse {
  data: AddMilstoneResponseData
}

export interface AddMilstoneResponseData {
  id: string
  type: string
  attributes: AddMilstoneResponseDataAttributes
}

export interface AddMilstoneResponseDataAttributes {
  id: number
  contract_id: number
  name: string
  description: string
  amount: number
  due_date: string
  client_approval: boolean
  deliverables: AddMilstoneResponseDataAttributesDeliverables
  edits: any
}

export interface AddMilstoneResponseDataAttributesDeliverables {
  data: AddMilstoneResponseDataAttributesDeliverablesData[]
}

export interface AddMilstoneResponseDataAttributesDeliverablesData {
  id: string
  type: string
  attributes: AddMilstoneResponseDataAttributesDeliverablesDataAttributes
}

export interface AddMilstoneResponseDataAttributesDeliverablesDataAttributes {
  id: number
  deliverable: string
}

export type Deliverable = {
  id: number;
  deliverable: string;
  deliverable_type: string;
  after_activate_deliverable: string | null;
};

export type Milestone = {
  id: number;
  name: string;
  description: string;
  amount: number;
  due_date: string | null;
  milestone_status: string;
  activate: boolean;
  deliverables: Deliverable[];
  milestone_type: string;
  work_submission: boolean;
  view_request_change: boolean;
  is_approved: boolean;
  after_activate_description: string | null;
  after_activate_name: string | null;
  after_activate_amount: number | null;
  after_activate_due_date: string | null;
  remaining_milestones?: number;
};

interface SendBirdMock {
  appId: string;
  currentUser: User;
  unreadMessageCount: number;
  groupChannel: {
    createChannel: (params?: Partial<Channel>) => Promise<Channel>;
    getChannel: (channelUrl: string) => Promise<Channel>;
    sendMessage: (channelUrl: string, message: string) => Promise<Message>;
    addGroupChannelHandler: (handlerId: string, handler: any) => void;
  };
  connect: (userId: string) => Promise<User>;
  disconnect: () => Promise<string>;
  markAsRead: () => Promise<void>;
  createPreviousMessageListQuery: () => {
    load: () => Promise<{ messages: Message[]; query: object }>;
  };
}

export interface NotificationRoot {
  profile_details: ProfileDetails;
  notification_count: number;
  unread_notification_count: number;
  notifications: Notification[];
  error:{message: string}
  errors:{message: string}
}

export interface ProfileDetails {
  full_name: string;
  profile_image: string | null;
  background_color: string | null;
  location: string | null;
}

export interface Notification {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

export interface NotificationAttributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  navigation_id: number;
  app_url: string;
  is_read: boolean;
  read_at: string | null;
  created_at: string;
  updated_at: string;
}

interface SubmissionFile {
  file_type: string;
  file_name: string;
  submission_time: string;
  url: string;
}

interface SubmissionLink {
  link_url: string;
  submission_time: string;
}

type WorkSubmission = {
  id: string;
  type: string;
  attributes: {
    id: number;
    contract_id: number;
    account_id: number;
    submission_links: string[];
    message: string;
    contract_milestone_id: number;
    contract_milestone_type: string;
    created_at: string;
    updated_at: string;
    submissio_approval: boolean;
    files: {
      file_type: string;
      file_name: string;
      url: string;
    }[];
    submission_time: string;
    milestone_details: {
      id: number;
      contract_id: number;
      name: string;
      description: string;
      amount: number;
      due_date: string;
      created_at: string;
      updated_at: string;
      milestone_status: string;
      activate: boolean;
      payment_done: boolean;
      client_approval: boolean;
      action_taken_by: number | null;
      is_approved: boolean;
      after_activate_name: string | null;
      after_activate_description: string | null;
      after_activate_amount: number | null;
      after_activate_due_date: string | null;
    };
  };
};

export interface RazorpayOptions {
  key: string;
  amount: number;
  currency: string;
  order_id: string;
  name: string;
  prefill: {
    GST: number;
    Processing_Fee: number;
    Wallet_Amount: number;
    Final_Price: number;
  };
  handler: () => void;
  modal?: {
    ondismiss?: () => void;
  };
};

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start 
  isContractEnd:boolean; 
  viewProposal:boolean;
  feedBack:string;
  ratingCount:number;
  viewWorkPost:boolean;
  endContractPopUp:boolean;
  proposalData:ProposalData;
  workOpportunityData:WorkOpportunityData;
  reasonEndContractDescription:string;
  giveRatingPopUp:boolean;
  menuOpen: boolean;
  showNotification: boolean;
  notification_count: number;
  unread_notification_count: number;
  mainDetails: ContractDetailMain
  isViewAllMilestone: boolean;
  showUpcoming: boolean;
  addMileStone: boolean;
  milestoneName: string;
  milestoneDescription: string;
  milestoneDeliverables: {
    id: number | string;
    deliverable: string;
    deliverableError: boolean;
    deliverable_type: string;
    prevAdd: boolean
  }[];
  milestoneAmount: number | null;
  milestoneDueDate: Dayjs | null;
  isMilestoneNameLimit: boolean
  deleteDataState: any[]
  openCalender: boolean
  isWordLimitDescription: boolean
  feedbackError: boolean;
  raiseDisputeDescriptionError: boolean
  isWordLimitReasonDescritpion: boolean
  isWordLimitSubmitDescritpion: boolean
  editAmountCheck: boolean;
  expanded: boolean[];
  accordingToCondiitonData: Milestone[] | { remaining_milestones: number; }[];
  mileStoneData: any[];
  editPopUp: boolean;
  milestoneId: number;
  milestoneType: string;
  isViewSubmessionPopUp: boolean
  viewAllMilestoneData: any[];
  remaining_milestones: number;
  toastOpen: boolean;
  toastError: string;
  message: string;
  action: string;
  submessionLinksData: any[];
  resourcelinks: any[];
  isViewSubmessionPopUpAll: boolean
  viewSubmessionData: any[];
  afterSubmessiondata: boolean;
  userId: string;
  accessToken: string;
  error: string;
  user: User | null;
  loading: boolean;
  query: null | PreviousMessageListQuery;
  isChatRoomOpened: boolean;
  messages: (UserMessage | FileMessage)[];
  isMessagesLoading: boolean;
  channelUrl: string;
  channel: GroupChannel | null;
  fileUploadAnchorEl: null | HTMLElement;
  input: string;
  unreadMessageCount: number;
  page: number;
  perPage: number;
  indexOfNotification: boolean
  notificationdata:Notification[];
  temp: number;
  isActivatingLoader: boolean;
  requestModalOpen: boolean;
  changeRequestIndex: number,
  changeRequestMilestone: Milestone;
  openApproveMilstone: boolean;
  openDeleteMilestoneModal: boolean;
  openViewSubmissionModal: boolean;
  openAddResourceModal: boolean;
  openRenderAllSubmissionsModal: boolean;
  workSubmissionForMilestone: WorkSubmissionsResponse;
  openExtendContractModal: boolean;
  openActiveMilestoneModal: boolean;
  milestoneActivateData: Milestone,
  milestoneActiveIndex: number;
  activateMilestoneContractId: number | string;
  razorPayOrderData: RazorpayOrderResponse;
  razorPayOptions: RazorpayOptions;
  taxValues: {
    loadWallet: number,
    gst: number,
  };
  paymentPopUp1: boolean;
  paymentStatus1: string
  statusText12: string;
  statusText21: string;
  statusColor1: string;
  walletBalance: number | string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContractDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start  
  walletPayApicallAPIcallId: string = ""
  getWalletBalanceApiCallId: string = ""
  failApiCallId1: string = ""
  successApiCallId1: string = ""
  getTaxCalcDataApicallId: string = ""
  getOrderIdForrazorPayAPIcallId: string = ""
  apiActiveContrcatDetail: string = ''
  editMilestoneId: string = "";
  postAddMilestoneID: string = ""
  allSubmissionId: string = ''
  resourceLinkId: string = ''
  viewSubmissionId: string = ""
  setDemoChannelId: string = ""
  notificationLinkId: string = "";
  designerCardRef: RefObject<HTMLDivElement> = createRef()
  activateMilstoneApiCallId: string = ""
  deleteNonActiveMilstoneApiCallId: string = ""
  approveMilestonApiCallId: string = ""
  requestChabgesApiCallId: string = ""
  approveChangesFromDesignerApiId: string = ""
  declineChangesReqApiCallId: string = ""
  getSubmissionViewDataApiCallId: string = ""
  viewProposalId:string = ""
  viewWrokPostId:string = ""
  createContractEnd: string = ""
  addResourcesAPICallId: string = ""
  allSubmessionsId: string = ""
  extendContractApiId: string = ""
  viewNotificationApiCallId: string = ""
  sendBirdMock: SendBirdMock = {
    appId: "YOUR_APP_ID",
    currentUser: {
      userId: "user_123",
      nickname: "John Doe",
      profileUrl: "https://example.com/avatar.jpg",
      connectionStatus: "online",
      lastSeenAt: 1706612345678, // Timestamp
    } as unknown as User,
    unreadMessageCount: 5,
  } as unknown as SendBirdMock;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      isContractEnd:false,
      ratingCount:0,
      feedBack:"",
      giveRatingPopUp:false,
      workOpportunityData:{},
      proposalData:{},
      viewProposal:false,
      viewWorkPost:false,
      endContractPopUp:false,
      reasonEndContractDescription:"",
      menuOpen: false,
      showNotification: false,
      notification_count: 0,
      unread_notification_count: 0,
      mainDetails: {},
      isViewAllMilestone: false,
      showUpcoming: false,
      addMileStone: false,
      milestoneName: "",
      milestoneDescription: '',
      milestoneDeliverables: [],
      milestoneAmount: null,
      milestoneDueDate: dayjs(),
      isMilestoneNameLimit: false,
      deleteDataState: [],
      openCalender: false,
      isWordLimitDescription: false,
      feedbackError: false,
      isWordLimitReasonDescritpion: false,
      isWordLimitSubmitDescritpion: false,
      raiseDisputeDescriptionError: false,
      editAmountCheck: false,
      expanded: [],
      accordingToCondiitonData: [],
      mileStoneData: [],
      editPopUp: false,
      milestoneId: 0,
      milestoneType: '',
      isViewSubmessionPopUp: false,
      viewAllMilestoneData: [],
      remaining_milestones: 0,
      action: '',
      message: '',
      toastError: '',
      toastOpen: false,
      submessionLinksData: [],
      resourcelinks: [],
      isViewSubmessionPopUpAll: false,
      viewSubmessionData: [],
      afterSubmessiondata: false,
      userId: "",
      accessToken: "",
      error: "",
      user: null,
      loading: false,
      query: null,
      isChatRoomOpened: false,
      messages: [],
      isMessagesLoading: false,
      channelUrl: "",
      channel: null,
      fileUploadAnchorEl: null,
      input: "",
      unreadMessageCount: 0,
      page: 1,
      perPage: 10,
      indexOfNotification: false,
      notificationdata: [],
      temp: 0,
      isActivatingLoader: false,
      requestModalOpen: false,
      changeRequestIndex: 1,
      changeRequestMilestone: {} as Milestone,
      openApproveMilstone: false,
      openDeleteMilestoneModal: false,
      openViewSubmissionModal: false,
      workSubmissionForMilestone: {} as WorkSubmissionsResponse,
      openExtendContractModal: false,
      openAddResourceModal: false,
      openRenderAllSubmissionsModal: false,
      openActiveMilestoneModal: false,
      milestoneActivateData: {} as Milestone,
      milestoneActiveIndex: 0,
      activateMilestoneContractId: "",
      razorPayOrderData: {} as RazorpayOrderResponse,
      razorPayOptions: {} as RazorpayOptions,
      taxValues: {
        loadWallet: 3,
        gst: 18,
      },
      paymentPopUp1: false,
      paymentStatus1: "",
      statusText12: "",
      statusText21: "",
      statusColor1: "",
      walletBalance: 0
    };
    this.designerCardRef = React.createRef<HTMLDivElement>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiActiveContrcatDetail:
          this.handleActiveContrcatDetailData(responseJson);
          break;

        case this.editMilestoneId:
          this.handleEditMilestoneResponse(responseJson);
          break;

        case this.postAddMilestoneID:
          this.handleAddMilestoneResponse(responseJson);
          break;

        case this.resourceLinkId:
          this.handleResourceData(responseJson);
          break;

        case this.viewSubmissionId:
          this.manageViewSubmitData(responseJson);
          break;

        case this.setDemoChannelId:
          this.setState({
            channel: this.sendBirdMock as unknown as GroupChannel
          })
          break;
        
        case this.activateMilstoneApiCallId:
          this.handleActiveMilestoneResponse(responseJson)
          break;

        case this.deleteNonActiveMilstoneApiCallId:
          this.handleDeleteNonActiveMilestonApiResponse(responseJson)
          break;

        case this.requestChabgesApiCallId:
          this.handleRequestChangeApiCallResponse(responseJson)
          break;

        case this.approveMilestonApiCallId:
          this.handleApproveMilestonApiCallResponse(responseJson)
          break;

        case this.approveChangesFromDesignerApiId:
          this.handleApproveChangesFromDesignerApiResponse(responseJson);
          break;
        case this.viewProposalId:
          this.handleProposalApiResponse(responseJson)
          break;
        case this.viewWrokPostId:
          this.handleWorkPostApiResponse(responseJson)
          break;
        case this.createContractEnd:
          this.handleEndContractApiResponse(responseJson)
          break;
    
        case this.notificationLinkId:
          this.manageNotification(responseJson);
          break;

        case this.declineChangesReqApiCallId:
          this.handleDeclineChangeReqResponse(responseJson);
          break;

        case this.getSubmissionViewDataApiCallId:
          this.handeleSubmissionData(responseJson);
          break;

        case this.addResourcesAPICallId:
          this.handleAddResourceresp(responseJson);
          break;
        case this.allSubmessionsId:
          this.handleReponseAllSubmissionsData(responseJson);
          break;

        case this.extendContractApiId:
          this.handleExtendContractRes(responseJson);
          break;

        case this.viewNotificationApiCallId:
          this.setState({ indexOfNotification: true });
          this.handleNotification(responseJson);
          break;

        case this.getOrderIdForrazorPayAPIcallId:
          this.handleOrderIdResponse(responseJson);
          break;

        case this.getTaxCalcDataApicallId:
          this.handleChargesData(responseJson);
          break;

        case this.getWalletBalanceApiCallId:
          this.handleWalletBalanceData(responseJson);
          break;

        case this.walletPayApicallAPIcallId:
          this.handleWalletPayResponse(responseJson);
          break;

        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  lastMessageRef: HTMLDivElement | null = null;
  chatHistoryConRef: HTMLDivElement | null = null;

  async componentDidMount() {
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    cardId = this.props.navigation.getParam("id");
    this.activeContractAPI();
    this.getNotificationLink(this.state.page);
    this.getResourceLink();
    this.initializeSendBird();
    this.getTaxCalcData()
    if (!(window as any).Razorpay) {
      this.loadRazorpay();
    }
  }

  loadRazorpay = () => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.body.appendChild(script);
  };

  declineChangesRequestApiCall = async (contractId: string, milestoneId: number, isDelete: boolean) => {
    if(isDelete) {
      this.deleteMilestoneApiCall(contractId, milestoneId);
      return;
    }

    this.setState({
      isActivatingLoader: true
    });

    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    let reqBody = {
        "data": {
            "attributes": {
                "contract_id": contractId,
                "milestone_id": milestoneId,
                "milestone_type": "BxBlockDashboard::ContractMilestone"
            }
        }
    }

    const activateMilestoneApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.declineChangesReqApiCallId = activateMilestoneApiCall.messageId

    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/decline_edit_request/`
    )
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(activateMilestoneApiCall.id, activateMilestoneApiCall);
  }

  handleApproveChangesFromDesigner = (contractId: string, milestoneId: number, isNewRequest: boolean) => {

    this.setState({
      isActivatingLoader: true
    });

    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    let reqBody = {
        "data": {
            "attributes": {
                "contract_id": contractId,
                "milestone_id": milestoneId,
                "milestone_type": "BxBlockDashboard::ContractMilestone"
            }
        }
    }

    const activateMilestoneApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.approveChangesFromDesignerApiId = activateMilestoneApiCall.messageId

    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isNewRequest ? "/bx_block_cfdesignersidecontractmanagement/client_contracts/approve_request/"
       : `/bx_block_cfdesignersidecontractmanagement/client_contracts/approve_edit_request/`
    )
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(activateMilestoneApiCall.id, activateMilestoneApiCall);
  }

  handleApproveChangesFromDesignerApiResponse = (responseJson: {message: string}) => {
    this.setState({
      isActivatingLoader: false
    });
    if((responseJson.message === "Designer edit request approved successfully") || (responseJson.message === "Milestone request approve successfully") ){
      this.activeContractAPI();
      this.setState({
        toastOpen: true,
        message: responseJson.message,
        action: "success",
      })
    } else {
      this.setState({
        toastOpen: true,
        message: responseJson.message,
        action: "danger",
      })
    }
  }

  handleApproveMilestonApiCallResponse = (responseJson: {milestone_status?: string}) => {
    this.setState({
      isActivatingLoader: false
    });

    if(responseJson.milestone_status !== "approved") {
      return;
    }

    this.activeContractAPI();
    this.setState({
      toastOpen: true,
      message: "Milestone Approved successfully",
      action: "success",
    })
  }

  handleRequestChangeApiCallResponse = (responseJson: {}) => {
    this.setState({
      isActivatingLoader: false
    });
    this.activeContractAPI();
    this.setState({
      toastOpen: true,
      message: "Change request sent succeessfully",
      action: "success",
    })
  }

  handleDeclineChangeReqResponse = (responseJson: { message: string }) => {
    this.setState({
      isActivatingLoader: false
    });
    if (responseJson.message === "Edit request declined successfullly") {
      this.activeContractAPI();
      this.setState({
        toastOpen: true,
        message: responseJson.message,
        action: "success",
      })
    }
  }

  handleOpenDeleteMilestoneModal = () => {
    this.setState({
      openDeleteMilestoneModal: true
    })
  }

  handleCloseDeleteMilestoneModal = () => {
    this.setState({
      openDeleteMilestoneModal: false
    })
  }

  handleCloseActiveMilestoneModal = () => {
    this.setState({
      openActiveMilestoneModal: false
    })
  }

  handleOpenActiveMilestoneModal = () => {
    this.setState({
      openActiveMilestoneModal: true
    })
  }

  deleteMilestoneApiCall = async (contractId: string, milestoneId: number) => {

    this.setState({
      isActivatingLoader: true
    });

    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const deleteNonActiveMilestoneApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteNonActiveMilstoneApiCallId = deleteNonActiveMilestoneApiCall.messageId

    deleteNonActiveMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfdesignersidecontractmanagement/client_contracts/delete_milestone?data[attributes][contract_id]=${contractId}&data[attributes][milestone_id]=${milestoneId}&data[attributes][milestone_type]=BxBlockDashboard::ContractMilestone`
    )
    deleteNonActiveMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteNonActiveMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(deleteNonActiveMilestoneApiCall.id, deleteNonActiveMilestoneApiCall);
  }

  handleOpenApproveMilestoneModal = () => {
    this.setState({
      openApproveMilstone: true
    })
  }

  handleCloseApprovalMilestoneModal = () => {
    this.setState({
      openApproveMilstone: false
    })
  }

  handleApproveClick = (mileston: Milestone, index: number) => {
    this.setState({
      changeRequestMilestone: mileston,
      changeRequestIndex: index + 1
    }, () => {
      this.handleOpenApproveMilestoneModal()
    })
  }

  handleViewSubmissionClick = (mileston: Milestone, index: number, contractId: number | string, increment?: boolean) => {

    this.getSubmissionViewData(contractId, mileston.id)

    this.setState({
      changeRequestMilestone: mileston,
      changeRequestIndex: increment ? index + 1 : this.state.changeRequestIndex
    })
  }

  handleCloseViewSubmissionModal = () => {
    this.setState({
      openViewSubmissionModal: false,
    })
  }

  getSubmissionViewData = async (contractId: number | string, milestoneId: number | string) => {
    const headers = {
        "Content-Type": "application/json",
        token: tokenData,
    };

    const getSubmissionViewDataApiCall = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
      this.getSubmissionViewDataApiCallId = getSubmissionViewDataApiCall.messageId
   
    getSubmissionViewDataApiCall.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_cfdesignersidecontractmanagement/designer_work_submissions/get_submissions_based_on_milestone/?work_submission[contract_id]=${contractId}&work_submission[contract_milestone_id]=${milestoneId}&work_submission[contract_milestone_type]=BxBlockDashboard::ContractMilestone`
      )
    getSubmissionViewDataApiCall.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getSubmissionViewDataApiCall.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(getSubmissionViewDataApiCall.id, getSubmissionViewDataApiCall);
  }

  handeleSubmissionData = (responseJson: WorkSubmissionsResponse) => {
    if(responseJson?.data){
      this.setState({
        openViewSubmissionModal: true,
        workSubmissionForMilestone: responseJson
      })
    }
  }

  handleAddResourceresp = (responseJson: [] | { errors: { error: string } }) => {
    if ("errors" in responseJson) {
      this.setState({
        toastOpen: true,
        message: (responseJson as { errors: { error: string } }).errors.error,
        action: "danger",
      })
    } else {
      this.setState({
        resourcelinks: responseJson as []
      })
      this.setState({
        toastOpen: true,
        message: "Resource has added successfully",
        action: "success",
      })
    }
  }

  openRenderAllSubmissionsModalFn = () => {
    this.setState({
      openRenderAllSubmissionsModal: true
    })
  }

  closeRenderAllSubmissionModalFn = () => {
    this.setState({
      openRenderAllSubmissionsModal: false
    })
  }
  
  handleReponseAllSubmissionsData = (responseJson: {
    data: WorkSubmission[];
    meta: {
      message: string;
    };
  }) => {
    const transformedData: (SubmissionFile | SubmissionLink)[] = responseJson.data.flatMap((item) => {
      const submissionTime = item.attributes.submission_time;

      const files: SubmissionFile[] = (item.attributes.files || []).map((file) => ({
        file_type: file.file_type,
        file_name: file.file_name,
        url: file.url,
        submission_time: submissionTime,
      }));

      const links: SubmissionLink[] = (item.attributes.submission_links || []).map((link) => ({
        link_url: link,
        submission_time: submissionTime,
      }));

      return [...files, ...links];
    });

    this.setState((prevState) => {
      const existingData = new Set(
        prevState.submessionLinksData.map((item) => 
          "url" in item ? `file:${item.url}` : `link:${item.link_url}`
        )
      );
    
      const uniqueData = transformedData.filter((item) => {
        const identifier = "url" in item ? `file:${item.url}` : `link:${item.link_url}`;
        if (existingData.has(identifier)) {
          return false;
        }
        existingData.add(identifier);
        return true;
      });
    
      return {
        submessionLinksData: [...prevState.submessionLinksData, ...uniqueData],
      };
    });
  };
  

  handleOpenAddResourceModal = () => {
    this.setState({
      openAddResourceModal: true
    })
  }

  handleCloseAddResourceModal = () => {
    this.setState({
      openAddResourceModal: false
    })
  }

  sendApprovalMilestoneApiCall = async (contractId: string, milestoneId: number) => {

    this.setState({
      isActivatingLoader: true
    });

    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    let reqBody = {
        "data": {
            "attributes": {
                "contract_id": contractId,
                "milestone_id": milestoneId,
                "milestone_type": "BxBlockDashboard::ContractMilestone"
            }
        }
    }

    const activateMilestoneApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.approveMilestonApiCallId = activateMilestoneApiCall.messageId

    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/approve_milestone/`
    )
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(activateMilestoneApiCall.id, activateMilestoneApiCall);
  }

  sendChangeRequestApiCall = async (contractId: string, milestoneId: number, changeMessage: string) => {

    this.setState({
      isActivatingLoader: true
    });

    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    let reqBody = {
        "data": {
            "attributes": {
                "contract_id": contractId,
                "milestone_id": milestoneId,
                "milestone_type": "BxBlockDashboard::ContractMilestone",
                "milestone_changes": changeMessage
            }
        }
    }

    const activateMilestoneApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestChabgesApiCallId = activateMilestoneApiCall.messageId

    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfdesignersidecontractmanagement/client_contracts/request_changes_milestone/`
    )
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(activateMilestoneApiCall.id, activateMilestoneApiCall);
  }

  handleOpenRequestModalOpen = () => {
    this.setState({
      requestModalOpen: true
    })
  }

  handleCloseRequestModalOpen = () => {
    this.setState({
      requestModalOpen: false
    })
  }

  handleChaneRequest = (mileston: Milestone, index: number) => {
    this.setState({
      changeRequestMilestone: mileston,
      changeRequestIndex: index + 1
    }, () => {
      this.handleOpenRequestModalOpen()
    })
  }

  handleActiveMilestoneResponse = (responseJson: { activate: boolean }) => {
    this.setState({
      isActivatingLoader: false
    });
    if (responseJson.activate) {
      this.activeContractAPI();
      this.setState({
        toastOpen: true,
        message: "Milstone has activated",
        action: "success",
      })
    }
  }

  handleDeleteNonActiveMilestonApiResponse = (responseJson: { message: string }) => {
    this.setState({
      isActivatingLoader: false
    });
    if (responseJson.message === "Milestone deleted successfully") {
      this.activeContractAPI();
      this.setState({
        toastOpen: true,
        message: responseJson.message,
        action: "success",
      })
    }
  }

  initializeSendBird = async () => {
    const sendBirdChat = SendbirdChat.init({
      appId: SendBird_Info.appId,
      localCacheEnabled: false,
      modules: [
        new GroupChannelModule()
      ]
    }) as SendbirdGroupChat
    if (!!sendBirdChat) {
      this.sendBird = sendBirdChat;
      collChatFunctions(this.inialSendBirdFunctions, 2000)
    }
  }

  inialSendBirdFunctions = () => {
    this.getTokenFromLocalStorage();
  }

  getTokenFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    let userId: string = "";
    let accessToken: string = "";
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.id) && (userId = tokenParseData.meta.sendbird_user_id);
      (tokenParseData.meta.id) && (accessToken = tokenParseData.meta.sendbird_access_token);
      this.connectingUserToSendbird(`${userId}`, accessToken);
      this.setState({ userId: `${userId}`, accessToken })
    }
  }

  connectingUserToSendbird = async (userId: string, accessToken: string) => {
    if (!!this.sendBird) {
      try {
        const user = await this.sendBird.connect(userId, accessToken);
        this.setState({ user: user }, () => this.connectChannel());

      }
      catch (error) {
        if (error instanceof SendbirdError) {
          this.setState({ error: error.message });
        } else {
          this.setState({ error: "" });
        }
      }
    }
  }
  connectChannel = async () => {
    if (!!this.sendBird && !!this.state.channelUrl) {
      try {
        const channel = await this.sendBird.groupChannel.getChannel(this.state.channelUrl);
        if (!!channel) {
          this.setState({ channel, unreadMessageCount: channel.unreadMessageCount }, () => {
            this.getMessages()
          });
          const groupChannelHandler = new GroupChannelHandler({
            onMessageReceived: this.onMesssageReceive
          });

          this.sendBird.groupChannel.addGroupChannelHandler("UNIQUE_HANDLER_ID", groupChannelHandler);
        }

      } catch (error) {
        this.setState({ error: configJSONChat.errorMessage });
      }
    }
  }

  onMesssageReceive = (channel: BaseChannel, message: BaseMessage) => {
    if (this.state.channel) {
      this.setState(prev => ({ messages: [...prev.messages, message as UserMessage] }), () => {
        setTimeout(this.scrollToLastMessage, 1000)
      })
      if (this.state.isChatRoomOpened) {
        this.handleMarkAsRead()
      }
    }
  }

  handleMarkAsRead = () => {
    this.state.channel?.markAsRead().then(() => this.setState({
      unreadMessageCount: 0
    }));
  }

  getMessages = async () => {
    if (!!this.state.channel) {
      const { messages, query } = await this.retriveMessageFromChannel(this.state.channel);
      this.setState({ messages: messages as UserMessage[], isMessagesLoading: false, query }, () => {
        this.scrollToLastMessage()
      }
      );
    }
  }

  retriveMessageFromChannel = async (channel: GroupChannel) => {
    const params: PreviousMessageListQueryParams = {
      limit: 20,
      reverse: false,
      includeReactions: true,
    };

    const query = channel.createPreviousMessageListQuery(params);
    const messages = await query.load();
    return { messages, query };
  }

  updateLastMessageRef = (element: HTMLDivElement) => {
    this.lastMessageRef = element;
  }

  updateChatHistoryConRef = (element: HTMLDivElement) => {
    this.chatHistoryConRef = element;
  }

  handleChatRoomOpen = () => {
    if (!this.state.isChatRoomOpened) {
      this.connectChannel().then(() => {
        this.handleMarkAsRead()
      })
    }
    this.setState({
      isChatRoomOpened: !this.state.isChatRoomOpened
    })
  }

  handleFilesMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ fileUploadAnchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ fileUploadAnchorEl: null })
  }

  handleVideoImagesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.files) {
      const file = event.target.files[0];

      if (file && (file.type.startsWith('image/') || file.type.startsWith('video/')) || this.isDocumentType(file.type)) {
        if (this.state.channel) {
          const params: FileMessageCreateParams = {
            file: file,
            fileName: file.name,
            fileSize: file.size,
            thumbnailSizes: [{ maxWidth: 200, maxHeight: 300 }, { maxWidth: 400, maxHeight: 600 }],
            mimeType: file.type,
          };
          this.state.channel.sendFileMessage(params)
            .onSucceeded((message) => {
              const newMessage = message as (UserMessage | FileMessage);
              this.setState(previous => ({ messages: [...previous.messages, newMessage], input: "", fileUploadAnchorEl: null }))
              this.scrollToLastMessage();
            });
        }
      }
    }
  }

  handleChatInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ input: event.target.value })
  }

  isDocumentType = (type: string) => {
    const documentMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain'
    ];
    return documentMimeTypes.includes(type)
  }

  handleChatInputKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.sendMessageToChannel()
    }
  }

  sendMessageToChannel = () => {
    if (this.state.input.trim().length > 0
    ) {
      this.sendMessage(this.maskSecuredData(this.state.input));
    }
  }

  sendMessage = (message: string) => {
    if (message.length > 0 && !!this.state.channel) {
      const params: UserMessageCreateParams = {
        message: message,
      }
      this.state.channel.sendUserMessage(params)
        .onSucceeded((message) => {
          const newMessage = message as UserMessage;
          this.setState((previous: S) => {
            return ({
              messages: [...previous.messages, newMessage],
              input: "",
            })
          }, () => {
            this.scrollToLastMessage();
          }
          )
        })
    }
  }
  maskSecuredData = (inputData: string) => {
    const matchFunction = (matchedData: string) => {
      return matchedData.slice(0, 3) + configJSONChat.starText.repeat(matchedData.length - 3)
    }

    const emailMatchFumction = (matchedData: string) => {
      let [headEmail, tailEmail] = matchedData.split("@");
      headEmail = headEmail.slice(0, 1) + configJSONChat.starText.repeat(headEmail.length - 1);
      return headEmail + "@" + tailEmail;
    }

    const matchedZoomFunction = (matchedData: string) => {
      return matchedData.slice(0, 5) + configJSONChat.starText.repeat(matchedData.length - 7) + matchedData.slice(matchedData.length - 2)
    }

    const matchedFunction = (matchedData: string) => {
      return matchedData.slice(0, 2) + configJSONChat.starText.repeat(matchedData.length - 4) + matchedData.slice(matchedData.length - 2)
    }

    const totalMask = (matchedData: string) => {
      return configJSONChat.starText.repeat(matchedData.length)
    }

    const termsMasking = (inputData: string) => {
      let sanitizedData = inputData;
      configJSONChat.secureKeywords.map((secureKeyword: string) => {
        if (sanitizedData.toLowerCase().includes(secureKeyword.toLowerCase())) {
          const regex = new RegExp(secureKeyword, "gi");
          sanitizedData = maskedLoop(sanitizedData, regex, totalMask);

        }
      })
      return sanitizedData;
    }

    const maskUnWantedTextRegex = (inputData: string) => {
      let sanitizedData: string = inputData;
      sanitizedData = termsMasking(sanitizedData);
      sanitizedData = sanitizedData.split(" ").map(word => {

        let count = 0;

        if (word.search(configJSONChat.specialCharRegex) !== -1) {
          count++;
        }
        if (word.search(configJSONChat.digitsRegex) !== -1) {
          count++;
        }
        if (word.search(configJSONChat.alphaRegex) !== -1) {
          count++;
        }

        return count > 1 ? totalMask(word) : word
      }).join(" ");
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.paymentCardRegex, totalMask);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.nondisclosureRegex, totalMask);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.emailNameRegex, emailMatchFumction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.zoomLinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.googleMeetlinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.instagramRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.twitterLinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.linkdinLinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.paypalRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.stripePaymentRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.phoneNumberRegex, matchFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.IAFCRegex, matchedFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.bankAccontRegex, matchedFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSONChat.pancardRegex, matchedFunction);
      return sanitizedData;
    }

    const maskedLoop = (inputData: string, regexp: RegExp, maskedFuntion: (substring: string, ...args: string[]) => string) => {
      const matches = Array.from(inputData.matchAll(regexp));
      let maskedData = inputData;
      for (const match of matches) {
        if (!!match && !!match[0] && match.index !== undefined) {
          maskedData = maskedData.slice(0, match.index) + match[0].replace(regexp, maskedFuntion) + maskedData.slice(match.index + match[0].length);
        }
      }

      return maskedData;
    }
    return maskUnWantedTextRegex(inputData)

  }

  scrollToLastMessage = () => {
    if (this.lastMessageRef && this.chatHistoryConRef) {
      this.chatHistoryConRef.scrollTop = this.chatHistoryConRef.scrollHeight - 400;
    }
  }
  hasContractEnded = (contract_end_date: string): boolean => {
    return !!contract_end_date;
  };
  handleActiveContrcatDetailData = (responseJson: ContractDetailMain) => {
    if (responseJson.contracts) {
      this.setState({ mainDetails: responseJson })
      const contractEnded = this.hasContractEnded(responseJson.contracts.data.attributes.contract_end_date)
      this.getProposalData(responseJson.contracts.data.attributes.proposal_id)
      this.getWorkPostData(responseJson.contracts.data.attributes.work_opportunity_id)
      this.getAllSubmessionLinksAndDocs(responseJson.contracts.data.id)
      const milestoneData = responseJson.contracts.data.attributes.active_milestone_details
      const remainingMilestone = milestoneData.length - 2;
      if(contractEnded){
        const index = milestoneData.map((m) => m.milestone_status).lastIndexOf("approved");
          milestoneData.splice(index+1, 0, {
            id: 1,
            name: responseJson.contracts.data.attributes.contract_end_text,
            description: "",
            amount: 0,
            due_date: responseJson.contracts.data.attributes.contract_end_date,
            milestone_status: "ended",
            activate: false,
            deliverables: [],
            milestone_type: "BxBlockDashboard::ContractMilestone",
        });
      }
      const accordingToConditionData = milestoneData.length > 2 ? milestoneData.slice(0, 2) : milestoneData as Milestone[];

      this.setState({
        isContractEnd : contractEnded,
        accordingToCondiitonData: this.state.isViewAllMilestone ? milestoneData as Milestone[] :
          [...accordingToConditionData, { remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0 }] as Milestone[],
        remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0,
        mileStoneData: milestoneData,
        expanded: new Array(milestoneData.length).fill(false),
        channelUrl: responseJson.contracts.data.attributes.sendbird_data.channels?.[0].channel_url
      }, () => {
        if (milestoneData.length > 0) {
          this.setState({ milestoneDueDate: dayjs(this.state.mileStoneData[this.state.mileStoneData.length - 1].due_date), })
        }
      })
    }
  }

  handleEditMilestoneResponse = (responseJson: { "message": "success", "errors": "", "error": "" }) => {
    if (!responseJson?.error && !responseJson?.errors) {
      this.setState({ addMileStone: false });
      this.setState(
        {
          action: "success",
          message: "Milestone edited successfully!",
          toastOpen: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              action: "",
              message: "",
              toastOpen: false,
            });
          }, 4000);
        }
      );
      this.resetStateForAddMilestone();
      this.activeContractAPI();
    }
  };

  handleAddMilestoneResponse = (responseJson: { "data": AddMilstoneResponse, "errors": "", "error": "" }) => {
    if (!responseJson?.error && !responseJson?.errors) {
      this.setState(
        {
          toastOpen: true,
          action: "success",
          message: "Milestone created successfully!",
        },
        () => {
          setTimeout(() => {
            this.setState({
              toastOpen: false,
              action: "",
              message: "",
            });
          }, 4000);
        }
      );
      this.resetStateForAddMilestone();
      this.setState({ addMileStone: false,showUpcoming:false });
      this.activeContractAPI();
    }
  };

  handleResourceData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({ resourcelinks: responseJson });
    }
  };

  manageViewSubmitData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({
        viewSubmessionData: responseJson?.data,
        afterSubmessiondata: responseJson?.data.length == 2 ? true : false,
      });
    }
  };

  viewMoreNotification = () => {
    this.setState({showNotification: !this.state.showNotification})
  }  

  handleScroll = () => {
    const loader = this.designerCardRef.current;
    const pageCount = Math.ceil(this.state.notification_count / this.state.perPage);
    if (loader) {
      const { scrollTop, scrollHeight, clientHeight } = loader;
      if (
        scrollTop + clientHeight >= scrollHeight
      ) {
        if (
          Math.ceil(this.state.notificationdata.length / this.state.perPage) <
          this.state.notification_count
        ) {
          if (this.state.page + 1 <= pageCount) {
            this.getNotificationLink(this.state.page + 1);
          }
        }
      }
    }
  };

  manageNotification = (responseJson: NotificationRoot) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({
        notificationdata: this.state.indexOfNotification
          ? this.state.notificationdata
          : [...this.state.notificationdata, ...responseJson.notifications],
        unread_notification_count: responseJson.unread_notification_count,
        notification_count: responseJson.notification_count,
      });
      this.setState({ indexOfNotification: false });
    }
  };

  handleMenuIcon = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };

  handleOpenViewAllMilestone = () => {
    this.setState({
      isViewAllMilestone: true,
      accordingToCondiitonData: this.state.mileStoneData,
      expanded: new Array(this.state.mileStoneData.length).fill(false),
      showUpcoming: false,
    });
  };

  escapeRemainingMilestone = () => {
    const remainingMilestone = this.state.mileStoneData.length - 2;
    const newConditionData = [
      ...this.state.mileStoneData.slice(0, 2),
      { remaining_milestones: Math.max(remainingMilestone, 0) }
    ];
    this.setState(prevState => ({
      accordingToCondiitonData: newConditionData,
      showUpcoming: false,
      expanded: [...prevState.expanded.slice(0, 2), ...new Array(Math.max(remainingMilestone, 0)).fill(false)]
    }));
  }

  handleCloseViewAllMilestone = () => {
    const remainingMilestone = this.state.mileStoneData.length - 2;
    this.setState({
      isViewAllMilestone: false,
      accordingToCondiitonData: [
        ...this.state.mileStoneData.slice(0, 2),
        {
          remaining_milestones: remainingMilestone > 0 ? remainingMilestone : 0,
        },
      ],
      expanded: new Array(this.state.mileStoneData.length).fill(false),
    });
  };

  handleAddMileStone = () => {
    this.setState({ addMileStone: true, editPopUp: false });
  };

  handleRemainingMilestone = () => {
    this.setState({
      accordingToCondiitonData: this.state.mileStoneData,
      showUpcoming: true,
    });
  };

  convertDateFormat = (dateString: string) => {
    if (dateString) {
      let [day, month, year] = dateString.split("-");
      year = year.length === 2 ? `20${year}` : year;
      return `${month}/${day}/${year}`;
    }
  };

  openEditMilestonePopUp = (data: any) => {
    const dataMilestone = data.deliverables
      .filter(
        (item: { id: number | string; deliverable: string }) => item.deliverable
      )
      .map((item: { id: number | string; deliverable: string }) => ({
        ...item,
        deliverableError: false,
        prevAdd: true,
      }));
    this.setState({
      addMileStone: true,
      editAmountCheck: data.activate,
      editPopUp: true,
      milestoneType: data.milestone_type,
      milestoneId: data.id,
      milestoneName: data.name,
      milestoneDescription: data.description,
      milestoneDeliverables: dataMilestone,
      milestoneAmount: data.amount,
      milestoneDueDate: dayjs(data?.due_date),
      isMilestoneNameLimit: false,
      openCalender: false,
      isWordLimitDescription: false,
      changeRequestMilestone: data
    });
  };

  getMinMaxDate: () => ({ minDate: Dayjs, maxDate: Dayjs }) = () => {
    let index = this.state.mileStoneData.findIndex((item: Milestone) => item.id === this.state.changeRequestMilestone.id);
    let minDate: Dayjs;
    let maxDate: Dayjs;

    if (index <= 0) {
      minDate = dayjs();
    } else {
      minDate = dayjs(this.state.mileStoneData[index - 1].due_date);
    }

    if (index + 1 >= this.state.mileStoneData.length) {
      maxDate = dayjs("2099-12-31");
    } else {
      maxDate = dayjs(this.state.mileStoneData[index + 1].due_date);
    }

    return { minDate, maxDate };
  }

  handleAccordionToggle = (index: number) => {
    const newExpanded = this.state.expanded.map((item: boolean, idx: number) =>
      idx === index ? !item : item
    );
    this.setState({ expanded: newExpanded });
  };

  findIndexOfWait = (data: any) => {
    for (let index = 0; index < data?.length; index++) {
      const item = data[index];

      if (item.milestone_status === "approved") {
        if (index + 1 < data.length) {
          if (!data[index + 1].activate) {
            return index + 1;
          }
        }
      }
    }

    return 0;
  };

  downloadImage = async (item: any) => {
    const response = await fetch(config.baseURL + item.url);
    const blob = await response.blob();
    const link = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    link.href = objectUrl;
    link.download = item.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectUrl);
  };

  showMileStoneNameError = () => {
    return this.state.isMilestoneNameLimit ? "Reached Limit of 50 char" : ""
  }

  showDescriptionError = () => {
    return this.state.isWordLimitSubmitDescritpion ? "Reached Limit of 1000 char" : ""
  }
  showMilestoneDescriptionError = () => {
    return this.state.isWordLimitDescription ? "Reached Limit of 500 char" : ""
  }
  showDeliverableError = (select: boolean) => {
    return select ? "Reached Limit of 150 char" : ""
  }

  handleMileStoneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    const isExceedingLimit = value.length > 50;

    if (!isExceedingLimit) {
      this.setState({ milestoneName: e.target.value });
    }
    this.setState({ isMilestoneNameLimit: isExceedingLimit });
  };

  handleMileStoneNameDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value.trim();
    const isExceedingLimit = value.length > 500;

    if (!isExceedingLimit) {
      this.setState({ milestoneDescription: e.target.value });
    }

    this.setState({ isWordLimitDescription: isExceedingLimit });
  };

  handleDeliverableChange = (id: number | string, e: any) => {
    const { value } = e.target;

    const updatedData = this.state.milestoneDeliverables.map(
      (
        milestone: {
          id: number | string;
          deliverable: string;
          deliverableError: boolean;
          prevAdd: boolean;
          deliverable_type: string;
        },
        index
      ) => {
        if (milestone.id == id) {
          return {
            ...milestone,
            deliverable: value,
            deliverableError: value.trim().length > 150,
          };
        }
        return milestone;
      }
    );

    this.setState({ milestoneDeliverables: updatedData });
  };

  handleDeleteDeliverable = (id: number | string) => {
    const updatedData = this.state.milestoneDeliverables.filter(
      (
        item: {
          id: number | string;
          deliverable: string;
          deliverableError: boolean;
          prevAdd: boolean;
        },
        index
      ) => {
        return item.id !== id;
      }
    );
    let deleteData = [];
    const deletDataFind = this.state.milestoneDeliverables.find(
      (
        item: {
          id: number | string;
          deliverable: string;
          deliverableError: boolean;
          prevAdd: boolean;
        },
        index
      ) => {
        return item.id == id && item.prevAdd;
      }
    );
    if (deletDataFind) {
      deleteData.push(deletDataFind.id);
      deleteData.push(deletDataFind.deliverable_type);
    }

    this.setState({
      milestoneDeliverables: updatedData,
      deleteDataState: [...this.state.deleteDataState, deleteData],
    });
  };

  handleAddDeliverable = () => {
    this.setState({
      milestoneDeliverables: [
        ...this.state.milestoneDeliverables,
        {
          id: this.state.milestoneDeliverables.length,
          deliverable: "",
          deliverableError: false,
          prevAdd: false,
          deliverable_type: "BxBlockDashboard::Deliverable",
        },
      ],
    });
  };

  handleMileStoneNameAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    if (numericValue <= 9999999) {
      this.setState({ milestoneAmount: numericValue });
    } else {
      this.setState({ milestoneAmount: 0 });
    }
  };

  handlePropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  openCalender = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openCalender: true,
    });
    this.handlePropagation(event);
  };

  isFormValid = () => {
    const {
      milestoneName,
      milestoneDescription,
      milestoneDueDate,
      milestoneDeliverables,
      milestoneAmount,
    } = this.state;
    if (
      this.state.isWordLimitDescription ||
      this.state.isMilestoneNameLimit ||
      !milestoneName ||
      !milestoneDescription ||
      !milestoneAmount ||
      milestoneAmount <= 0 ||
      !milestoneDueDate ||
      milestoneDeliverables.some(
        (item: { deliverable: string; deliverableError: boolean }) =>
          !item.deliverable || item.deliverableError
      )
    ) {
      return false;
    }
    return true;
  };

  handleApiForAddEditMilestone = () => {
    if (this.state.editPopUp) {
      this.handleEditMilestone();
    } else {
      this.handleAddMilestoneApi();
    }
  };

  closeCalender = () => {
    this.setState({
      openCalender: false,
    });
  };

  handleDateChange = (date: Dayjs | null) => {
    this.setState({ milestoneDueDate: date });
  };

  resetStateForAddMilestone = () => {
    this.setState({
      milestoneName: "",
      milestoneDescription: "",
      milestoneDeliverables: [
        {
          id: 0,
          deliverable: "",
          deliverableError: false,
          prevAdd: false,
          deliverable_type: "BxBlockDashboard::Deliverable",
        },
      ],
      milestoneAmount: null,
      milestoneDueDate: dayjs(
        this.state.mileStoneData[this.state.mileStoneData.length - 1].due_date
      ),
      isMilestoneNameLimit: false,
      deleteDataState: [],
      openCalender: false,
      isWordLimitDescription: false,
    });
  };

  handleAddMileStoneClose = () => {
    this.setState({ addMileStone: false });
    this.resetStateForAddMilestone();
  };

  getLastDate = (date:string) => {
      const notifyDate = new Date(date);
      const todayDate = new Date();
      const yesterdayDate = new Date(Date.now() - (24*60*60*1000));
  
      const notifyDateString = `${notifyDate.getDate()}/${notifyDate.getMonth()}/${notifyDate.getFullYear()}`;
      const todayDateString = `${todayDate.getDate()}/${todayDate.getMonth()}/${notifyDate.getFullYear()}`;
      const yesterdayDateString = `${yesterdayDate.getDate()}/${yesterdayDate.getMonth()}/${notifyDate.getFullYear()}`;
      if(notifyDateString === todayDateString) return configJSON.todayText;
      if(notifyDateString === yesterdayDateString) return configJSON.yesterdayText;
      return configJSON.lastWeekText;
    }

    formatDate = (isoDate: string): string => {
      const date = new Date(isoDate);
      const day1 = date.getDate().toString().padStart(2, "0");
      const month1 = (date.getMonth() + 1).toString().padStart(2, "0");
      const year1 = date.getFullYear().toString().slice(-2);
      const time1 = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${day1}/${month1}/${year1}, ${time1}`;
  };

  activeContractAPI = () => {
    const headerObj = {
      token: tokenData,
      "Content-Type": configJSON.ApiContentType,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiActiveContrcatDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activeContractApiEndPoint + cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditMilestone = () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };
    const data = this.state.milestoneDeliverables.map((item: any) => {
      if (item.prevAdd) {
        return {
          id: item.id,
          deliverable: item.deliverable,
          deliverable_type: item.deliverable_type,
        };
      } else {
        return {
          new_deliverable: item.deliverable,
        };
      }
    });

    let formattedDate = "";
    if (this.state.milestoneDueDate) {
      formattedDate = this.state.milestoneDueDate.format("YYYY-MM-DD");
    }

    const httpBody = {
      data: {
        attributes: {
          contract_id: cardId,
          milestone_type: "BxBlockDashboard::ContractMilestone",
          milestone_id: this.state.milestoneId,
          milestone_edits: {
            name: this.state.milestoneName,
            description: this.state.milestoneDescription,
            amount: this.state.milestoneAmount,
            due_date: formattedDate,
            deliverables: data,
            deleted_deliverables: this.state.deleteDataState,
          },
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editMilestoneId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/edit_milestone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddMilestoneApi = () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };
    const deliverablesData = this.state.milestoneDeliverables.map((item) => {
      return item.deliverable;
    });
    const httpBody = {
      data: {
        attributes: {
          contract_id: cardId,
          milestone_details: {
            name: this.state.milestoneName,
            description: this.state.milestoneDescription,
            deliverables: deliverablesData,
            amount: this.state.milestoneAmount,
            due_date: this.state.milestoneDueDate?.format("DD-MM-YYYY"),
          },
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddMilestoneID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/client_contracts/add_milestone"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getResourceLink = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resourceLinkId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfdesignersidecontractmanagement/contract_resources/${cardId}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getNotificationLink = async (page: number) => {
    this.setState({ page: page });
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationLinkId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications/contract_notifications?contract_id=${cardId}&page=${page}&per_page=${this.state.perPage}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  paymentSuccessFull = async (contractId: string | number, milestoneId: number) => {

    this.setState({
      isActivatingLoader: true
    });

    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    let reqBody = {
      "data": {
        "attributes": {
          "contract_id": contractId,
          "milestone_id": milestoneId
        }
      }
    }

    const activateMilestoneApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activateMilstoneApiCallId = activateMilestoneApiCall.messageId

    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/activate_milestone/`
    )
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activateMilestoneApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(activateMilestoneApiCall.id, activateMilestoneApiCall);
  }

  activateMilestone = async (contractId: string, mileston: Milestone, index: number) => {
    this.setState({
      milestoneActivateData: mileston,
      milestoneActiveIndex: index,
      activateMilestoneContractId: contractId
    }, () => {
      this.getExactPriceWithTaxes()
      this.getWalletBalance()
      this.handleOpenActiveMilestoneModal()
    })
  }

  handleWalletActivation = () => {
    this.setState({
      isActivatingLoader: true
    });
    const headers = {
      "Content-Type": "application/json",
      "token": tokenData
    };

    const httpBody = {
      "data": {
          "attributes": {
              "contract_id": this.state.activateMilestoneContractId,
              "milestone_id": this.state.milestoneActivateData.id
          }
      }
    };
    
    const walletPayApicall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    walletPayApicall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.walletPayApicallAPIcallId = walletPayApicall.messageId;

    walletPayApicall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/client_contracts/payment_from_wallet"
    );

    walletPayApicall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    walletPayApicall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(walletPayApicall.id, walletPayApicall);
    return true;

  }

  handleRazorPayActivation = () => {
    const options = {
      key: configJSON.apiKey,
      amount: this.state.razorPayOrderData.razorpay_order.attributes.amount,
      currency: this.state.razorPayOrderData.razorpay_order.attributes.currency,
      order_id: this.state.razorPayOrderData.razorpay_order.attributes.id,
      name: "Deziners Knot",
      handler: (response:RazorpayPaymentSuccessResponse) => {
        this.handleAfterPayment1("success", "Your payment was successful", "Thank you for your payment", "green");
        this.handleSuccessCall(response);
        this.paymentSuccessFull(this.state.activateMilestoneContractId, this.state.milestoneActivateData.id);
      },
      modal: {
        ondismiss: () => {
          this.handleAfterPayment1("cancel", "Your payment was canceled", "Please try again", "red");
        }
      }
    };

    const razorpay = new (window as any).Razorpay(options);
    razorpay.on('payment.failed', (response: RazorpayPaymentFailResponse) => {
      this.handleFailCall(response)
      this.handleAfterPayment1("fail", "Your payment was failed", "Please try again", "red");
    });
    razorpay.open();
  }

  handlePaymentPopupClose1 = () => {
    this.setState({ paymentPopUp1: !this.state.paymentPopUp1 })
  }

  handleAfterPayment1 = (status: string, param1: string, param2: string,bgColor:string) => {
    this.setState({
      paymentPopUp1:true,
      paymentStatus1:status,
      statusText12:param1,
      statusText21:param2,
      statusColor1:bgColor
    })
  };

  handleSuccessCall= async (response:RazorpayPaymentSuccessResponse)=> {
    const tokens = await getStorageData("userInfo",true); 
    const token = tokens.meta.token  
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      razorpay_order_id: response.razorpay_order_id,
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_signature: response.razorpay_signature
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/client_contracts/capture_milestone_payment"
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "POST"
    );
    this.successApiCallId1 = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleFailCall= async (response:RazorpayPaymentFailResponse)=> {
    const tokens = await getStorageData("userInfo",true);
    const token = tokens.meta.token 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
      razorpay_order_id: response.error.metadata.order_id,
      razorpay_payment_id: response.error.metadata.payment_id
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_payment_admin/payments/payment_failed"
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.failApiCallId1 = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getWalletBalance = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getWalletBalanceApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWalletBalanceApiCallId = getWalletBalanceApiCall.messageId

    getWalletBalanceApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/get_wallet_balance`
    )
    getWalletBalanceApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getWalletBalanceApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getWalletBalanceApiCall.id, getWalletBalanceApiCall);
  }

  getExactPriceWithTaxes = () => {
    this.setState({
      isActivatingLoader: true
    });
    const headers = {
      "Content-Type": "application/json",
      "token": tokenData
    };

    const httpBody = {
      "data": {
          "attributes": {
              "milestone_id": this.state.milestoneActivateData.id,
              "contract_id": this.state.activateMilestoneContractId,
          }
      }
    };
    
    const getOrderIdForrazorPay = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    getOrderIdForrazorPay.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getOrderIdForrazorPayAPIcallId = getOrderIdForrazorPay.messageId;

    getOrderIdForrazorPay.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/client_contracts/payment_from_razorpay"
    );

    getOrderIdForrazorPay.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    getOrderIdForrazorPay.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(getOrderIdForrazorPay.id, getOrderIdForrazorPay);
    return true;

  }

  handleChargesData = (response: { tax_configuration: {title: string, tax: number}[]}) =>  {
    this.setState({
      taxValues: {
        loadWallet: Math.round(this.getTaxValue("Wallet load processing fee",response )),
        gst: Math.round(this.getTaxValue("GST fee", response)),
      }
    })
  }

  handleWalletBalanceData = (response: {wallet_balance: number | string, }) => {
    if("wallet_balance" in response) {
      this.setState({
        walletBalance: response.wallet_balance
      })
    }
  }

  handleWalletPayResponse = (response: {message: string}) => {
    this.setState({
      isActivatingLoader: false
    });
    this.handleCloseActiveMilestoneModal();
    if("message" in response) {
      if(response.message === "Payment for milestone successfully done via wallet") {
        this.paymentSuccessFull(this.state.activateMilestoneContractId, this.state.milestoneActivateData.id);
      }
    }
  }

  getTaxValue = (title: string, data: { tax_configuration: {title: string, tax: number}[]}): number => {
    return data.tax_configuration.find((item) => item.title === title)?.tax || 0;
  };

  handleOrderIdResponse = (response: RazorpayOrderResponse) => {
    this.setState({
      isActivatingLoader: false
    });
    if (response.message === "Razorpay order created successfully") {
      this.setState({
        razorPayOrderData: response
      })
    } else {
      this.setState({
        toastOpen: true,
        message: "Something went wrong, Please try again later",
        action: "danger",
        openActiveMilestoneModal: false
      })
    }
  };

  getTaxCalcData = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getTaxCalcDataApicall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaxCalcDataApicallId = getTaxCalcDataApicall.messageId

    getTaxCalcDataApicall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_payment_admin/payments/tax_configuration`
    )
    getTaxCalcDataApicall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTaxCalcDataApicall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getTaxCalcDataApicall.id, getTaxCalcDataApicall);
  }
  
  deleteNonActiveMilestone = async (mileston: Milestone, index: number) => {
    this.setState({
      openDeleteMilestoneModal: true,
      changeRequestIndex: index + 1,
      changeRequestMilestone: mileston
    })
  }
  getProposalData = async (proposal_id: number | string) => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewProposalId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/proposals/${proposal_id}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  formatToLocaleString = (data: any) => {
    const rate_types = data?.attributes?.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }
  getWorkPostData = async (work_opportunity_id: number | string) => {
    const headers = {
      "Content-Type": "application/json",
      token: tokenData,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewWrokPostId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/work_opportunities/${work_opportunity_id}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getRateTypeLabelProposal = (data: any) => {
    const rate_types = data?.attributes?.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }
  handleReasonDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.trim();
    const isExceedingLimit = value.length > 1000;

    if (!isExceedingLimit) {
      this.setState({ reasonEndContractDescription: e.target.value });
    }

    this.setState({ isWordLimitReasonDescritpion: isExceedingLimit });
  };
  isSubmitFormValidRaesonEndContarct = () => {
    if (this.state.isWordLimitReasonDescritpion || !this.state.reasonEndContractDescription) {
      return true;
    }
    return false;
  }
  handleStarClick = (index: number) => {
    this.setState({ ratingCount: index + 1 });
  }
  handleFeedBackRating = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.trim();
    const isExceedingLimit = value.length > 1000;

    if (!isExceedingLimit) {
      this.setState({ feedBack: e.target.value });
    }

    this.setState({ feedbackError: isExceedingLimit });
  };
  showDescriptionErrorEndContractfeedback = () => {
    return this.state.feedbackError ? "Reached Limit of 1000 char" : ""
  }
  isFeedbackVaild = () => {
    if (this.state.feedbackError) {
      return true;
    }
    return false;
  }
  contractEndFn = () => {
    const allApproved = this.state.mileStoneData.every(milestone => milestone.milestone_status === "approved")
    const headers = {
      "Content-Type": "application/json",
      "token": tokenData
    };

    const httpBody = {
      "contract_end": {
        "status": allApproved ? "contract_end_succesfully":"contract_mid_ended",
        "reason": this.state.reasonEndContractDescription,
        "contract_id": cardId,
        "designer_rating": this.state.ratingCount,
        "designer_feedback": this.state.feedBack,
      }
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.createContractEnd = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_cfdesignersidecontractmanagement/designers_contracts/create_contract_end"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 
  handleWorkPostApiResponse = (responseJson: any) => {
    if (!responseJson?.error && !responseJson?.errors) {
      this.setState({ workOpportunityData: responseJson })
    }
  }
  handleProposalApiResponse = (responseJson: any) => {
    if (!responseJson?.error && !responseJson?.errors)
      this.setState({ proposalData: responseJson })
  }
  handleCloseRatingPopUp = () => {
    this.setState({ giveRatingPopUp: false, reasonEndContractDescription: '', feedBack: '', ratingCount: 0 })
  }
  handleEndContractApiResponse = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({ giveRatingPopUp: false })
      this.setState(
        {
          toastOpen: true,
          action: "success",
          message: responseJson.message,
        },
        () => {
          setTimeout(() => {
            this.setState({
              toastOpen: false,
              action: "",
              message: "",
            });
            this.props.navigation.navigate("ContractPage")
          }, 2000);
        })
    }
  }

  handleExtendContractRes = (responseJson: {"message": string}) => {
    if(responseJson.message) {
      this.setState(
        {
          toastOpen: true,
          action: "success",
          message: responseJson.message,
        },
        () => {
          setTimeout(() => {
            this.setState({
              toastOpen: false,
              message: "",
              action: "",
            });
          }, 2000);
        })
    }
  }

  handleNotification = (responseJson: { meta: { message: string } }) => {
    if (responseJson.meta.message) {
      this.getNotificationLink(this.state.page)
    }
  }

  addResourceLinksApiCall = (contractId: number | string, resources: { title: string, link: string }[]) => {
    const headers = {
      "Content-Type": "application/json",
      "token": tokenData
    };

    const httpBody = {
        "contract_id": contractId,
        "resources" : resources
    };
    
    const addResourcesApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    addResourcesApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.addResourcesAPICallId = addResourcesApiCall.messageId;

    addResourcesApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/contract_resources"
    );

    addResourcesApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    addResourcesApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(addResourcesApiCall.id, addResourcesApiCall);
    return true;
    
  }

  getAllSubmessionLinksAndDocs = async (contractId: string | number) => {
    const headers = {
      "Content-Type": "application/json",
      "token": tokenData
    };

    const getAllSubmissions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allSubmessionsId = getAllSubmissions.messageId

    getAllSubmissions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/designer_work_submissions/view_submissions/?work_submission[contract_id]=${contractId}`
    )
    getAllSubmissions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllSubmissions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getAllSubmissions.id, getAllSubmissions);
  }


  extendContract = (contractId: number | string, type: string, timeline: number) => {

    const headers = {
      "Content-Type": "application/json",
      "token": tokenData
    };

    const httpBody = {
      "data": {
        "attributes": {
          "contract_id": contractId,
          "extend_contract_timeline": timeline,
          "extend_contract_timeline_type": type
        }
      }
    };

    const extendContractApiCAll = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    extendContractApiCAll.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.extendContractApiId = extendContractApiCAll.messageId;

    extendContractApiCAll.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_cfdesignersidecontractmanagement/client_contracts/add_extend_contract"
    );

    extendContractApiCAll.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    extendContractApiCAll.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(extendContractApiCAll.id, extendContractApiCAll);
  };

  openExtendContractModal = () => {
    this.setState({
      openExtendContractModal: true
    })
  }

  closeExtendContractModal = () => {
    this.setState({
      openExtendContractModal: false
    })
  }

  viewNotification = (id:string) => {
    const headers = {
      "token": tokenData
    };

    const getAllSubmissions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewNotificationApiCallId = getAllSubmissions.messageId

    getAllSubmissions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_notifications/notifications/${id}`
    )
    getAllSubmissions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllSubmissions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getAllSubmissions.id, getAllSubmissions);
  }

  newFormatDate = (inputDate:string|undefined|null) => {
    let day, month, year;
    if (inputDate) {
      if (inputDate.includes('-')) {
        [year, month, day] = inputDate.split('-');
      } else if (inputDate.includes('/')) {
        [day, month, year] = inputDate.split('/');
      } else {
        return inputDate
      }
      return `${day}/${month}/${year.slice(-2)}`;
    }
  }
  navigateContract = () =>{
    const tabValue = this.state.isContractEnd?1:2
    setStorageData("contractTab",tabValue)
    this.props.navigation.navigate("ContractPage")
  }
  // Customizable Area End
}