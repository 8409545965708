import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData} from "../../../../framework/src/Utilities";
import {DropDownData,DropDownMain,DropDownElement} from '../JobListingController'
import { getTabIndex } from "../../../../../packages/components/src/Utils";

export interface DesignerContract {
  type: string;
  id: string;
  attributes: {
    work_opportunity_description: string;
    work_opportunity_title: string;
    designer_details: {
      skills_data: {
        updated_at: string;
        id: number;
        created_at: string;
        category_id: number;
        name: string;
      }[];
      location: string;
      company_name?: string;
    };
    calculate_years: string;
    duration: {
      contract_end_date: string | null;
      contract_start_date: string;
    };
  };
};


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isEditable:boolean;
  // Customizable Area End
}

interface S {
  tabsValue:number;
  active_ClientTab:string;
  showBullet:boolean;
  selected_Opportunity:string;
  dropDown: DropDownData[];
  openDropDown:boolean;
  SelectedDropDownName:string;
  SelectedDropDownColor:string;
  selectedOpportunity: string;
  SelectedDropDownID:number;
  activeId:string;
  work_Opportunities:any;
  currentPage:number;
  total_workopportunity:number;
  total_work_opportunity_count:number;
  DkContracts: DesignerContract[];
  
}

interface SS {
  id: any;
}
export const configJSON = require("../config.js");
export default class OpportunityClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  allOpportunityApiCallId :string='';
  selectedOpportunityApiCallId:string='';
  getDKcontractsAPIXcallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state ={
      tabsValue:0,
      active_ClientTab:'Opportunities',
      showBullet: false,
      selected_Opportunity:'',
      dropDown: [],
      openDropDown:false,
      SelectedDropDownName:'Open opportunity',
      SelectedDropDownColor:'#59BDD3',
      selectedOpportunity: 'open_opportunity',
      SelectedDropDownID:1,
      activeId:'',
      work_Opportunities:[],
      currentPage:1,
      total_workopportunity:0,
      total_work_opportunity_count:0,
      DkContracts:[] as DesignerContract[],
    }
   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
     this.allOpportiunity()
    this.handleWorkOpportuinity(this.state.selectedOpportunity)
    this.getDkContractsApiX()
        const tabValue = await getStorageData("activeCard")
        if(tabValue){
        this.setState({
          active_ClientTab:tabValue,
          tabsValue: getTabIndex(tabValue)
        })
        this.handleClientactivePort(tabValue)
        removeStorageData("activeCard")
      }
  }
  componentDidUpdate(prevProps:any) {
    if (prevProps.id !== this.props.id) {
      this.handleWorkOpportuinity(this.state.selectedOpportunity)
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.allOpportunityApiCallId) {
        this.seealltheOpportunity(responseJson) 
      }
      if(apiRequestCallId === this.selectedOpportunityApiCallId) {
        if (!responseJson.error) {
          this.setState(prevState => ({
            work_Opportunities: [...prevState.work_Opportunities, ...responseJson.data],
            total_workopportunity:responseJson.opportunities_count
        }))
        }else{
          this.setState({work_Opportunities:[]})
        }
      }
      if(apiRequestCallId === this.getDKcontractsAPIXcallId) {
        if (!responseJson?.error) { 
            this.setState({ DkContracts: responseJson.data })
        }
      }
    }
   
  }
  seealltheOpportunity =(response: DropDownMain) =>{

    if (response) {
      this.setState({total_work_opportunity_count:response.total_work_opportunity_count})
      
    }
    if (response.data.length) {
      const arr1 = response.data
      arr1.forEach(item => {
        const match = configJSON.dropDownDataStatic.find((el: DropDownElement) => el.value === item.value);
        if (match) {
          item.color = match.color;
        }
      });
      const filteredData = arr1.filter(item => {
        return !(item.value === 'deleted_post' && item.work_opportunity_count === 0);
      });
      this.setState({ dropDown: filteredData })
    }

  }
  handleTabValueChange = (event:React.ChangeEvent<{}>, newValue: number) => {
   
    this.setState({tabsValue:newValue})
    
  };

  handlenavigatetoFindwork =()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PostOpportunity');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  dropDownViewData = (element: DropDownElement) => {
    this.setState({
      currentPage:1,
      work_Opportunities:[],
      SelectedDropDownName: element.name,
      SelectedDropDownColor: element.color,
      selectedOpportunity: element.value,
      openDropDown: !this.state.openDropDown,
      SelectedDropDownID: element.id,
      activeId: ''
    },()=>this.handleWorkOpportuinity(this.state.selectedOpportunity)) 

  }
  handleClientactivePort=(data:string)=>{
    setStorageData("activeCard", data)
    this.setState({active_ClientTab:data})
  }
  
  
allOpportiunity = async() => {
  
  const tokens = await getStorageData('userInfo');   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
  

  const header = {
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.allOpportunityApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.dropDownApiEndPoint}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handlepagination=()=>{
  this.setState({currentPage:this.state.currentPage+1})
  this.handleWorkOpportuinity(this.state.selectedOpportunity)
}
handleNavigatePostOpportunity =()=>{
  const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PostOpportunity');
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}
handlerateAmount = (data:any)=>{
  let RateAmount = data.attributes.rate_amount?data.attributes.rate_amount.toLocaleString('en-IN'):0
  return RateAmount
}
handleMonths = (data:any) =>{
  let months = data.attributes.project_timeline?data.attributes.project_timeline : 0
  return months
}
handleWorkOpportuinity =async(data:string) =>{
  const tokens = await getStorageData('userInfo');   
  let newToken = JSON.parse(tokens)
  const {meta: { token} } = newToken;
  let id = await this.props.id
  if (id != '0') {
   
const header = {
  token: token
};

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.selectedOpportunityApiCallId = requestMessage.messageId;
 requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.workOpportunitiesEndPoint}?page=${this.state.currentPage}&per_page=12&account_id=${id}&filter=${data}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.httpGetMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);
return true;
  }
}
handleSetting = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Settings2');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
}
navigateInsitePage = () => {
  const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Analytics');
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}

getDkContractsApiX = async() => {
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token, id } } = newToken;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const getDkContractsApiX = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getDKcontractsAPIXcallId = getDkContractsApiX.messageId;

  getDkContractsApiX.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_projectportfolio/professional_experiences/designer_dk_contract?account_id=${id}&page=1&per_page=1000000`
  );
  
  getDkContractsApiX.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getDkContractsApiX.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(getDkContractsApiX.id, getDkContractsApiX);
  return true;
}
  // Customizable Area End
}