Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.confirmPaymentGetMethod = "GET";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  
  exports.addAmountApiEndPoint = "bx_block_payment_admin/payments/add_wallet_money"
  exports.failPaymentEndPoint = "bx_block_payment_admin/payments/payment_failed"
  exports.successPaymentEndPoint = "bx_block_payment_admin/payments/capture_payment"
  exports.apiKey="rzp_test_eNtGQLO4qSEXE4"
  exports.taxConfigurationEndPoint = "bx_block_payment_admin/payments/tax_configuration"
  exports.fetchFundAccountEndPoint = "bx_block_payment_admin/payments/fetch_fund_accounts_based_on_contact"
  exports.payoutApiEndPoint = "bx_block_payment_admin/payments/bank_payouts"
  // Customizable Area End
  