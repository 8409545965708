import React from "react";

// Customizable Area Start
import { Box, Button, Typography, styled, Grid, TableContainer, TableCell, Table, TableHead, TableRow, TableBody, Paper, Divider, Tooltip } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { eyeIcon, likeIcon, featureIcon, profileIcon } from "../../landingpage/src/assets";
import {
  deleteIcon,
  leftArrowIcon
} from '../../dashboard/src/assets';
import UpdatedBarChart from "../../../components/src/BarChart";
import { rightArrow } from "./assets";
import StarIcon from '@material-ui/icons/Star';
import { CustomCircularProgress, GradientCircularProgress } from "../../../components/src/SmallComponent.web";
import ProjectDetails from "../../landingpage/src/discoverproject/ProjectDeatils.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderButtons = () => {
    const { currentNav } = this.state;
    return currentNav === 'Contracts' ? (
      <Box style={webStyle.find_work_and_praposals_button}>
        <StyledButton variant="contained">
          Contracts
        </StyledButton>
        <Typography id="praposals_button" onClick={this.handleChangeCurrentNavData} style={webStyle.praposal_text}>
          Earnings
        </Typography>
      </Box>
    ) : (
      <Box style={webStyle.find_work_and_praposals_button}>
        <Typography id="find_work_button" onClick={this.handleChangeCurrentNavData} style={webStyle.praposal_text}>
          Contracts
        </Typography>
        <StyledButton variant="contained">
          Earnings
        </StyledButton>
      </Box>
    );
  };
  renderButtons2 = () => {
    const { contractHighLightToggle } = this.state;
    return contractHighLightToggle === 'Contracts' ? (
      <Box style={webStyle.find_work_and_praposals_button}>
        <StyledButton2 variant="contained">
          <Box style={webStyle.highEarnStyle}>Highest Earned <Box style={webStyle.highEarnValue}>₹ 12,000</Box></Box>
        </StyledButton2>
        <Typography id="praposals_button" onClick={this.handleContractHighLight} style={webStyle.praposal_text2}>
        <Box style={webStyle.buttonStyle1}>Longest Duration</Box>
        </Typography>
      </Box>
    ) : (
      <Box style={webStyle.find_work_and_praposals_button}>
        <Typography id="find_work_button" onClick={this.handleContractHighLight} style={webStyle.praposal_text2}>
        <Box style={webStyle.buttonStyle1}>Highest Earned</Box>
        </Typography>
        <StyledButton2 variant="contained">
          <Box style={webStyle.highEarnStyle}>Longest Duration <Box style={webStyle.earnValueStyle}>{`${this.state.contractHighlight.data?.attributes.duration} ${this.state.contractHighlight.data?.attributes.duration_type}`}</Box></Box>
        </StyledButton2>
      </Box>
    );
  };
  emptyState1 = () => {
    return (
      <Box>
        <Typography style={{ fontWeight: 600, fontSize: "36px", lineHeight: "36px", color: "#FFF2E3" }}>My Insights</Typography>
        <Box style={{ borderRadius: "16px", marginTop: "28px", flexDirection: "column", backgroundColor: "#1F1F1F", width: "100%", height: "520px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography style={{ marginBottom: "24px", fontWeight: 600, fontSize: "20px", lineHeight: "24px", color: "#FFF2E3" }}>Unlock Insights</Typography>
          <Typography style={{ fontWeight: 400, fontSize: "16px", lineHeight: "22px", color: "#FFF2E3" }}>Empty Insights! Find work now. Track earnings, contracts by adding</Typography>
          <Typography style={{ fontWeight: 400, fontSize: "16px", lineHeight: "22px", color: "#FFF2E3" }}>Designer's Knot work experience, and gain valuable insights.</Typography>
          <Typography style={{
            borderImage: "linear-gradient(90.83deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%) 2% stretch",
            cursor: "pointer",
            backgroundImage: "linear-gradient(90.83deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%)",
            WebkitBackgroundClip: "text",
            MozBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            borderImageSlice: "fill",
            borderImageRepeat: "round",
            fontWeight: 600,
            fontFamily: 'Inter,sans-serif',
            fontSize: '20px',
            borderBottom: '2px solid',
            marginTop: '50px'
          }}
            onClick={() => this.redirectTo(this.state.userRole === 'designer' ? "FindWork" : "MyWork")}
          >{this.state.userRole === 'designer' ? "Find Work" : "My Work"}</Typography>
        </Box>
      </Box>
    )
  }
  rattingEmptyState = () =>{
    return(
      <Box>
          <Typography style={webStyle.headerFont}>Ratings & Reviews</Typography>
          <Box style={webStyle.submissionsBox}>
            <Box>
              <Box>
                <Typography style={{ color: "#8C8C8C", fontWeight: 600, fontSize: "20px", lineHeight: "24px" }}>Ratings</Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ color: "#535353", fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>0 Reviews</Typography>
                <Typography style={{ color: "#535353", fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>No ratings Found</Typography>
              </Box>
            </Box>
            <Box style={{ marginTop: "24px" }}>
              <Typography style={{ marginBottom: "16px", color: "#8C8C8C", fontWeight: 600, fontSize: "20px", lineHeight: "24px" }}>Reviews</Typography>
              <Typography style={{ color: "#535353", fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>-</Typography>
            </Box>
          </Box>
        </Box>
    )
  }
  emptyState2 = () => {
    return (
      <>
        {this.rattingEmptyState()}
        <Box style={webStyle.gradientBox}>
          <Box style={webStyle.innerBox}>
            <Typography style={webStyle.titleText}>Ongoing Contracts</Typography>
            <Box style={webStyle.countContainer}>
              <Typography style={{ color: "#535353", fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>
                -
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography style={webStyle.headerFont}>{this.state.userRole === 'designer' ? 'Upcoming Submissions' : 'Activate Milestones and Submissions'}</Typography>
          <Typography style={{ borderRadius: "16px", padding: "16px 20px", border: "1px solid #222222", marginTop: "16px", color: "#535353", fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>
            {this.state.userRole === 'designer' ? 'No Upcoming Submissions' : "No Activate Milestones and Submissions"}
          </Typography>
        </Box>
      </>
    )
  }
  renderPhoto = (mainPhoto: string | undefined | null, backgroundColor: string | undefined | null) => {
    let content;
    if (mainPhoto) {
      content = (
        <img
          style={webStyle.profileImage} alt="Default Profile" src={`${this.baseUrlString}${mainPhoto}`} />
      );
    } else if (backgroundColor) {
      content = (
        <Box style={{ width: "49px",
          height: "49px",
          borderRadius: "50%",
          backgroundColor: backgroundColor }}></Box>
      );
    } else {
      content = (
        <img
        style={webStyle.profileImage} src={profileIcon} alt="Default Profile"  />
      );

    }

    return <>

      {content}
    </>;

  }
  ReviewText = (text: string|null ) => {
    const words = text?.split(" ");
    const firstFour = words?.slice(0, 4).join(" ");
    const rest = words?.slice(4).join(" ");
    return (
      <Typography style={webStyle.reviewText}>
        <span style={{ color: "#FFF2E3" }}>{firstFour}</span>{" "} {rest}
      </Typography>
    );
  };
  renderContractDueDate = (milestone_due_date:string|null) =>{
    return(
    this.state.userRole === 'designer' &&
      <Box style={webStyle.upcomingSubmissionStyle}>
        <Typography style={webStyle.submissionDueDate}>Due Date</Typography>
        <Typography style={webStyle.submissionDate}>{milestone_due_date}</Typography>
      </Box>)
  }
  renderContractTitle = () => {
    return (
      <Typography style={webStyle.headerFont}>{this.state.userRole === 'designer' ? 'Upcoming Submissions' : 'Activate Milestones and Submissions'}</Typography>
    )
  }
  renderContract = () => {
    return (
      <>
        <Box>
          {this.renderContractTitle()}
          {this.state.upcomingSubmission.upcoming_submission.data?.slice(-2).reverse().map((item) => (
            <Box style={webStyle.submissionsBox}>
              {this.state.userRole !== 'designer' && <Typography style={webStyle.activeMilStoneStyle}>Activate Milestone</Typography>}
              <Typography style={webStyle.submissionTitle}>{item.attributes.contract_name}</Typography>
              {this.renderContractDueDate(item.attributes.milestone_due_date)}
              <Box style={webStyle.upcomingSubmissionStyle}>
                <Typography style={webStyle.submissionDueDate}>Milestone</Typography>
                <Typography style={webStyle.mileStoneStyle}>{this.state.userRole === 'designer' ? item.attributes.milestone_name:item.attributes.activate_milestone_name}</Typography>
              </Box>
              {this.state.userRole !== 'designer' &&
                <Box style={webStyle.upcomingSubmissionStyle}>
                  <Typography style={webStyle.submissionDueDate}>Amount</Typography>
                  <Typography style={webStyle.amountStyle}>{`₹${this.getMilestoneAmount(item.attributes.activate_milestone_amount)}`}</Typography>
                </Box>}
              <Box style={webStyle.mileStoneTextStyle}>
                <Box style={webStyle.flexCenter1}>
                  {this.renderPhoto(this.state.userRole === 'designer' ? item.attributes.client_profile_photo : item.attributes.designer_profile_photo,
                    this.state.userRole === 'designer' ? item.attributes.client_profile_background_color : item.attributes.designer_profile_background_color
                  )}
                  <Box style={webStyle.clientInfo}>
                    <Typography style={webStyle.clientName}>
                      {this.state.userRole === 'designer' ? item.attributes.client_name : item.attributes.designer_name}
                    </Typography>
                    <Typography style={webStyle.clientLocation}>
                      {this.state.userRole === 'designer' ? item.attributes.client_location : item.attributes.designer_location}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <button data-test-id="upcoming-mileStone-data-id" style={webStyle.buttonStyle3} onClick={() => this.handleUpComingMileStoneNavigation(`${item.attributes.contract_id}`)}>
                    <img style={webStyle.arrowIcon} src={rightArrow} alt="view history" />
                  </button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box style={webStyle.gradientBox}>
          <Box style={webStyle.innerBox}>
            <Typography style={webStyle.titleText}>Ongoing Contracts</Typography>
            <Box style={webStyle.countContainer}>
              <Typography style={webStyle.countText}>
                {this.state.upcomingSubmission.ongoing_contract_count}
              </Typography>
              <button style={webStyle.buttonStyle5} data-test-id="view-ongoing-contracts-id" onClick={() => this.redirectTo("ContractPage")}>
                <img style={webStyle.arrowIcon} src={rightArrow} alt="view history" />
              </button>
            </Box>
          </Box>
        </Box>
        {this.state.rettingAndReview.reviews?.data.length > 0 ?
        <Box>
          <Typography style={webStyle.headerFont}>Ratings & Reviews</Typography>
          <Box style={webStyle.submissionsBox}>
            <Box style={webStyle.ratingsContainer}>
              <Box style={webStyle.ratingsInfo}>
                <Typography style={webStyle.ratingText}>Ratings</Typography>
              </Box>
            </Box>
            <Box style={webStyle.ratingMainBox}>
              <Box style={webStyle.ratingBox}>
                <Typography style={webStyle.ratingScore}>{this.state.rettingAndReview.rating}</Typography>
                <StarIcon style={{ fontSize: "40px" }} />
              </Box>
              <Typography style={webStyle.reviewCount}>{`${this.state.rettingAndReview.reviews_count} reviews`}</Typography>
            </Box>
            <Box>
              <Typography style={webStyle.reviewsTitle}>Reviews</Typography>
              <Box style={webStyle.reviewStyle}>
                {this.state.rettingAndReview.reviews.data.map((item) => (
                  <Box style={webStyle.reviewBox}>
                    {this.ReviewText(item.attributes.review)}
                    <Typography style={webStyle.reviewerInfo}>
                      {`by ${item.attributes.review_by}`}{item.attributes.user_designation?.name && ` (${item.attributes.user_designation?.name})`}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {this.state.rettingAndReview.reviews_count > this.state.rettingAndReview.reviews.data.length &&
                <Box style={webStyle.showMoreContainer}>
                  <Typography style={webStyle.showMoreText}>Show More</Typography>
                  <Box>
                    <button data-test-id="show-more-btn-id" style={webStyle.buttonContainer} onClick={() => this.handleLoadMoreRatings()}>
                      <img style={webStyle.arrowIcon3} src={rightArrow} alt="view history" />
                    </button>
                  </Box>
                </Box>}
            </Box>
          </Box>
        </Box>:this.rattingEmptyState()}
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          style={webStyle.newResponseCon}
          data-testid="outside">
          <NavigationMenu {...this.props} />
          <MainOuterWrap>
            <Box data-testid="allProposals"
              style={webStyle.allPropsCon}
            >
              <img src={leftArrowIcon} data-test-id="goback" onClick={this.goBack} style={{ cursor: 'pointer' }} alt="left-icon" />
              <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>My Insights</Typography>
            </Box>
            <Box style={webStyle.marginTop45}>
              <Grid container spacing={3}>
                <Grid lg={4} md={3} sm={12} item>
                  <Box style={webStyle.lightGrayBackground}>
                    <Box style={webStyle.padding40alll}>
                      <Typography style={webStyle.font20White}>Total Earnings</Typography>
                      <Box style={webStyle.marginTop60}>
                        <CustomCircularProgress
                          text="Total Earnings"
                          value={75}
                          size={263}
                          thickness={2}
                          color="#FFF2E3"
                          amount="5460$"
                        />
                        <Box style={webStyle.flexRow}>
                          <Box style={webStyle.flexCenter}>
                            <Box style={webStyle.whiteCircle19}></Box>
                            <Typography
                              style={webStyle.font20White}
                            >
                              Wallet
                            </Typography>
                            <Typography
                              style={webStyle.font32White}
                            >
                              10K
                            </Typography>
                          </Box>
                          <Box style={webStyle.flexCenter}>
                            <Box style={webStyle.grayCircle19}></Box>
                            <Typography
                              style={webStyle.font20Gray}
                            >
                              Credited
                            </Typography>
                            <Typography
                              style={webStyle.font32White}
                            >
                              4K
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {this.state.contractAnalytics.completed_contract_count + this.state.contractAnalytics.ongoing_contract_count !== 0 ?
                   this.renderContract() :
                    this.emptyState2()
                  }
                </Grid>
                <Grid lg={8} md={9} sm={12} item>
                  {this.state.contractAnalytics.completed_contract_count + this.state.contractAnalytics.ongoing_contract_count !== 0 ?
                    <>
                      <Box style={webStyle.lightGrayBackground}>
                        <Box style={webStyle.padding4030}>
                          <Grid container>
                            <Grid item lg={3} md={3} sm={12}>
                              <Typography style={webStyle.font20White}>Contracts</Typography>
                              <Box style={webStyle.borderRightBlack}>
                                <Box style={webStyle.marginTop0}>
                                  <GradientCircularProgress value={this.calculateCompletedPercentage()} size={180} thickness={15} amount={(this.state.contractAnalytics.ongoing_contract_count + this.state.contractAnalytics.completed_contract_count)} />
                                  <Box style={webStyle.completeBorder}>
                                    <Box style={webStyle.flexRow}>
                                      <Box style={webStyle.flexSpaceBetween}>
                                        <Box style={webStyle.displayFlex}>
                                          <Box style={webStyle.circleStyleMultiColor26}></Box>
                                          <Typography
                                            style={{ ...webStyle.font20Gray, fontSize: "16px !important" }}
                                          >
                                            Completed
                                          </Typography>
                                        </Box>
                                        <Typography
                                          style={{ ...webStyle.font32White, fontSize: "26px !important" }}
                                        >
                                          {this.state.contractAnalytics.completed_contract_count}
                                        </Typography>
                                      </Box>
                                      <Box style={webStyle.flexSpaceBetween}>
                                        <Box style={webStyle.displayFlex}>
                                          <Box style={webStyle.whiteCircle26}></Box>
                                          <Typography
                                            style={{ ...webStyle.font20Gray, fontSize: "16px !important" }}
                                          >
                                            Ongoing
                                          </Typography>
                                        </Box>
                                        <Typography
                                          style={{ ...webStyle.font32White, fontSize: "26px !important" }}
                                        >
                                          {this.state.contractAnalytics.ongoing_contract_count}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item lg={9} md={9} sm={12}>
                              <Box style={{ padding: '0 20px' }}>
                                {this.renderButtons()}
                                {this.state.currentNav === 'Contracts' ?
                                  <UpdatedBarChart
                                    labels={configJSON.monthLabel}
                                    datasets={[
                                      {
                                        label: "Contracts ",
                                        data: Object.values(this.state.contractAnalytics.graph_data),
                                        backgroundColor: "rgba(83, 83, 83, 1)",
                                        borderRadius: 6,
                                        hoverBackgroundColor: 'rgba(255, 242, 227, 1)'
                                      },
                                    ]}
                                    barThickness={30}
                                    xTickColor="rgba(83, 83, 83, 1)"
                                    yTickColor="rgba(83, 83, 83, 1)"
                                    viewingMonth="No. of Contracts"
                                    stepSize={5}
                                    label="Contracts"
                                  />
                                  :
                                  <UpdatedBarChart
                                    labels={configJSON.monthLabel}
                                    datasets={[
                                      {
                                        label: "",
                                        data: [7500, 7008, 6005, 1001, 2200, 3300, 4400, 5500, 6600],
                                        backgroundColor: "rgba(83, 83, 83, 1)",
                                        borderRadius: 6,
                                        hoverBackgroundColor: 'rgba(255, 242, 227, 1)'
                                      },
                                    ]}
                                    barThickness={30}
                                    xTickColor="rgba(83, 83, 83, 1)"
                                    yTickColor="rgba(83, 83, 83, 1)"
                                    viewingMonth="Earnings ($)"
                                    stepSize={1000}
                                    label=""
                                  />
                                }
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      <Box style={webStyle.MainContainer}>
                        <Box style={webStyle.leftColumn}>
                          <Box style={webStyle.submissionsBox}>
                            <Typography style={webStyle.submissionTitle}>{this.state.userRole === 'designer'?"Designers Worked With":"Clients Worked with"}</Typography>
                            <TableContainer component={Paper} className="scrollContainer" style={webStyle.tableContainer} elevation={0}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={webStyle.tableHeaderCell}>{this.state.userRole === 'designer'?"Client":"Designer"}</TableCell>
                                    <TableCell style={webStyle.tableHeaderCell}>Earnings</TableCell>
                                    <TableCell style={webStyle.tableHeaderCell}>Contracts</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.workWith.data.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell style={webStyle.tableRowCellWidth}>
                                        <Box style={webStyle.avatarContainer}>
                                        {this.renderPhoto(row.attributes.profile_image,row.attributes.background_color)}
                                          <Box style={webStyle.profileStyle}>
                                            <Typography style={webStyle.boldText}>{row.attributes.full_name}</Typography>
                                            <Typography style={webStyle.subText}>{row.attributes.city}</Typography>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                      <TableCell style={webStyle.tableRowCell}>
                                        <Typography style={webStyle.boldText}>{row.attributes.earnings}</Typography>
                                      </TableCell>
                                      <TableCell style={webStyle.tableRowCell}>
                                        <Tooltip title={row.attributes.contracts.join(", ") || "No contracts available"} arrow>
                                          <Typography style={webStyle.subText}>
                                            {this.formatContractsList(row.attributes.contracts)}
                                          </Typography>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                        <Box style={webStyle.rightColumn}>
                          <Box style={webStyle.submissionsBox}>
                            <Typography style={{ ...webStyle.submissionTitle, marginBottom: "16px" }}>Contract Highlights</Typography>
                            {this.renderButtons2()}
                            <Box style={webStyle.contractBoxWrapper} onClick={()=>this.navigateTo(this.state.userRole === 'designer'?"ArchiveDesignerContractPage":"ArchiveClientContractPage",this.state.contractHighlight.data.id)}>
                              <Box style={webStyle.contractBox}>
                                <Typography style={webStyle.successText}>{this.state.contractHighlight.data?.attributes.contract_status}</Typography>
                                <Typography style={webStyle.contractTitle}>{this.state.contractHighlight.data?.attributes.contract_title}</Typography>
                                <Divider style={webStyle.divider} />
                                <Typography style={webStyle.earningsText}>
                                  Total earnings :
                                  <span style={webStyle.earningsAmount}>₹ {this.state.contractHighlight.data?.attributes.total_earnings}</span>
                                </Typography>
                                <Typography style={webStyle.earningsText}>
                                  Ended on :
                                  <span style={webStyle.earningsAmount}>{this.state.contractHighlight.data?.attributes.contract_end_date}</span>
                                </Typography>
                                <Box style={webStyle.durationWrapper}>
                                  <Typography style={webStyle.durationText}>Duration:</Typography>
                                  <Typography style={webStyle.durationBox}>{`${this.state.contractHighlight.data?.attributes.duration} ${this.state.contractHighlight.data?.attributes.duration_type}`}</Typography>
                                </Box>
                                <Divider style={webStyle.divider} />
                                <Box style={webStyle.clientInfoWrapper}>
                                {this.renderPhoto(this.state.contractHighlight.data?.attributes.profile_photo,this.state.contractHighlight.data?.attributes.profile_background_color)}
                                  <Box style={webStyle.clientDetails}>
                                    <Typography style={webStyle.clientName1}>{this.state.contractHighlight.data?.attributes.user_name}</Typography>
                                    <Typography style={webStyle.clientLocation1}>{this.state.contractHighlight.data?.attributes.city}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {this.state.userRole === 'designer' && this.state.topRatedProject?.data[0] &&
                      <Box style={webStyle.MainContainer}>
                        <Box style={webStyle.topRatedProjectStyle}>
                          <Box style={{ ...webStyle.submissionsBox, display: "flex", gap: "16px" }}>
                            <Box style={webStyle.container3}>
                              <Typography style={webStyle.submissionTitle}>Top rated project</Typography>
                              <Box style={webStyle.topRatedProjectBox}>
                                <Box data-test-id="open-project-detail" onClick={this.handleProjectDetail}>
                                  <img
                                    style={webStyle.projectImage}
                                    src={this.baseUrlString + this.state.topRatedProject?.data[0]?.attributes.cover_image_url}
                                    alt="project cover image"
                                  />
                                </Box>
                                <Box style={webStyle.projectInfoContainer}>
                                  <Box style={webStyle.projectNameContainer}>
                                    <Typography style={webStyle.projectName} title={"test"}>
                                      {this.state.topRatedProject.data[0]?.attributes.project_name}
                                    </Typography>
                                    <Typography style={webStyle.username}>
                                      {this.state.topRatedProject.data[0]?.attributes.username}
                                    </Typography>
                                  </Box>
                                  <Box style={webStyle.statsContainer}>
                                    <Box style={webStyle.statsItem}>
                                      <span style={webStyle.statsText}>
                                        {this.state.topRatedProject.data[0]?.attributes.views}
                                      </span>
                                      <img style={webStyle.eyeIcon} src={eyeIcon} alt="eye icon" />
                                    </Box>
                                    <Box style={webStyle.statsItemWithGap}>
                                      <span style={webStyle.statsText}>
                                        {this.state.topRatedProject.data[0]?.attributes.likes}
                                      </span>
                                      <img style={webStyle.likeIcon} src={likeIcon} alt="like icon" />
                                    </Box>
                                    <Box style={webStyle.statsItemWithGap}>
                                      <span style={webStyle.statsText}>
                                        {this.state.topRatedProject.data[0]?.attributes.saved_count}
                                      </span>
                                      <img style={webStyle.featureIcon} src={featureIcon} alt="feature icon" />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box style={webStyle.container3}>
                              <Typography style={webStyle.submissionTitle}>Top Rated Collection</Typography>
                              <Box style={webStyle.card}>
                                <Typography style={{ ...webStyle.submissionTitle, ...webStyle.cardHeader }}>
                                  {this.state.topRatedCollection?.data[0]?.name}
                                </Typography>
                                <Box style={webStyle.contentBox}>
                                  <Box style={webStyle.rowWithBorder}>
                                    <Box style={webStyle.statBox}>
                                      <Typography style={webStyle.statText}>Projects</Typography>
                                      <Typography style={webStyle.statValue}>
                                        {this.state.topRatedCollection?.data[0]?.project_count}
                                      </Typography>
                                    </Box>
                                    <Divider style={webStyle.divider2} />
                                    <Box style={{ ...webStyle.statBox, marginLeft: "5%" }}>
                                      <Typography style={webStyle.statText}>Likes</Typography>
                                      <Typography style={webStyle.statValue}>
                                        {this.state.topRatedCollection.data[0]?.likes_count}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box style={webStyle.row}>
                                    <Box style={webStyle.statBox}>
                                      <Typography style={webStyle.statText}>Views</Typography>
                                      <Typography style={webStyle.statValue}>
                                        {this.state.topRatedCollection.data[0]?.views_count}
                                      </Typography>
                                    </Box>
                                    <Divider style={webStyle.divider2} />
                                    <Box style={webStyle.statBox}>
                                      <Typography style={webStyle.statText}>Saves</Typography>
                                      <Typography style={webStyle.statValue}>
                                        {this.state.topRatedCollection.data[0]?.save_count}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={webStyle.container}>
                          <Box style={{ ...webStyle.submissionsBox, flex: "1 !important" }}>
                            <Typography style={webStyle.submissionTitle}>Top skills used in contracts</Typography>
                            {this.state.topContractSkill.data.map((item, index) => (
                              <Box key={index} style={webStyle.skillItem}>
                                <Typography style={webStyle.submissionTitle}>{item.attributes.skill_name}</Typography>
                                <Box style={webStyle.skillPercentageWrapper}>
                                  <Box style={webStyle.skillInfo}>
                                    <Typography style={webStyle.skillPercentageText}>{item.attributes.calculate_percentag}%</Typography>
                                    <Box style={webStyle.progressBarContainer}>
                                      <Box
                                        style={{
                                          height: "8px",
                                          width: `${item.attributes.calculate_percentag}%`,
                                          backgroundColor: this.baseColor[index],
                                          borderRadius: "24px",
                                        }}
                                      ></Box>
                                    </Box>
                                  </Box>
                                  <Typography style={webStyle.projectCountText}>{`${item.attributes.contract_count} Projects`}</Typography>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>}
                    </>
                    :
                    this.emptyState1()}
                </Grid>
              </Grid>
            </Box>
          </MainOuterWrap>
        </Box>
        <Box data-test-id={"projectDetailsModal"}>
          {this.state.showDetails &&
            <ProjectDetails handlenavigation={this.HandleNavigation}
              navigateTo={this.navigateTo}
              token={this.state.token}
              selectedProject={this.state.topRatedProject.data[0]?.id}
              open={this.state.showDetails}
              closeModal={this.closeModal}
              allprojectList={[]}
              data-test-id="projectDetails" />}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
      primary: {
          main: '#fff2e3',
      }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "red", // Set the root-level background color
        },
      },
    },
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: '#222',
                  color: '#fff2e3'
              }
          }
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#fff2e3 !important',
              }
          }
      },
      MuiIconButton: {
          styleOverrides: {
              root: {
                  color: '#fff2e3 !important'
              }
          }
      },
      MuiButtonBase: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#7d7d7d !important',
                  '&.Mui-selected': {
                      color: '#222 !important',
                      backgroundColor: '#fff2e3 !important'
                  },
                  '&.MuiPickersDay-today': {
                      border: '1px solid #fff2e3 !important'
                  }
              }
          }
      },
      MuiDialogContent: {
          styleOverrides: {
              root: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: "transparent",
                  overflowY: 'auto',
                  boxShadow: "none",
                  overflowX: "hidden",
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      width: '0',
                      height: '0',
                  }
              }
          }
      }
  },
});

const MainOuterWrap = styled(Box)({
  marginTop: '120px',
  marginLeft:"auto",
  marginRight:"auto",
  paddingLeft:"3%",
  paddingRight:"3%",
  width: "94%",
  backgroundColor: '#0D0D0D',
  "& .scrollContainer": {
    '&::-webkit-scrollbar': {
      height: '30px',
      borderRadius: '10px',
      width:"10px"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#222222',
      borderRadius: '10px',
      border: '2px solid transparent',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
  },
})

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  borderRadius: '50px',
  background: '#313131',
  color: 'white',
  textTransform: 'none',
  fontWeight: 'bold',
  fontFamily: "Inter,sans-serif",
  overflow: 'hidden',
  fontSize: '20px', padding: '1% 17%',
  zIndex: 1,
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: '50px',
    padding: '2px',
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
  '&:hover': {
    background: '#313131',
  },
  [theme.breakpoints.down(1340)]: {
    fontSize: '17px',
    padding: '5% 10%',
  },

}));

const StyledButton2 = styled(Button)(({ theme }) => ({
  position: 'relative',
  width:"50%",
  height:"48px",
  borderRadius: '50px',
  background: '#171717',
  color: 'white',
  textTransform: 'none',
  fontFamily: "Inter,sans-serif",
  overflow: 'hidden',
  padding: '1% 0px',
  zIndex: 1,
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: '50px',
    padding: '2px'
  },
  '&:hover': {
    background: '#171717',
  },
  [theme.breakpoints.down(1340)]: {
    fontSize: '17px',
    padding: '5% 10%',
  },

}));

const dataOld = [
  ["Name", "Popularity"],
  ["Cesar", 250],
  ["Rachel", 4200],
  ["Patrick", 2900],
  ["Eric", 8200],
];

const dataNew = [
  ["Name", "Popularity"],
  ["Cesar", 370],
  ["Rachel", 600],
  ["Patrick", 700],
  ["Eric", 1500],
];

export const diffdata = {
  old: dataOld,
  new: dataNew,
};

const webStyle: Record<string, React.CSSProperties> = {
  newResponseCon: {
    backgroundColor: "#0D0D0D",
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '43.57px',
    color: '#FFF2E3'
  },
  marginTop45:{
    marginTop: '45px'
  },
  lightGrayBackground :{
    backgroundColor: '#111'
  },
  padding40alll:{
    padding:'40px'
  },
  submissionsBox:{
    backgroundColor:"#111111",
    padding:"16px 20px",
    marginTop:"16px",
    borderRadius:"16px"
  },
  font20White: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  submissionTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  submissionDueDate:{
    color:"#8C8C8C",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    marginRight:"18px"
  },
  amountStyle:{
    backgroundColor:"#363636",
    padding:"6px 16px 6px 16px",
    fontWeight:600,
    fontSize:"14px",
    lineHeight:"16.94px",
    fontFamily:"Inter",
    color:"#61CA78",
    borderRadius:"24px"
  },
  submissionDate:{
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    background: "linear-gradient(224.84deg, #ffc29f, #ffb9c7, #cf8ccc, #ab9ef5, #6c98ee, #1cbae0, #a8e5ec)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  headerFont:{
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFF2E3',
    marginTop:"40px"
  },
  font20Gray: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.2px',
    color: 'rgba(119, 119, 119, 1)'
  },
  font32White: {
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.38px',
    color: 'rgba(255, 242, 227, 1)'
  },
  marginTop60: {
    marginTop: '60px',
    display: 'flex',
    alignItems:'center',
    gap: '32px',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  marginTop0: {
    display: 'flex',
    alignItems:'center',
    gap: '32px',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  whiteCircle19:{
    width:'19px',
    height:'19px',
    backgroundColor: "rgba(255, 242, 227, 1)",
    borderRadius: '50%'
  },
  grayCircle19:{
    width:'19px',
    height:'19px',
    backgroundColor: "rgba(83, 83, 83, 1)",
    borderRadius: '50%'
  },
  flexCenter:{
    display:'flex',
    alignItems:'center',
    gap: '12px'
  },
  flexJusCenter:{
    display:'flex',
    alignItems:'center',
    gap: '12px',
    justifyContent: 'center'
  },
  flexRow:{
    display: 'flex',
    flexDirection: 'column',
    gap: "30px"
  },
  completeBorder:{
    padding: '16px',
    border: '1px solid rgba(83, 83, 83, 1)',
    borderRadius: '16px',
    margin: '25px 0',
    maxWidth: '230px',
    width: '70%',
  },
  circleStyleMultiColor26: {
    width: '26px',
    height: '26px',
    borderRadius: '50%',
    background: 'linear-gradient(90.83deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%)',
  },
  whiteCircle26:{
    width:'26px',
    height:'26px',
    backgroundColor: "rgba(255, 242, 227, 1)",
    borderRadius: '50%'
  },
  displayFlex:{
    display:'flex',
    gap:'10px'
  },
  flexSpaceBetween:{
    display:'flex',
    justifyContent:'space-between'
  },
  borderRightBlack:{
    borderRight: '1px solid rgba(34, 34, 34, 1)'
  },
  praposal_text: { 
    color: '#8C8C8C',
    fontFamily: "Inter,sans-serif",
    fontSize: '20px',
    padding: '2% 17%',
    cursor: 'pointer'
  },
  praposal_text2: { 
    color: '#8C8C8C',
    fontFamily: "Inter,sans-serif",
    fontSize: '20px',
    padding:"0px 45px",
    cursor: 'pointer'
  },
  
  find_work_and_praposals_button: { 
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: "center", 
    padding: 6, 
    backgroundColor: '#0D0D0D', 
    borderRadius: 50, 
    marginBottom: 20
  },
  earnValueStyle:{
    color:"#FFB9C7",fontWeight:700,fontSize:"17px"
  },
  padding4030:{
    padding:'40px 0 40px 30px'
  },
  highEarnStyle:{
    display:"flex",gap:"10px",fontWeight:600,fontSize:"16px",lineHeight:"24px"
  },
  highEarnValue:{
    color:"#1CBAE0",fontWeight:700,fontSize:"17px"
  },
  buttonStyle1:{
    display:"flex"
  },
  upcomingSubmissionStyle:{
    display:"flex",marginTop:"15px",alignItems:"center"
  },
  flexCenter1: {
    display: "flex",
    alignItems: "center",
  },
  profileImage: {
    width: "49px",
    height: "49px",
    borderRadius: "50%",
  },
  clientInfo: {
    marginLeft: "31px",
  },
  clientName: {
    color: "#E3E3E3",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16.33px",
    lineHeight: "19.76px",
  },
  clientLocation: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12.83px",
    lineHeight: "15.53px",
  },
  buttonStyle3: {
    borderRadius: "8px",
    width: "36px",
    height: "36px",
    backgroundColor: "#222222",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    cursor: "pointer",
    color: "#FFF2E3",
  },
  arrowIcon: {
    width: "7.78px",
    height: "12.73px",
    transform: "rotate(180deg)",
    color: "#FFF2E3",
  },
  mileStoneStyle:{color:"#FFF2E3",fontFamily:"Inter",fontWeight:400,fontSize:"20px", whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",},
  mileStoneTextStyle:{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:"14px", paddingTop:"14px", borderTop:"1px solid #222222"},

  gradientBox: {
    position: "relative",
    padding: "2px 1.5px",
    marginTop: "40px",
    borderRadius: "16px",
    background: "linear-gradient(90deg, #3FA7E1, #7699EF, #AE9DF2, #E8A5CA, #F1B2C6)",
  },
  innerBox: {
    backgroundColor: "#111111",
    padding: "16px 20px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleText: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24.2px",
    color: "#FFF2E3",
  },
  countContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  countText: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "43.57px",
    color: "#FFF2E3",
  },
  buttonStyle5: {
    borderRadius: "8px",
    width: "36px",
    height: "36px",
    backgroundColor: "#222222",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    cursor: "pointer",
    color: "#FFF2E3",
  },
  ratingsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ratingsInfo: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  ratingText: {
    color: "#8C8C8C",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
  },
  reviewCount: {
    color: "#FFF2E3",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
  },
  ratingBox: {
    borderRadius: "6px",
    padding: "5px",
    backgroundColor: "#349E4B",
    display: "flex",
    alignItems: "center",
    color: "#FFF2E3",
    justifyContent: "center",
  },
  ratingMainBox: {
    display: "flex", alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0px",
    flexDirection: "row-reverse"
  },
  ratingScore: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "30.15px",
    lineHeight: "36.49px",
  },
  reviewsTitle: {
    color: "#8C8C8C",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
  },
  reviewBox: {
    backgroundColor: "#171717",
    borderRadius: "16px",
    padding: "16px",
    marginTop: "16px",
  },
  reviewStyle:{
    height: "250px",
    maxHeight: "250px",
    overflowY: "auto",
    scrollbarWidth:"none",
  },
  reviewText: {
    color: "#535353",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    display:"-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordBreak: "break-word"
  },
  reviewerInfo: {
    color: "#8C8C8C",
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  showMoreContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "24px",
    padding: "16px",
  },
  showMoreText: {
    color: "#FFF2E3",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24.2px",
  },
  buttonContainer: {
    borderRadius: "8px",
    width: "36px",
    height: "36px",
    backgroundColor: "#222222",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    cursor: "pointer",
    color: "#FFF2E3",
  },
  arrowIcon3: {
    width: "7.78px",
    height: "12.73px",
    transform: "rotate(270deg)",
    color: "#FFF2E3",
  },
  container: {
    display: "flex",
    gap: "24px",
  },
  MainContainer:{
    display: "flex",
    gap: "24px",
    flexWrap:"wrap"
  },
  leftColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  rightColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  tableContainer: {
    maxHeight: "444px",
    overflowY: "auto",
    color: "#fff",
    backgroundColor: "#111111",
    boxShadow: "none",
  },
  tableHeaderCell: {
    position: "sticky",
    top: 0,
    backgroundColor: "#111111",
    color: "#8C8C8C",
    fontWeight: 600,
    borderBottom: "1px #222222 solid",
    zIndex: 2,
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    paddingBottom: "5px",
  },
  tableRowCell: {
    borderBottom: "none",
  },
  tableRowCellWidth: {
    borderBottom: "none",
    width: "35%",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: 10,
  },
  boldText: {
    fontWeight: "bold",
    color: "#fff",
    fontFamily:"Inter"
  },
  profileStyle:{
    marginLeft:"10px"
  },
  subText: {
    fontSize: 12,
    color: "#ccc",
    fontFamily:"Inter"
  },
  contractBoxWrapper: {
    position: "relative",
    padding: "2px 1.5px",
    marginTop: "40px",
    borderRadius: "16px",
    background: "linear-gradient(90deg, #3FA7E1, #7699EF, #AE9DF2, #E8A5CA, #F1B2C6)",
    cursor:"pointer"
  },
  contractBox: {
    backgroundColor: "#111111",
    padding: "16px 20px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  successText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.94px",
    color: "#61CA78",
    marginTop: "16px",
  },
  contractTitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24.2px",
    color: "#FFF2E3",
    marginTop: "9px",
  },
  divider: {
    width: "108%",
    height: "1px",
    backgroundColor: "#545454",
    marginTop: "21px",
    marginLeft: "-4%",
  },
  earningsText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.94px",
    color: "#FFF2E3",
    marginTop: "21px",
  },
  earningsAmount: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19.36px",
    marginLeft: "5px",
  },
  durationWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "25px",
  },
  durationText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.94px",
    color: "#FFF2E3",
  },
  durationBox: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16.94px",
    color: "#FFB9C7",
    padding: "6px 16px",
    backgroundColor: "#363636",
    borderRadius: "24px",
  },
  clientInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "21px",
  },
  clientImage: {
    width: "49px",
    height: "49px",
    borderRadius: "50%",
  },
  clientDetails: {
    marginLeft: "10px",
  },
  clientName1: {
    color: "#E3E3E3",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16.33px",
    lineHeight: "19.76px",
  },
  clientLocation1: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12.83px",
    lineHeight: "15.53px",
  },
  container1: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  skillItem: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  skillPercentageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  skillInfo: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  skillPercentageText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#8C8C8C",
    marginLeft:"20px"
  },
  progressBarContainer: {
    height: "8px",
    width: "150px",
    borderRadius: "24px",
  },
  projectCountText: {
    fontFamily: "Inter",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#8C8C8C",
  },
  container3: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  card: {
    marginTop: "16px",
    backgroundColor: "#0D0D0D",
    height: "100%",
    width: "100%",
    borderRadius: "16px",
    border: "1px solid #171717",
  },
  cardHeader: {
    padding: "16px",
    borderBottom: "1px solid #171717",
  },
  contentBox: {
    height: "240px",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  rowWithBorder: {
    display: "flex",
    justifyContent: "space-evenly",
    borderBottom: "1px solid #171717",
    paddingBottom: "20px",
    paddingTop: "20px",
    paddingRight: "3%",
  },
  statBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  statText: {
    color: "#8C8C8C",
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "24px",
  },
  statValue: {
    color: "#FFF2E3",
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "24px",
  },
  divider2: {
    backgroundColor: "#171717",
    width: "1px",
    height: "100%",
  },
  topRatedProjectBox: {
    width: "100%",
    height: "100%",
    marginTop: "16px",
  },
  projectImage: {
    height: "260px",
    width: "100%",
    borderRadius: 10,
    cursor: "pointer",
  },
  projectInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  projectNameContainer: {
    maxWidth: "100px",
    paddingRight: "6px",
  },
  projectName: {
    fontFamily: "Inter-SemiBold, sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.38px",
    color: "#fff2e3",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  username: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0.38px",
    color: "#fff2e3",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  statsContainer: {
    columnGap: "10px",
    display: "flex",
    height: "24px",
  },
  statsItem: {
    display: "flex",
    alignItems: "center",
  },
  statsItemWithGap: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  statsText: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0.38px",
    color: "#fff2e3",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  eyeIcon: {
    width: "24.2px",
    height: "17.6px",
  },
  likeIcon: {
    width: "18.7px",
    height: "16.5px",
  },
  featureIcon: {
    width: "22px",
    height: "22px",
  },
  topRatedProjectStyle:{display: "flex", flex: 1.2, flexDirection: "column"},
  activeMilStoneStyle:{ fontFamily:"Inter",fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#8C8C8C",marginBottom:"16px"}
};
// Customizable Area End
