// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData,removeStorageData} from "framework/src/Utilities";
export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}
export interface Itabs {
  label:string
}

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  EditProfile:boolean;

  
}

interface S {
  Addform:boolean;
  token:string
  isPublic:boolean;
  workActiveBord:string;
  professionalExplist:any;
  deletemodal:boolean;
  selectedId:number | null;
  ToastStatusdelet:boolean;
  ToastMessage:string;
  ToastActiondelete:string;
  DkContracts:DesignerContract[];
}

interface SS {
  id: any;
}

export interface DesignerContract {
  id: string;
  type: string;
  attributes: {
    work_opportunity_title: string;
    work_opportunity_description: string;
    designer_details: {
      skills_data: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        category_id: number;
      }[];
      location: string;
    };
    duration: {
      contract_start_date: string;
      contract_end_date: string | null;
    };
    calculate_years: string;
  };
};
export default class ProfessionalExperience extends BlockComponent<
  Props,
  S,
  SS
> {
  
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  getclientProfileworkExperience:string = '';
  deleteclientProfessionalExperience:string = '';
  getDKcontractsAPIcallId: string = "";
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token:"",
      isPublic:false,
      Addform:false,
      workActiveBord:'DK Contracts',
      professionalExplist:[],
      deletemodal:false,
      selectedId:null,
      ToastStatusdelet:false,
  ToastMessage:'',
  ToastActiondelete:'',
  DkContracts:[] as DesignerContract[]

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!apiRequestCallId1 || !responseJson1) {
      return;
    }

    const handleSuccess = (stateUpdate: object) => {
      this.setState(stateUpdate);
    };

    switch (apiRequestCallId1) {
      case this.getclientProfileworkExperience:
        if (!responseJson1?.error) {
          handleSuccess({ professionalExplist: responseJson1.data });
        }
        break;

      case this.deleteclientProfessionalExperience:
        if (!responseJson1.error) {
          handleSuccess({
            ToastStatusdelet: true,
            ToastActiondelete: "success",
            ToastMessage: `Sucessfully Deleted`,
          });
          this.handleCloseDeleteModal();
          this.handleWorkExperience();
        } else {
          handleSuccess({
            ToastStatusdelet: true,
            ToastActiondelete: "danger",
            ToastMessage: `Something went wrong`,
          });
        }
        break;

      case this.getDKcontractsAPIcallId:
        if (!responseJson1?.error) {
          handleSuccess({ DkContracts: responseJson1.data });
        }
        break;

      default:
        break;
    }
  }



  async componentDidMount() {

    this.handleWorkExperience()
    this.getDkContractsApi()
    const isPublicProfile = await getStorageData("isPublicProfile")
    const tokens = await getStorageData("userInfo",true);  
    this.setState({
      isPublic : isPublicProfile,
      token:tokens.meta.token
    })
    const subTab = await getStorageData("subTab")
    if (subTab === "Professional Experience") {
      this.setState({ workActiveBord: subTab })
    }
  }

  async componentWillUnmount() {
    removeStorageData("subTab")
  }

  handleWorkactiveBoard =(active:string)=>{
    this.setState({workActiveBord:active})
  }
  openformmodal = ()=>{
    this.setState({Addform:true})

  }
  closeFormModal =()=>{
    this.setState({Addform:false,selectedId:null})
    this.handleWorkExperience()

  }

  HandledeleteModal = (id:any)=>{
    
    this.setState({deletemodal:true,selectedId:id.id})
  }

handleEditModel=(id:any)=>{
  this.setState({Addform:true,selectedId:id.id})
}

  handleCloseDeleteModal=()=>{
    this.setState({deletemodal:false,selectedId:null})
  }
  deleteprofessionalexp=async()=>{
    const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteclientProfessionalExperience = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.deletesingleProfessionalExp+this.state.selectedId
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  }
  handlenavigatetoFindwork=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'FindWork');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
handleWorkExperience = async () =>{
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getclientProfileworkExperience = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getallListsofProfessionalWxp+this.props.id
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleToastClose = () => {
  this.setState({ ToastStatusdelet: false });
}
getDkContractsApi = async() => {
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const getDkContractsApi = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getDKcontractsAPIcallId = getDkContractsApi.messageId;

  getDkContractsApi.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_projectportfolio/professional_experiences/designer_dk_contract?account_id=${this.props.id}&page=1&per_page=1000000`
  );
  
  getDkContractsApi.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getDkContractsApi.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(getDkContractsApi.id, getDkContractsApi);
  return true;
}
}

// Customizable Area End
