import React, { Component } from 'react';
import {
    Box, Dialog
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@material-ui/styles';

interface ViewAllSubmissionModalProps {
    open: boolean;
    handleClose: () => void;
    submissions: {
        file_type?: string;
        file_name?: string;
        url?: string;
        link_url?: string;
        submission_time: string;
    }[];
    downloadImage: (item: { url: string, file_name: string }) => void;
}

interface StateProp {

}


const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "70vw",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            width: "0px",
        },
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px",
        },
    },
});

export default class ViewAllSubmissionModal extends Component<ViewAllSubmissionModalProps, StateProp> {
    constructor(props: ViewAllSubmissionModalProps) {
        super(props);
        this.state = {

        };
    }

    removeExtension = (fileNameStr: string | undefined) => {
        if (fileNameStr) {
            return fileNameStr.substring(0, fileNameStr.lastIndexOf('.'))
        }
    }

    linkStyle = { width: "25%", border: "0px !important", color: "#545454", fontSize: "14px", fontWeight: "400", padding: "0px 40px 15px 16px" };
    linkBox = { display: "flex", alignItems: "center", fontFamily: "inter", color: "#545454", fontSize: "16px", fontWeight: "600" };
    rowheader = { width: "25%", border: "0px !important", fontSize: "16px", fontFamily: "inter", fontWeight: "600", color: "#FFF2E3" };

    render() {
        const { open, handleClose, downloadImage, submissions } = this.props;

        return (
            <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} data-test-id="close-modal" id="dialog-id">
                <Box style={{ border: "1px solid #5F5F5F", background: "#222222", borderRadius: "10px" }}>
                    <Box>
                        <Box style={{ display: "flex", justifyContent: "start", padding: "24px 80px", fontFamily: "inter", alignItems: "center" }}>
                            <svg
                                onClick={handleClose}
                                style={{
                                    cursor: "pointer",
                                }} width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L2 9.5L11 18" stroke="#FFF2E3" stroke-width="2" stroke-linecap="round" />
                            </svg>
                            &nbsp;
                            &nbsp;
                            <span style={{ color: "#FFF2E3", fontSize: "20px", fontWeight: 600 }}>
                                View submissions
                            </span>
                        </Box>
                    </Box>
                    <Box style={{
                        padding: "24px 80px"
                    }}>
                        <TableContainer>
                            <Table aria-label="caption table"
                                style={{
                                    padding: "24px 40px"
                                }}>
                                <TableHead>
                                    <TableRow sx={{ border: "none" }}>
                                        <TableCell sx={{ fontSize: "16px", fontFamily: "inter", width: "50%", border: "0px !important", fontWeight: "600", color: "#FFF2E3", paddingLeft: "0px" }}>File name</TableCell>
                                        <TableCell sx={this.rowheader} align="left">File Type</TableCell>
                                        <TableCell sx={this.rowheader} align="left">Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {submissions.map((item, index) => (
                                        item.file_type ? (
                                            <TableRow key={index} sx={{ border: "0px !important" }}>
                                                <TableCell sx={{ width: "50%", border: "0px !important", padding: "0px 40px 15px 0px" }} component="th" scope="row">
                                                    <Box sx={this.linkBox}>
                                                        <span>{index + 1}.</span>
                                                        <Box style={{ borderRadius: "8px", margin: "0px 10px", flex: 1, padding: "15px 20px", background: "#2F2F2F", }}>
                                                            <span>{this.removeExtension(item.file_name)}</span>
                                                        </Box>
                                                        <svg
                                                            data-test-id="download-btn"
                                                            onClick={() => downloadImage({ url: item.url as string, file_name: item.file_name as string })}
                                                            style={{ cursor: "pointer" }}
                                                            width="16"
                                                            viewBox="0 0 16 16"
                                                            height="16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M8 10L7.64645 10.3536L8 10.7071L8.35355 10.3536L8 10ZM8.5 1C8.5 0.723858 8.27614 0.5 8 0.5C7.72386 0.5 7.5 0.723858 7.5 1L8.5 1ZM2.64645 5.35355L7.64645 10.3536L8.35355 9.64645L3.35355 4.64645L2.64645 5.35355ZM8.35355 10.3536L13.3536 5.35355L12.6464 4.64645L7.64645 9.64645L8.35355 10.3536ZM8.5 10L8.5 1L7.5 1L7.5 10L8.5 10Z" fill="#FFF2E3" />
                                                            <path stroke="#FFF2E3" d="M1 11.9961L1 12.9961C1 14.1007 1.89543 14.9961 3 14.9961L13 14.9961C14.1046 14.9961 15 14.1007 15 12.9961V11.9961" />
                                                        </svg>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left" sx={this.linkStyle}>
                                                    {item.file_type}
                                                </TableCell>
                                                <TableCell align="left" sx={this.linkStyle}>
                                                    {item.submission_time}
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow sx={{ border: "0px !important" }} key={index}>
                                                <TableCell component="th" scope="row" sx={{ border: "0px !important", padding: "0px 40px 15px 0px", width: "50%", }}>
                                                    <Box sx={this.linkBox}>
                                                        <span>{index + 1}.</span>
                                                        <Box style={{ margin: "0px 10px", flex: 1, padding: "15px 5px", }}>
                                                            <a href={item.link_url} target="_blank" rel="noopener noreferrer" style={{ wordBreak: "break-all", color: "#528ADF" }}>
                                                                {item.link_url}
                                                            </a>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left" sx={this.linkStyle}>
                                                    Link
                                                </TableCell>
                                                <TableCell align="left" sx={this.linkStyle}>
                                                    {item.submission_time}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </StyledDialog>
        );
    }
}
