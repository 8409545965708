import React, { Component } from "react";
import {
    Box,
    Button,
    Dialog,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "@material-ui/core/Input";
import { getStorageData } from "../../../packages/framework/src/Utilities";

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        borderRadius: "10px",
        minWidth: "700px",
    },
    "@media (max-width: 500px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "500px",
            maxWidth: "400px",
        },
    },
});

const CustomRadio = withStyles({
    root: {
        color: "#FFF2E3",
        "&.Mui-checked": {
            color: "#FFF2E3",
        },
    },
    "& .MuiFormControlLabel-label": {
        color: "#FFF2E3 !important"
    }
})(Radio);

const CustomFormControlLabel = withStyles({
    root: {
        "& .MuiFormControlLabel-label": {
            color: "#FFF2E3 !important",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600
        }
    },
})(FormControlLabel)

const CustomTextField = withStyles({
    root: {
        "& .MuiInputBase-input": {
            backgroundColor: "#171717",
            color: "#FFF2E3",
            border: "none",
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            textAlign: "left",
            "&::placeholder": {
                color: "#676767 !important",
                opacity: 1,
                fontFamily: "Inter"
            },
            "&:focus": {
                outline: "none",
            },
            borderRadius: "8px",
            padding: "15px"
        },
        "&::before, &::after": {
            borderBottom: "none",
        },
        "&:hover:not(.Mui-disabled)::before": {
            borderBottom: "none !important",
        },
    },
})(Input);

interface AddWithdrawelModalProps {
    open: boolean;
    handleClose: () => void;
    handleAddWithDrawmethod: (data: {}) => void;
}

export default class AddWithdrawelModal extends Component<AddWithdrawelModalProps> {
    render() {
        const { handleClose, open, handleAddWithDrawmethod } = this.props;

        return (
            <StyledDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <Box
                    style={{
                        border: "1px solid #5F5F5F",
                        background: "#222222",
                        borderRadius: "10px",
                        padding: "40px",
                    }}
                >
                    <Typography
                        style={{
                            color: "#FFF2E3",
                            fontSize: "20px",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 600,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        Add Withdrawal Method
                    </Typography>

                    <Formik
                        initialValues={{
                            paymentMethod: "bank",
                            accountName: "",
                            accountNumber: "",
                            confirmAccountNumber: "",
                            ifscCode: "",
                            upiId: "",
                        }}
                        validationSchema={Yup.object().shape({
                            paymentMethod: Yup.string().required("Required"),
                            accountName: Yup.string()
                                .when("paymentMethod", {
                                    is: "bank",
                                    then: Yup.string()
                                        .required("Account name is required")
                                        .matches(/^[^\d]*$/, "Account name cannot contain numbers"),
                                }),
                            accountNumber: Yup.string().when("paymentMethod", {
                                is: "bank",
                                then: Yup.string()
                                    .matches(/^\d+$/, "Must be numbers only")
                                    .required("Account number is required"),
                            }),
                            confirmAccountNumber: Yup.string().when("paymentMethod", {
                                is: "bank",
                                then: Yup.string()
                                    .oneOf([Yup.ref("accountNumber")], "Account numbers must match")
                                    .required("Confirm account number is required"),
                            }),
                            ifscCode: Yup.string().when("paymentMethod", {
                                is: "bank",
                                then: Yup.string()
                                    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code")
                                    .required("IFSC code is required"),
                            }),
                            upiId: Yup.string().when("paymentMethod", {
                                is: "upi",
                                then: Yup.string()
                                    .matches(/^[\w.-]+@[\w.-]+$/, "Invalid UPI ID")
                                    .required("UPI ID is required"),
                            }),
                        })}
                        onSubmit={async (values) => {
                            console.log("Form Data:", values);
                            let data = {}

                                const tokens = await getStorageData("userInfo");   
                                let newToken = JSON.parse(tokens)
                                const { meta: { razorpay_contact_id = "" } } = newToken; 

                            if(values.paymentMethod === "bank") {
                                data = {
                                    "contact_id": razorpay_contact_id,
                                    "bank_ifsc_code": values.ifscCode,
                                    "bank_account_number": values.accountNumber,
                                    "bank_account_name": values.accountName,
                                    "account_type": "bank_account", 
                                }
                            } else {
                                data = {
                                    "contact_id": razorpay_contact_id,
                                    "account_type": "vpa", 
                                    "bank_address": values.upiId 
                                }
                            }
                            handleAddWithDrawmethod(data);
                            handleClose();
                        }}
                    >
                        {({ values, errors, touched, handleChange }) => (
                            <Form>
                                <RadioGroup
                                    name="paymentMethod"
                                    value={values.paymentMethod}
                                    onChange={handleChange}
                                    style={{ gap: "20px", marginBottom: "20px" }}
                                >
                                    <CustomFormControlLabel
                                        value="bank"
                                        control={<CustomRadio />}
                                        label="Bank Account"
                                    />
                                    <Field
                                        name="accountName"
                                        as={CustomTextField}
                                        placeholder="Account Name"
                                        fullWidth
                                    />
                                    {errors.accountName && touched.accountName && (
                                        <Typography color="error" style={{ marginTop: "-17px", paddingLeft: "8px" }}>{errors.accountName}</Typography>
                                    )}

                                    <Field
                                        name="accountNumber"
                                        as={CustomTextField}
                                        placeholder="Account Number"
                                        fullWidth
                                    />
                                    {errors.accountNumber && touched.accountNumber && (
                                        <Typography color="error" style={{ marginTop: "-17px", paddingLeft: "8px" }}>{errors.accountNumber}</Typography>
                                    )}

                                    <Field
                                        name="confirmAccountNumber"
                                        as={CustomTextField}
                                        placeholder="Confirm Account Number"
                                        fullWidth
                                    />
                                    {errors.confirmAccountNumber && touched.confirmAccountNumber && (
                                        <Typography color="error" style={{ marginTop: "-17px", paddingLeft: "8px" }}>
                                            {errors.confirmAccountNumber}
                                        </Typography>
                                    )}

                                    <Field
                                        name="ifscCode"
                                        as={CustomTextField}
                                        placeholder="IFSC Code"
                                        fullWidth
                                    />
                                    {errors.ifscCode && touched.ifscCode && (
                                        <Typography color="error" style={{ marginTop: "-17px", paddingLeft: "8px" }}>{errors.ifscCode}</Typography>
                                    )}
                                    <CustomFormControlLabel
                                        value="upi"
                                        control={<CustomRadio />}
                                        label="UPI ID"
                                    />
                                    <Field
                                        name="upiId"
                                        as={CustomTextField}
                                        placeholder="UPI ID"
                                        fullWidth
                                    />
                                    {errors.upiId && touched.upiId && (
                                        <Typography color="error" style={{ marginTop: "-17px", paddingLeft: "8px" }}>{errors.upiId}</Typography>
                                    )}
                                </RadioGroup>

                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <Button
                                        data-test-id="close-modal"
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            borderRadius: "7px",
                                            padding: "10px 30px",
                                            fontFamily: "'Inter', sans-serif",
                                            textTransform: "none",
                                            cursor: "pointer",
                                            marginRight: "15px",
                                            background: "#0D0D0D",
                                            color: "#FFF2E3",
                                        } as React.CSSProperties}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        data-test-id="delete-milestone-btn"
                                        type="submit"
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            borderRadius: "7px",
                                            padding: "10px 30px",
                                            fontFamily: "'Inter', sans-serif",
                                            textTransform: "none",
                                            cursor: "pointer",
                                            background: "rgba(255, 242, 227, 1)",
                                            color: "#121212",
                                            marginRight: "10px",
                                        } as React.CSSProperties}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </StyledDialog>
        );
    }
}
