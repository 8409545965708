Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfdesignersidecontractmanagement";
exports.labelBodyText = "cfdesignersidecontractmanagement Body";

exports.offerContractApiEnd = "bx_block_joblisting/proposals/offer_contract?data[attributes][proposal_id]="
exports.createContractApiEndPoint = "bx_block_dashboard/contracts"
exports.offerContractApiEnd = "bx_block_joblisting/proposals/offer_contract?data[attributes][proposal_id]="
exports.getMilestoneAPiEndPoinf = "bx_block_cfdesignersidecontractmanagement/client_contracts/display_client_contract?work_opportunity_id="
exports.btnExampleTitle = "CLICK ME";
exports.resendContractApiEndPoint = "bx_block_dashboard/contracts/resend_contract"
exports.put = "PUT"
exports.noNotificationsFoundText = "No notifications found."
exports.activeContractApiEndPoint = "bx_block_cfdesignersidecontractmanagement/client_contracts/active_contract_details?data[attributes][contract_id]="
// Customizable Area End